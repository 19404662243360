// import { createSelector } from "@reduxjs/toolkit";
// export const selectSubTotal = (couponDiscountPercentage) =>
//   createSelector(
//     (state) => ({
//       carts: state.allCart.carts,
//       flooringPrice:
//         state.allDowpdown.industry?.name !== "Specialty Cleaning"
//           ? state.flooringPrice.flooringPrice.vaccumprice
//           : 0,
//       moppingPrice:
//         state.allDowpdown.industry?.name !== "Specialty Cleaning"
//           ? state.flooringPrice.flooringPrice.moppingprice
//           : 0,
//       dustingPrice:
//         state.allDowpdown.industry?.name !== "Specialty Cleaning"
//           ? state.flooringPrice.flooringPrice.dustingprice
//           : 0,
//       flooringSubCharges: state.allDowpdown.floors?.floorCost,
//       subChargePercentage:
//         state.allDowpdown.industry?.name == "Post Construction"
//           ? state.allDowpdown.bedrooms?.subChargePercentage
//           : state.allDowpdown.industry?.subChargePercentage,
//       platformFees: state.formData.platformFees,
//       taxes: state.formData.taxes,
//     }),
//     ({
//       carts,
//       flooringPrice,
//       moppingPrice,
//       dustingPrice,
//       flooringSubCharges,
//       subChargePercentage,
//       platformFees,
//       taxes,
//     }) => {
//       console.log("platformFees", platformFees);
//       console.log("taxes", taxes);

//       // Calculate the subtotal from the cart items
//       const subtotalFromCarts = carts.reduce((acc, item) => {
//         return acc + item.price * item.quantity;
//       }, 0);

//       // Calculate additional prices
//       const additionalPrices =
//         flooringPrice +
//         moppingPrice +
//         dustingPrice +
//         Number(flooringSubCharges);

//       // Subtotal with additional prices
//       const subtotalWithAdditionalPrices = subtotalFromCarts + additionalPrices;

//       // Calculate discount amount
//       const discountPercentage = couponDiscountPercentage / 100; // Convert percentage to decimal
//       const discountAmount = subtotalWithAdditionalPrices * discountPercentage;

//       // Subtotal after applying discount
//       const subtotalAfterDiscount =
//         subtotalWithAdditionalPrices - discountAmount;

//       // Calculate industry sub-charge amount (after discount)
//       const industrySubChargeAmount = subChargePercentage
//         ? (subtotalAfterDiscount * parseFloat(subChargePercentage)) / 100
//         : 0;

//       // Subtotal after adding industry sub-charge
//       const subtotalAfterDiscountAndSubCharge =
//         subtotalAfterDiscount + industrySubChargeAmount;

//       // Calculate platform fees and taxes
//       const platformFeeAmount =
//         (subtotalAfterDiscountAndSubCharge * platformFees) / 100;
//       const taxAmount = (subtotalAfterDiscountAndSubCharge * taxes) / 100;

//       // Total amount before YFOS service fees
//       const totalAmountNoYfosFee =
//         subtotalAfterDiscountAndSubCharge + platformFeeAmount + taxAmount;

//       // YFOS service fees logic
//       const yfosServiceFees =
//         Number(totalAmountNoYfosFee.toFixed(0)) < 200 ? 200 : 0;

//       // Final total amount
//       const totalAmount = totalAmountNoYfosFee + yfosServiceFees;

//       return {
//         subTotalnoIndustryCharge: subtotalAfterDiscount,
//         subTotal: subtotalAfterDiscountAndSubCharge,
//         totalAmountNoYfosFee: totalAmountNoYfosFee,
//         totalAmount: totalAmount,
//         platformFeeAmount: platformFeeAmount,
//         taxAmount: taxAmount,
//         discountAmount: discountAmount,
//         cartItemsPrice: subtotalFromCarts,
//         totalItemPrice: subtotalWithAdditionalPrices,
//       };
//     }
//   );

import { createSelector } from "@reduxjs/toolkit";
export const selectSubTotal = (couponDiscountPercentage) =>
  createSelector(
    (state) => ({
      carts: state.allCart.carts,
      flooringPrice:
        state.allDowpdown.industry?.name !== "Specialty Cleaning"
          ? state.flooringPrice.flooringPrice.vaccumprice
          : 0,
      moppingPrice:
        state.allDowpdown.industry?.name !== "Specialty Cleaning"
          ? state.flooringPrice.flooringPrice.moppingprice
          : 0,
      dustingPrice:
        state.allDowpdown.industry?.name !== "Specialty Cleaning"
          ? state.flooringPrice.flooringPrice.dustingprice
          : 0,
      flooringSubCharges: state.allDowpdown.floors?.floorCost,
      subChargePercentage:
        state.allDowpdown.industry?.name == "Post Construction"
          ? state.allDowpdown.bedrooms?.subChargePercentage
          : state.allDowpdown.industry?.subChargePercentage,
      platformFees: state.formData.platformFees,
      taxes: state.formData.taxes,
    }),
    ({
      carts,
      flooringPrice,
      moppingPrice,
      dustingPrice,
      flooringSubCharges,
      subChargePercentage,
      platformFees,
      taxes,
    }) => {
      // Calculate the subtotal from the cart items
      const subtotalFromCarts = carts.reduce((acc, item) => {
        return acc + item.price * item.quantity;
      }, 0);

      // Calculate additional prices
      const additionalPrices =
        flooringPrice +
        moppingPrice +
        dustingPrice +
        Number(flooringSubCharges);

      // Subtotal with additional prices
      const subtotalWithAdditionalPrices = subtotalFromCarts + additionalPrices;

      // Calculate discount amount
      // const discountPercentage = couponDiscountPercentage / 100; // Convert percentage to decimal
      // const discountAmount = subtotalWithAdditionalPrices * discountPercentage;

      // Subtotal after applying discount
      // const subtotalAfterDiscount =
      //  subtotalWithAdditionalPrices - discountAmount;

      // Calculate industry sub-charge amount (after discount)
      const industrySubChargeAmount = subChargePercentage
        ? (subtotalWithAdditionalPrices * parseFloat(subChargePercentage)) / 100
        : 0;

      // Subtotal after adding industry sub-charge
      const subtotalAfterSubCharge =
        subtotalWithAdditionalPrices + industrySubChargeAmount;

      // Calculate platform fees and taxes
      const platformFeeAmount = (subtotalAfterSubCharge * platformFees) / 100;
      const taxAmount = (subtotalAfterSubCharge * taxes) / 100;

      // Total amount before YFOS service fees
      const totalAmountNoYfosFee =
        subtotalAfterSubCharge + platformFeeAmount + taxAmount;

      // YFOS service fees logic - 10% of subtotal
      const yfosServiceFees = (subtotalAfterSubCharge * 10) / 100;

      // Calculate discount amount (apply discount on subtotalAfterDiscountAndSubCharge)
      const discountPercentage = couponDiscountPercentage / 100; // Convert percentage to decimal
      const discountAmount = subtotalAfterSubCharge * discountPercentage;
      console.log("discountAmount", discountAmount);

      // Final total amount
      // const totalAmount = totalAmountNoYfosFee + yfosServiceFees;
      // Total amount including YFOS service fees
      const totalAmount =
        subtotalAfterSubCharge +
        platformFeeAmount +
        taxAmount +
        yfosServiceFees -
        discountAmount;

      return {
        subTotalnoIndustryCharge: subtotalWithAdditionalPrices,
        subTotal: subtotalAfterSubCharge,
        totalAmountNoYfosFee: totalAmountNoYfosFee,
        totalAmount: totalAmount,
        platformFeeAmount: platformFeeAmount,
        taxAmount: taxAmount,
        discountAmount: discountAmount,
        cartItemsPrice: subtotalFromCarts,
        totalItemPrice: subtotalWithAdditionalPrices,
        yfosServiceFees: yfosServiceFees,
      };
    }
  );

/// Change Order SubTotal Calculation
// export const selectChangeOrderSubTotal = (
//   couponDiscountPercentage,
//   orderData
// ) =>
//   createSelector(
//     (state) => ({
//       carts: state.allCart.carts,
//       flooringPrice:
//         state.allDowpdown.industry?.name !== "Specialty Cleaning"
//           ? state.flooringPrice.flooringPrice.vaccumprice
//           : 0,
//       moppingPrice:
//         state.allDowpdown.industry?.name !== "Specialty Cleaning"
//           ? state.flooringPrice.flooringPrice.moppingprice
//           : 0,
//       dustingPrice:
//         state.allDowpdown.industry?.name !== "Specialty Cleaning"
//           ? state.flooringPrice.flooringPrice.dustingprice
//           : 0,
//       flooringSubCharges: orderData?.floorSurcharge,
//       industrySubChargeAmount: orderData?.industrySurcharge,
//       platformFees: state.formData.platformFees,
//       taxes: state.formData.taxes,
//     }),
//     ({
//       carts,
//       flooringPrice,
//       moppingPrice,
//       dustingPrice,
//       flooringSubCharges,
//       industrySubChargeAmount,
//       platformFees,
//       taxes,
//     }) => {
//       console.log("platformFees", platformFees);
//       console.log("taxes", taxes);
//       const subtotalFromCarts = carts.reduce((acc, item) => {
//         return acc + item.price * item.quantity;
//       }, 0);

//       const additionalPrices =
//         flooringPrice +
//         moppingPrice +
//         dustingPrice +
//         Number(flooringSubCharges);
//       const subtotalWithAdditionalPrices = subtotalFromCarts + additionalPrices;

//       const discountPercentage = couponDiscountPercentage / 100; // Convert percentage to decimal
//       const discountAmount = subtotalWithAdditionalPrices * discountPercentage;

//       const subtotalAfterDiscount =
//         subtotalWithAdditionalPrices - discountAmount;
//       // Final subtotal after applying both discount and industry sub-charge
//       const subtotalAfterDiscountAndSubCharge =
//         subtotalWithAdditionalPrices -
//         discountAmount +
//         Number(industrySubChargeAmount);

//       // Platform fees and taxes
//       const platformFeeAmount =
//         (subtotalAfterDiscountAndSubCharge * platformFees) / 100;
//       const taxAmount = (subtotalAfterDiscountAndSubCharge * taxes) / 100;

//       // Final total amount
//       const totalAmountNoYfosFee =
//         subtotalAfterDiscountAndSubCharge + platformFeeAmount + taxAmount;

//       // YFOS Service Fees logic
//       const yfosServiceFess =
//         Number(totalAmountNoYfosFee?.toFixed(0)) < 200 ? 200 : 0;
//       const totalAmount = totalAmountNoYfosFee + yfosServiceFess;

//       return {
//         subTotalnoIndustryCharge: subtotalAfterDiscount,
//         subTotal: subtotalAfterDiscountAndSubCharge,
//         totalAmountNoYfosFee: totalAmountNoYfosFee,
//         totalAmount: totalAmount,
//         platformFeeAmount: platformFeeAmount,
//         taxAmount: taxAmount,
//         discountAmount: discountAmount,
//         cartItemsPrice: subtotalFromCarts,
//         totalItemPrice: subtotalWithAdditionalPrices,
//       };
//     }
//   );

/// Change Order SubTotal Calculation
export const selectChangeOrderSubTotal = (
  couponDiscountPercentage,
  orderData
) =>
  createSelector(
    (state) => ({
      state: state,
      carts: state.allCart.carts,
      flooringPrice:
        orderData.industry !== "Specialty Cleaning"
          ? state.flooringPrice.flooringPrice.vaccumprice
          : 0,
      moppingPrice:
        orderData.industry !== "Specialty Cleaning"
          ? state.flooringPrice.flooringPrice.moppingprice
          : 0,
      dustingPrice:
        orderData.industry !== "Specialty Cleaning"
          ? state.flooringPrice.flooringPrice.dustingprice
          : 0,
      flooringSubCharges: orderData?.floorSurcharge,
      industrySubChargeAmount: orderData?.industrySurcharge,
      platformFees: state.formData.platformFees,
      taxes: state.formData.taxes,
    }),
    ({
      state,
      carts,
      flooringPrice,
      moppingPrice,
      dustingPrice,
      flooringSubCharges,
      industrySubChargeAmount,
      platformFees,
      taxes,
    }) => {
      console.log("state", state);
      console.log("orderData", orderData);
      console.log("flooringPrice", flooringPrice);
      console.log("moppingPrice", moppingPrice);
      console.log("dustingPrice", dustingPrice);

      const subtotalFromCarts = carts.reduce((acc, item) => {
        return acc + item.price * item.quantity;
      }, 0);

      const additionalPrices =
        flooringPrice +
        moppingPrice +
        dustingPrice +
        Number(flooringSubCharges);

      console.log("additionalPrices", additionalPrices);

      console.log("flooringSubCharges", flooringSubCharges);

      // Subtotal before applying discounts
      const subtotalWithAdditionalPrices = subtotalFromCarts + additionalPrices;

      // Add industry sub-charge after applying the discount
      const subtotalAfterSubCharge =
        subtotalWithAdditionalPrices + Number(industrySubChargeAmount);

      // Platform fees and taxes
      const platformFeeAmount = (subtotalAfterSubCharge * platformFees) / 100;
      const taxAmount = (subtotalAfterSubCharge * taxes) / 100;

      // Total amount before YFOS service fees
      const totalAmountNoYfosFee =
        subtotalAfterSubCharge + platformFeeAmount + taxAmount;

      // YFOS service fees logic - 10% of subtotal
      const yfosServiceFees = (subtotalAfterSubCharge * 10) / 100;

      // Calculate discount amount (apply discount on subtotalAfterDiscountAndSubCharge)
      const discountPercentage = couponDiscountPercentage / 100; // Convert percentage to decimal
      const discountAmount = subtotalAfterSubCharge * discountPercentage;
      console.log("discountAmount", discountAmount);

      // Total amount including YFOS service fees
      const totalAmount =
        subtotalAfterSubCharge +
        platformFeeAmount +
        taxAmount +
        yfosServiceFees -
        discountAmount;

      return {
        subTotalnoIndustryCharge: subtotalWithAdditionalPrices,
        subTotal: subtotalAfterSubCharge,
        totalAmountNoYfosFee: totalAmountNoYfosFee,
        totalAmount: totalAmount,
        platformFeeAmount: platformFeeAmount,
        taxAmount: taxAmount,
        discountAmount: discountAmount,
        cartItemsPrice: subtotalFromCarts,
        totalItemPrice: subtotalWithAdditionalPrices,
        yfosServiceFees: yfosServiceFees,
      };
    }
  );

export const selectTotalTime = (couponDiscountPercentage) =>
  createSelector(
    (state) => ({
      carts: state.allCart.carts,
      flooringTime: state.flooringPrice.flooringPrice.vaccumtime,
      moppingTime: state.flooringPrice.flooringPrice.moppingtime,
      dustingTime: state.flooringPrice.flooringPrice.dustingtime,
      floorTime: state.allDowpdown.floors?.floorTime,
      industryTimePercentage:
        state.allDowpdown.industry?.name == "Post Construction"
          ? state.allDowpdown.bedrooms?.subChargeTime
          : state.allDowpdown.industry?.subChargeTime,
    }),
    ({
      carts,
      flooringTime,
      moppingTime,
      dustingTime,
      floorTime,
      industryTimePercentage,
    }) => {
      const totalTimeFromCart = carts.reduce((acc, item) => {
        return acc + item.time * item.quantity;
      }, 0);

      const addtionalItemTime =
        Number(flooringTime) +
        Number(moppingTime) +
        Number(dustingTime) +
        Number(floorTime);

      const timeWithAdditionalTimes = totalTimeFromCart + addtionalItemTime;

      // Calculate industry sub-charge amount
      const industryTime = industryTimePercentage
        ? (timeWithAdditionalTimes * parseFloat(industryTimePercentage)) / 100
        : 0;

      const totalTime = timeWithAdditionalTimes + industryTime;
      console.log("totalTime", totalTime);

      return {
        totalTime: totalTime,
      };
    }
  );

import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  address: false,
  addressline2: false,
  city: false,
  postalcode: false,
  date: false,
  time: false,
};

const errorSlice = createSlice({
  name: "formErrors",
  initialState,
  reducers: {
    setError(state, action) {
      const { field, value } = action.payload;
      state[field] = value;
    },
    clearErrors(state) {
      Object.keys(state).forEach((key) => {
        state[key] = false;
      });
    },
  },
});

export const { setError, clearErrors } = errorSlice.actions;
export default errorSlice.reducer;

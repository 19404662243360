import React, { useState } from "react";
import Modal from "react-bootstrap/Modal";
import "./modal.css";

const PercentageGuideModal = (props) => {
  return (
    <Modal
      {...props}
      size="lg"
      dialogClassName="confirmation-modal-main-wrapper percentage-modal-main-wrapper"
      aria-labelledby="contained-modal-title-vcenter"
      centered
    >
      <Modal.Body>
        <div className="confirm-modal-main-wrapper">
          <div className="confirm-modal-main-wrap">
            <div className="modal-logo-wrap">
              <img src="./assets/modal-logo.png" alt="modal-logo" />
            </div>

            <div className="close-icon-wrap">
              <img
                onClick={() => props.onHide()}
                src="./assets/icons/close-icon.svg"
                alt="icon"
              />
            </div>

            <div className="percentage-modal-content-wrap">
              <div className="percentage-modal-image">
                <h4> % </h4>
              </div>
              <h3>Select Cleaning Percentages</h3>
              <div className="percentage-item-warp">
                <p> 50%</p>
                <img src="./assets/icons/mopping-icon.png" alt="icon" />
              </div>
              <p>
                Please adjust the sliders to set the cleaning percentages for
                each service. For example, if you choose 50% for vacuuming and
                50% for mopping, your space will be evenly divided between the
                two services. Use the second slider to indicate the percentage
                of dusting needed.
                <span>
                  (Slide left or right to adjust the percentage for each
                  service.)
                </span>
              </p>
            </div>
          </div>
        </div>
      </Modal.Body>
    </Modal>
  );
};
export default PercentageGuideModal;

import React, { Fragment, useEffect, useState } from "react";
import "./mobilecart.css";
import PercentageItem from "../components/PercentageItem";
import { useSelector } from "react-redux";
import { useDispatch } from "react-redux";
import {
  addToCart,
  removeToCart,
  updateFlooringPercentage,
  updateMoppingPercentage,
  updateDustingPercentage,
} from "../../../redux/features/cartSlice";
import ProductItem from "../components/ProductItem";
import Button from "../../UIComponents/Button";
import { useNavigate } from "react-router-dom";
import { updateFlooringPrices } from "../../../redux/features/flooringPriceSlice";
import { servicePost } from "../../../helper/api";

const MobileCart = () => {
  const cartItems = useSelector((state) => state.allCart.carts);
  const percentageData = useSelector((state) => state.allCart);
  const dropdownValues = useSelector((state) => state.allDowpdown);
  const formData = useSelector((state) => state.formData);
  const serviceType = useSelector((state) => state.serviceType.serviceType);

  // get AuthToken from localStorage
  const authToken = localStorage.getItem("token");
  const notification = "";

  const flooringPrices = useSelector(
    (state) => state.flooringPrice.flooringPrice
  );

  const [floorinPercentage, setFlooringPercentage] = useState(
    percentageData.flooringPercentage
  );

  const [moppingPercentage, setMoppingPercentage] = useState(
    percentageData.moppingPercentage
  );

  const [dustingPercentage, setDustingPercentage] = useState(
    percentageData.dustingPercentage
  );

  /// Add to cart
  const dispatch = useDispatch();
  const handleIncrement = (data) => {
    dispatch(addToCart(data));
  };

  const handleDecrement = (data) => {
    dispatch(removeToCart(data));
  };

  // Increment Flooring Percentage (Decrement Mopping Percentage)
  const incrementFlooringPercentage = () => {
    setFlooringPercentage((prevFlooringPercentage) => {
      if (prevFlooringPercentage < 100) {
        const newFlooringPercentage = prevFlooringPercentage + 1;
        const newMoppingPercentage = 100 - newFlooringPercentage;
        dispatch(updateFlooringPercentage(newFlooringPercentage));
        dispatch(updateMoppingPercentage(newMoppingPercentage));
        setMoppingPercentage(newMoppingPercentage); // Ensure both states are updated together
        return newFlooringPercentage;
      }
      return prevFlooringPercentage;
    });
  };

  // Decrement Flooring Percentage (Increment Mopping Percentage)
  const decrementFlooringPercentage = () => {
    setFlooringPercentage((prevFlooringPercentage) => {
      if (prevFlooringPercentage > 0) {
        const newFlooringPercentage = prevFlooringPercentage - 1;
        const newMoppingPercentage = 100 - newFlooringPercentage;
        dispatch(updateFlooringPercentage(newFlooringPercentage));
        dispatch(updateMoppingPercentage(newMoppingPercentage));
        setMoppingPercentage(newMoppingPercentage); // Ensure both states are updated together
        return newFlooringPercentage;
      }
      return prevFlooringPercentage;
    });
  };

  // Increment Mopping Percentage (Decrement Flooring Percentage)
  const incrementMoppingPercentage = () => {
    setMoppingPercentage((prevMoppingPercentage) => {
      if (prevMoppingPercentage < 100) {
        const newMoppingPercentage = prevMoppingPercentage + 1;
        const newFlooringPercentage = 100 - newMoppingPercentage;
        dispatch(updateMoppingPercentage(newMoppingPercentage));
        dispatch(updateFlooringPercentage(newFlooringPercentage));
        setFlooringPercentage(newFlooringPercentage); // Ensure both states are updated together
        return newMoppingPercentage;
      }
      return prevMoppingPercentage;
    });
  };

  // Decrement Mopping Percentage (Increment Flooring Percentage)
  const decrementMoppingPercentage = () => {
    setMoppingPercentage((prevMoppingPercentage) => {
      if (prevMoppingPercentage > 0) {
        const newMoppingPercentage = prevMoppingPercentage - 1;
        const newFlooringPercentage = 100 - newMoppingPercentage;
        dispatch(updateMoppingPercentage(newMoppingPercentage));
        dispatch(updateFlooringPercentage(newFlooringPercentage));
        setFlooringPercentage(newFlooringPercentage); // Ensure both states are updated together
        return newMoppingPercentage;
      }
      return prevMoppingPercentage;
    });
  };

  // Increment Flooring Percentage
  const incrementDustingPercentage = () => {
    setDustingPercentage((prevDustingPercentage) => {
      if (prevDustingPercentage < 100) {
        const newDustingPercentage = prevDustingPercentage + 1;
        dispatch(updateDustingPercentage(newDustingPercentage));
        return newDustingPercentage;
      }
      return prevDustingPercentage; // No change if it already is 100
    });
  };

  // Decrement Flooring Percentage
  const decrementDustingPercentage = () => {
    setDustingPercentage((prevDustingPercentage) => {
      if (prevDustingPercentage > 0) {
        const newDustingPercentage = prevDustingPercentage - 1;
        dispatch(updateDustingPercentage(newDustingPercentage));
        return newDustingPercentage;
      }
      return prevDustingPercentage; // No change if it already is 0
    });
  };

  const getSqFeetPrice = async () => {
    try {
      const sqFeetPriceRes = await servicePost(
        "booking/getSqFeetData",
        {
          vaccum: floorinPercentage,
          mopping: moppingPercentage,
          dusting: dustingPercentage,
          squarefeet: percentageData.squreFeetPercentage,
          serviceType: serviceType,
        },
        notification,
        authToken
      );

      dispatch(updateFlooringPrices(sqFeetPriceRes.data[0]));
    } catch (error) {
      console.log("error", error);
    }
  };

  useEffect(() => {
    getSqFeetPrice();
  }, [percentageData]);

  const navigate = useNavigate();
  const goToBookingConfirmation = () => {
    if (
      serviceType == "Commercial" &&
      dropdownValues?.industry.name !== "Specialty Cleaning"
    ) {
      navigate("/commercial-services-mobile");
    } else if (
      serviceType == "Residential" &&
      dropdownValues?.industry.name !== "Specialty Cleaning"
    ) {
      navigate("/services-mobile");
    } else {
      navigate("/special-services");
    }
  };

  return (
    <div className="mobile-cart-main-wrapper">
      <div className="mobile-cart-main-wrap container">
        {cartItems?.length === 0 ? (
          <div className="noting-found-main-wrapper">
            <div className="noting-found-main-wrap nothing-found-cart-wrap">
              <img src="./assets/icons/empty-cart.png" alt="empty-cart" />
              <p>Nothing Found</p>
            </div>
          </div>
        ) : (
          <>
            <h3> My Cart</h3>
            <div className="mobile-cart-square-feet-wrap">
              <div className="cart-square-image">
                <img src="./assets/service-1.png" alt="img" />
              </div>
              <h4> Retail, 1 Floor, 1 Bedroom </h4>
              <div className="booking-squarefeet-wrap">
                <p> Square Feet of your Building </p>
                <span> {percentageData.squreFeetPercentage} Sq</span>
              </div>
            </div>

            <div className="mobile-booking-cart-items-wrap">
              <h5> Items </h5>
              <div className="booking-cart-flooring-wrap">
                <div className="accordion" id="accordionExample">
                  <div className="accordion-item" data-accordion-item>
                    <h2 className="accordion-header" id="headingOne">
                      <button
                        className="accordion-button"
                        type="button"
                        data-bs-toggle="collapse"
                        data-bs-target="#collapseOne"
                        aria-expanded="false"
                        aria-controls="collapseOne"
                      >
                        <div className="accordion-btn-left-wrap">
                          Cleaning Items
                        </div>
                        {/* <div className="accordion-btn-right-wrap">$100</div> */}
                      </button>
                    </h2>
                    <div
                      id="collapseOne"
                      className="accordion-collapse collapse"
                      aria-labelledby="headingOne"
                      data-bs-parent="#accordionExample"
                    >
                      <div className="accordion-body">
                        <div className="mobile-accordion-body-wrapper">
                          <div className="mobile-flooring-item-wrap">
                            <PercentageItem
                              image="./assets/icons/vacuum-icon.png"
                              category="Flooring:"
                              label="Vacuum Cleaning"
                              percentage={percentageData.flooringPercentage}
                              handleIncementPercentage={
                                incrementFlooringPercentage
                              }
                              handleDecrementPercentage={
                                decrementFlooringPercentage
                              }
                              price={flooringPrices?.vaccumprice}
                            />

                            <PercentageItem
                              image="./assets/icons/mopping-icon.png"
                              category="Flooring:"
                              label="Mopping or sweeping"
                              percentage={percentageData.moppingPercentage}
                              handleIncementPercentage={
                                incrementMoppingPercentage
                              }
                              handleDecrementPercentage={
                                decrementMoppingPercentage
                              }
                              price={flooringPrices?.moppingprice}
                            />

                            <PercentageItem
                              image="./assets/dusting-slider.png"
                              category="Dusting:"
                              label="Dusting:"
                              percentage={percentageData.dustingPercentage}
                              handleIncementPercentage={
                                incrementDustingPercentage
                              }
                              handleDecrementPercentage={
                                decrementDustingPercentage
                              }
                              price={flooringPrices?.dustingprice}
                            />

                            {cartItems?.map((item, index) => {
                              return (
                                <Fragment key={index}>
                                  <ProductItem
                                    item={item}
                                    handleIncrement={handleIncrement}
                                    handleDecrement={handleDecrement}
                                  />
                                </Fragment>
                              );
                            })}
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="mobile-cart-footer-wrap container">
              <Button onClick={goToBookingConfirmation} label="Continue" />
            </div>
          </>
        )}
      </div>
    </div>
  );
};

export default MobileCart;

import React, { Fragment, useState, useEffect, useRef } from "react";
import "./dropdown.css";

const Dropdown = ({
  onTypeChange,
  data,
  label,
  alreadyValue,
  checkIcon,
  disabled,
  onClick,
  extraClass,
}) => {
  const [isStateActive, setIsStateActive] = useState(false);
  const [stateSelected, setStateSelected] = useState(
    alreadyValue.name ? alreadyValue.name : "Select"
  );

  const [selectedImage, setSelectedImage] = useState(
    alreadyValue?.activeImageUrl ? alreadyValue.activeImageUrl : ""
  );
  const [dropUp, setDropUp] = useState(false);
  const dropdownRef = useRef(null);

  useEffect(() => {
    setStateSelected(alreadyValue?.name ? alreadyValue.name : "Select");
    setSelectedImage(
      alreadyValue?.activeImageUrl ? alreadyValue.activeImageUrl : ""
    );
  }, [alreadyValue]);

  const checkDropdownPosition = () => {
    if (dropdownRef.current) {
      const rect = dropdownRef.current.getBoundingClientRect();
      const windowHeight = window.innerHeight;

      if (rect.bottom + 300 > windowHeight) {
        setDropUp(true);
      } else {
        setDropUp(false);
      }
    }
  };

  useEffect(() => {
    if (isStateActive) {
      checkDropdownPosition();
    }
  }, [isStateActive]);

  return (
    <div
      className={
        extraClass ? `${extraClass} select-box-wrapper` : "select-box-wrapper"
      }
      ref={dropdownRef}
    >
      <label> {label} </label>
      <div className="react-dropdown">
        <div
          onClick={onClick}
          id={disabled ? "disabledDropdown" : ""}
          className={
            extraClass
              ? `${extraClass} react-dropdown-btn`
              : "react-dropdown-btn"
          }
          onMouseEnter={(e) => {
            e.stopPropagation();
            setIsStateActive(disabled ? false : true);
          }}
          onMouseLeave={(e) => {
            e.stopPropagation();
            setIsStateActive(false);
          }}
        >
          <div
            className={`selected-item ${
              stateSelected?.startsWith("Select") ? "placeholderText" : ""
            }`}
          >
            {selectedImage && <img src={selectedImage} alt="icon" />}
            <p>{stateSelected}</p>
            {selectedImage && checkIcon && (
              <img className="checkdrop-icon" src={checkIcon} alt="icon" />
            )}
          </div>

          <img
            className={
              isStateActive
                ? "dropdown-down-arrow rotate-arrow"
                : "dropdown-down-arrow"
            }
            src="../assets/icons/dropdown-arrow.svg"
            alt="arrow"
          />
        </div>
        <div
          className="dropdown-space-manage"
          onMouseEnter={(e) => {
            e.stopPropagation();
            setIsStateActive(true);
          }}
          onMouseLeave={(e) => {
            e.stopPropagation();
            setIsStateActive(false);
          }}
        ></div>
        {isStateActive && (
          <div
            className={`dropdown-content ${dropUp ? "drop-up" : ""}`}
            onMouseEnter={(e) => {
              e.stopPropagation();
              setIsStateActive(true);
            }}
            onMouseLeave={(e) => {
              e.stopPropagation();
              setIsStateActive(false);
            }}
          >
            {data &&
              data.map((option, index) => {
                return (
                  <Fragment key={index}>
                    <div
                      onClick={(e) => {
                        setStateSelected(option.name);
                        setSelectedImage(option.activeImageUrl);
                        setIsStateActive(false);
                        onTypeChange({
                          name: option.name,
                          activeImageUrl: option.activeImageUrl,
                          imageUrl: option.imageUrl,
                          subChargePercentage: option.surchargeInPercetage,
                          subChargeTime: option.surchargeTimePercetage,
                          floorCost: option.cost,
                          floorTime: option.time,
                          price: option.price,
                          time: option.time,
                          pricePercentage: option.pricePercentage,
                          timePercentage: option.timePercentage,
                        }); // Call callback function
                      }}
                      className={
                        option.name === stateSelected
                          ? "dropdown-item active"
                          : "dropdown-item"
                      }
                    >
                      <div className="dropdown-options">
                        {option.name === stateSelected
                          ? option.activeImageUrl && (
                              <img src={option.activeImageUrl} alt="icon" />
                            )
                          : option.imageUrl && (
                              <img src={option.imageUrl} alt="icon" />
                            )}
                        <p
                          className={`${
                            option.name === stateSelected
                              ? "option-active"
                              : "option-normal"
                          }`}
                        >
                          {option.name}
                        </p>
                      </div>
                    </div>
                  </Fragment>
                );
              })}
          </div>
        )}
      </div>
    </div>
  );
};

export default Dropdown;

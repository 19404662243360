// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `/* .slick-track {
    transform: none !important; 
    will-change: unset; 
  } */
  

  .slick-track {
    transition: transform 0.3s ease !important; /* Ensure smooth transition */
  }`, "",{"version":3,"sources":["webpack://./src/components/cardItem/extraitem.css"],"names":[],"mappings":"AAAA;;;KAGK;;;EAGH;IACE,0CAA0C,EAAE,6BAA6B;EAC3E","sourcesContent":["/* .slick-track {\r\n    transform: none !important; \r\n    will-change: unset; \r\n  } */\r\n  \r\n\r\n  .slick-track {\r\n    transition: transform 0.3s ease !important; /* Ensure smooth transition */\r\n  }"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;

import React, { Fragment, useState } from "react";
import { Table } from "react-bootstrap";
import "./drawer.css";
import { useMediaQuery } from "react-responsive";
import { useNavigate } from "react-router-dom";
import moment from "moment";
import { setOrderBookingId } from "../../redux/features/bookingSlice";
import { useDispatch } from "react-redux";

const HistoryDrawer = ({ data, onHide, title }) => {
  const dispatch = useDispatch();
  console.log("data", data);

  const isMobile = useMediaQuery({ maxWidth: 1024 });
  const navigate = useNavigate();
  const goToTrackOrder = (bookingId) => {
    dispatch(setOrderBookingId(bookingId));
    navigate(`/tracking-order/${bookingId}`);
  };

  // Get Status Images According status
  const getStatusImage = (status) => {
    if (status === "Processing") {
      return <img src="./assets/icons/processing.png" alt="processing" />;
    } else if (status === "Booking Confirmed") {
      return <img src="./assets/icons/processing.png" alt="processing" />;
    } else if (status === "Completed") {
      return <img src="./assets/icons/complete.png" alt="complete" />;
    } else if (status === "Booking Cancelled") {
      return <img src="./assets/icons/cancel.png" alt="cancel" />;
    } else {
      return <img src="./assets/icons/processing.png" alt="pending" />;
    }
  };

  return (
    <>
      {!isMobile ? (
        <div className="history-list-drawer-table">
          <div className="drawer-main-header-wrap">
            <h4> {title} </h4>
            <div className="drawer-close-icon">
              <img
                onClick={onHide}
                src="./assets/icons/close-icon.svg"
                alt="close-icon"
              />
            </div>
          </div>

          <Table responsive hover>
            <thead>
              <tr>
                <th>Items</th>
                <th>Schedule</th>
                <th> Amount </th>
                <th>Status</th>
                <th>View</th>
              </tr>
            </thead>
            <tbody>
              {data?.length === 0 ? (
                <tr>
                  <td
                    style={{ textAlign: "center", fontSize: "18px" }}
                    colSpan={8}
                  >
                    No Data Found
                  </td>
                </tr>
              ) : (
                data?.map((data, index) => {
                  return (
                    <Fragment key={index}>
                      <tr>
                        <td>
                          <div className="history-items-wrapper">
                            <div className="history-item-images-wrapper">
                              {/* <img
                                className="main-image"
                                src="./assets/service-1.png"
                                alt="icon"
                              /> */}
                              <div className="history-item-images-holder">
                                {/* <div className="history-item-image-box">
                                  <img src="./assets/extra-1.png" alt="icon" />
                                </div>
                                <div className="history-item-image-box">
                                  <img src="./assets/extra-2.png" alt="icon" />
                                </div>
                                <div className="history-item-image-box">
                                  <img src="./assets/extra-3.png" alt="icon" />
                                </div> */}
                                {data?.cartItems?.length > 0 ? (
                                  data?.cartItems
                                    ?.slice(0, 3)
                                    ?.map((item, index) => (
                                      <div
                                        className="history-item-image-box"
                                        key={index}
                                      >
                                        <img
                                          src={item.itemImage}
                                          alt={item.name}
                                        />
                                      </div>
                                    ))
                                ) : (
                                  <div className="notification-item-staticImg">
                                    <img
                                      src="../assets/service-1.png"
                                      alt="img"
                                    />
                                  </div>
                                )}
                              </div>
                              <div className="items-hold-wrap">
                                <p>
                                  {data?.industry}
                                  {data?.floors && ","} {data?.floors}{" "}
                                  {data?.bedrooms && ","}
                                  {data?.bedrooms}
                                </p>
                                {data?.bedrooms && <span>+1</span>}
                              </div>
                            </div>
                          </div>
                        </td>
                        <td className="color-primary">
                          {moment(data?.scheduleDate).format("DD MMM, YYYY")}
                        </td>
                        <td>${data.total}</td>
                        <td>
                          <div className="history-centered-icon history-status-image">
                            {data?.cancelStatus ? (
                              <img
                                src="./assets/icons/cancel.png"
                                alt="status-icon"
                              />
                            ) : (
                              getStatusImage(data?.bookingStatus)
                            )}
                          </div>
                        </td>
                        <td>
                          <div className="history-centered-icon">
                            <img
                              className="table-view-icon"
                              onClick={() => goToTrackOrder(data?.bookingId)}
                              src="./assets/icons/view-icon.svg"
                              alt="icon"
                            />
                          </div>
                        </td>
                      </tr>
                    </Fragment>
                  );
                })
              )}
            </tbody>
          </Table>
        </div>
      ) : (
        <div className="history-list-drawer-table">
          <div className="drawer-main-header-wrap">
            <h4> {title} </h4>
            <div className="drawer-close-icon">
              <img
                onClick={onHide}
                src="./assets/icons/close-icon.svg"
                alt="close-icon"
              />
            </div>
          </div>

          <Table responsive hover>
            <thead>
              <tr>
                <th>Items</th>
                <th> Amount </th>
                <th>View</th>
              </tr>
            </thead>
            <tbody>
              {data?.length === 0 ? (
                <tr>
                  <td
                    style={{ textAlign: "center", fontSize: "18px" }}
                    colSpan={8}
                  >
                    No Data Found
                  </td>
                </tr>
              ) : (
                data?.map((data, index) => {
                  return (
                    <Fragment key={index}>
                      <tr>
                        <td>
                          <div className="items-hold-wrap">
                            <p>
                              {data?.industry}, {data?.floors}, {data?.bedrooms}{" "}
                            </p>
                            <span>+1</span>
                          </div>
                        </td>

                        <td> ${data.total} </td>

                        <td>
                          <div className="table-view-icon-wrap">
                            <img
                              className="table-view-icon"
                              onClick={() => goToTrackOrder(data?.bookingId)}
                              src="./assets/icons/view-icon.svg"
                              alt="icon"
                            />
                          </div>
                        </td>
                      </tr>
                    </Fragment>
                  );
                })
              )}
            </tbody>
          </Table>
        </div>
      )}
    </>
  );
};

export default HistoryDrawer;

import React from "react";
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import { useSelector } from "react-redux";
import OverlayTrigger from "react-bootstrap/OverlayTrigger";
import Tooltip from "react-bootstrap/Tooltip";
import { useMediaQuery } from "react-responsive";

const CommercialCardItem = ({ data, addItems, removeItem }) => {
  // Define media queries
  const isLaptop = useMediaQuery({ minWidth: 1200, maxWidth: 1599 });
  const smallLaptop = useMediaQuery({ minWidth: 1024, maxWidth: 1199 });
  const isTabs = useMediaQuery({ minWidth: 768, maxWidth: 1023 });

  const carts = useSelector((state) => state.allCart.carts);

  const CustomPrevArrow = ({ currentSlide, slideCount, ...props }) => {
    return (
      currentSlide !== 0 && (
        <div {...props} className="slick-arrow slick-prev">
          <img src="./assets/icons/previous-arrow.svg" alt="Prev" />
        </div>
      )
    );
  };

  const CustomNextArrow = ({ currentSlide, slideCount, ...props }) => {
    return (
      currentSlide + 4 < slideCount && (
        <div {...props} className="slick-arrow slick-next">
          <img src="./assets/icons/back-arrow.svg" alt="Next" />
        </div>
      )
    );
  };

  // Determine the number of static and slice items based on media queries
  const maxStaticItems = isLaptop ? 3 : smallLaptop ? 2 : 4;

  const settings = {
    dots: false,
    infinite: false,
    autoplay: false,
    speed: 300,
    slidesToShow: 4,
    slidesToScroll: 1,
    responsive: [
      {
        breakpoint: 1599,
        settings: {
          slidesToShow: 3,
          slidesToScroll: 1,
          infinite: true,
          dots: false,
        },
      },
      {
        breakpoint: 1199,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 1,
          infinite: true,
          dots: false,
        },
      },

      {
        breakpoint: 1023,
        settings: {
          slidesToShow: 3,
          slidesToScroll: 1,
          infinite: true,
          dots: false,
        },
      },

      {
        breakpoint: 600,
        settings: {
          slidesToShow: 2.5,
          slidesToScroll: 1,
        },
      },
      {
        breakpoint: 480,
        settings: {
          slidesToShow: 2.4,
          slidesToScroll: 1,
        },
      },
    ],
    prevArrow: <CustomPrevArrow />,
    nextArrow: <CustomNextArrow />,
  };

  return (
    <>
      <div className="service-item-card-wrapper additional-services-card-wrap">
        <div className="service-item-grid">
          {data.slice(0, maxStaticItems).map((data, index) => {
            const cartItem = carts.find((cartItem) => cartItem.id === data.id);
            return (
              <div className="service-item-card-wrap" key={index}>
                <div className="info-icon-wrap">
                  <OverlayTrigger
                    placement="top"
                    delay={{ show: 250, hide: 400 }}
                    overlay={<Tooltip id={`tooltip-up`}>{data.info}</Tooltip>}
                  >
                    <img src="./assets/icons/info.svg" alt="info-icon" />
                  </OverlayTrigger>
                </div>

                <div className="service-item-image">
                  <div className="service-item-img">
                    <img src={data.itemImage} alt="img" />
                  </div>

                  <div className="add-cart-btn-wrapper">
                    <div
                      className={`add-cart-btn-wrap ${
                        cartItem?.isAdded ? "expanded" : ""
                      }`}
                    >
                      {cartItem?.isAdded && (
                        <>
                          {cartItem?.quantity == 1 ? (
                            <div
                              className="card-delet-icon"
                              onClick={() => removeItem(data)}
                            >
                              <img
                                className="delete-icon"
                                src="./assets/icons/delete.svg"
                                alt="icon"
                              />
                            </div>
                          ) : (
                            <div
                              className="card-delet-icon"
                              onClick={() => removeItem(data)}
                            >
                              <img
                                className="delete-icon decrement-icon"
                                src="./assets/icons/decrement.svg"
                                alt="icon"
                              />
                            </div>
                          )}

                          <span className="quantity">{cartItem?.quantity}</span>
                        </>
                      )}
                      <div
                        className="plus-icon-wrap"
                        onClick={() => addItems(data)}
                      >
                        <img
                          className="plus-icon"
                          src="./assets/icons/plus.svg"
                          alt="icon"
                        />
                      </div>
                    </div>
                  </div>
                </div>

                <div className="service-item-card-desc">
                  <h6> {data.subCategory} </h6>
                  <p> {data.name} </p>
                </div>
              </div>
            );
          })}
        </div>
      </div>
    </>
  );
};

export default CommercialCardItem;

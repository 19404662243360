import React, { useState } from "react";
import Modal from "react-bootstrap/Modal";
import "./modal.css";
import Button from "../UIComponents/Button";
import { useNavigate } from "react-router-dom";
import OrderCompleteModal from "./OrderCompleteModal";
import { loadStripe } from "@stripe/stripe-js";
import { useDispatch, useSelector } from "react-redux";
import { servicePost } from "../../helper/api";
import {
  selectSubTotal,
  selectTotalTime,
} from "../../redux/features/selectors";
import { emptyCartItem } from "../../redux/features/cartSlice";
import { resetCoupon } from "../../redux/features/couponSlice";
import { resetSpecificFormData } from "../../redux/features/formSlice";
import { resetSpecificDropdown } from "../../redux/features/dropdownSlice";
import { setOrderBookingId } from "../../redux/features/bookingSlice";
import { setNotificationDep } from "../../redux/features/notificationDepSlice";
import { setOrderData } from "../../redux/features/orderChangeSlice";
import { clearFlooringPrices } from "../../redux/features/flooringPriceSlice";

const TermModalChangeOrder = (props) => {
  const [isLoading, setIsLoading] = useState(false);
  const [showConfirmModal, setShowComfirmModal] = useState(false);
  const [selectedReason, setSelectedReason] = useState("");
  const [termError, setTermError] = useState(false);
  const stripePublicKey = process.env.REACT_APP_STRIPE_KEY;

  console.log("props.paywith", props.orderData);

  // get Information from the redux
  const logedInUser = useSelector((state) => state.user.userInfo);
  const cartItems = useSelector((state) => state.allCart.carts);
  const percentageData = useSelector((state) => state.allCart);
  const dropdownValues = useSelector((state) => state.allDowpdown);
  const formData = useSelector((state) => state.formData);
  const serviceType = useSelector((state) => state.serviceType.serviceType);
  const couponData = useSelector((state) => state.coupon);
  const notificationDep = useSelector(
    (state) => state.notificationDep.notificationDep
  );

  const otpAccess = formData.otpAccess == "send-otp" ? true : false;
  const currentDate = new Date();
  const bookingDate = currentDate.toLocaleDateString();
  const bookingTime = currentDate.toLocaleTimeString();

  // get AuthToken from localStorage
  const authToken = localStorage.getItem("token");
  const notification = "";

  const dispatch = useDispatch();
  const navigate = useNavigate();
  const subtotalSelector = selectSubTotal(couponData?.couponDiscountPercentage);
  const {
    subTotal,
    discountAmount,
    cartItemsPrice,
    totalAmount,
    platformFeeAmount,
    taxAmount,
  } = useSelector(subtotalSelector);

  const FeesAndTexAmount = platformFeeAmount + taxAmount;

  // Get totalTime cartItem time + flooring + dusting time
  const totalTimeSelector = selectTotalTime();
  const { totalTime } = useSelector(totalTimeSelector);

  // Handle Check Term Condition
  const handleReasonChange = (event) => {
    if (selectedReason === event.target.value) {
      setSelectedReason("");
    } else {
      setTermError(false);
      setSelectedReason(event.target.value);
    }
  };

  // payment Handler Case of Simple Payment
  const makePayment = async () => {
    const stripe = await loadStripe(
      "pk_test_51Pl3GO2M2pNXvfFREUGzISqkYtTKwdQfsNg9MF3fbIC0nBnBTVdJM3CL1tDWCmINSL59HKdoqDSHb6RXcjfY5xk3009PSbqKVK"
    );

    try {
      setIsLoading(true);
      const response = await servicePost(
        "user/api/create-checkout-session",
        {
          products: "",
          bookingData: props.bookingData,
        },
        notification,
        authToken
      );

      const result = stripe.redirectToCheckout({
        sessionId: response.id,
      });
      dispatch(setOrderData(props.orderData));
      setIsLoading(false);
      if (result.error) {
        console.log(result.error);
        setIsLoading(false);
      }
    } catch (error) {
      console.error("Error creating payment session:", error);
      setIsLoading(false);
    }
  };

  // Handle Payment with Postpaid
  const handlePostpaidPayment = async () => {
    try {
      setIsLoading(true);
      const bookingResponse = await servicePost(
        "booking/updateChangeBookingData",
        {
          paymentIntent: null,
          sessionId: "",
          userPaymentId: "",
          accountId: props.orderData?.accountId,
          couponCode: couponData?.couponCode ? couponData?.couponCode : "",
          serviceType: props.orderData?.serviceType,
          industry: props.orderData?.industry,
          floors: props.orderData?.floors,
          bedrooms: props.orderData?.bedrooms,
          bookingId: props.orderData?.bookingId,
          squireFeet:
            props.orderData?.industry !== "Specialty Cleaning"
              ? percentageData?.squreFeetPercentage
              : null,
          contactPersonName: formData?.contactname,
          contactPersonNumber: formData?.contactnumber,
          vacummCleaning: percentageData.flooringPercentage,
          moppingCleaning: percentageData.moppingPercentage,
          vacummCleaningPrice: percentageData?.flooringPrice.toFixed(2),
          moppingCleaningPrice: percentageData?.moppingPrice.toFixed(2),
          dusting: percentageData.dustingPercentage,
          dustingPrice: percentageData?.dustingPrice.toFixed(2),
          recurring: "",
          address: props.orderData?.address,
          city: props.orderData?.city,
          postalCode: props.orderData?.postalCode,
          scheduleDate: props.orderData?.scheduleDate,
          scheduleTime: props.orderData?.scheduleTime,
          paymentMethod: props.orderData?.paymentMethod,
          price: cartItemsPrice,
          discount: discountAmount.toFixed(2),
          subTotal: subTotal.toFixed(2),
          fees: FeesAndTexAmount,
          total: totalAmount.toFixed(2),
          companyAddress: "Company Address",
          bookingStatus: "Processing",
          bookingTime: bookingTime,
          bookingDate: bookingDate,
          cleanerPartnerStatus: props.orderData?.cleanerPartnerStatus,
          assignedTime: "",
          assignedDate: "",
          arrivedStatus: props.orderData?.arrivedStatus,
          arrivedTime: props.orderData?.arrivedTime,
          arrivedDate: props.orderData?.arrivedDate,
          otp: props.orderData?.otp,
          otpAccess: true,
          cleanigServiceStatus: "",
          reviewReating: "",
          reviewText: "",
          cartItems: cartItems,
          totalTime: totalTime,
        },
        "Order Updated",
        authToken
      );

      setIsLoading(false);

      if (bookingResponse.message === "SUCCESS") {
        dispatch(emptyCartItem());
        dispatch(resetCoupon());
        dispatch(
          resetSpecificFormData([
            "squareFeet",
            "scheduleDate",
            "scheduleTime",
            "sessionStartTime",
            "contactname",
            "contactnumber",
            "otpAccess",
            "paywith",
          ])
        );
        dispatch(clearFlooringPrices());
        dispatch(resetSpecificDropdown(["industry", "floors", "bedrooms"]));
        dispatch(setOrderBookingId(props.orderData?.bookingId));
        dispatch(setNotificationDep(notificationDep + 1));

        props.onHide();
        setShowComfirmModal(true);

        setIsLoading(false);
      }
    } catch (error) {
      console.log("err", error);
      setIsLoading(false);
    }
  };

  const handlePaymetMethod = () => {
    if (!selectedReason) {
      setTermError(true);
      return;
    }
    if (props.paywith == "Postpaid") {
      handlePostpaidPayment();
    } else {
      makePayment();
    }
  };

  return (
    <>
      <OrderCompleteModal
        orderData={props.orderData}
        show={showConfirmModal}
        onHide={() => setShowComfirmModal(false)}
      />

      <Modal
        {...props}
        size="lg"
        dialogClassName="term-modal-main-wrapper"
        aria-labelledby="contained-modal-title-vcenter"
        centered
      >
        <Modal.Body>
          <div className="confirm-modal-main-wrapper">
            <div className="term-modal-main-wrap">
              <div className="close-icon-wrap">
                <img
                  onClick={() => props.onHide()}
                  src="./assets/icons/close-icon.svg"
                  alt="icon"
                />
              </div>

              <div className="term-modal-content-wrap">
                <div className="term-modal-info-head">
                  <img src="./assets/icons/info.svg" alt="icon" />
                  {props.paywith && (
                    <p className="term-modal-paywith">Payment</p>
                  )}
                </div>

                <h3>{props.title}</h3>

                <p>
                  Please ensure that all selected items are approved by you
                  before making payment. If you wish to cancel, modify, or add
                  additional items after our cleaner has arrived at your
                  location, a cancellation fee of $40 will apply.
                </p>
              </div>
              <div className="term-condition-content">
                <p> - Cancellation Charges $40 </p>
                <p> - Cancellation Refund Credited in 3-5 Business days </p>
              </div>

              <div className="pay-modal-term-wrapper">
                <form>
                  <div className="term-modal-checkmark-wrap">
                    <label className="checkbox-container">
                      <input
                        type="checkbox"
                        id="terms-condition"
                        name="cancel-reason"
                        value="terms-condition"
                        onChange={handleReasonChange}
                        checked={selectedReason === "terms-condition"}
                      />
                      <span
                        className={
                          termError ? "error-msg checkmark" : "checkmark"
                        }
                      ></span>
                    </label>
                    <p>
                      I agree this <a> Terms & Condiations </a>
                    </p>
                  </div>
                </form>
                <div className="pay-button-wrapper">
                  <Button
                    isLoading={isLoading}
                    extraClass={!selectedReason ? "disabled-button" : ""}
                    // label={props.paywith ? `Pay with ${props.paywith}` : "Pay"}
                    label="Pay"
                    onClick={handlePaymetMethod}
                  />
                </div>
              </div>
            </div>
          </div>
        </Modal.Body>
      </Modal>
    </>
  );
};
export default TermModalChangeOrder;

import React, { useState, useRef } from "react";
import "./TimePicker.css";

const TimePicker = ({ disabled, scheduleTime }) => {
  const [isOpen, setIsOpen] = useState(false);
  const [selectedTime, setSelectedTime] = useState("");
  const timePickableRef = useRef(null);
  console.log("selectedTime", selectedTime);

  const handleTimePickableFocus = () => {
    setIsOpen(true);

    const handleClickOutside = (event) => {
      if (!timePickableRef.current.contains(event.target)) {
        setIsOpen(false);
        document.removeEventListener("mousedown", handleClickOutside);
      }
    };

    document.addEventListener("mousedown", handleClickOutside);
  };

  const handleTimeChange = () => {
    setSelectedTime(getTimeStringFromPicker());
  };

  const getTimeStringFromPicker = () => {
    const hour = document.getElementById("hour-select").value;
    const minute = document.getElementById("minute-select").value;
    const meridiem = document.getElementById("meridiem-select").value;

    return `${hour}:${minute} ${meridiem}`;
  };

  const numberToOption = (number) => {
    const padded = number.toString().padStart(2, "0");
    return <option value={padded}>{padded}</option>;
  };

  const hourOptions = [1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12].map(
    numberToOption
  );
  const minuteOptions = [0, 5, 10, 15, 20, 25, 30, 35, 40, 45, 50, 55].map(
    numberToOption
  );

  return (
    <div className="time-input-wrap" ref={timePickableRef}>
      <label className="time-input-label">
        <img
          src="../assets/icons/time.svg"
          alt="icon"
          className="timepicker-icon"
        />
        <p>Time</p>
        <input
          disabled={disabled}
          type="text"
          placeholder="Select Time"
          className={disabled ? "time-pickable desabled" : "time-pickable"}
          value={scheduleTime}
          onFocus={handleTimePickableFocus}
          readOnly
        />
      </label>
      {isOpen && (
        <div className="time-picker">
          <select
            id="hour-select"
            className="time-picker__select"
            onChange={handleTimeChange}
          >
            {hourOptions}
          </select>
          :
          <select
            id="minute-select"
            className="time-picker__select"
            onChange={handleTimeChange}
          >
            {minuteOptions}
          </select>
          <select
            id="meridiem-select"
            className="time-picker__select"
            onChange={handleTimeChange}
          >
            <option value="am">am</option>
            <option value="pm">pm</option>
          </select>
        </div>
      )}
    </div>
  );
};

export default TimePicker;

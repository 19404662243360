import React, { useEffect, useState } from "react";
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import { useSelector } from "react-redux";
import OverlayTrigger from "react-bootstrap/OverlayTrigger";
import Tooltip from "react-bootstrap/Tooltip";
import { ShimmerPostItem } from "react-shimmer-effects";

const CardItem = ({ data, addItems, removeItem }) => {
  const carts = useSelector((state) => state.allCart.carts);

  const [shimmerCount, setShimmerCount] = useState(4); // default shimmer items

  useEffect(() => {
    // Function to set shimmer count based on screen width
    // Function to set shimmer count based on screen width
    const handleResize = () => {
      if (window.innerWidth < 480) {
        setShimmerCount(2.4); // Matches slidesToShow: 2.4 for breakpoint < 480
      } else if (window.innerWidth < 600) {
        setShimmerCount(2.5); // Matches slidesToShow: 2.5 for breakpoint < 600
      } else if (window.innerWidth < 1023) {
        setShimmerCount(3); // Matches slidesToShow: 3 for breakpoint < 1023
      } else if (window.innerWidth < 1199) {
        setShimmerCount(2); // Matches slidesToShow: 2 for breakpoint < 1199
      } else if (window.innerWidth < 1599) {
        setShimmerCount(3); // Matches slidesToShow: 3 for breakpoint < 1599
      } else {
        setShimmerCount(4); // Default for larger screens
      }
    };

    // Set initial count and add event listener
    handleResize();
    window.addEventListener("resize", handleResize);

    // Cleanup event listener on unmount
    return () => window.removeEventListener("resize", handleResize);
  }, []);

  // function CustomNextArrow(props) {
  //   const { className, style, onClick } = props;
  //   return (
  //     data?.length > 2 && (
  //       <div {...props} className="slick-arrow slick-next">
  //         <img src="../assets/icons/back-arrow.svg" alt="Next" />
  //       </div>
  //     )
  //   );
  // }

  // function CustomPrevArrow(props) {
  //   const { className, style, onClick } = props;
  //   return (
  //     data?.length > 2 && (
  //       <div {...props} className="slick-arrow slick-prev">
  //         <img src="../assets/icons/previous-arrow.svg" alt="Prev" />
  //       </div>
  //     )
  //   );
  // }

  function CustomNextArrow({ currentSlide, slideCount, ...props }) {
    return (
      currentSlide + props.slidesToShow < slideCount && (
        <div {...props} className="slick-arrow slick-next">
          <img src="../assets/icons/back-arrow.svg" alt="Next" />
        </div>
      )
    );
  }

  function CustomPrevArrow({ currentSlide, ...props }) {
    return (
      currentSlide > 0 && (
        <div {...props} className="slick-arrow slick-prev">
          <img src="../assets/icons/previous-arrow.svg" alt="Prev" />
        </div>
      )
    );
  }

  const settings = {
    dots: false,
    infinite: data.length > 1,
    autoplay: false,
    speed: 300,
    slidesToShow: data.length < shimmerCount ? data.length : shimmerCount,
    slidesToScroll: 1,
    prevArrow: (
      <CustomPrevArrow
        slidesToShow={data.length < shimmerCount ? data.length : shimmerCount}
      />
    ),
    nextArrow: (
      <CustomNextArrow
        slidesToShow={data.length < shimmerCount ? data.length : shimmerCount}
      />
    ),
    responsive: [
      {
        breakpoint: 1599,
        settings: {
          slidesToShow: data.length < 3 ? data.length : 3,
          slidesToScroll: 1,
          infinite: data.length > 3,
          dots: false,
          prevArrow: (
            <CustomPrevArrow slidesToShow={data.length < 3 ? data.length : 3} />
          ),
          nextArrow: (
            <CustomNextArrow slidesToShow={data.length < 3 ? data.length : 3} />
          ),
        },
      },
      {
        breakpoint: 1199,
        settings: {
          slidesToShow: data.length < 2 ? data.length : 2,
          slidesToScroll: 1,
          infinite: data.length > 2,
          dots: false,

          prevArrow: (
            <CustomPrevArrow slidesToShow={data.length < 2 ? data.length : 2} />
          ),
          nextArrow: (
            <CustomNextArrow slidesToShow={data.length < 2 ? data.length : 2} />
          ),
        },
      },

      {
        breakpoint: 1023,
        settings: {
          slidesToShow: data.length < 3 ? data.length : 3,
          slidesToScroll: 1,
          infinite: data.length > 3,
          dots: false,
          prevArrow: (
            <CustomPrevArrow slidesToShow={data.length < 3 ? data.length : 3} />
          ),
          nextArrow: (
            <CustomNextArrow slidesToShow={data.length < 3 ? data.length : 3} />
          ),
        },
      },

      {
        breakpoint: 600,
        settings: {
          slidesToShow: data.length < 2.5 ? data.length : 2.5,
          slidesToScroll: 1,
          infinite: data.length > 2.5,
        },
      },
      {
        breakpoint: 480,
        settings: {
          slidesToShow: data.length < 2.4 ? data.length : 2.4,
          slidesToScroll: 1,
          infinite: data.length > 2.4,
        },
      },
    ],
    // prevArrow: <CustomPrevArrow />,
    // nextArrow: <CustomNextArrow />,
  };

  return (
    <>
      {data.length === 0 ? (
        <div className="service-item-grid summer-effect-item-wrap">
          {Array.from({ length: shimmerCount }).map((_, index) => (
            <div key={index}>
              <ShimmerPostItem card title />
            </div>
          ))}
        </div>
      ) : (
        <div className="service-item-card-wrapper">
          <Slider {...settings}>
            {data?.map((data, index) => {
              const cartItem = carts.find(
                (cartItem) => cartItem.id === data.id
              );
              return (
                <div className="service-item-card-wrap" key={index}>
                  <div className="info-icon-wrap">
                    <OverlayTrigger
                      placement="top"
                      delay={{ show: 250, hide: 400 }}
                      overlay={<Tooltip id={`tooltip-up`}>{data.info}</Tooltip>}
                    >
                      <img src="../assets/icons/info.svg" alt="info-icon" />
                    </OverlayTrigger>
                  </div>

                  <div className="service-item-image">
                    <div className="service-item-img">
                      <img src={data.itemImage} alt="img" />
                    </div>

                    <div className="add-cart-btn-wrapper">
                      <div
                        className={`add-cart-btn-wrap ${
                          cartItem?.isAdded ? "expanded" : ""
                        }`}
                      >
                        {cartItem?.isAdded && (
                          <>
                            {cartItem?.quantity == 1 ? (
                              <div
                                className="card-delet-icon"
                                onClick={() => removeItem(data)}
                              >
                                <img
                                  className="delete-icon"
                                  src="../assets/icons/delete.svg"
                                  alt="icon"
                                />
                              </div>
                            ) : (
                              <div
                                className="card-delet-icon"
                                onClick={() => removeItem(data)}
                              >
                                <img
                                  className="delete-icon decrement-icon"
                                  src="../assets/icons/decrement.svg"
                                  alt="icon"
                                />
                              </div>
                            )}

                            <span className="quantity">
                              {cartItem?.quantity}
                            </span>
                          </>
                        )}
                        <div
                          className="plus-icon-wrap"
                          onClick={() => addItems(data)}
                        >
                          <img
                            className="plus-icon"
                            src="../assets/icons/plus.svg"
                            alt="icon"
                          />
                        </div>
                      </div>
                    </div>
                  </div>

                  <div className="service-item-card-desc">
                    <h6> {data.subCategory} </h6>
                    <p> {data.name} </p>
                  </div>
                </div>
              );
            })}
          </Slider>
        </div>
      )}
    </>
  );
};

export default CardItem;

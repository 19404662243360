import React from "react";
import RoundButton from "../UIComponents/RoundButton";
import { useNavigate } from "react-router-dom";

const CancelPage = () => {
  const navigate = useNavigate();

  const goToDashboard = () => {
    navigate("/");
  };
  return (
    <div>
      <div className="success-page-wrapper">
        <div className="success-page-wrap">
          <h3>We're Sorry, Your Payment Failed</h3>

          <p className="cancel-payment-msg">
            Unfortunately, we were unable to process your payment. Please review
            your payment details and try again.
          </p>
          <div className="success-track-order-btn">
            <RoundButton onClick={goToDashboard} label="Try Again" />
          </div>
        </div>
      </div>
    </div>
  );
};

export default CancelPage;

import React, { useState } from "react";
import Modal from "react-bootstrap/Modal";
import "./modal.css";
import Button from "../UIComponents/Button";
import { updateFormData } from "../../redux/features/formSlice";
import { useDispatch } from "react-redux";

const ServiceAvailibleModal = (props) => {
  const dispatch = useDispatch();

  const handleModalClose = () => {
    dispatch(updateFormData({ field: "address", value: "" }));
    dispatch(updateFormData({ field: "addressline2", value: "" }));
    dispatch(updateFormData({ field: "postalcode", value: "" }));
    props.onHide();
  };

  return (
    <>
      <Modal
        {...props}
        size="lg"
        backdrop="static"
        dialogClassName="term-modal-main-wrapper no-service-modal-main-wrapper"
        aria-labelledby="contained-modal-title-vcenter"
        centered
      >
        <Modal.Body>
          <div className="confirm-modal-main-wrapper">
            <div className="term-modal-main-wrap">
              <div className="close-icon-wrap">
                <img
                  onClick={handleModalClose}
                  src="./assets/icons/close-icon.svg"
                  alt="icon"
                />
              </div>

              <div className="term-modal-content-wrap">
                <h3 className="time-slot-modal-heading no-service-modal-heading">
                  Service Not Available
                </h3>

                <p className="time-slot-modal-contant">
                  We apologize, but our cleaning services are currently not
                  available in the Address you selected. Please try searching
                  for a different location or check back later, as we may expand
                  our service area in the future.
                </p>
              </div>

              <div className="pay-button-wrapper timeslot-modal-btn">
                <Button label="Ok" onClick={handleModalClose} />
              </div>
            </div>
          </div>
        </Modal.Body>
      </Modal>
    </>
  );
};
export default ServiceAvailibleModal;

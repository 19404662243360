import { getCsrfToken } from "./helper";
import { SERVICE_URL } from "./utility";
import axios from "axios";

const getCsrfTokenFromStorage = () => {
  return localStorage.getItem("csrfToken");
};

const fetchJSON = async (url, options = {}) => {
  try {
    const response = await fetch(url, options);
    if (!response.ok) {
      throw await response.json();
    }
    return await response.json();
  } catch (error) {
    throw error;
  }
};

const servicePost = async (path, payload, notification, authToken) => {
  try {
    let csrfToken = localStorage.getItem("csrfToken");

    // If the CSRF token is missing, fetch it
    if (!csrfToken) {
      await getCsrfToken();
      csrfToken = localStorage.getItem("csrfToken");
    }

    const response = await axios.post(`${SERVICE_URL}/${path}`, payload, {
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
        "auth-token": authToken,
        "X-CSRF-Token": csrfToken,
        notification: notification,
      },
      // credentials: "include",
      // mode: "cors",
      withCredentials: true,
    });

    return response.data;
  } catch (error) {
    console.error("API request error:", error.response || error.message);
    throw error;
  }
};

const serviceImagePost = async (path, payload, authToken) => {
  try {
    let csrfToken = localStorage.getItem("csrfToken");

    // If the CSRF token is missing, fetch it
    if (!csrfToken) {
      await getCsrfToken();
      csrfToken = localStorage.getItem("csrfToken");
    }
    const response = await axios.post(`${SERVICE_URL}/${path}`, payload, {
      headers: {
        Accept: "application/json",
        "Content-Type": "multipart/form-data",
        "auth-token": authToken,
        "X-CSRF-Token": csrfToken,
      },
      withCredentials: true,
    });
    return response.data;
  } catch (error) {
    throw error;
  }
};

const servicePut = async (path, payload, authToken) => {
  let csrfToken = localStorage.getItem("csrfToken");

  // If the CSRF token is missing, fetch it
  if (!csrfToken) {
    await getCsrfToken();
    csrfToken = localStorage.getItem("csrfToken");
  }
  try {
    const response = await axios.put(`${SERVICE_URL}/${path}`, payload, {
      headers: { "auth-token": authToken, "X-CSRF-Token": csrfToken },
      withCredentials: true,
    });
    return response.data;
  } catch (error) {
    throw error;
  }
};

const serviceGet = async (path, payload) => {
  try {
    const response = await axios.get(`${SERVICE_URL}/${path}`, payload);
    return response.data;
  } catch (error) {
    throw error;
  }
};

const serviceDelete = async (path, payload, authToken) => {
  const csrfToken = getCsrfTokenFromStorage();
  try {
    const response = await axios.delete(`${SERVICE_URL}/${path}`, payload, {
      headers: { "auth-token": authToken, "X-CSRF-Token": csrfToken },
      withCredentials: true,
    });
    return response.data;
  } catch (error) {
    throw error;
  }
};

export {
  fetchJSON,
  servicePost,
  serviceImagePost,
  serviceGet,
  servicePut,
  serviceDelete,
};

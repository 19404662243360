import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  totalPrice: 0,
  totalTime: 0,
};

const extraItemPriceSlice = createSlice({
  name: "additionalPrice",
  initialState,
  reducers: {
    updateTotalPriceAndTime: (state, action) => {
      state.totalPrice = action.payload.totalPrice;
      state.totalTime = action.payload.totalTime;
    },
  },
});

export const { updateTotalPriceAndTime } = extraItemPriceSlice.actions;
export default extraItemPriceSlice.reducer;

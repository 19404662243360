import React, { useState, useEffect } from "react";
import Button from "../UIComponents/Button";
import StartRating from "../dashboard/components/StartRating";
import OrderCancelModal from "../modals/OrderCancelModal";
import MobileOrderCancel from "../modals/MobileOrderCancel";
import { servicePost } from "../../helper/api";
import { convertTo12HourFormat } from "../../helper/helper";
import moment from "moment";
import { useDispatch, useSelector } from "react-redux";
import { setNotificationDep } from "../../redux/features/notificationDepSlice";

const TrackOrderDrawer = ({ onHide, bookingId }) => {
  const [isLoading, setIsLoading] = useState(false);
  const [showCancelModal, setShowCancelModal] = useState(false);
  const [data, setData] = useState();
  const [isOrderCancelled, setOrderCancelled] = useState(false);

  const [reviewError, setReviewError] = useState(false);
  const [customerReview, setCustomerReview] = useState("");
  const [reviewRating, setReviewRating] = useState(null);

  // get loggedInUser for Redux
  const dispatch = useDispatch();
  const logedInUser = useSelector((state) => state.user.userInfo);
  const notificationDep = useSelector(
    (state) => state.notificationDep.notificationDep
  );

  // get AuthToken from localStorage
  const authToken = localStorage.getItem("token");
  const notification = "";

  const handleRatingChange = (rating) => {
    setReviewRating(rating);
  };

  const cancelOrderHandler = () => {
    setShowCancelModal(true);
  };

  const handleOrderCancel = (data) => {
    setOrderCancelled(data);
  };

  // Get Booking Detail Basis of BookingId
  const getOrderDetail = async () => {
    try {
      const orderDetailRes = await servicePost(
        "booking/getBookingData",
        {
          bookingId: bookingId.toString(),
        },
        notification,
        authToken
      );

      setData(orderDetailRes.BookingDetail);
    } catch (error) {
      console.log("error", error);
    }
  };

  useEffect(() => {
    getOrderDetail();
  }, [bookingId, isOrderCancelled, notificationDep]);

  const customerReviewHandler = (e) => {
    const { name, value } = e.target;
    setCustomerReview(value);
    setReviewError(false);
  };

  // Update Booking Order Review
  const updateOrderReviewHandler = async () => {
    if (customerReview == "") {
      setReviewError(true);
      return;
    }
    try {
      setIsLoading(true);
      const contactPersonRes = await servicePost(
        "booking/updateBookingData",
        {
          bookingId: data?.bookingId,
          accountId: logedInUser?.id,
          reviewReating: reviewRating,
          reviewText: customerReview,
        },
        notification,
        authToken
      );

      if (contactPersonRes.message == "SUCCESS") {
        setIsLoading(false);
        dispatch(setNotificationDep(notificationDep + 1));
      }
    } catch (error) {
      console.log("error", error);
      setIsLoading(false);
    }
  };

  return (
    <>
      <MobileOrderCancel
        show={showCancelModal}
        onHide={() => setShowCancelModal(false)}
        showCloseIcon={true}
        data={data}
        onOrderCancel={handleOrderCancel}
      />

      <div className="order-track-main-wrapper">
        <div className="track-close-icon-wrap">
          <img
            onClick={() => onHide()}
            src="../assets/icons/close-icon.svg"
            alt="icon"
          />
        </div>
        <div className="order-track">
          <div className="order-track-step">
            <div className="order-track-status">
              <img src="../assets/icons/location.svg" alt="icon" />
              <span className="order-track-status-line"></span>
            </div>
            <div className="order-track-text">
              <p className="order-track-text-stat">YFOS Company</p>
              <span className="order-track-text-sub">
                {data?.companyAddress}
              </span>
            </div>
          </div>

          <>
            <div className="order-track-step">
              <div className="order-track-status">
                <span className="order-track-status-dot"></span>
                <span className="order-track-status-line"></span>
              </div>
              <div className="order-track-text">
                <p className="order-track-label">
                  {data?.bookingStatus == "Completed"
                    ? "Booking Confirmed"
                    : data?.bookingStatus}
                </p>
                <span className="order-track-text-sub">
                  {convertTo12HourFormat(
                    data?.bookingTime ? data?.bookingTime : ""
                  )}{" "}
                  , {""}
                  {moment(data?.createdAt).format("DD MMM YYYY")}
                </span>
              </div>
            </div>

            {data?.cleanerPartnerStatus && (
              <div className="order-track-step">
                <div className="order-track-status">
                  <span className="order-track-status-dot"></span>
                  <span className="order-track-status-line"></span>
                </div>
                <div className="order-track-text">
                  <p className="order-track-label">
                    {data?.cleanerPartnerStatus}
                  </p>
                  {/* <span className="order-track-text-sub">
                Stay Connected with Cleaner Partner
              </span> */}
                  {/* <h5> +1 (657)-747-7467 </h5> */}
                  <p className="order-text-schedul">
                    {convertTo12HourFormat(
                      data?.assignedTime ? data?.assignedTime : ""
                    )}{" "}
                    , {""}
                    {data?.assignedDate
                      ? moment(data?.assignedDate, [
                          "DD/MM/YYYY",
                          "MM/DD/YYYY",
                        ]).isValid()
                        ? moment(data?.assignedDate, [
                            "DD/MM/YYYY",
                            "MM/DD/YYYY",
                          ]).format("DD MMM, YYYY")
                        : "Invalid date"
                      : "--"}
                  </p>
                </div>
              </div>
            )}

            {data?.dispatchedStatus && (
              <div className="order-track-step">
                <div className="order-track-status">
                  <span className="order-track-status-dot"></span>
                  <span className="order-track-status-line"></span>
                </div>
                <div className="order-track-text">
                  <p className="order-track-label">
                    Cleaner Partner has been Dispatched.
                  </p>
                  <span className="order-track-text-sub">
                    {moment(data?.dispatchedDate).format(
                      "hh:mm A, DD MMM YYYY"
                    )}
                  </span>
                </div>
              </div>
            )}

            {data?.arrivedStatus && (
              <div className="order-track-step order-track-long-step">
                <div className="order-track-status">
                  <span className="order-track-status-dot"></span>
                  <span className="order-track-status-line"></span>
                </div>
                <div className="order-track-text">
                  <p className="order-track-label">Arrived at your Location</p>
                  <span className="order-track-text-sub">Please share OTP</span>
                  <h5> {data?.otp}</h5>
                  <p className="order-text-schedul">
                    {convertTo12HourFormat(
                      data?.arrivedTime ? data?.arrivedTime : ""
                    )}
                    , {""}
                    {data?.arrivedDate
                      ? moment(data?.arrivedDate, [
                          "DD/MM/YYYY",
                          "MM/DD/YYYY",
                        ]).isValid()
                        ? moment(data?.arrivedDate, [
                            "DD/MM/YYYY",
                            "MM/DD/YYYY",
                          ]).format("DD MMM, YYYY")
                        : "Invalid date"
                      : "--"}
                  </p>
                </div>
              </div>
            )}

            {data?.otpAccepted && (
              <div className="order-track-step">
                <div className="order-track-status">
                  <span className="order-track-status-dot"></span>
                  <span className="order-track-status-line"></span>
                </div>
                <div className="order-track-text">
                  <p className="order-track-label">
                    OTP Shared by the Customer
                  </p>
                  <span className="order-track-text-sub">
                    {moment(data?.otpAcceptedDate).format(
                      "hh:mm A, DD MMM YYYY"
                    )}
                  </span>
                </div>
              </div>
            )}

            {data?.cancelStatus && (
              <div className="order-track-step">
                <div className="order-track-status">
                  <span className="order-track-status-dot"></span>
                  <span className="order-track-status-line"></span>
                </div>
                <div className="order-track-text">
                  <p className="order-track-label"> Booking Cancelled</p>
                  <span className="order-track-text-sub">
                    {data?.cancelReason}
                  </span>
                </div>
              </div>
            )}

            {data?.workStartedStatus && (
              <div className="order-track-step">
                <div className="order-track-status">
                  <span className="order-track-status-dot"></span>
                  <span className="order-track-status-line"></span>
                </div>
                <div className="order-track-text">
                  <p className="order-track-label">
                    Cleaner Has Started the Work
                  </p>
                  <span className="order-track-text-sub">
                    {/* 12:04 AM , 21 Nov 2024 */}
                    {moment(data?.workStartedDate).format(
                      "hh:mm A, DD MMM YYYY"
                    )}
                  </span>
                </div>
              </div>
            )}

            {data?.workCompleteStatus && !data?.cancelStatus ? (
              <div className="order-track-step order-track-long-step">
                <div className="order-track-status">
                  <img
                    className="order-step-complete-icon"
                    src="../assets/confirm-tick.png"
                    alt="icon"
                  />
                  <span className="order-track-status-line"></span>
                </div>
                <div className="order-track-text">
                  <p className="order-track-label">Cleaning Service Complete</p>
                  <span className="order-track-text-sub">
                    Please review our Cleaning Services
                  </span>

                  {data?.reviewText ? (
                    <>
                      <div className="rating-main-wrapper">
                        {[...Array(5)].map((star, index) => {
                          const currentRate = index + 1;
                          return (
                            <label key={index}>
                              <svg
                                xmlns="http://www.w3.org/2000/svg"
                                width="23.064"
                                height="22.365"
                                viewBox="0 0 23.064 22.365"
                                aria-label={`Star ${currentRate}`}
                              >
                                <path
                                  id="star"
                                  d="M1.216,11.375l3.261,2.382L3.239,17.592A2.957,2.957,0,0,0,7.8,20.885l3.189-2.347,3.19,2.344a2.956,2.956,0,0,0,4.565-3.29l-1.239-3.836,3.261-2.382A2.956,2.956,0,0,0,19.03,6.033H15.024L13.808,2.244a2.956,2.956,0,0,0-5.63,0L6.963,6.033h-4A2.956,2.956,0,0,0,1.22,11.375Z"
                                  transform="translate(0.537 0.31)"
                                  fill={
                                    currentRate <= data.reviewReating
                                      ? "#72BBBB"
                                      : "none"
                                  }
                                  stroke="#e2e2e2"
                                  strokeWidth="1"
                                />
                              </svg>
                            </label>
                          );
                        })}
                      </div>
                      <div className="write-review-wrapper">
                        <p> {data?.reviewText} </p>
                      </div>
                    </>
                  ) : (
                    <>
                      <StartRating onRatingChange={handleRatingChange} />
                      <div className="write-review-wrapper">
                        <textarea
                          className={reviewError ? "error-msg" : ""}
                          onChange={customerReviewHandler}
                          placeholder="Write Review here"
                        ></textarea>
                      </div>
                    </>
                  )}

                  {!data?.reviewText && (
                    <div className="book-button-wrapper">
                      <Button
                        isLoading={isLoading}
                        label="Submit"
                        onClick={updateOrderReviewHandler}
                      />
                    </div>
                  )}
                </div>
              </div>
            ) : !data?.cancelStatus ? (
              <div className="cancel-order-main-wrapper">
                <div className="cancel-order-main-wrap">
                  <div className="cancel-order-info">
                    <img src="../assets/icons/info.svg" alt="icon" />
                    <p>
                      If you cancel Before 24 hours no charges will applied{" "}
                    </p>
                  </div>

                  <div className="order-cance-btn">
                    <Button
                      onClick={cancelOrderHandler}
                      extraClass="cancel-order-button"
                      label="Cancel"
                    />
                  </div>
                </div>
              </div>
            ) : (
              ""
            )}
          </>
        </div>
      </div>
    </>
  );
};

export default TrackOrderDrawer;

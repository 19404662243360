import React, { useState } from "react";
import Modal from "react-bootstrap/Modal";
import "./modal.css";
import Button from "../UIComponents/Button";
import { useNavigate } from "react-router-dom";
import OrderCompleteModal from "./OrderCompleteModal";
import { loadStripe } from "@stripe/stripe-js";
import { useDispatch, useSelector } from "react-redux";
import { servicePost } from "../../helper/api";
import {
  selectSubTotal,
  selectTotalTime,
} from "../../redux/features/selectors";
import { emptyCartItem } from "../../redux/features/cartSlice";
import { resetCoupon } from "../../redux/features/couponSlice";
import { resetSpecificFormData } from "../../redux/features/formSlice";
import { resetSpecificDropdown } from "../../redux/features/dropdownSlice";
import { setOrderBookingId } from "../../redux/features/bookingSlice";
import { setNotificationDep } from "../../redux/features/notificationDepSlice";
import { setOrderData } from "../../redux/features/orderChangeSlice";
import { clearFlooringPrices } from "../../redux/features/flooringPriceSlice";

const TermsModal = (props) => {
  const [isLoading, setIsLoading] = useState(false);
  const [showConfirmModal, setShowComfirmModal] = useState(false);
  const [selectedReason, setSelectedReason] = useState("");
  const [termError, setTermError] = useState(false);
  const stripePublicKey = process.env.REACT_APP_STRIPE_KEY;

  // get Information from the redux
  const logedInUser = useSelector((state) => state.user.userInfo);
  const cartItems = useSelector((state) => state.allCart.carts);
  const percentageData = useSelector((state) => state.allCart);
  const dropdownValues = useSelector((state) => state.allDowpdown);
  const formData = useSelector((state) => state.formData);
  const serviceType = useSelector((state) => state.serviceType.serviceType);
  const couponData = useSelector((state) => state.coupon);
  const notificationDep = useSelector(
    (state) => state.notificationDep.notificationDep
  );

  const flooringPrices = useSelector(
    (state) => state.flooringPrice.flooringPrice
  );

  const otpAccess = formData.otpAccess == "send-otp" ? true : false;
  const currentDate = new Date();
  const bookingDate = currentDate.toLocaleDateString();
  const bookingTime = currentDate.toLocaleTimeString();

  const dispatch = useDispatch();
  const navigate = useNavigate();

  // Get totalTime cartItem time + flooring + dusting time
  const totalTimeSelector = selectTotalTime();
  const { totalTime } = useSelector(totalTimeSelector);

  // get AuthToken from localStorage
  const authToken = localStorage.getItem("token");
  const notification = "";

  // Get subTotal cartItem price + flooring + dusting Price
  const subtotalSelector = selectSubTotal(couponData?.couponDiscountPercentage);
  const {
    subTotal,
    discountAmount,
    cartItemsPrice,
    totalAmount,
    platformFeeAmount,
    totalAmountNoYfosFee,
    subTotalnoIndustryCharge,
    taxAmount,
    yfosServiceFees,
  } = useSelector(subtotalSelector);

  const FeesAndTexAmount = platformFeeAmount + taxAmount;
  // YFOS Service Fees
  // const yfosServiceFess =
  //   Number(totalAmountNoYfosFee.toFixed(0)) < 200 ? 200 : 0;

  // Get the subChargePercentage from the dropdownValues
  const subChargePercentage = dropdownValues?.industry?.subChargePercentage;

  const industrySubChargeAmount = subChargePercentage
    ? (subTotalnoIndustryCharge * parseFloat(subChargePercentage)) / 100
    : 0;

  // Get the subChargePercentage from the dropdownValues
  const postConstractionSubChargePercentage =
    dropdownValues?.bedrooms?.subChargePercentage;
  const postConstractionSubChargeAmount = postConstractionSubChargePercentage
    ? (subTotalnoIndustryCharge *
        parseFloat(postConstractionSubChargePercentage)) /
      100
    : 0;

  const industrySubCharge =
    dropdownValues?.industry?.name == "Post Construction"
      ? postConstractionSubChargeAmount
      : industrySubChargeAmount;

  // In The Case of Saved Card...
  const selectedCard = props?.selectedSavedCard;
  const customerId = selectedCard?.customerId;
  const paymentMethodId = selectedCard?.paymentMethodId;
  const totalAmountPay = Math.round(props?.bookingData?.total * 100);

  // Handle Check Term Condition
  const handleReasonChange = (event) => {
    if (selectedReason === event.target.value) {
      setSelectedReason("");
    } else {
      setTermError(false);
      setSelectedReason(event.target.value);
    }
  };

  // payment Handler Case of Simple Payment
  const makePayment = async () => {
    const stripe = await loadStripe(
      "pk_test_51Pl3GO2M2pNXvfFREUGzISqkYtTKwdQfsNg9MF3fbIC0nBnBTVdJM3CL1tDWCmINSL59HKdoqDSHb6RXcjfY5xk3009PSbqKVK"
    );

    try {
      setIsLoading(true);
      const response = await servicePost(
        "user/api/create-checkout-session",
        {
          products: "",
          bookingData: props.bookingData,
        },
        notification,
        authToken
      );

      const result = stripe.redirectToCheckout({
        sessionId: response.id,
      });
      setIsLoading(false);
      dispatch(setOrderData(null));

      if (result.error) {
        console.log(result.error);
        setIsLoading(false);
      }
    } catch (error) {
      console.error("Error creating payment session:", error);
      setIsLoading(false);
    }
  };

  // Handle Payment with Saved Card
  const handleSaveCardPayment = async () => {
    if (!customerId || !paymentMethodId) {
      console.error("Customer ID or Payment Method ID is missing");
      return;
    }
    try {
      setIsLoading(true);
      const response = await servicePost(
        "user/pay-with-saved-card",
        {
          customerId,
          paymentMethodId,
          amount: totalAmountPay,
          currency: "cad",
        },
        notification,
        authToken
      );

      console.log("response", response);
      const paymentIntentId = response.paymentIntentId;
      console.log("paymentIntentId", paymentIntentId);

      if (paymentIntentId) {
        const bookingResponse = await servicePost(
          `booking/addBookingData`,
          {
            paymentIntent: paymentIntentId,
            sessionId: "",
            userPaymentId: paymentIntentId,
            accountId: logedInUser?.id,
            couponCode: couponData?.couponCode ? couponData?.couponCode : "",
            serviceType: serviceType,
            industry: dropdownValues?.industry?.name,
            floors: dropdownValues?.floors?.name,
            bedrooms: dropdownValues?.bedrooms?.name,
            bookingId: "",
            squireFeet:
              dropdownValues?.industry?.name !== "Specialty Cleaning"
                ? percentageData?.squreFeetPercentage
                : null,
            contactPersonName: formData?.contactname,
            contactPersonNumber: formData?.contactnumber,
            vacummCleaning: percentageData.flooringPercentage,
            moppingCleaning: percentageData.moppingPercentage,
            vacummCleaningPrice: flooringPrices?.vaccumprice.toFixed(2),
            moppingCleaningPrice: flooringPrices?.moppingprice.toFixed(2),
            dusting: percentageData.dustingPercentage,
            dustingPrice: flooringPrices?.dustingprice.toFixed(2),
            recurring: "",
            address: formData?.address,
            city: dropdownValues?.city?.name,
            postalCode: formData?.postalcode,
            scheduleDate: formData?.scheduleDate,
            scheduleTime: formData?.scheduleTime,
            paymentMethod: "Credit Card",
            price: cartItemsPrice,
            discount: discountAmount.toFixed(2),
            subTotal: subTotal.toFixed(2),
            fees: FeesAndTexAmount,
            total: totalAmount.toFixed(2),
            companyAddress: "Company Address",
            bookingStatus: "Processing",
            bookingTime: bookingTime,
            bookingDate: bookingDate,
            cleanerPartnerStatus: "",
            assignedTime: "",
            assignedDate: "",
            arrivedStatus: "",
            arrivedTime: "",
            arrivedDate: "",
            otp: "",
            otpAccess: otpAccess,
            cleanigServiceStatus: "",
            reviewReating: "",
            reviewText: "",
            cartItems: cartItems,
            totalTime: totalTime,
            industrySurcharge: industrySubCharge,
            floorSurcharge: dropdownValues?.floors?.floorCost,
            yfosServiceCharge: yfosServiceFees,
            addressLine2: formData?.addressline2,
          },
          "Order Created",
          authToken
        );

        setIsLoading(false);

        const bookingId = bookingResponse.data;
        if (bookingResponse.data) {
          dispatch(emptyCartItem());
          dispatch(resetCoupon());
          dispatch(
            resetSpecificFormData([
              "squareFeet",
              "scheduleDate",
              "scheduleTime",
              "sessionStartTime",
              "contactname",
              "contactnumber",
              "otpAccess",
              "paywith",
            ])
          );

          dispatch(clearFlooringPrices());

          dispatch(resetSpecificDropdown(["industry", "floors", "bedrooms"]));
          dispatch(setOrderBookingId(bookingId));
          dispatch(setNotificationDep(notificationDep + 1));

          navigate(`/tracking-order/${bookingId}`);
          // navigate("/tracking-order", {
          //   state: {
          //     bookingId: bookingId,
          //   },
          // });

          setIsLoading(false);
        }
      }
    } catch (error) {
      console.log("err", error);
      setIsLoading(false);
    }
  };

  // Handle Payment with Postpaid
  const handlePostpaidPayment = async () => {
    try {
      setIsLoading(true);
      const bookingResponse = await servicePost(
        `booking/addBookingData`,
        {
          paymentIntent: null,
          sessionId: "",
          userPaymentId: "",
          accountId: logedInUser?.id,
          couponCode: couponData?.couponCode ? couponData?.couponCode : "",
          serviceType: serviceType,
          industry: dropdownValues?.industry?.name,
          floors: dropdownValues?.floors?.name,
          bedrooms: dropdownValues?.bedrooms?.name,
          bookingId: "",
          squireFeet:
            dropdownValues?.industry?.name !== "Specialty Cleaning"
              ? percentageData?.squreFeetPercentage
              : null,
          contactPersonName: formData?.contactname,
          contactPersonNumber: formData?.contactnumber,
          vacummCleaning: percentageData.flooringPercentage,
          moppingCleaning: percentageData.moppingPercentage,
          vacummCleaningPrice: flooringPrices?.vaccumprice.toFixed(2),
          moppingCleaningPrice: flooringPrices?.moppingprice.toFixed(2),
          dusting: percentageData.dustingPercentage,
          dustingPrice: flooringPrices?.dustingprice.toFixed(2),
          recurring: "",
          address: formData?.address,
          city: dropdownValues?.city?.name,
          postalCode: formData?.postalcode,
          scheduleDate: formData?.scheduleDate,
          scheduleTime: formData?.scheduleTime,
          paymentMethod: "PostPaid",
          price: cartItemsPrice,
          discount: discountAmount.toFixed(2),
          subTotal: subTotal.toFixed(2),
          fees: FeesAndTexAmount,
          total: totalAmount.toFixed(2),
          companyAddress: "Company Address",
          bookingStatus: "Processing",
          bookingTime: bookingTime,
          bookingDate: bookingDate,
          cleanerPartnerStatus: "",
          assignedTime: "",
          assignedDate: "",
          arrivedStatus: "",
          arrivedTime: "",
          arrivedDate: "",
          otp: "",
          otpAccess: otpAccess,
          cleanigServiceStatus: "",
          reviewReating: "",
          reviewText: "",
          cartItems: cartItems,
          totalTime: totalTime,
          industrySurcharge: industrySubCharge,
          floorSurcharge: dropdownValues?.floors?.floorCost,
          yfosServiceCharge: yfosServiceFees,
          addressLine2: formData?.addressline2,
        },
        "Order Created",
        authToken
      );

      setIsLoading(false);

      const bookingId = bookingResponse.data;
      if (bookingResponse.data) {
        dispatch(emptyCartItem());
        dispatch(resetCoupon());
        dispatch(
          resetSpecificFormData([
            "squareFeet",
            "scheduleDate",
            "scheduleTime",
            "sessionStartTime",
            "contactname",
            "contactnumber",
            "otpAccess",
            "paywith",
          ])
        );
        dispatch(clearFlooringPrices());
        dispatch(resetSpecificDropdown(["industry", "floors", "bedrooms"]));
        dispatch(setOrderBookingId(bookingId));
        dispatch(setNotificationDep(notificationDep + 1));

        navigate(`/tracking-order/${bookingId}`);
        // navigate("/tracking-order", {
        //   state: {
        //     bookingId: bookingId,
        //   },
        // });

        setIsLoading(false);
      }
    } catch (error) {
      console.log("err", error);
      setIsLoading(false);
    }
  };

  const handlePaymetMethod = () => {
    // if (props.paywith == "Postpaid") {
    //   handlePostpaidPayment();
    // } else if (selectedCard) {
    //   handleSaveCardPayment();
    // } else {
    //   makePayment();
    // }

    if (!selectedReason) {
      setTermError(true);
      return;
    }

    if (props.paywith == "Postpaid") {
      handlePostpaidPayment();
    } else {
      makePayment();
    }
  };

  return (
    <>
      <OrderCompleteModal
        orderData={props.orderData}
        show={showConfirmModal}
        onHide={() => setShowComfirmModal(false)}
      />

      <Modal
        {...props}
        size="lg"
        dialogClassName="term-modal-main-wrapper"
        aria-labelledby="contained-modal-title-vcenter"
        centered
      >
        <Modal.Body>
          <div className="confirm-modal-main-wrapper">
            <div className="term-modal-main-wrap">
              <div className="close-icon-wrap">
                <img
                  onClick={() => props.onHide()}
                  src="./assets/icons/close-icon.svg"
                  alt="icon"
                />
              </div>

              <div className="term-modal-content-wrap">
                <div className="term-modal-info-head">
                  <img src="./assets/icons/info.svg" alt="icon" />
                  <p className="term-modal-paywith">Payment</p>
                </div>

                <h3>{props.title}</h3>

                <p>
                  Please confirm that all selected items are correct before
                  making payment. If you need to cancel, modify, or add items
                  after the cleaner arrives at your location, a $40 cancellation
                  fee will apply.
                </p>
              </div>
              <div className="term-condition-content">
                <p> - Cancellation Charges $40 </p>
                <p> - Cancellation Refund Credited in 3-5 Business days </p>
              </div>

              <div className="pay-modal-term-wrapper">
                <form>
                  <div className="term-modal-checkmark-wrap">
                    <label className="checkbox-container">
                      <input
                        type="checkbox"
                        id="terms-condition"
                        name="cancel-reason"
                        value="terms-condition"
                        onChange={handleReasonChange}
                        checked={selectedReason === "terms-condition"}
                      />
                      <span
                        className={
                          termError ? "error-msg checkmark" : "checkmark"
                        }
                      ></span>
                    </label>
                    <p>
                      I agree to the <a> Terms & Condiations </a>
                    </p>
                  </div>
                </form>
                <div className="pay-button-wrapper">
                  <Button
                    isLoading={isLoading}
                    extraClass={!selectedReason ? "disabled-button" : ""}
                    label={
                      selectedCard
                        ? "Pay with Saved card"
                        : props.paywith
                        ? `Pay with ${
                            props.paywith == "Card"
                              ? "Creditcard"
                              : props.paywith
                          }`
                        : "Pay"
                    }
                    onClick={handlePaymetMethod}
                  />
                </div>
              </div>
            </div>
          </div>
        </Modal.Body>
      </Modal>
    </>
  );
};
export default TermsModal;

import React, { useState, useEffect } from "react";
import Dropdown from "../../dropdown/Dropdown";
import InputField from "../../UIComponents/InputField";
import { availibleServiceCites } from "../../dummydata/DummyData";
import { updateDropdown } from "../../../redux/features/dropdownSlice";
import { updateFormData } from "../../../redux/features/formSlice";
import { setError, clearErrors } from "../../../redux/features/errorSlice";
import { useMediaQuery } from "react-responsive";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { useLoadScript } from "@react-google-maps/api";
import PlacesAutocomplete, {
  geocodeByAddress,
  getLatLng,
} from "react-places-autocomplete";
import ServiceAvailibleModal from "../../modals/ServiceAvailibleModal";
import { servicePost } from "../../../helper/api";

const AddressDetails = () => {
  const [showAvailibleModal, setShowAvailibleModal] = useState(false);
  const [postalCode, setPostalCode] = useState("");
  const isMobile = useMediaQuery({ maxWidth: 1024 });
  const dropdownValues = useSelector((state) => state.allDowpdown);
  const formData = useSelector((state) => state.formData);
  const errors = useSelector((state) => state.formErrors);
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [city, setCity] = useState("");

  // get AuthToken from localStorage
  const authToken = localStorage.getItem("token");
  const notification = "";

  const googleAddressKey = process.env.REACT_APP_GOOGLE_ADDRESS_KEY;
  const libraries = ["places"];
  const { isLoaded, loadError } = useLoadScript({
    googleMapsApiKey: "AIzaSyBGbGW17FHzIqx2Wqv0OfKc30vmyz22cHs",
    libraries,
  });
  const [address, setAddress] = useState("");
  const handleSelect = async (value) => {
    const results = await geocodeByAddress(value);
    const addressComponents = results[0].address_components;
    const latLng = await getLatLng(results[0]);

    // Clear previous postal code and city before processing new data
    setCity("");
    setPostalCode("");
    dispatch(updateFormData({ field: "address", value }));
    dispatch(updateFormData({ field: "addressline2", value: "" }));
    dispatch(updateFormData({ field: "postalcode", value: "" }));
    dispatch(updateDropdown({ field: "city", value: { name: "" } }));
    dispatch(setError({ field: "address", value: false }));

    let city = "";
    let postalCode = "";

    // Loop through the address components and find the city and postal code
    addressComponents.forEach((component) => {
      const types = component.types;

      if (types.includes("locality")) {
        city = component.long_name;
      }

      if (types.includes("postal_code")) {
        postalCode = component.long_name;
      }
    });

    // Update state and dispatch new values
    if (postalCode) {
      setPostalCode(postalCode);
      dispatch(updateFormData({ field: "postalcode", value: postalCode }));
      dispatch(setError({ field: "postalcode", value: false }));
    }

    if (city) {
      setCity(city);
      dispatch(updateDropdown({ field: "city", value: { name: city } }));
      dispatch(setError({ field: "city", value: false }));
    }
  };

  const handleAddressChange = (value) => {
    setAddress(value);
    dispatch(updateFormData({ field: "address", value }));
    dispatch(setError({ field: "address", value: false }));

    // Clear previous postal code when Address Change
    setCity("");
    setPostalCode("");
    dispatch(updateFormData({ field: "postalcode", value: "" }));
    dispatch(updateDropdown({ field: "city", value: { name: "" } }));
  };

  useEffect(() => {
    const addressComplete =
      formData?.address &&
      // dropdownValues?.city?.name &&
      // formData?.addressline2 &&
      formData?.postalcode &&
      !errors?.address &&
      // !errors?.city &&
      // !errors.addressline2 &&
      !errors?.postalcode;

    dispatch(
      updateFormData({ field: "addressComplete", value: addressComplete })
    );
  }, [
    formData?.address,
    formData?.postalcode,
    // formData?.addressline2,
    // dropdownValues?.city?.name,
    postalCode,
    errors,
    dispatch,
  ]);

  // get Paltfrom fee and Texes
  const getPaltfromFeeAndTexs = async () => {
    try {
      const getFeeAndTexsRes = await servicePost(
        "booking/getTaxFeesData",
        {
          country: "canada",
          // city: dropdownValues?.city?.name?.toLowerCase(),
          city: "toronto",
        },
        notification,
        authToken
      );

      const data = getFeeAndTexsRes.data[0];
      console.log("getFeeAndTexsRes", data);
      console.log("data", data.platformFees);

      dispatch(updateFormData({ field: "taxes", value: data.tax }));
      dispatch(
        updateFormData({ field: "platformFees", value: data.platformFees })
      );
    } catch (error) {
      console.log("error", error);
    }
  };

  useEffect(() => {
    getPaltfromFeeAndTexs();
  }, [dropdownValues?.city]);

  const handleInputChange = (event) => {
    const { name, value } = event.target;
    if (value) {
      dispatch(setError({ field: name, value: false }));
    }

    const isValidCity = availibleServiceCites.some((city) =>
      formData?.address.toLowerCase().includes(city.name.toLowerCase())
    );

    if (!isValidCity) {
      setShowAvailibleModal(true);
    }

    if (name === "postalcode") {
      // Convert to uppercase and remove any non-alphanumeric characters
      let newValue = value.toUpperCase().replace(/[^A-Z0-9]/g, "");

      // Insert a space after the third character if it doesn't already have one
      if (newValue.length > 3) {
        newValue = newValue.slice(0, 3) + " " + newValue.slice(3);
      }

      // Progressive pattern to allow partial matches
      const pattern =
        /^[A-Za-z]?$|^[A-Za-z]\d?$|^[A-Za-z]\d[A-Za-z]?$|^[A-Za-z]\d[A-Za-z] ?$|^[A-Za-z]\d[A-Za-z] \d?$|^[A-Za-z]\d[A-Za-z] \d[A-Za-z]?$|^[A-Za-z]\d[A-Za-z] \d[A-Za-z]\d?$/;

      // Update the state if the input matches the partial pattern or is empty
      if (pattern.test(newValue) || newValue === "") {
        setPostalCode(newValue);
        dispatch(updateFormData({ field: name, value: newValue }));
      }
      return;
    }

    // Update the state and Redux store for other fields
    dispatch(updateFormData({ field: name, value }));
  };

  const handleDropdownChange = (field) => (value) => {
    dispatch(updateDropdown({ field, value }));
    if (value) {
      dispatch(setError({ field, value: false }));
    }
  };

  const handleBlur = (event) => {
    const { name, value } = event.target;
    if (!value) {
      dispatch(setError({ field: name, value: true }));
    } else {
      dispatch(setError({ field: name, value: false }));
    }
  };

  // Function to filter results based on available cities
  const handleSuggestionsFilter = (predictions) => {
    return predictions.filter((prediction) => {
      return availibleServiceCites.some((city) =>
        prediction.terms.some(
          (term) => term.value.toLowerCase() === city.name.toLowerCase()
        )
      );
    });
  };

  const handleKeyDown = (e) => {
    const charCode = e.keyCode || e.which;
    const charStr = String.fromCharCode(charCode).toUpperCase();

    // Allow Backspace and Delete keys
    if (charCode === 8 || charCode === 46) {
      return;
    }

    const value = e.target.value.toUpperCase().replace(/[^A-Z0-9]/g, "");

    // Prevent initial invalid characters
    if (value.length === 0 && !/[A-Za-z]/.test(charStr)) {
      e.preventDefault();
    } else if (value.length === 1 && !/\d/.test(charStr)) {
      e.preventDefault();
    } else if (value.length === 2 && !/[A-Za-z]/.test(charStr)) {
      e.preventDefault();
    }
  };

  if (loadError) return "Error loading maps";
  if (!isLoaded) return "Loading Maps";

  return (
    <>
      <ServiceAvailibleModal
        show={showAvailibleModal}
        onHide={() => setShowAvailibleModal(false)}
        showCloseIcon={true}
      />

      <div className="address-detail-wrap">
        <h4>Your Address Details</h4>
        <div className="address-form-input-wrapper">
          <div className="login-form-main-wrap">
            <PlacesAutocomplete
              value={address}
              onChange={(e) => handleAddressChange(e)}
              onSelect={handleSelect}
              searchOptions={{
                componentRestrictions: { country: "ca" }, // Restrict to Canada
              }}
            >
              {({
                getInputProps,
                suggestions,
                getSuggestionItemProps,
                loading,
              }) => (
                <div className="address-input-wrapper">
                  <div className="login-option">
                    <form>
                      <InputField
                        extraClass={errors.address ? "error-msg" : ""}
                        {...getInputProps({
                          className: "location-search-input",
                        })}
                        type="text"
                        name="address"
                        id="address"
                        value={formData?.address}
                        label="Enter Address"
                        passwordIcon={false}
                        // onChange={handleInputChange}
                        // onBlur={handleBlur}
                      />
                    </form>
                  </div>

                  {suggestions?.length > 0 && (
                    <div className="autocomplete-dropdown-container">
                      {loading && <div>Loading...</div>}
                      {suggestions.map((suggestion) => {
                        const className = suggestion.active
                          ? "suggestion-item--active"
                          : "suggestion-item";
                        // inline style for demonstration purpose
                        {
                          /* const style = suggestion.active
                        ? { backgroundColor: "#fafafa", cursor: "pointer" }
                        : { backgroundColor: "#ffffff", cursor: "pointer" }; */
                        }
                        return (
                          <div
                            className="suggestion-item"
                            {...getSuggestionItemProps(suggestion, {
                              // className,
                              // style,
                            })}
                          >
                            <span>{suggestion.description}</span>
                          </div>
                        );
                      })}
                    </div>
                  )}
                </div>
              )}
            </PlacesAutocomplete>
            {/* <div className="login-option">
            <form>
              <InputField
                extraClass={errors.address ? "error-msg" : ""}
                type="text"
                name="address"
                id="address"
                value={formData?.address}
                label={errors.address ? "Enter Address" : "Address*"}
                passwordIcon={false}
                onChange={handleInputChange}
                onBlur={handleBlur}
              />
            </form>
          </div> */}
            <div className="address-city-postal-wrapper">
              {/* <Dropdown
              onTypeChange={handleDropdownChange("city")}
              label={errors.city ? "Select City" : "City*"}
              data={CityDummyData}
              alreadyValue={dropdownValues?.city}
              extraClass={errors.city ? "error-msg" : ""}
              onBlur={handleBlur}
            /> */}

              <div className="postal-code-option">
                <label>Address line 2</label>
                <input
                  type="text"
                  name="addressline2"
                  id="addressline2"
                  value={formData?.addressline2}
                  onChange={handleInputChange}
                  placeholder="Apartment, Suite, etc"
                />
              </div>

              <div className="postal-code-option">
                <label className={errors.postalcode ? "error-msg" : ""}>
                  {errors.postalcode ? "Postal Code" : "Postal code"}
                </label>
                <input
                  type="text"
                  name="postalcode"
                  id="postalcode"
                  value={postalCode ? postalCode : formData?.postalcode}
                  onChange={handleInputChange}
                  onBlur={handleBlur}
                  onKeyDown={handleKeyDown}
                  maxLength="7"
                  placeholder="Eg: A1B 2C3"
                  className={errors.postalcode ? "error-msg" : ""}
                />
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default AddressDetails;

import React, { useState } from "react";
import validator from "validator";
import Lottie from "lottie-react";
import LoadingLottie from "../lottiefiles/loading.json";
import "./login.css";
import Button from "../UIComponents/Button";
import InputField from "../UIComponents/InputField";
import { useNavigate } from "react-router-dom";
import ConfirmationModal from "../modals/ConfirmationModal";

const SetForgotPassword = () => {
  const [isLoading, setIsLoading] = useState(false);
  const [showConfirmationModal, setShowConfirmationModal] = useState(false);
  const [passwordShown, setPasswordShown] = useState(false);
  const [confirmPasswordShown, setConfirmPasswordShown] = useState(false);

  const togglePassword = () => {
    setPasswordShown(!passwordShown);
  };

  const toggleConfirmPassword = () => {
    setConfirmPasswordShown(!confirmPasswordShown);
  };

  const [loginData, setLoginData] = useState({
    email: "",
    password: "",
  });

  const { email, password } = loginData;

  const loginHandler = (e) => {
    const { name, value } = e.target;
    setLoginData((preVal) => {
      return {
        ...preVal,
        [name]: value,
      };
    });
  };

  const navigate = useNavigate();

  // Password Change Handler
  const passwordChangeHandler = () => {
    setShowConfirmationModal(true);
    setTimeout(() => {
      setShowConfirmationModal(false);
      navigate("/login");
    }, 3000);
  };

  return (
    <>
      <ConfirmationModal
        show={showConfirmationModal}
        onHide={() => setShowConfirmationModal(false)}
        // icon="./assets/confirm-tick.png"
        type="Password"
        content="Your Password Successfully changed."
        showCloseIcon={true}
      />
      <div className="login-main-wrapper">
        <div className="login-main-wrap container">
          <h3> Create new Password </h3>

          <div className="login-form-main-wrap">
            <div className="login-option">
              <form autoComplete="off">
                <InputField
                  type={passwordShown ? "text" : "password"}
                  name="password"
                  id="password"
                  label="New Passwords"
                  passwordIcon={true}
                  togglePassword={togglePassword}
                  iconDisplaye={passwordShown}
                  onChange={loginHandler}
                />

                <InputField
                  type={confirmPasswordShown ? "text" : "password"}
                  name="password"
                  id="password"
                  label="Confirm Password"
                  passwordIcon={true}
                  togglePassword={toggleConfirmPassword}
                  iconDisplaye={confirmPasswordShown}
                  onChange={loginHandler}
                />
              </form>
              <div className="login-button">
                <Button
                  isLoading={isLoading}
                  onClick={passwordChangeHandler}
                  label="Save Password"
                />
              </div>
              <div className="login-with-google-main-wrapper">
                <div className="full ordivider">
                  <span>Or</span>
                </div>

                <div className="login-with-google-btn-wrap">
                  <Button
                    extraClass="gray-btn"
                    label="Continue with Google"
                    icon="./assets/icons/google-icon.png"
                  />
                </div>
                <div className="login-screen-description">
                  <p>
                    By proceeding, you consent to receive calls and SMS
                    messages, including those sent by automated means, from YFOS
                    and its affiliates at the number provided.
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default SetForgotPassword;

import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  orderData: null,
};

const orderChangeSlice = createSlice({
  name: "orderChange",
  initialState,
  reducers: {
    setOrderData: (state, action) => {
      state.orderData = action.payload;
    },
    clearOrderData: (state) => {
      state.orderData = null;
    },
  },
});

export const { setOrderData, clearOrderData } = orderChangeSlice.actions;

export default orderChangeSlice.reducer;

import React, { useEffect, useState } from "react";
import "../services.css";
import "./changeOrder.css";
import InputField from "../../UIComponents/InputField";
import RangeSlider from "../../UIComponents/RangeSlider";
import CardItem from "../../cardItem/CardItem";
import ExtraItems from "../../cardItem/ExtraItems";
import Button from "../../UIComponents/Button";
import OverlayTrigger from "react-bootstrap/OverlayTrigger";
import Tooltip from "react-bootstrap/Tooltip";

import { useDispatch } from "react-redux";
import {
  addToCart,
  removeToCart,
  updateFlooringPercentage,
  updateMoppingPercentage,
  updateDustingPercentage,
  updateSqureFeetPercentage,
} from "../../../redux/features/cartSlice";
import { resetSpecificDropdown } from "../../../redux/features/dropdownSlice";
import { updateFormData } from "../../../redux/features/formSlice";
import { useMediaQuery } from "react-responsive";
import { useLocation, useNavigate } from "react-router-dom";
import { useSelector } from "react-redux";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import moment from "moment";
import TimePicker from "../components/TimePicker";
import AddressDetails from "../components/AddressDetails";
import { setError } from "../../../redux/features/errorSlice";
import { servicePost } from "../../../helper/api";
import ServiceDropdwons from "../components/ServiceDropdwons";
import PercentageGuideModal from "../../modals/PercentageGuideModal";
import RatingBookings from "../components/RatingBookings";
import Dropdown from "../../dropdown/Dropdown";
import TimeSlot from "../components/TimeSlot";
import SqRangeSlider from "../../UIComponents/SqRangeSlider";

const ChangeOrder = () => {
  const errors = useSelector((state) => state.formErrors);
  const [enableBookBtn, setEnableBookBtn] = useState(false);
  const [enableContinueBtn, setEnableContinueBtn] = useState(false);
  const [selectedDate, setSelectedDate] = useState(null);
  const isMobile = useMediaQuery({ maxWidth: 1024 });
  const percentageData = useSelector((state) => state.allCart);
  const cartItems = useSelector((state) => state.allCart.carts);

  // Residentail store Washroom Window and Extra Data
  const [washroomData, setWashroomData] = useState([]);
  const [windowsData, setWindowsData] = useState([]);
  const [extrasData, setExtrasData] = useState([]);

  // get AuthToken from localStorage
  const authToken = localStorage.getItem("token");
  const notification = "";

  //Set Dropdown datas
  const [showPercentageGuideModal, setShowPercentageGuideModal] =
    useState(false);

  const [floorinPercentage, setFlooringPercentage] = useState(
    percentageData.flooringPercentage
  );

  const [moppingPercentage, setMoppingPercentage] = useState(
    percentageData.moppingPercentage
  );
  const [dustingPercentage, setDustingPercentage] = useState(
    percentageData.dustingPercentage
  );

  const [squreFeetPercentage, setSqureFeetPercentage] = useState(
    percentageData.squreFeetPercentage
  );

  //get orderData from navigate state
  const location = useLocation();
  const orderData = location?.state?.orderData;

  const formData = useSelector((state) => state.formData);
  const handleInputChange = (event) => {
    const { name, value } = event.target;
    if (value == "") {
      dispatch(setError({ field: name, value: true }));
    } else {
      dispatch(setError({ field: name, value: false }));
    }
    dispatch(updateFormData({ field: name, value }));
  };

  /// Add to cart
  const dispatch = useDispatch();
  const addToCartHandler = (data) => {
    dispatch(addToCart(data));
  };

  const removeFromCartHandler = (data) => {
    dispatch(removeToCart(data));
  };

  // handle Flooring Pecsentage Change
  function handleFlooringPercentage(event) {
    setFlooringPercentage(parseInt(event.target.value));
    setMoppingPercentage(parseInt(100 - event.target.value));
    dispatch(updateFlooringPercentage(parseInt(event.target.value)));
    dispatch(updateMoppingPercentage(parseInt(100 - event.target.value)));
  }

  // handle Dusting Percentage Change
  function handleDustingPercentage(event) {
    setDustingPercentage(parseInt(event.target.value));
    dispatch(updateDustingPercentage(parseInt(event.target.value)));
  }

  // handle SqureFeet Percentage Change
  function handleSqureFeetPercentage(event) {
    setSqureFeetPercentage(parseInt(event.target.value));
    dispatch(updateSqureFeetPercentage(parseInt(event.target.value)));
  }

  // Update PersentageData When update Some thing
  useEffect(() => {
    setFlooringPercentage(percentageData.flooringPercentage);
    setDustingPercentage(percentageData.dustingPercentage);
    setSqureFeetPercentage(percentageData.squreFeetPercentage);
  }, [percentageData]);

  const navigate = useNavigate();
  // go to Booking confirmation
  const goToBookingConfirmation = () => {
    // if (!formData?.squareFeet) {
    //   dispatch(setError({ field: "squareFeet", value: true }));
    //   return;
    // }

    navigate("/change-order-confirmation", {
      state: {
        orderData: orderData,
      },
    });

    dispatch(updateFlooringPercentage(floorinPercentage));
    dispatch(updateMoppingPercentage(moppingPercentage));
    dispatch(updateDustingPercentage(dustingPercentage));
    dispatch(updateSqureFeetPercentage(squreFeetPercentage));
  };

  const goToMobileServices = () => {
    navigate("/change-order-mobile", {
      state: {
        orderData: orderData,
      },
    });
  };

  // HandleDate Change
  const handleDateChange = (date) => {
    setSelectedDate(date);
    dispatch(updateFormData({ field: "scheduleDate", value: date }));
    if (date) {
      dispatch(setError({ field: "date", value: false }));
    }
  };

  useEffect(() => {
    if (orderData?.scheduleDate) {
      setEnableBookBtn(true);
    } else {
      setEnableBookBtn(false);
    }
  }, [formData, formData?.scheduleDate, orderData]);

  // Get Washrooms Window and Addition Data
  const getResidentialData = async () => {
    try {
      const residentialDataRes = await servicePost(
        "residential/getResidetialData",
        {
          industry: orderData?.industry,
          // industry: "House",
        },
        notification,
        authToken
      );

      const responseData = residentialDataRes.data;
      setWashroomData(responseData.BathroomData);
      setWindowsData(responseData.WindowsData);
      setExtrasData(responseData.extraData);
    } catch (error) {
      console.log("error", error);
    }
  };

  useEffect(() => {
    getResidentialData();
  }, [orderData?.industry]);

  // get Paltfrom fee and Texes
  const getPaltfromFeeAndTexs = async () => {
    try {
      const getFeeAndTexsRes = await servicePost(
        "booking/getTaxFeesData",
        {
          country: "canada",
          // city: dropdownValues?.city?.name?.toLowerCase(),
          city: "toronto",
        },
        notification,
        authToken
      );

      const data = getFeeAndTexsRes.data[0];
      console.log("getFeeAndTexsRes", data);
      console.log("data", data.platformFees);

      dispatch(updateFormData({ field: "taxes", value: data.tax }));
      dispatch(
        updateFormData({ field: "platformFees", value: data.platformFees })
      );
    } catch (error) {
      console.log("error", error);
    }
  };

  useEffect(() => {
    getPaltfromFeeAndTexs();
  }, [orderData?.address]);

  return (
    <>
      <PercentageGuideModal
        show={showPercentageGuideModal}
        onHide={() => setShowPercentageGuideModal(false)}
        // icon="../assets/confirm-tick.png"
        type="Forget Password"
        content="Reset Password otp send to your registered email"
      />
      <div className="dashboard-services-main-wrapper">
        <div className="dashboard-services-main-wrap container">
          <div className="service-header-wrap">
            <div className="service-header-left">
              <h3 className="for-desktop-view"> Residential Cleaning </h3>
              <h3 className="for-mobile-view"> Residential </h3>
              <div className="service-header-reating">
                <img src="../assets/icons/review-icon.png" alt="icon" />
                {/* <p> 4.8 (102k bookings) </p> */}
                <RatingBookings />
              </div>
            </div>

            <div className="service-header-right">
              <div className="service-right-nav">
                <p> Home </p>
                <img src="../assets/icons/right-black-arrow.svg" alt="arrow" />
                <h6> Residential </h6>
              </div>
            </div>
          </div>

          <div className="dashboard-service-detail-wrapper">
            <div className="dashboard-service-detail-wrap">
              <div className="row">
                <div className="col-md-12 col-lg-4">
                  <div
                    className={`address-detail-wrapper ${
                      !isMobile && "disabled-section"
                    }`}
                    style={{ pointerEvents: "none", opacity: 0.6 }}
                  >
                    {/* <AddressDetails /> */}
                    <div className="address-detail-wrap">
                      <h4>Your Address Details</h4>
                      <div className="address-form-input-wrapper">
                        <div className="login-form-main-wrap">
                          <div className="login-option">
                            <form>
                              <InputField
                                extraClass={errors.address ? "error-msg" : ""}
                                type="text"
                                name="address"
                                id="address"
                                value={orderData?.address}
                                label={
                                  errors.address ? "Enter Address" : "Address*"
                                }
                              />
                            </form>
                          </div>
                          <div className="address-city-postal-wrapper">
                            <div className="postal-code-option">
                              <label>Address line 2</label>
                              <input
                                type="text"
                                name="postalcode"
                                id="postalcode"
                                value={orderData?.addressLine2}
                                maxLength="7"
                                placeholder="Eg: A1B 2C3"
                              />
                            </div>
                            <div className="postal-code-option">
                              <label>Postal Code</label>
                              <input
                                type="text"
                                name="postalcode"
                                id="postalcode"
                                value={orderData?.postalCode}
                                maxLength="7"
                                placeholder="Eg: A1B 2C3"
                              />
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>

                    <div className="service-select-wrapper">
                      <div className="service-select-wrap">
                        {/* <ServiceDropdwons /> */}
                        <div className="service-select-dropdown-wrap">
                          <h4> Select a service </h4>

                          <Dropdown
                            label="Industry"
                            alreadyValue={{
                              name: orderData.industry,
                            }}
                            checkIcon="../assets/icons/drowdown-check.svg"
                          />
                          {orderData.floors && (
                            <Dropdown
                              label="How many Floors"
                              alreadyValue={{
                                name: orderData.floors,
                              }}
                              checkIcon="../assets/icons/drowdown-check.svg"
                            />
                          )}

                          {orderData.bedrooms && (
                            <Dropdown
                              label="Type"
                              alreadyValue={{
                                name: orderData.bedrooms,
                              }}
                              checkIcon="../assets/icons/drowdown-check.svg"
                            />
                          )}
                        </div>
                      </div>
                    </div>
                  </div>
                  {isMobile && (
                    <div className="mobile-continue-button">
                      <Button onClick={goToMobileServices} label="Continue" />
                    </div>
                  )}
                </div>

                <div className="col-md-12 col-lg-8">
                  {!isMobile && (
                    <div className="home-service-detail-main-wrapper">
                      <div className="home-service-detail-main-wrap">
                        {!orderData?.floors ? (
                          <div className="empty-service-wrapper">
                            <img src="../assets/empty-image.png" alt="img" />
                            {formData?.addressComplete ? (
                              <p> Select your service Now</p>
                            ) : (
                              <p> Fill in your address details</p>
                            )}
                          </div>
                        ) : (
                          <div className="selected-service-detail-wrapper">
                            <div className="selected-service-detail-wrap">
                              <div className="selected-service-detail-left">
                                <div className="home-sequare-feet-wrap">
                                  {/* <h4> Enter Your Home Square Feet</h4> */}
                                  {/* <div className="login-option sequirefeet-input-wrap">
                                    <form>
                                      <InputField
                                        extraClass={
                                          errors?.squareFeet
                                            ? `squirefeet-input error-msg`
                                            : "squirefeet-input"
                                        }
                                        type="number"
                                        onChange={handleInputChange}
                                        value={formData?.squareFeet}
                                        name="squareFeet"
                                        id="squareFeet"
                                        label="Enter Square Feet of your Building"
                                        passwordIcon={false}
                                        // placeholder="1000 Sq"
                                      />
                                      <p className="sq-placeholder"> Sq </p>
                                    </form>
                                    <div className="squrefeet-input-img">
                                      <img
                                        src="../assets/squire-feet.png"
                                        alt="img"
                                      />
                                    </div>
                                  </div> */}

                                  <div className="dusting-slider-wrap squrefeet-slider-wrap">
                                    <SqRangeSlider
                                      percentage={squreFeetPercentage}
                                      onChange={handleSqureFeetPercentage}
                                      step="100"
                                      min="500"
                                      max="20000"
                                      leftIcon="./assets/squire-feet.png"
                                      // extraClass="dusting-slider"
                                      leftName="Select Sq of Property"
                                      extraClass="flooring-slider"
                                    />
                                  </div>
                                </div>

                                <div className="home-cleaning-persentage-warpper">
                                  <div className="home-cleaning-persentage-warp">
                                    <div className="cleaning-persentage-info">
                                      <h4> Select your Service </h4>
                                      <OverlayTrigger
                                        placement="right"
                                        delay={{ show: 250, hide: 400 }}
                                        overlay={
                                          <Tooltip id={`tooltip-right`}>
                                            Can you specify the percentage of
                                            cleaning needed? For example, what
                                            percentage of your bedroom space
                                            requires vacuuming? Please provide
                                            an estimate in percentage. (Slider
                                            Left right to adjust the %)
                                          </Tooltip>
                                        }
                                      >
                                        <img
                                          src="../assets/icons/info.svg"
                                          alt="info-icon"
                                        />
                                      </OverlayTrigger>
                                    </div>
                                  </div>
                                  <div className="flooring-slider-wrap">
                                    <div className="flooring-slider-info-wrap">
                                      <h5 className="flooring-text">
                                        Flooring
                                      </h5>
                                      <OverlayTrigger
                                        placement="top"
                                        delay={{ show: 250, hide: 400 }}
                                        overlay={
                                          <Tooltip id={`tooltip-top`}>
                                            Slide from left to right to set your
                                            vacuum and mopping percentages.
                                          </Tooltip>
                                        }
                                      >
                                        <img
                                          src="./assets/icons/info.svg"
                                          alt="info-icon"
                                        />
                                      </OverlayTrigger>
                                    </div>
                                    <RangeSlider
                                      percentage={floorinPercentage}
                                      moppingPercentage={moppingPercentage}
                                      onChange={handleFlooringPercentage}
                                      leftIcon="../assets/icons/vacuum-icon.png"
                                      rightIcon="../assets/icons/mopping-icon.png"
                                      leftName="Vaccum Cleaning"
                                      rightName="Mopping or sweeping"
                                      extraClass="flooring-slider"
                                    />
                                  </div>

                                  <div className="dusting-slider-wrap">
                                    <div className="flooring-slider-info-wrap">
                                      <h5 className="dusting-text">Dusting</h5>
                                      <OverlayTrigger
                                        placement="top"
                                        delay={{ show: 250, hide: 400 }}
                                        overlay={
                                          <Tooltip id={`tooltip-top`}>
                                            Slide from left to right to set your
                                            Dusting percentages.
                                          </Tooltip>
                                        }
                                      >
                                        <img
                                          src="./assets/icons/info.svg"
                                          alt="info-icon"
                                        />
                                      </OverlayTrigger>
                                    </div>
                                    <RangeSlider
                                      percentage={dustingPercentage}
                                      onChange={handleDustingPercentage}
                                      leftIcon="../assets/dusting-slider.png"
                                      extraClass="dusting-slider"
                                    />
                                  </div>

                                  <div className="service-items-main-wrapper">
                                    <div className="service-items-main-wrap">
                                      <h5> Washroom </h5>
                                      <CardItem
                                        data={washroomData}
                                        addItems={addToCartHandler}
                                        removeItem={removeFromCartHandler}
                                      />
                                    </div>
                                  </div>

                                  <div className="service-items-main-wrapper">
                                    <div className="service-items-main-wrap">
                                      <h5> Windows </h5>
                                      <CardItem
                                        data={windowsData}
                                        addItems={addToCartHandler}
                                        removeItem={removeFromCartHandler}
                                      />
                                    </div>
                                  </div>
                                </div>
                              </div>

                              <div className="selected-service-detail-right">
                                <div className="service-items-main-wrapper">
                                  <div className="service-items-main-wrap extra-service-item-wrap">
                                    <h5> Additional Service </h5>
                                    <ExtraItems
                                      summerItemLength="11"
                                      extraData={extrasData}
                                      addItems={addToCartHandler}
                                      removeItem={removeFromCartHandler}
                                    />
                                  </div>
                                </div>

                                <div className="schedule-main-wrap">
                                  <div
                                    className={`address-detail-wrapper ${
                                      !isMobile && "disabled-section"
                                    }`}
                                    style={{
                                      pointerEvents: "none",
                                      opacity: 0.6,
                                    }}
                                  >
                                    <h5> Schedule </h5>

                                    <div className="date-and-time-wrapper">
                                      <div className="date-input-wrap">
                                        <DatePicker
                                          minDate={new Date()}
                                          maxDate={moment()
                                            .add(30, "days")
                                            .toDate()}
                                          selected={formData?.scheduleDate}
                                          onChange={(date) =>
                                            handleDateChange(date)
                                          }
                                          customInput={
                                            <div className="custom-date-input">
                                              <img
                                                src="../assets/icons/date.svg"
                                                alt="icon"
                                                className="datepicker-icon"
                                              />
                                              <p>Date</p>
                                              <input
                                                className={
                                                  errors?.date
                                                    ? "error-msg"
                                                    : ""
                                                }
                                                type="text"
                                                placeholder="Select date"
                                                value={
                                                  orderData?.scheduleDate
                                                    ? moment(
                                                        orderData?.scheduleDate
                                                      ).format("DD MMM YYYY")
                                                    : ""
                                                }
                                                readOnly
                                              />
                                            </div>
                                          }
                                        />
                                      </div>

                                      <TimePicker
                                        disabled={!selectedDate}
                                        scheduleTime={orderData?.scheduleTime}
                                      />
                                    </div>
                                  </div>
                                  <div className="book-button-wrapper">
                                    <Button
                                      extraClass={
                                        !enableBookBtn ? "disabled-button" : ""
                                      }
                                      // disabled={true}
                                      onClick={goToBookingConfirmation}
                                      // isLoading={isLoading}
                                      label="Book"
                                    />
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                        )}
                      </div>
                    </div>
                  )}
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default ChangeOrder;

import React, { useState, useEffect } from "react";
import "./footer.css";
import { useLocation, useNavigate } from "react-router-dom";
import { servicePost } from "../../helper/api";
import { useSelector } from "react-redux";

const Footer = () => {
  const location = useLocation();
  const currentpath = location.pathname;
  const [notificationCount, setNotificationCount] = useState();
  const logedInUser = useSelector((state) => state.user.userInfo);
  const notificationDep = useSelector(
    (state) => state.notificationDep.notificationDep
  );

  // get AuthToken from localStorage
  const authToken = localStorage.getItem("token");
  const notification = "";

  const navigate = useNavigate();

  const changeOrderPath = currentpath.includes("change-order");

  const deleteNotificationCount = async () => {
    try {
      const deleteCountRes = await servicePost(
        "user/updateNotification",
        {
          accountId: logedInUser?.id,
        },
        notification,
        authToken
      );

      if (deleteCountRes.message == "SUCCESS") {
        setNotificationCount(0);
      }
    } catch (error) {
      console.log("error", error);
    }
  };

  const goToHomePage = () => {
    navigate("/");
  };

  const goToProfilePage = () => {
    navigate("/profile");
  };

  const goToMyCartPage = () => {
    navigate("/mobile-cart");
  };

  const goToNotification = () => {
    navigate("/mobile-notification");
    deleteNotificationCount();
  };

  // Get Notification Count
  const getNotificationCount = async () => {
    try {
      const notificationCountRes = await servicePost(
        `user/latestNotificationCount`,
        {
          accountId: logedInUser?.id,
        },
        notification,
        authToken
      );
      setNotificationCount(notificationCountRes.data);
    } catch (error) {
      console.log("error", error);
    }
  };

  useEffect(() => {
    getNotificationCount();
  }, [logedInUser?.id, notificationCount, notificationDep]);

  return (
    <>
      <div
        className="mobile-footer-main-wrapper"
        style={
          changeOrderPath
            ? { pointerEvents: "none", cursor: "not-allowed" }
            : {}
        }
      >
        <div
          className="mobile-footer-main-wrap container"
          style={
            changeOrderPath ? { display: "none", cursor: "not-allowed" } : {}
          }
        >
          <div
            className={
              currentpath === "/"
                ? "mobile-footer-icon-warp active"
                : "mobile-footer-icon-warp"
            }
          >
            <svg
              onClick={goToHomePage}
              xmlns="http://www.w3.org/2000/svg"
              width="53"
              height="53"
              viewBox="0 0 53 53"
            >
              <defs>
                <clipPath id="clipPath">
                  <rect
                    id="Rectangle_13444"
                    data-name="Rectangle 13444"
                    width="53"
                    height="53"
                    transform="translate(0 0.001)"
                    fill="#fff"
                    opacity="0"
                  />
                </clipPath>
              </defs>
              <g
                id="Home_Icon"
                data-name="Home Icon"
                transform="translate(0 -0.001)"
                clipPath="url(#clip-path)"
              >
                <g id="home" transform="translate(13.692 13.544)">
                  <path
                    id="Path_31581"
                    data-name="Path 31581"
                    d="M195.2,319.841a3.2,3.2,0,0,0-3.2,3.2v6.4h6.4v-6.4A3.2,3.2,0,0,0,195.2,319.841Z"
                    transform="translate(-182.396 -303.69)"
                    fill="#fff"
                  />
                  <g
                    id="Group_53192"
                    data-name="Group 53192"
                    transform="translate(0 0.16)"
                  >
                    <path
                      id="Path_31582"
                      data-name="Path 31582"
                      d="M18.142,19.352v6.4H22.41a3.2,3.2,0,0,0,3.2-3.2V12.82a2.134,2.134,0,0,0-.6-1.485l-9.069-9.8A4.269,4.269,0,0,0,9.91,1.294q-.123.114-.237.237L.62,11.332a2.134,2.134,0,0,0-.62,1.5v9.717a3.2,3.2,0,0,0,3.2,3.2H7.47v-6.4a5.336,5.336,0,1,1,10.671,0Z"
                      transform="translate(0 -0.16)"
                      fill="#fff"
                    />
                    <path
                      id="Path_31583"
                      data-name="Path 31583"
                      d="M195.2,319.841a3.2,3.2,0,0,0-3.2,3.2v6.4h6.4v-6.4A3.2,3.2,0,0,0,195.2,319.841Z"
                      transform="translate(-182.396 -303.85)"
                      fill="#fff"
                    />
                  </g>
                </g>
              </g>
            </svg>
          </div>
          <div
            className={
              currentpath === "/mobile-cart"
                ? "mobile-footer-icon-warp active"
                : "mobile-footer-icon-warp"
            }
          >
            <svg
              onClick={goToMyCartPage}
              xmlns="http://www.w3.org/2000/svg"
              width="53"
              height="53"
              viewBox="0 0 53 53"
            >
              <defs>
                <clipPath id="clip-path">
                  <rect
                    id="Rectangle_13445"
                    data-name="Rectangle 13445"
                    width="53"
                    height="53"
                    transform="translate(0 0.001)"
                    fill="#fff"
                    opacity="0"
                  />
                </clipPath>
              </defs>
              <g
                id="Cart_Icon"
                data-name="Cart Icon"
                transform="translate(0 -0.001)"
                clipPath="url(#clip-path)"
              >
                <g id="shopping-cart" transform="translate(12.526 12.175)">
                  <path
                    id="Path_18689"
                    data-name="Path 18689"
                    d="M27.116,4.867a3.573,3.573,0,0,0-2.749-1.286H5.064l-.05-.419A3.582,3.582,0,0,0,1.459,0H1.194a1.194,1.194,0,0,0,0,2.388h.265A1.194,1.194,0,0,1,2.644,3.442L4.287,17.41a5.969,5.969,0,0,0,5.929,5.273H22.683a1.194,1.194,0,0,0,0-2.388H10.216a3.582,3.582,0,0,1-3.367-2.388H21.08A5.969,5.969,0,0,0,26.955,13l.937-5.2a3.574,3.574,0,0,0-.776-2.932Z"
                    fill="#fff"
                  />
                  <circle
                    id="Ellipse_113"
                    data-name="Ellipse 113"
                    cx="2.388"
                    cy="2.388"
                    r="2.388"
                    transform="translate(5.969 23.877)"
                    fill="#fff"
                  />
                  <circle
                    id="Ellipse_114"
                    data-name="Ellipse 114"
                    cx="2.388"
                    cy="2.388"
                    r="2.388"
                    transform="translate(17.908 23.877)"
                    fill="#fff"
                  />
                </g>
                <g
                  id="Group_975"
                  data-name="Group 975"
                  transform="translate(-1311.579 -17.502)"
                >
                  <circle
                    id="Ellipse_110"
                    data-name="Ellipse 110"
                    cx="10.29"
                    cy="10.29"
                    r="10.29"
                    transform="translate(1385 22)"
                    fill="#fff"
                  />
                  <text
                    id="_2"
                    data-name="2"
                    transform="translate(1391.291 24.79)"
                    fill="#00A19B"
                    fontSize="12"
                    fontFamily="Montserrat-Medium, Montserrat"
                    fontWeight="500"
                  >
                    <tspan x="-3" y="12">
                      2
                    </tspan>
                  </text>
                </g>
              </g>
            </svg>
          </div>
          <div
            className={
              currentpath === "/mobile-notification"
                ? "mobile-footer-icon-warp footer-notification active"
                : "mobile-footer-icon-warp footer-notification"
            }
          >
            <svg
              onClick={goToNotification}
              xmlns="http://www.w3.org/2000/svg"
              width="53"
              height="53"
              viewBox="0 0 53 53"
            >
              <defs>
                <clipPath id="clip-path">
                  <rect
                    id="Rectangle_13654"
                    data-name="Rectangle 13654"
                    width="53"
                    height="53"
                    transform="translate(0 0.001)"
                    fill="#fff"
                    opacity="0"
                  />
                </clipPath>
              </defs>
              <g
                id="Notification_Icon"
                data-name="Notification Icon"
                transform="translate(0 -0.001)"
                clipPath="url(#clip-path)"
              >
                <g id="bell" transform="translate(13.23 12.918)">
                  <path
                    id="Path_13"
                    data-name="Path 13"
                    d="M7.424,21a5.648,5.648,0,0,0,10.36,0Z"
                    transform="translate(0.836 2.771)"
                    fill="#fff"
                  />
                  <path
                    id="Path_14"
                    data-name="Path 14"
                    d="M25.2,14.205,23.238,7.727a10.551,10.551,0,0,0-20.461.514L1.251,14.508a5.66,5.66,0,0,0,5.5,7H19.787a5.66,5.66,0,0,0,5.416-7.3Z"
                    transform="translate(0)"
                    fill="#fff"
                  />
                </g>
              </g>
            </svg>
            {notificationCount > 0 && (
              <div className="footer-notification-count-wrap"></div>
            )}
          </div>
          <div
            className={
              currentpath === "/profile"
                ? "mobile-footer-icon-warp active"
                : "mobile-footer-icon-warp"
            }
          >
            <svg
              onClick={goToProfilePage}
              xmlns="http://www.w3.org/2000/svg"
              width="53"
              height="53"
              viewBox="0 0 53 53"
            >
              <defs>
                <clipPath id="clip-path">
                  <rect
                    id="Rectangle_13653"
                    data-name="Rectangle 13653"
                    width="53"
                    height="53"
                    transform="translate(0 0.001)"
                    fill="#fff"
                    opacity="0"
                  />
                </clipPath>
              </defs>
              <g
                id="Profile_Icon"
                data-name="Profile Icon"
                transform="translate(0 -0.001)"
                clipPath="url(#clip-path)"
              >
                <g id="user" transform="translate(-68.271 10.918)">
                  <path
                    id="Union_42"
                    data-name="Union 42"
                    d="M23966.27-1391.749a7.084,7.084,0,0,1,7.084-7.083,7.084,7.084,0,0,1,7.084,7.083,7.084,7.084,0,0,1-7.084,7.083A7.088,7.088,0,0,1,23966.27-1391.749Z"
                    transform="translate(-23878.582 1398.831)"
                    fill="#fff"
                  />
                  <ellipse
                    id="Ellipse_1930"
                    data-name="Ellipse 1930"
                    cx="12.5"
                    cy="7"
                    rx="12.5"
                    ry="7"
                    transform="translate(82.27 17.169)"
                    fill="#fff"
                  />
                </g>
              </g>
            </svg>
          </div>
        </div>
      </div>
    </>
  );
};

export default Footer;

import React, { useState } from "react";
import Modal from "react-bootstrap/Modal";
import "./modal.css";

const NoTimeModal = (props) => {
  return (
    <Modal
      {...props}
      size="lg"
      backdrop="static"
      dialogClassName="confirmation-modal-main-wrapper speciality-modal-main-wrapper"
      aria-labelledby="contained-modal-title-vcenter"
      centered
    >
      <Modal.Body>
        <div className="confirm-modal-main-wrapper">
          <div className="confirm-modal-main-wrap">
            <div className="modal-logo-wrap">
              <img src="./assets/modal-logo.png" alt="modal-logo" />
            </div>

            <div className="close-icon-wrap">
              <img src="./assets/icons/close-icon.svg" alt="icon" />
            </div>

            <div className="specaility-modal-content-wrap">
              <h3> Time was not Available</h3>
              <p>
                Your Previous selected items will be Removed from your cart. If
                you add these services
              </p>
              <div className="modal-leave-stay-btn-wrap">
                <p> You want to leave this Current to Page </p>
                <div className="modal-leave-stay-btn">
                  <button className="simple-button">Try again</button>
                  <button className="add-to-cart-btn">Cancel Order</button>
                </div>
              </div>
            </div>
          </div>
        </div>
      </Modal.Body>
    </Modal>
  );
};
export default NoTimeModal;

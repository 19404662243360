import React, { Fragment, useEffect, useState } from "react";
import "../bookingconfirmation.css";

import { useLocation, useNavigate } from "react-router-dom";
import { useSelector } from "react-redux";
import { useDispatch } from "react-redux";
import {
  addToCart,
  removeToCart,
  updateFlooringPercentage,
  updateMoppingPercentage,
  updateDustingPercentage,
} from "../../../redux/features/cartSlice";

import { updateDropdown } from "../../../redux/features/dropdownSlice";
import Offcanvas from "react-bootstrap/Offcanvas";
import PercentageItem from "../components/PercentageItem";
import ProductItem from "../components/ProductItem";
import InputField from "../../UIComponents/InputField";
import Button from "../../UIComponents/Button";
import CouponDrawer from "../../drawers/CouponDrawer";
import PaymentDetail from "../components/PaymentDetail";
import TermsModal from "../../modals/TermsModal";
import PostPaidDrawer from "../../drawers/PostPaidDrawer";
import { useMediaQuery } from "react-responsive";
import { updateFormData } from "../../../redux/features/formSlice";
import OverlayTrigger from "react-bootstrap/OverlayTrigger";
import Tooltip from "react-bootstrap/Tooltip";
import { servicePost } from "../../../helper/api";
import {
  selectChangeOrderSubTotal,
  selectSubTotal,
} from "../../../redux/features/selectors";
import { setOrderBookingId } from "../../../redux/features/bookingSlice";
import moment from "moment";
import { setNotificationDep } from "../../../redux/features/notificationDepSlice";
import RatingBookings from "../components/RatingBookings";
import NoTimeModal from "../../modals/NoTimeModal";
import OrderCompleteModal from "../../modals/OrderCompleteModal";
import OrderCancelledModal from "../../modals/OrderCancelledModal";
import TermModalChangeOrder from "../../modals/TermModalChangeOrder";
import IndustryCharge from "../components/IndustryCharge";
import { updateFlooringPrices } from "../../../redux/features/flooringPriceSlice";

const ChangeOrderConfirmation = () => {
  const [isLoading, setIsLoading] = useState(false);
  const [bookingId, setBookingId] = useState();
  const isMobile = useMediaQuery({ maxWidth: 1024 });
  const cartItems = useSelector((state) => state.allCart.carts);
  const percentageData = useSelector((state) => state.allCart);
  const formData = useSelector((state) => state.formData);
  const couponData = useSelector((state) => state.coupon);

  const [showTermModal, setShowTermModal] = useState(false);
  const [showCouponDrawer, setShowCouponDrawer] = useState(false);
  const [showPostpadDrawer, setShowPostpadDrawer] = useState(false);
  const handleCloseCouponDrawer = () => setShowCouponDrawer(false);
  const [selectedReason, setSelectedReason] = useState("send-otp");
  const otpAccess = selectedReason == "send-otp" ? true : false;

  const [showCancelModal, setShowCancelModal] = useState(false);
  const notificationDep = useSelector(
    (state) => state.notificationDep.notificationDep
  );

  // get loggedInUser for Redux
  const logedInUser = useSelector((state) => state.user.userInfo);
  const flooringPrices = useSelector(
    (state) => state.flooringPrice.flooringPrice
  );

  // get AuthToken from localStorage
  const authToken = localStorage.getItem("token");
  const notification = "";

  const location = useLocation();
  const currentpath = location.pathname;
  const orderData = location?.state?.orderData;
  console.log("BookingOrderData", orderData.bookingId);

  // Get subTotal cartItem price + flooring + dusting Price
  const TexFees = 30;
  const subtotalSelector = selectChangeOrderSubTotal(
    couponData?.couponDiscountPercentage,
    orderData
  );

  const {
    subTotal,
    discountAmount,
    cartItemsPrice,
    totalItemPrice,
    subTotalnoIndustryCharge,
    totalAmount,
    totalAmountNoYfosFee,
    yfosServiceFees,
    platformFeeAmount,
    taxAmount,
  } = useSelector(subtotalSelector);

  const FeesAndTexAmount = platformFeeAmount + taxAmount;
  // YFOS Service Fees
  // const yfosServiceFess =
  //   Number(totalAmountNoYfosFee.toFixed(0)) < 200 ? 200 : 0;

  const handleDivClick = (value) => {
    setSelectedReason(value);
  };

  const handleShowCouponDrawer = (title) => {
    setShowCouponDrawer(true);
  };

  const handleClosePostpaidDrawer = () => setShowPostpadDrawer(false);
  const handleShowPostpaidDrawer = (title) => {
    setShowPostpadDrawer(true);
  };

  const [floorinPercentage, setFlooringPercentage] = useState(
    percentageData.flooringPercentage
  );

  const [moppingPercentage, setMoppingPercentage] = useState(
    percentageData.moppingPercentage
  );

  const [dustingPercentage, setDustingPercentage] = useState(
    percentageData.dustingPercentage
  );

  const navigate = useNavigate();
  const goBackServices = () => {
    navigate(-1);
  };

  /// Add to cart
  const dispatch = useDispatch();
  const handleIncrement = (data) => {
    dispatch(addToCart(data));
  };

  const handleDecrement = (data) => {
    dispatch(removeToCart(data));
  };

  // Increment Flooring Percentage (Decrement Mopping Percentage)
  const incrementFlooringPercentage = () => {
    setFlooringPercentage((prevFlooringPercentage) => {
      if (prevFlooringPercentage < 100) {
        const newFlooringPercentage = prevFlooringPercentage + 1;
        const newMoppingPercentage = 100 - newFlooringPercentage;
        dispatch(updateFlooringPercentage(newFlooringPercentage));
        dispatch(updateMoppingPercentage(newMoppingPercentage));
        setMoppingPercentage(newMoppingPercentage); // Ensure both states are updated together
        return newFlooringPercentage;
      }
      return prevFlooringPercentage;
    });
  };

  // Decrement Flooring Percentage (Increment Mopping Percentage)
  const decrementFlooringPercentage = () => {
    setFlooringPercentage((prevFlooringPercentage) => {
      if (prevFlooringPercentage > 0) {
        const newFlooringPercentage = prevFlooringPercentage - 1;
        const newMoppingPercentage = 100 - newFlooringPercentage;
        dispatch(updateFlooringPercentage(newFlooringPercentage));
        dispatch(updateMoppingPercentage(newMoppingPercentage));
        setMoppingPercentage(newMoppingPercentage); // Ensure both states are updated together
        return newFlooringPercentage;
      }
      return prevFlooringPercentage;
    });
  };

  // Increment Mopping Percentage (Decrement Flooring Percentage)
  const incrementMoppingPercentage = () => {
    setMoppingPercentage((prevMoppingPercentage) => {
      if (prevMoppingPercentage < 100) {
        const newMoppingPercentage = prevMoppingPercentage + 1;
        const newFlooringPercentage = 100 - newMoppingPercentage;
        dispatch(updateMoppingPercentage(newMoppingPercentage));
        dispatch(updateFlooringPercentage(newFlooringPercentage));
        setFlooringPercentage(newFlooringPercentage); // Ensure both states are updated together
        return newMoppingPercentage;
      }
      return prevMoppingPercentage;
    });
  };

  // Decrement Mopping Percentage (Increment Flooring Percentage)
  const decrementMoppingPercentage = () => {
    setMoppingPercentage((prevMoppingPercentage) => {
      if (prevMoppingPercentage > 0) {
        const newMoppingPercentage = prevMoppingPercentage - 1;
        const newFlooringPercentage = 100 - newMoppingPercentage;
        dispatch(updateMoppingPercentage(newMoppingPercentage));
        dispatch(updateFlooringPercentage(newFlooringPercentage));
        setFlooringPercentage(newFlooringPercentage); // Ensure both states are updated together
        return newMoppingPercentage;
      }
      return prevMoppingPercentage;
    });
  };

  // Increment Flooring Percentage
  const incrementDustingPercentage = () => {
    setDustingPercentage((prevDustingPercentage) => {
      if (prevDustingPercentage < 100) {
        const newDustingPercentage = prevDustingPercentage + 1;
        dispatch(updateDustingPercentage(newDustingPercentage));
        return newDustingPercentage;
      }
      return prevDustingPercentage; // No change if it already is 100
    });
  };

  // Decrement Flooring Percentage
  const decrementDustingPercentage = () => {
    setDustingPercentage((prevDustingPercentage) => {
      if (prevDustingPercentage > 0) {
        const newDustingPercentage = prevDustingPercentage - 1;
        dispatch(updateDustingPercentage(newDustingPercentage));
        return newDustingPercentage;
      }
      return prevDustingPercentage; // No change if it already is 0
    });
  };

  // Handle Industry Dropdown data
  const handleDropdownChange = (field) => (value) => {
    dispatch(updateDropdown({ field, value }));
  };

  // // handle Input Change
  // const handleInputChange = (event) => {
  //   const { name, value } = event.target;
  //   dispatch(updateFormData({ field: name, value }));
  // };

  // handle Input Change
  const handleInputChange = (event) => {
    const { name, value } = event.target;

    let formattedValue = value;

    if (name === "contactnumber") {
      // Remove all non-numeric characters
      const numericValue = value.replace(/\D/g, "");

      // Format the number as xxx-xxx-xxxx
      if (numericValue.length > 3 && numericValue.length <= 6) {
        formattedValue = `${numericValue.slice(0, 3)}-${numericValue.slice(3)}`;
      } else if (numericValue.length > 6) {
        formattedValue = `${numericValue.slice(0, 3)}-${numericValue.slice(
          3,
          6
        )}-${numericValue.slice(6, 10)}`;
      } else {
        formattedValue = numericValue;
      }
    }

    // Dispatch the formatted value
    dispatch(updateFormData({ field: name, value: formattedValue }));
  };

  // get Current Date and Time
  const currentDate = new Date();
  const bookingDate = currentDate.toLocaleDateString();
  const bookingTime = currentDate.toLocaleTimeString();

  const bookOrderHandler = async () => {
    const createdTime = new Date(orderData.createdAt).getTime();
    const currentTime = Date.now();
    const timeDifference = currentTime - createdTime;

    // 15 minutes in milliseconds
    const fifteenMinutesInMs = 15 * 60 * 1000;
    // Check if the time difference is greater than or equal to 15 minutes
    if (timeDifference >= fifteenMinutesInMs) {
      try {
        const cancelOrderRes = await servicePost(
          "booking/cancelBooking",
          {
            bookingId: orderData?.bookingId,
            cancelStatus: true,
            cancelReason: "Order link expire",
            cancelBy: "User",
          },
          notification,
          authToken
        );
        console.log("cancelOrderRes", cancelOrderRes);
        setShowCancelModal(true);
      } catch (error) {
        console.log("err", error);
        setShowCancelModal(true);
      }
    } else {
      setShowTermModal(true);
    }
  };

  // pass total Amount to Stripe
  const StirpePayAmount = {
    total: totalAmount.toFixed(2),
  };

  const getSqFeetPrice = async () => {
    try {
      const sqFeetPriceRes = await servicePost(
        "booking/getSqFeetData",
        {
          vaccum: floorinPercentage,
          mopping: moppingPercentage,
          dusting: dustingPercentage,
          squarefeet: percentageData.squreFeetPercentage,
          serviceType: orderData.serviceType,
        },
        notification,
        authToken
      );

      console.log("sqFeetPriceRes.data[0]", sqFeetPriceRes.data[0]);

      dispatch(updateFlooringPrices(sqFeetPriceRes.data[0]));
    } catch (error) {
      console.log("error", error);
    }
  };

  useEffect(() => {
    getSqFeetPrice();
  }, [percentageData]);

  return (
    <>
      <TermModalChangeOrder
        orderData={orderData}
        type="Change Order"
        title="Request to Change Order"
        show={showTermModal}
        onHide={() => setShowTermModal(false)}
        showCloseIcon={true}
        bookingId={bookingId}
        bookingData={StirpePayAmount}
        paywith={orderData.paymentMethod == "PostPaid" ? "Postpaid" : "Prepaid"}
      />

      {/* <NoTimeModal show={false} onHide={() => setShowTermModal(false)} /> */}

      <OrderCancelledModal
        show={showCancelModal}
        onHide={() => setShowTermModal(false)}
        orderData={orderData}
      />

      <Offcanvas
        className="coupon-offcanvas-wrapper"
        show={showCouponDrawer}
        onHide={handleCloseCouponDrawer}
        placement="end"
      >
        <Offcanvas.Body>
          <CouponDrawer
            orderData={orderData}
            onHide={handleCloseCouponDrawer}
          />
        </Offcanvas.Body>
      </Offcanvas>

      <Offcanvas
        className="extras-offcanvas-wrapper postpaid-offcanvas-wrapper"
        show={showPostpadDrawer}
        onHide={handleClosePostpaidDrawer}
        placement="end"
      >
        <Offcanvas.Body>
          <PostPaidDrawer onHide={handleClosePostpaidDrawer} />
        </Offcanvas.Body>
      </Offcanvas>
      <div className="dashboard-services-main-wrapper">
        <div className="dashboard-services-main-wrap container">
          {!isMobile && (
            <div className="service-header-wrap">
              <div className="service-header-left">
                <h3>{orderData?.serviceType} Cleaning </h3>
                <div className="service-header-reating">
                  <img src="./assets/icons/review-icon.png" alt="icon" />
                  {/* <p> 4.8 (102k bookings) </p> */}
                  <RatingBookings />
                </div>
              </div>

              <div className="service-header-right">
                <div className="service-right-nav">
                  <p> Home </p>
                  <img src="./assets/icons/right-black-arrow.svg" alt="arrow" />
                  <h6> {orderData?.serviceType}</h6>
                </div>
              </div>
            </div>
          )}

          <div className="booking-confirmation-main-wrapper">
            <div className="booking-confirmation-main-wrap">
              {!isMobile ? (
                <div className="booking-cart-wrapper">
                  <div className="booking-cart-wrap">
                    <div className="booking-cart-head">
                      <img
                        onClick={goBackServices}
                        src="./assets/icons/previous-arrow.svg"
                        alt="icon"
                      />
                      <h4> Your Cart </h4>
                    </div>
                    {orderData?.industry == "Specialty Cleaning" ? (
                      ""
                    ) : (
                      <div className="booking-squarefeet-wrap">
                        <p> Square Feet of your Home </p>
                        <span> {percentageData.squreFeetPercentage} Sq </span>
                      </div>
                    )}

                    <div className="booking-cart-items-wrap">
                      <h5> Items </h5>
                      <div className="booking-cart-flooring-wrap">
                        {orderData?.industry == "Specialty Cleaning" ? (
                          ""
                        ) : (
                          <>
                            {orderData?.industry == "Post Construction" ? (
                              <IndustryCharge
                                image="../assets/icons/yfos-logo.png"
                                category={orderData?.bedrooms}
                                label="Surcharge"
                                percentage={orderData?.industrySurcharge}
                                price={orderData?.industrySurcharge}
                                type="industry charges"
                                pageType="TrankingOrder"
                              />
                            ) : (
                              <IndustryCharge
                                image="../assets/icons/yfos-logo.png"
                                category={orderData?.industry}
                                label="Surcharge"
                                percentage={orderData?.industrySurcharge}
                                price={orderData?.industrySurcharge}
                                type="industry charges"
                                // pageType="TrankingOrder"
                              />
                            )}

                            <IndustryCharge
                              image="../assets/icons/yfos-logo.png"
                              category={orderData?.floors}
                              label="Surcharge"
                              percentage={orderData?.floorSurcharge}
                              price={orderData?.floorSurcharge}
                              type="floors charges"
                              // pageType="TrankingOrder"
                            />

                            <PercentageItem
                              image="./assets/icons/vacuum-icon.png"
                              category="Flooring:"
                              label="Vacuum Cleaning"
                              percentage={percentageData.flooringPercentage}
                              handleIncementPercentage={
                                incrementFlooringPercentage
                              }
                              handleDecrementPercentage={
                                decrementFlooringPercentage
                              }
                              price={flooringPrices?.vaccumprice}
                            />
                            <PercentageItem
                              image="./assets/icons/mopping-icon.png"
                              category="Flooring:"
                              label="Mopping or sweeping"
                              percentage={percentageData.moppingPercentage}
                              handleIncementPercentage={
                                incrementMoppingPercentage
                              }
                              handleDecrementPercentage={
                                decrementMoppingPercentage
                              }
                              price={flooringPrices?.moppingprice}
                            />
                            <PercentageItem
                              image="./assets/dusting-slider.png"
                              category="Dusting:"
                              label=""
                              percentage={percentageData.dustingPercentage}
                              handleIncementPercentage={
                                incrementDustingPercentage
                              }
                              handleDecrementPercentage={
                                decrementDustingPercentage
                              }
                              price={flooringPrices?.dustingprice}
                            />
                          </>
                        )}

                        {cartItems?.map((item, index) => {
                          return (
                            <Fragment key={index}>
                              <ProductItem
                                item={item}
                                handleIncrement={handleIncrement}
                                handleDecrement={handleDecrement}
                              />
                            </Fragment>
                          );
                        })}
                      </div>
                    </div>
                  </div>
                </div>
              ) : (
                <div className="mobile-booking-cart-wrapper">
                  <div className="mobile-booking-cart-wrap">
                    <div className="booking-cart-head">
                      <img
                        onClick={goBackServices}
                        src="./assets/icons/previous-arrow.svg"
                        alt="icon"
                      />
                      <h4> {orderData?.industry} </h4>
                    </div>
                    {orderData?.industry == "Specialty Cleaning" ? (
                      " "
                    ) : (
                      <div className="booking-squarefeet-wrap">
                        <p> Square Feet of your Building </p>
                        <span> {percentageData.squreFeetPercentage} Sq</span>
                      </div>
                    )}

                    <div className="mobile-booking-cart-items-wrap">
                      <h5> Items </h5>
                      <div className="booking-cart-flooring-wrap">
                        <div className="accordion" id="accordionExample">
                          <div className="accordion-item" data-accordion-item>
                            <h2 className="accordion-header" id="headingOne">
                              <button
                                className="accordion-button"
                                type="button"
                                data-bs-toggle="collapse"
                                data-bs-target="#collapseOne"
                                aria-expanded="false"
                                aria-controls="collapseOne"
                              >
                                <div className="accordion-btn-left-wrap">
                                  Cleaning Items
                                </div>
                                <div className="accordion-btn-right-wrap">
                                  ${totalAmount?.toFixed(2)}
                                </div>
                              </button>
                            </h2>
                            <div
                              id="collapseOne"
                              className="accordion-collapse collapse"
                              aria-labelledby="headingOne"
                              data-bs-parent="#accordionExample"
                            >
                              <div className="accordion-body">
                                <div className="mobile-accordion-body-wrapper">
                                  <div className="mobile-flooring-item-wrap">
                                    {orderData?.industry ==
                                    "Specialty Cleaning" ? (
                                      ""
                                    ) : (
                                      <>
                                        {orderData?.industry ==
                                        "Post Construction" ? (
                                          <IndustryCharge
                                            image="../assets/icons/yfos-logo.png"
                                            category={orderData?.bedrooms}
                                            label="Surcharge"
                                            percentage={
                                              orderData?.industrySurcharge
                                            }
                                            price={orderData?.industrySurcharge}
                                            type="industry charges"
                                            pageType="TrankingOrder"
                                          />
                                        ) : (
                                          <IndustryCharge
                                            image="../assets/icons/yfos-logo.png"
                                            category={orderData?.industry}
                                            label="Surcharge"
                                            percentage={
                                              orderData?.industrySurcharge
                                            }
                                            price={orderData?.industrySurcharge}
                                            type="industry charges"
                                            pageType="TrankingOrder"
                                          />
                                        )}

                                        <IndustryCharge
                                          image="../assets/icons/yfos-logo.png"
                                          category={orderData?.floors}
                                          label="Surcharge"
                                          percentage={orderData?.floorSurcharge}
                                          price={orderData?.floorSurcharge}
                                          type="floors charges"
                                          pageType="TrankingOrder"
                                        />
                                        <PercentageItem
                                          image="./assets/icons/vacuum-icon.png"
                                          category="Flooring:"
                                          label="Vacuum Cleaning"
                                          percentage={
                                            percentageData.flooringPercentage
                                          }
                                          handleIncementPercentage={
                                            incrementFlooringPercentage
                                          }
                                          handleDecrementPercentage={
                                            decrementFlooringPercentage
                                          }
                                          price={flooringPrices?.vaccumprice}
                                        />

                                        <PercentageItem
                                          image="./assets/icons/mopping-icon.png"
                                          category="Flooring:"
                                          label="Mopping or sweeping"
                                          percentage={
                                            percentageData.moppingPercentage
                                          }
                                          handleIncementPercentage={
                                            incrementMoppingPercentage
                                          }
                                          handleDecrementPercentage={
                                            decrementMoppingPercentage
                                          }
                                          price={flooringPrices?.moppingprice}
                                        />

                                        <PercentageItem
                                          image="./assets/dusting-slider.png"
                                          category="Dusting:"
                                          label="Dusting:"
                                          percentage={
                                            percentageData.dustingPercentage
                                          }
                                          handleIncementPercentage={
                                            incrementDustingPercentage
                                          }
                                          handleDecrementPercentage={
                                            decrementDustingPercentage
                                          }
                                          price={flooringPrices?.dustingprice}
                                        />
                                      </>
                                    )}

                                    {cartItems?.map((item, index) => {
                                      return (
                                        <Fragment key={index}>
                                          <ProductItem
                                            item={item}
                                            handleIncrement={handleIncrement}
                                            handleDecrement={handleDecrement}
                                          />
                                        </Fragment>
                                      );
                                    })}
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              )}

              <div className="booking-details-wrapper">
                <div className="booking-cart-head">
                  <h4> Booking Details </h4>
                </div>

                <div className="booking-industry-floor-wrap">
                  <p>
                    {orderData?.industry} {orderData?.floors && ","}
                  </p>
                  <p>
                    {orderData?.floors} {orderData?.bedrooms && ","}
                  </p>
                  <p>{orderData?.bedrooms}</p>
                  <img src="./assets/icons/verify-check.svg" alt="icon" />
                </div>

                <div className="booking-detail-address-wrap">
                  <div className="booking-detail-address">
                    <p> Address </p>
                    <h6>{orderData?.address}</h6>
                  </div>

                  <div className="booking-detail-address">
                    <p> City </p>
                    <h6>{orderData?.city}</h6>
                  </div>
                </div>

                <div className="contact-person-booking-detail">
                  <div className="contact-person-heading">
                    <h4> Contact Person (Optional) </h4>
                    <OverlayTrigger
                      placement="top"
                      delay={{ show: 250, hide: 400 }}
                      overlay={
                        <Tooltip id={`tooltip-top`}>
                          This is Optional Information
                        </Tooltip>
                      }
                    >
                      <img src="./assets/icons/info.svg" alt="info-icon" />
                    </OverlayTrigger>
                  </div>

                  <div className="login-option">
                    <form>
                      <InputField
                        type="text"
                        name="contactname"
                        id="contactname"
                        label="Enter Name"
                        maxLength="50"
                        value={formData?.contactname}
                        passwordIcon={false}
                        onChange={handleInputChange}
                      />
                      <InputField
                        type="text"
                        name="contactnumber"
                        id="contactnumber"
                        label="Enter Number"
                        value={formData?.contactnumber}
                        passwordIcon={false}
                        onChange={handleInputChange}
                      />
                    </form>
                  </div>
                </div>

                {orderData?.serviceType === "Residential" ? (
                  <div className="yfos-promise-main-wrapper">
                    <div className="yfos-promise-main-wrap">
                      <div className="yfos-promise-head">
                        <div className="yfos-promise-head-left">
                          <h3> YFOS</h3>
                          <h4> Promise </h4>
                        </div>
                      </div>

                      <div className="yfos-promise-list-wrap">
                        <div className="yfos-promise-list-repeat">
                          <img src="./assets/icons/check.svg" alt="icon" />
                          <p> Verified Professionals </p>
                        </div>
                        <div className="yfos-promise-list-repeat">
                          <img src="./assets/icons/check.svg" alt="icon" />
                          <p> Safe Chemicals </p>
                        </div>
                        <div className="yfos-promise-list-repeat">
                          <img src="./assets/icons/check.svg" alt="icon" />
                          <p> Superior Stain Removal </p>
                        </div>
                      </div>
                    </div>
                  </div>
                ) : (
                  <div className="yfos-promise-main-wrapper yfos-promise-otp-main-wrapper">
                    <div
                      className="yfos-promise-main-wrap yfos-promise-otp-main-wrap"
                      style={{
                        pointerEvents: "none",
                        opacity: 0.5,
                      }}
                    >
                      <div className="commercial-yfos-otp">
                        <img src="./assets/otp-img.png" alt="icon" />
                      </div>
                      <div className="commercial-otp-heading">
                        <h3> Cleaner Access OTP Verification</h3>
                        <OverlayTrigger
                          placement="right"
                          delay={{ show: 250, hide: 400 }}
                          overlay={
                            <Tooltip id={`tooltip-right`}>
                              This process involves verifying an OTP sent by the
                              user to authorize the cleaner's access.
                            </Tooltip>
                          }
                        >
                          <img src="./assets/icons/info.svg" alt="info-icon" />
                        </OverlayTrigger>
                      </div>

                      <div
                        onClick={() => handleDivClick("send-otp")}
                        className={`pay-modal-term-wrapper yfos-otp-access-wrap ${
                          selectedReason === "send-otp" ? "active" : ""
                        }`}
                      >
                        <form>
                          <div className="term-modal-checkmark-wrap">
                            <p> Sent OTP for access</p>
                            <label className="checkbox-container">
                              <input
                                type="checkbox"
                                id="send-otp"
                                name="cancel-reason"
                                value="send-otp"
                                // onChange={handleOtpChange}
                                checked={selectedReason === "send-otp"}
                              />
                              <span className="checkmark"> </span>
                            </label>
                          </div>
                        </form>
                      </div>

                      <div
                        onClick={() => handleDivClick("no-otp")}
                        className={`pay-modal-term-wrapper yfos-otp-access-wrap ${
                          selectedReason === "no-otp" ? "active" : ""
                        }`}
                      >
                        <form>
                          <div className="term-modal-checkmark-wrap">
                            <p> Did not send OTP for access </p>
                            <label className="checkbox-container">
                              <input
                                type="checkbox"
                                id="no-otp"
                                name="cancel-reason"
                                value="no-otp"
                                // onChange={handleOtpChange}
                                checked={selectedReason === "no-otp"}
                              />
                              <span className="checkmark"> </span>
                            </label>
                          </div>
                        </form>
                      </div>
                    </div>
                  </div>
                )}
              </div>

              <div className="booking-payment-method-wrapper">
                <div className="booking-cart-head">
                  <h4> Payment Method </h4>
                </div>
                {/* <div className="recurring-dropdown-main-wrapper">
                  <Dropdown
                    onTypeChange={handleDropdownChange("recurring")}
                    label="Recurring"
                    data={RecurringData}
                    alreadyValue={orderData?.recurring}
                  />
                </div> */}

                <div
                  className="payment-saved-card-wrap"
                  style={{
                    pointerEvents: "none",
                    opacity: 0.8,
                  }}
                >
                  {/* <div className="payment-saved-card-info">
                    <img src="./assets/icons/card-icon.png" alt="icon" />
                    <p> 2726*********7890 </p>
                  </div> */}

                  <div className="payment-save-card-right">
                    <div className="add-card-left-wrap">
                      <img src="./assets/icons/card-icon.png" alt="icon" />
                      <p> Pay with Card </p>
                    </div>
                  </div>
                </div>

                {/* <div className="payment-saved-card-wrap">
                  <div className="payment-saved-card-info add-payment-card-info">
                    <div className="add-card-left-wrap">
                      <img src="./assets/icons/plus.png" alt="icon" />
                      <p> Add New Credit Card or Debit Card </p>
                    </div>
                    <div className="add-card-left-right">
                      <img src="./assets/icons/card-icon.png" alt="icon" />
                    </div>
                  </div>
                </div> */}

                <div
                  className="postpaid-payment-main-wrapper"
                  style={{
                    pointerEvents: "none",
                    opacity: 0.5,
                  }}
                >
                  <div className="postpaid-payment-main-wrap">
                    <div className="postpaid-payment-left-wrap">
                      <img src="./assets/icons/yfos-logo.png" alt="icon" />
                      <p className="desktop-text">Enterprise YFOS(postpaid)</p>
                      <p className="mobile-text"> postpaid </p>
                    </div>
                    <div className="postpaid-payment-right-wrap">
                      <div
                        className="get-veried-now"
                        onClick={handleShowPostpaidDrawer}
                      >
                        <p> Get Verified now!</p>
                        <img src="./assets/icons/right-arrow.svg" alt="icon" />
                      </div>

                      <div className="postpaid-toggle-button">
                        <label className="switch">
                          <input type="checkbox" checked={false} />
                          <span className="slider round"></span>
                        </label>
                      </div>
                    </div>
                  </div>
                </div>

                <div className="offer-and-benefits-wrapper">
                  <div className="offer-and-benefits-wrap">
                    <h4> Offers & Benefits </h4>

                    <div
                      className="apply-coupon-wrap"
                      onClick={handleShowCouponDrawer}
                    >
                      <p>
                        {couponData?.couponCode
                          ? couponData?.couponCode
                          : "Apply Coupon"}
                      </p>
                      <img src="./assets/icons/right-arrow.svg" alt="icon" />
                    </div>
                  </div>
                </div>

                <div className="payment-method-detail-wrapper">
                  <PaymentDetail
                    recurring="one Time"
                    address={orderData?.address}
                    city={orderData?.city}
                    addressline2={orderData?.addressLine2}
                    PostalCode={orderData?.postalCode}
                    scheduleDate={
                      orderData?.scheduleDate
                        ? moment(orderData?.scheduleDate).format("DD MMM YYYY")
                        : ""
                    }
                    scheduleTime={orderData?.scheduleTime}
                    paymentMethod={orderData?.paymentMethod}
                    price={subTotal}
                    discount={discountAmount.toFixed(2)}
                    yfosServiceFess={yfosServiceFees}
                    subTotal={subTotal.toFixed(2)}
                    fees={FeesAndTexAmount.toFixed(2)}
                    total={totalAmount.toFixed(2)}
                  />
                  {!isMobile && (
                    <div className="pay-button-wrapper">
                      <div className="payment-detail-repeat total-payment-detail-repeat">
                        <div className="payment-detail-left">
                          <h4> Total </h4>
                        </div>
                        <div className="payment-detail-right">
                          <h4>${totalAmount.toFixed(2)}</h4>
                        </div>
                      </div>
                      <Button
                        label="Pay"
                        onClick={bookOrderHandler}
                        isLoading={isLoading}
                      />
                    </div>
                  )}
                </div>
              </div>
              {isMobile && (
                <div className="pay-button-wrapper pay-mobile-button-wrapper">
                  <div className="payment-detail-repeat total-payment-detail-repeat">
                    <div className="payment-detail-left">
                      <h4> Total </h4>
                    </div>
                    <div className="payment-detail-right">
                      <h4>${totalAmount.toFixed(2)}</h4>
                    </div>
                  </div>
                  <Button
                    isLoading={isLoading}
                    label="Pay"
                    onClick={bookOrderHandler}
                  />
                </div>
              )}
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default ChangeOrderConfirmation;

import { createSlice } from "@reduxjs/toolkit";
import { useEffect } from "react";
import { useSelector } from "react-redux";

// Define base prices for services
const BASE_PRICES = {
  flooring: 100,
  mopping: 200,
  dusting: 200,
};

// Function to get serviceType from localStorage (or default to 'Residential')
const getServiceTypeFromLocalStorage = () => {
  const serviceType = localStorage.getItem("ServiceTypeForSq");

  console.log("sdfsdfsd", serviceType);
  return serviceType ? serviceType : "Residential";
};

const initialState = {
  carts: [],
  squreFeetPercentage: 500,
  flooringPercentage: 50,
  dustingPercentage: 50,
  moppingPercentage: 50,
  flooringPrice: (BASE_PRICES.flooring * 50) / 100, // initial price
  dustingPrice: (BASE_PRICES.dusting * 50) / 100, // initial price
  moppingPrice: (BASE_PRICES.mopping * 50) / 100, // initial price
  serviceType: "Residential", //
};

// cart slice
const cartSlice = createSlice({
  name: "cartSlice",
  initialState,
  reducers: {
    setServiceTypeForSq: (state, action) => {
      state.serviceType = action.payload;
      // Save to localStorage
      localStorage.setItem("ServiceTypeForSq", action.payload);

      // Update other state variables based on serviceType
      if (action.payload === "Residential") {
        state.squreFeetPercentage = 500;
      } else if (action.payload === "Commercial") {
        state.squreFeetPercentage = 1000;
      }
    },

    // add to cart
    addToCart: (state, action) => {
      const itemIndex = state.carts.findIndex(
        (item) => item.id === action.payload.id
      );

      if (itemIndex >= 0) {
        state.carts[itemIndex].quantity += 1;
      } else {
        const temp = { ...action.payload, isAdded: true, quantity: 1 };
        state.carts = [...state.carts, temp];
      }
    },

    updateExtraCart: (state, action) => {
      const itemIndex = state.carts.findIndex(
        (item) => item.id === action.payload.id
      );

      if (itemIndex >= 0 && action.payload.edit) {
        state.carts[itemIndex] = {
          ...state.carts[itemIndex],
          ...action.payload,
        };
      } else {
        const temp = { ...action.payload, isAdded: true, quantity: 1 };
        state.carts = [...state.carts, temp];
      }
    },

    // remove single items
    removeToCart: (state, action) => {
      const itemIndex_dec = state.carts.findIndex(
        (item) => item.id === action.payload.id
      );

      if (state.carts[itemIndex_dec].quantity > 1) {
        state.carts[itemIndex_dec].quantity -= 1;
      } else {
        const data = state.carts.filter((ele) => ele.id !== action.payload.id);
        state.carts = data;
      }
    },

    // clear cart
    emptyCartItem: (state, action) => {
      state.carts = [];
      state.flooringPercentage = initialState.flooringPercentage;
      state.moppingPercentage = initialState.moppingPercentage;
      state.dustingPercentage = initialState.dustingPercentage;
      state.squreFeetPercentage = initialState.squreFeetPercentage;
      state.flooringPrice = initialState.flooringPrice;
      state.dustingPrice = initialState.dustingPrice;
      state.moppingPrice = initialState.moppingPrice;
    },

    // update flooring percentage and price
    updateFlooringPercentage: (state, action) => {
      state.flooringPercentage = action.payload;
      state.flooringPrice = (BASE_PRICES.flooring * action.payload) / 100;
    },

    // update mopping percentage and price
    updateMoppingPercentage: (state, action) => {
      state.moppingPercentage = action.payload;
      state.moppingPrice = (BASE_PRICES.mopping * action.payload) / 100;
    },

    // update dusting percentage and price
    updateDustingPercentage: (state, action) => {
      state.dustingPercentage = action.payload;
      state.dustingPrice = (BASE_PRICES.dusting * action.payload) / 100;
    },

    // update SqureFeet percentage and price
    updateSqureFeetPercentage: (state, action) => {
      state.squreFeetPercentage = action.payload;
    },
  },
});

export const {
  addToCart,
  removeToCart,
  emptyCartItem,
  updateFlooringPercentage,
  updateMoppingPercentage,
  updateDustingPercentage,
  updateSqureFeetPercentage,
  updateExtraCart,
  setServiceTypeForSq,
} = cartSlice.actions;

export default cartSlice.reducer;

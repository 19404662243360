// src/redux/features/couponSlice.js
import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  couponCode: "",
  couponDiscountPercentage: 0, // Default to 0% discount
};

const couponSlice = createSlice({
  name: "coupon",
  initialState,
  reducers: {
    updateCoupon: (state, action) => {
      const { couponCode, couponDiscountPercentage } = action.payload;
      state.couponCode = couponCode;
      state.couponDiscountPercentage = couponDiscountPercentage;
    },

    resetCoupon: (state) => {
      state.couponCode = initialState.couponCode;
      state.couponDiscountPercentage = initialState.couponDiscountPercentage;
    },
  },
});

export const { updateCoupon, resetCoupon } = couponSlice.actions;

export default couponSlice.reducer;

import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  industry: {
    name: "",
    imageUrl: "",
    subChargePercentage: "",
    subChargeTime: "",
  },
  floors: {
    name: "",
    imageUrl: "",
    floorCost: "",
    floorTime: "",
  },
  bedrooms: { name: "", imageUrl: "" },
  recurring: { name: "One Time", imageUrl: "" },
  city: { name: "", imageUrl: "" },
};

const dropdownSlice = createSlice({
  name: "dropdownSlice",
  initialState,
  reducers: {
    updateDropdown: (state, action) => {
      const { field, value } = action.payload;
      state[field] = value;
    },
    resetDropdown: (state) => initialState,
    resetSpecificDropdown: (state, action) => {
      const fieldsToReset = action.payload;
      fieldsToReset.forEach((field) => {
        if (initialState.hasOwnProperty(field)) {
          state[field] = initialState[field];
        }
      });
    },
  },
});

export const { updateDropdown, resetDropdown, resetSpecificDropdown } =
  dropdownSlice.actions;
export default dropdownSlice.reducer;

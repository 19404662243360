import React, { Fragment } from "react";
import { useMediaQuery } from "react-responsive";

const BookingItems = ({ item }) => {
  const isMobile = useMediaQuery({ maxWidth: 1024 });
  console.log("Item", item);

  return (
    <>
      {isMobile ? (
        <div className="booking-cart-item-mobile-wrap">
          {item.size ? (
            <div className="booking-cart-item-repeat">
              <div className="booking-cart-item-left">
                <div className="booking-item-image">
                  <img src={item.itemImage} alt="item-img" />
                </div>
                <div className="booking-item-detail additional-dropdown-shows">
                  <h6> {item.name}</h6>
                  {item.name === "Exterior Window Cleaning" ? (
                    <>
                      {item.selectedDropdownItems.map((item, index) => (
                        <div key={index} className="selected-item">
                          <p>
                            {Object.keys(item)
                              .filter(
                                (key) => key !== "price" && key !== "time"
                              )
                              .map((key) => `${item[key]}`)
                              .join(", ")}{" "}
                          </p>
                        </div>
                      ))}
                    </>
                  ) : (
                    <>
                      {item.additionItemSq && (
                        <p>
                          <span className="additional-dropdown-type">
                            SquareFeet:
                          </span>
                          {item.additionItemSq} Sq
                        </p>
                      )}

                      <p>
                        {Object.keys(item.selectedDropdown)?.map(
                          (categoryKey) => (
                            <Fragment key={categoryKey}>
                              <span className="additional-dropdown-type">
                                {categoryKey} :
                              </span>
                              {item.selectedDropdown[categoryKey]?.name
                                ? item.selectedDropdown[categoryKey].name
                                : ""}
                              <br />
                            </Fragment>
                          )
                        )}
                      </p>
                    </>
                  )}
                </div>
              </div>

              <div className="booking-cart-item-right-wrapper">
                <div className="booking-cart-item-right">
                  <div className="booking-item-price-wrap">
                    <p> ${(item?.quantity * item?.price).toFixed(2)} </p>
                  </div>
                </div>
              </div>
            </div>
          ) : (
            <div className="booking-cart-item-repeat">
              <div className="mobile-booking-cart-item-left">
                <div className="booking-item-detail">
                  <p> {item.name}</p>
                  <span> {item.subCategory}</span>
                </div>
              </div>

              <div className="booking-cart-item-right-wrapper">
                <div className="booking-cart-item-right">
                  <div className="booking-item-price-wrap">
                    <p> ${(item?.quantity * item?.price).toFixed(2)} </p>
                  </div>
                </div>
              </div>
            </div>
          )}
        </div>
      ) : (
        <div className="booking-cart-item-desktop-wrap">
          {item.size ? (
            <div className="booking-cart-item-repeat">
              <div className="booking-cart-item-left">
                <div className="booking-item-image">
                  <img src={item.itemImage} alt="item-img" />
                </div>
                <div className="booking-item-detail additional-dropdown-shows">
                  <h6> {item.name}</h6>
                  {item.name === "Exterior Window Cleaning" ? (
                    <>
                      {item.selectedDropdownItems.map((item, index) => (
                        <div key={index} className="selected-item">
                          <p>
                            {Object.keys(item)
                              .filter(
                                (key) => key !== "price" && key !== "time"
                              )
                              .map((key) => `${item[key]}`)
                              .join(", ")}{" "}
                          </p>
                        </div>
                      ))}
                    </>
                  ) : (
                    <>
                      {item.additionItemSq && (
                        <p>
                          <span className="additional-dropdown-type">
                            SquareFeet:
                          </span>
                          {item.additionItemSq} Sq
                        </p>
                      )}

                      <p>
                        {Object.keys(item.selectedDropdown)?.map(
                          (categoryKey) => (
                            <Fragment key={categoryKey}>
                              <span className="additional-dropdown-type">
                                {categoryKey} :
                              </span>
                              {item.selectedDropdown[categoryKey]?.name
                                ? item.selectedDropdown[categoryKey].name
                                : ""}
                              <br />
                            </Fragment>
                          )
                        )}
                      </p>
                    </>
                  )}
                </div>
              </div>

              <div className="booking-cart-item-right-wrapper">
                <div className="booking-cart-item-right">
                  <div className="booking-item-price-wrap">
                    <p> ${(item?.quantity * item?.price).toFixed(2)} </p>
                  </div>
                </div>
              </div>
            </div>
          ) : (
            <div className="booking-cart-item-repeat">
              <div className="booking-cart-item-left">
                <div className="booking-item-image">
                  <img src={item.itemImage} alt="item-img" />
                </div>
                <div className="booking-item-detail">
                  <h6> {item.name}</h6>
                  <p> {item.subCategory}</p>
                </div>
              </div>

              <div className="booking-cart-item-right-wrapper">
                <div className="booking-cart-item-right">
                  <div className="booking-item-price-wrap">
                    <p> ${(item?.quantity * item?.price).toFixed(2)} </p>
                  </div>
                </div>
              </div>
            </div>
          )}
        </div>
      )}
    </>
  );
};

export default BookingItems;

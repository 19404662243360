import React, { Fragment, useState, useEffect } from "react";
import moment from "moment";
import { useDispatch, useSelector } from "react-redux";
import { servicePost } from "../../helper/api";
import { useNavigate } from "react-router-dom";
import { setOrderBookingId } from "../../redux/features/bookingSlice";

const getDateLabel = (date) => {
  const today = moment().startOf("day");
  const messageDate = moment(date).startOf("day"); // Set messageDate to start of its day
  const diff = today.diff(messageDate, "days");

  if (diff === 0) return "Today";
  if (diff === 1) return "Yesterday";
  return "Earlier";
};

const groupNotificationsByDate = (notifications) => {
  return notifications.reduce((acc, notification) => {
    const label = getDateLabel(notification.createdAt);
    if (!acc[label]) {
      acc[label] = [];
    }
    acc[label].push(notification);
    return acc;
  }, {});
};

const NotificationDrawer = ({ onHide }) => {
  const [notificationData, setNotificationData] = useState([]);
  const logedInUser = useSelector((state) => state.user.userInfo);

  // get AuthToken from localStorage
  const authToken = localStorage.getItem("token");
  const notification = "";

  // Get User Detail By UserId
  const getUserNotification = async () => {
    try {
      const notificationRes = await servicePost(
        "user/getNotfications",
        {
          accountId: logedInUser?.id,
        },
        notification,
        authToken
      );
      console.log("notificationRes", notificationRes);
      // Sort notifications by createdAt in descending order
      const sortedNotifications = notificationRes.data.sort(
        (a, b) => new Date(b.createdAt) - new Date(a.createdAt)
      );
      setNotificationData(sortedNotifications);
    } catch (error) {
      console.log("error", error);
    }
  };

  useEffect(() => {
    getUserNotification();
  }, [logedInUser?.id]);

  const groupedNotifications = notificationData.length
    ? groupNotificationsByDate(notificationData)
    : {};

  const navigate = useNavigate();
  const dispatch = useDispatch();

  const goToTrakingOrder = (item) => {
    onHide();
    dispatch(setOrderBookingId(item.bookingId));
    navigate(`/tracking-order/${item.bookingId}`);
  };

  return (
    <div className="notification-drawer-main-wrapper">
      <div className="notification-drawer-main-wrap">
        <div className="notification-drawer-header">
          <h3>Notification</h3>
          <div className="drawer-close-icon-wrap">
            <img
              onClick={onHide}
              src="../assets/icons/close-icon.svg"
              alt="icon"
            />
          </div>
        </div>

        {notificationData.length === 0 ? (
          <div className="noting-found-main-wrapper">
            <div className="noting-found-main-wrap">
              <img src="../assets/icons/empty-cart.png" alt="empty-cart" />
              <p>Nothing Found</p>
            </div>
          </div>
        ) : (
          ["Today", "Yesterday", "Earlier"].map(
            (dateLabel) =>
              groupedNotifications[dateLabel] && (
                <Fragment key={dateLabel}>
                  <div className="notification-date-wrap">
                    <p>{dateLabel}</p>
                    <div className="notification-line-wrap"></div>
                  </div>
                  {groupedNotifications[dateLabel].map((item, idx) => (
                    <div className="notification-order-repeat" key={idx}>
                      <div className="notification-left-wrap">
                        <div className="notification-items-wrap">
                          {item?.itemImage?.length > 0 ? (
                            item?.itemImage?.slice(0, 3).map((data, index) => (
                              <div
                                className="notification-item-image"
                                key={index}
                              >
                                <img src={data.img} alt="img" />
                              </div>
                            ))
                          ) : item?.itemImage?.length == 0 ? (
                            <div className="notification-item-staticImg">
                              <img src="../assets/service-1.png" alt="img" />
                            </div>
                          ) : (
                            <div className="notification-item-staticImg">
                              <img
                                src="../assets/icons/yfos-logo.png"
                                alt="img"
                              />
                            </div>
                          )}
                        </div>

                        <div className="notification-item-detail">
                          <div className="notification-title-wrap">
                            <h4>{item.message}</h4>
                            <img src={item.statuslogo} alt="icon" />
                          </div>

                          {item.bookingId && (
                            <div className="notification-booking-id-wrap">
                              <p>
                                Booking ID{" "}
                                <span onClick={() => goToTrakingOrder(item)}>
                                  {" "}
                                  {item.bookingId}
                                </span>{" "}
                              </p>
                            </div>
                          )}

                          {item.description ? (
                            <div className="notification-item-desc">
                              <p>{item?.description}</p>
                            </div>
                          ) : (
                            <div className="notification-item-date-wrap">
                              {item?.itemCount > 0 && (
                                <p>{item.itemCount} items</p>
                              )}
                              <p>
                                {moment(item.createdAt).format("DD MMM YYYY")}
                              </p>
                            </div>
                          )}
                        </div>
                      </div>

                      <div className="notification-right-wrap">
                        {item.price && (
                          <div className="notification-item-price">
                            <h4>${item.price}</h4>
                          </div>
                        )}

                        {item.bookingId && (
                          <div className="booking-view-icon">
                            <img
                              onClick={() => goToTrakingOrder(item)}
                              src="../assets/icons/view-icon.svg"
                              alt="icon"
                            />
                          </div>
                        )}
                      </div>
                    </div>
                  ))}
                </Fragment>
              )
          )
        )}
      </div>
    </div>
  );
};

export default NotificationDrawer;

import React, { useState } from "react";
import Slider from "react-slick";
import "./extraitem.css";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import Offcanvas from "react-bootstrap/Offcanvas";
import OverlayTrigger from "react-bootstrap/OverlayTrigger";
import Tooltip from "react-bootstrap/Tooltip";
import { useSelector } from "react-redux";
import AdditionalDrawer from "../drawers/AdditionalDrawer";

const MobileExtraItems = ({ extraData, addItems, removeItem, showItems }) => {
  const carts = useSelector((state) => state.allCart.carts);
  const [selectedData, setSelectedData] = useState();
  const [showDrawer, setShowDrawer] = useState(false);
  const handleClose = () => setShowDrawer(false);

  const handleShow = (data) => {
    setSelectedData(data);
    setShowDrawer(true);
  };

  const CustomPrevArrow = ({ currentSlide, ...props }) => {
    return (
      currentSlide !== 0 && (
        <div {...props} className="slick-arrow slick-prev">
          <img src="./assets/icons/previous-arrow.svg" alt="Prev" />
        </div>
      )
    );
  };

  const CustomNextArrow = ({ currentSlide, slideCount, ...props }) => {
    return (
      currentSlide + 4 < slideCount && (
        <div {...props} className="slick-arrow slick-next">
          <img src="./assets/icons/back-arrow.svg" alt="Next" />
        </div>
      )
    );
  };

  const settings = {
    dots: false,
    infinite: false,
    autoplay: false,
    speed: 300,
    slidesToShow: 4,
    slidesToScroll: 1,
    responsive: [
      {
        breakpoint: 1500,
        settings: {
          slidesToShow: 3,
          slidesToScroll: 1,
          infinite: true,
          dots: false,
        },
      },
      {
        breakpoint: 1024,
        settings: {
          slidesToShow: 3,
          slidesToScroll: 1,
          infinite: true,
          dots: false,
        },
      },
      {
        breakpoint: 600,
        settings: {
          slidesToShow: 2.5,
          slidesToScroll: 1,
          infinite: true,
        },
      },
      {
        breakpoint: 480,
        settings: {
          slidesToShow: 2.4,
          slidesToScroll: 1,
          infinite: true,
        },
      },
    ],
    prevArrow: <CustomPrevArrow />,
    nextArrow: <CustomNextArrow />,
  };

  let itemsToShow;

  if (showItems === "10") {
    itemsToShow = extraData.slice(0, 10);
  } else if (showItems === "other") {
    itemsToShow = extraData.slice(10);
  } else {
    itemsToShow = extraData;
  }

  return (
    <>
      <Offcanvas
        className="extras-offcanvas-wrapper"
        show={showDrawer}
        onHide={handleClose}
        placement="end"
      >
        <Offcanvas.Body>
          <AdditionalDrawer onHide={handleClose} data={selectedData} />
        </Offcanvas.Body>
      </Offcanvas>

      <Slider {...settings} className="service-item-card-wrapper">
        {itemsToShow.map((data, index) => {
          const cartItem = carts.find((cartItem) => cartItem.id === data.id);
          return (
            <div className="service-item-card-wrap" key={index}>
              <div className="info-icon-wrap">
                <OverlayTrigger
                  placement="top"
                  delay={{ show: 250, hide: 400 }}
                  overlay={<Tooltip id={`tooltip-up`}>{data.info}</Tooltip>}
                >
                  <img src="./assets/icons/info.svg" alt="info-icon" />
                </OverlayTrigger>
              </div>

              <div className="service-item-image">
                <div className="service-item-img">
                  <img src={data.itemImage} alt="img" />
                </div>
                <div className="add-cart-btn-wrapper">
                  {data.extraWithSize ? (
                    <div
                      className="add-cart-btn-wrap"
                      onClick={() => handleShow(data)}
                    >
                      <img
                        className="plus-icon"
                        src="./assets/icons/plus.svg"
                        alt="icon"
                      />
                    </div>
                  ) : (
                    <div
                      className={`add-cart-btn-wrap ${
                        cartItem?.isAdded ? "expanded" : ""
                      }`}
                    >
                      {cartItem?.isAdded && (
                        <>
                          <div
                            className="card-delet-icon"
                            onClick={() => removeItem(data)}
                          >
                            <img
                              className="delete-icon"
                              src="./assets/icons/delete.svg"
                              alt="icon"
                            />
                          </div>
                          <span className="quantity">{cartItem?.quantity}</span>
                        </>
                      )}
                      <div
                        className="plus-icon-wrap"
                        onClick={() => addItems(data)}
                      >
                        <img
                          className="plus-icon"
                          src="./assets/icons/plus.svg"
                          alt="icon"
                        />
                      </div>
                    </div>
                  )}
                </div>
              </div>

              <div className="service-item-card-desc">
                <p> {data.name} </p>
              </div>
            </div>
          );
        })}
      </Slider>
    </>
  );
};

export default MobileExtraItems;

import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  address: "",
  addressline2: "",
  postalcode: "",
  squareFeet: "",
  scheduleDate: "",
  scheduleTime: "",
  contactname: "",
  contactnumber: "",
  addressComplete: false,
  otpAccess: "send-otp",
  paywith: "",
  platformFees: "",
  taxes: "",
  sessionStartTime: null,
};

const formSlice = createSlice({
  name: "formSlice",
  initialState,
  reducers: {
    updateFormData: (state, action) => {
      const { field, value } = action.payload;
      state[field] = value;

      // Set sessionStartTime based on whether scheduleTime has a value
      if (field === "scheduleTime") {
        state.sessionStartTime = value ? Date.now() : null;
      }
    },
    setFormData: (state, action) => {
      return { ...state, ...action.payload };
    },
    resetSpecificFormData: (state, action) => {
      const fieldsToReset = action.payload;
      fieldsToReset.forEach((field) => {
        if (initialState.hasOwnProperty(field)) {
          state[field] = initialState[field];
        }
      });
    },
  },
});

export const { updateFormData, setFormData, resetSpecificFormData } =
  formSlice.actions;
export default formSlice.reducer;

import React, { useState, useEffect } from "react";
import Dropdown from "../dropdown/Dropdown";
import Button from "../UIComponents/Button";
import { useDispatch, useSelector } from "react-redux";
import { updateExtraCart } from "../../redux/features/cartSlice";
import InputField from "../UIComponents/InputField";
import { setError } from "../../redux/features/errorSlice";
import { updateTotalPriceAndTime } from "../../redux/features/extraItemPriceSlice";
import OverlayTrigger from "react-bootstrap/OverlayTrigger";
import Tooltip from "react-bootstrap/Tooltip";

const AdditionalScrubDrawer = ({ onHide, data, edit }) => {
  const [enableButton, setEnableButton] = useState(false);
  const [totalPrice, setTotalPrice] = useState(null);
  const [totalTime, setTotalTime] = useState(null);
  const [errorMessage, setErrorMessage] = useState("Enter Square Feet");
  const [selectedCategory, setSelectedCategory] = useState(
    edit ? data.selectedDropdown : {}
  );

  const [dropdownData, setDropdownData] = useState([]);
  const [isFlooring, setIsFlooring] = useState(edit ? data?.isFlooring : true);
  const [additionItemSq, setAdditionItemSq] = useState(
    edit ? data.additionItemSq : ""
  );
  const [dropdownErrors, setDropdownErrors] = useState({});
  const [dropdownEnabledState, setDropdownEnabledState] = useState([]);
  const errors = useSelector((state) => state.formErrors);

  const [filteredAdditionalOptions, setFilteredAdditionalOptions] = useState(
    []
  );

  // Set initial enabled state for dropdowns when dropdownData changes
  useEffect(() => {
    if (dropdownData.length > 0) {
      const initialEnabledState = dropdownData.map((_, index) =>
        index === 0 ? true : false
      );
      setDropdownEnabledState(initialEnabledState);
    }
  }, [dropdownData]);

  useEffect(() => {
    if (edit) {
      if (data?.data) {
        const activeDropdownData = isFlooring
          ? data?.data?.dropDowns
          : data?.data?.tilesDropdDown;
        setDropdownData(activeDropdownData || []);
      }
    } else {
      if (data) {
        const activeDropdownData = isFlooring
          ? data.dropDowns
          : data.tilesDropdDown;
        setDropdownData(activeDropdownData || []);
      }
    }
  }, [data, isFlooring]);

  useEffect(() => {
    const allDropdownsFilled = dropdownData.every((dropdown) => {
      if (dropdown.type.toLowerCase().includes("optional")) {
        return true; // Skip this check, treat it as filled
      }
      return selectedCategory[dropdown.type];
    });
    setEnableButton(
      Boolean(
        (data?.squireFeet || data?.additionItemSq ? additionItemSq : true) &&
          allDropdownsFilled
      )
    );
  }, [additionItemSq, selectedCategory, dropdownData]);

  const dispatch = useDispatch();

  const addToCartHandler = () => {
    let hasError = false;

    if (
      data?.squireFeet || data?.additionItemSq ? additionItemSq === "" : false
    ) {
      dispatch(setError({ field: "additionItemSq", value: true }));
      setErrorMessage("Enter Square Feet");
      hasError = true;
    } else if (
      data?.squireFeet || data?.additionItemSq
        ? additionItemSq === "" ||
          additionItemSq < 100 ||
          additionItemSq > 10000
        : false
    ) {
      dispatch(
        setError({
          field: "additionItemSq",
          value: true,
        })
      );
      setErrorMessage("Enter a value between 100 and 10000.");
      hasError = true;
    } else {
      dispatch(setError({ field: "additionItemSq", value: false }));
    }

    if (hasError) {
      return;
    }

    for (let dropdown of dropdownData) {
      // Skip dropdowns that include "optional" in their type
      if (dropdown.type.toLowerCase().includes("optional")) {
        continue; // Skip this dropdown and move to the next one
      }
      if (!selectedCategory[dropdown.type]) {
        setDropdownErrors({ [dropdown.type]: true });
        hasError = true;
        break; // Exit the loop if there's an error
      }
    }

    if (hasError) {
      return;
    }

    dispatch(
      updateExtraCart({
        id: data?.id,
        itemImage: data?.itemImage,
        selectedDropdown: selectedCategory,
        name: data?.name,
        price: totalPrice,
        time: totalTime,
        additionItemSq: additionItemSq,
        isFlooring: isFlooring,
        size: true,
        edit: edit,
        data: edit ? data?.data : data,
      })
    );
    onHide();
  };

  const handleDropdownChange = (type, value, index) => {
    // Update the selected category state
    setSelectedCategory((prev) => ({ ...prev, [type]: value }));

    // Clear the error for the current dropdown
    setDropdownErrors((prev) => ({ ...prev, [type]: false }));

    // Enable the next dropdown if the current one is selected
    const newEnabledState = [...dropdownEnabledState];
    if (value) {
      newEnabledState[index + 1] = true;
    }
    setDropdownEnabledState(newEnabledState);

    // Apply filtering logic for "Additional (optional)" dropdown when "Flooring Type" changes
    if (type === "Flooring Type") {
      const additionalDropdown = dropdownData.find(
        (dropdown) => dropdown.type === "additonal(optional)"
      );

      // Filter options for "Additional (optional)" based on the selected "Flooring Type"
      if (
        value.name === "Vinyl Composition Tile" ||
        value.name === "Linoleum"
      ) {
        setFilteredAdditionalOptions(
          additionalDropdown?.data.filter((item) =>
            ["Add Finish (Wax)", "STRIPPING + WAXING (FINISH)"].includes(
              item.name
            )
          )
        );
      } else if (value.name === "Concrete" || value.name === "Terrazzo") {
        setFilteredAdditionalOptions(
          additionalDropdown?.data.filter((item) => item.name === "Sealing")
        );
      } else {
        setFilteredAdditionalOptions([]);
      }
    }
  };

  const inputChangeHandler = (event) => {
    const { name, value } = event.target;
    setAdditionItemSq(value);
    if (value === "") {
      dispatch(setError({ field: name, value: true }));
    } else {
      dispatch(setError({ field: name, value: false }));
    }
  };

  const handleSelectedCategory = (selectedCategory) => {
    let basePrice = data.price > 0 ? Number(data.price) : 0;
    let totalPrice = 0;
    let totalTime = data.time > 0 ? Number(data.time) : 0;

    const squareFeet = additionItemSq ? parseFloat(additionItemSq) : 0;

    let flooringTypePrice = 0;
    let flooringTypeTime = 0;
    let pricePercentage = 0;
    let timePercentage = 0;
    let additionalPrice = 0;
    let additionalTime = 0;

    Object.keys(selectedCategory).forEach((category) => {
      const currentItem = selectedCategory[category];

      // Flooring Type price and time calculation
      if (category === "Flooring Type" && currentItem) {
        if (currentItem.price !== null && currentItem.price !== undefined) {
          flooringTypePrice = parseFloat(currentItem.price) * squareFeet; // Multiply by squareFeet
          console.log("Flooring Type Price", flooringTypePrice);
        }

        if (currentItem.time !== null && currentItem.time !== undefined) {
          flooringTypeTime = parseFloat(currentItem.time) * squareFeet; // Multiply by squareFeet
          console.log("Flooring Type Time", flooringTypeTime);
        }
      }

      // Check Level of Soiling and calculate price percentage and time percentage
      if (category === "Level of Soiling" && currentItem) {
        if (
          currentItem.pricePercentage !== null &&
          currentItem.pricePercentage !== undefined
        ) {
          pricePercentage = parseFloat(currentItem.pricePercentage); // Get price percentage
          console.log("Price Percentage", pricePercentage);
        }

        if (
          currentItem.timePercentage !== null &&
          currentItem.timePercentage !== undefined
        ) {
          timePercentage = parseFloat(currentItem.timePercentage); // Get time percentage
          console.log("Time Percentage", timePercentage);
        }
      }

      // Additional (optional) dropdown price and time calculation
      if (category === "additonal(optional)" && currentItem) {
        if (currentItem.price !== null && currentItem.price !== undefined) {
          additionalPrice = parseFloat(currentItem.price) * squareFeet; // Multiply by squareFeet
          console.log("Additional Price", additionalPrice);
        }

        if (currentItem.time !== null && currentItem.time !== undefined) {
          additionalTime = parseFloat(currentItem.time) * squareFeet; // Multiply by squareFeet
          console.log("Additional Time", additionalTime);
        }
      }
    });

    // Calculate final price
    const percentageAmount = (pricePercentage / 100) * flooringTypePrice;
    basePrice = flooringTypePrice + percentageAmount + additionalPrice;
    console.log("Base Price with percentage and additional", basePrice);

    // Apply time percentage to flooringTypeTime
    const timePercentageAmount = (timePercentage / 100) * flooringTypeTime;
    totalTime = flooringTypeTime + timePercentageAmount + additionalTime;
    console.log("Total Time with time percentage and additional", totalTime);

    // Set the calculated values
    totalPrice = parseFloat(basePrice.toFixed(2));
    totalTime = parseFloat(totalTime.toFixed(2));

    dispatch(
      updateTotalPriceAndTime({
        totalPrice,
        totalTime,
      })
    );

    setTotalPrice(totalPrice);
    setTotalTime(totalTime);
  };

  useEffect(() => {
    handleSelectedCategory(selectedCategory);
  }, [selectedCategory, additionItemSq, data.price]);

  return (
    <div className="extra-drawer-main-wrapper">
      <div className="extra-drawer-main-header">
        <div className="extra-back-wrap">
          <img src="../assets/icons/right-black-arrow.svg" alt="icon" />
          <h4> Additional Service</h4>
        </div>
        <div className="extra-close-drwaer-btn">
          <img
            onClick={onHide}
            src="../assets/icons/close-icon.svg"
            alt="close-icon"
          />
        </div>
      </div>
      <div className="addition-drawer-main-wrap">
        <div className="addition-drawer-item-wrap">
          <img src={data?.itemImage} alt="img" />
          <p> {data?.name}</p>
        </div>
        {(data?.squireFeet || data?.additionItemSq) && (
          <div className="additional-drawer-sqfeet-wrap">
            <div className="additional-sqfeet-wrap">
              <p> Total Sqft</p>
              <OverlayTrigger
                placement="top"
                delay={{ show: 250, hide: 400 }}
                overlay={
                  <Tooltip id={`tooltip-up`}>
                    Please enter a value between 100 and 10000.
                  </Tooltip>
                }
              >
                <img src="../assets/icons/info.svg" alt="info-icon" />
              </OverlayTrigger>
            </div>
            <div className="login-option sequirefeet-input-wrap addtional-drawer-squirefeet-input">
              <form>
                <InputField
                  extraClass={
                    errors?.additionItemSq
                      ? `squirefeet-input error-msg`
                      : "squirefeet-input"
                  }
                  type="number"
                  name="additionItemSq"
                  id="additionItemSq"
                  onChange={inputChangeHandler}
                  value={additionItemSq}
                  label={
                    errors?.additionItemSq ? errorMessage : "Enter Square Feet"
                  }
                  passwordIcon={false}
                />
                <p className="sq-placeholder"> Sq </p>
              </form>
            </div>
          </div>
        )}

        <div className="recurring-dropdown-main-wrapper extra-category-dropdown-wrap">
          {dropdownData.map((dropdown, index) => {
            if (
              dropdown.type === "additonal(optional)" &&
              filteredAdditionalOptions.length === 0
            ) {
              return null;
            }

            return (
              <Dropdown
                extraClass={dropdownErrors[dropdown.type] ? "error-msg" : ""}
                key={index}
                onTypeChange={(value) =>
                  handleDropdownChange(dropdown.type, value, index)
                }
                disabled={!dropdownEnabledState[index]}
                label={dropdown.type}
                data={
                  dropdown.type === "additonal(optional)"
                    ? filteredAdditionalOptions
                    : dropdown.data
                }
                alreadyValue={selectedCategory[dropdown.type] || "Select"}
              />
            );
          })}
        </div>
      </div>
      <div className="extra-addto-cart-btn">
        <Button
          extraClass={!enableButton ? "disabled-button" : "add-to-cart-btn"}
          onClick={addToCartHandler}
          label={edit ? "Update" : "Add to cart"}
        />
      </div>
    </div>
  );
};

export default AdditionalScrubDrawer;

import React, { useState } from "react";

function RangeSlider({
  percentage,
  moppingPercentage,
  onChange,
  leftIcon,
  rightIcon,
  leftName,
  rightName,
  extraClass,
}) {
  // Define the gradient colors based on the extraClass
  const firstColor = "#00A19B"; // Common first color
  const secondColor = extraClass === "flooring-slider" ? "#000" : "#ccc"; // Second color based on extraClass
  return (
    <>
      <div className={`range-container ${extraClass}`}>
        <div className="chart-and-image-wrap chart-and-image-left-wrap">
          {leftIcon && <img src={leftIcon} alt="icon" />}
          <p id="output"> {percentage}% </p>
        </div>
        {leftName && (
          <div className="cleaning-name-wrap">
            <p>{leftName}</p>
          </div>
        )}

        <input
          type="range"
          id="stepsall"
          min="0"
          max="100"
          step="1"
          value={percentage}
          onChange={onChange}
          // style={{
          //   background: `linear-gradient(to right, #00A19B 0%, #00A19B ${percentage}%, #000 ${percentage}%, #000 100%)`,
          // }}
          style={{
            background: `linear-gradient(to right, ${firstColor} 0%, ${firstColor} ${percentage}%, ${secondColor} ${percentage}%, ${secondColor} 100%)`,
          }}
        />

        {rightName ? (
          <div className="chart-and-image-wrap">
            <p id="output-reverse"> {moppingPercentage}% </p>
            {rightIcon && <img src={rightIcon} alt="icon" />}
          </div>
        ) : (
          ""
          /* <div className="chart-and-image-wrap"></div> */
        )}

        {rightName && (
          <div className="cleaning-reverse-name-wrap">
            <p> {rightName} </p>
          </div>
        )}
      </div>
    </>
  );
}

export default RangeSlider;

import React, { useEffect, useState } from "react";
import Button from "../UIComponents/Button";
import { useDispatch, useSelector } from "react-redux";
import { serviceImagePost, servicePost } from "../../helper/api";
import { updateUserInfo } from "../../redux/features/userSlice";
import { setNotificationDep } from "../../redux/features/notificationDepSlice";
import { ShimmerThumbnail, ShimmerSectionHeader } from "react-shimmer-effects";

const PostPaidDrawer = ({ onHide }) => {
  const [userData, setUserData] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const [docUploadLoading, setDocUploadLoading] = useState(false);
  const [postpaidPath, setPostpaidPath] = useState("");
  const [postpaidFile, setPostpaidFile] = useState("");
  const [shimmerLoading, setShimmerLoading] = useState(true);

  console.log("userData", userData);

  // Error Validation
  const [errors, setErrors] = useState({
    uploadedFile: "",
  });

  // get loggedInUser for Redux
  const logedInUser = useSelector((state) => state.user.userInfo);
  const notificationDep = useSelector(
    (state) => state.notificationDep.notificationDep
  );

  // get AuthToken from localStorage
  const authToken = localStorage.getItem("token");
  const notification = "";

  const dispatch = useDispatch();

  // if Already Have Doc uploaded
  useEffect(() => {
    if (userData) {
      setPostpaidPath(!userData.postPaidDoc ? "" : userData.postPaidDoc);
    }
  }, [userData]);

  const handleFileChange = async (event) => {
    setDocUploadLoading(true);
    const file = event.target.files[0];
    const maxSize = 500 * 1024; // 500 KB in bytes

    if (file) {
      // Check file size
      if (file.size > maxSize) {
        setErrors((prevErrors) => ({
          ...prevErrors,
          uploadedFile: "The file size must be less than 500 KB.",
        }));
        setDocUploadLoading(false);
        setTimeout(() => {
          setErrors((prevErrors) => ({
            ...prevErrors,
            uploadedFile: null,
          }));
        }, 5000);

        return;
      }

      // Check file type
      if (file.type !== "application/pdf") {
        setErrors((prevErrors) => ({
          ...prevErrors,
          uploadedFile: "Only PDF files are allowed.",
        }));
        setDocUploadLoading(false);
        setTimeout(() => {
          setErrors((prevErrors) => ({
            ...prevErrors,
            uploadedFile: null,
          }));
        }, 5000);

        return;
      }

      // Set the file if validations pass
      setPostpaidFile(file);
      setPostpaidPath(file.name);
    }

    setDocUploadLoading(false);
  };

  // Submit Postpaid Document handler

  const submitPostpaidDocHandler = async () => {
    if (postpaidFile) {
      try {
        setIsLoading(true);
        const formData = new FormData();
        formData.append("image", postpaidFile);
        formData.append("id", logedInUser?.id);
        const uploadDocRes = await serviceImagePost(
          "user/postPaidData",
          formData,
          authToken
        );

        if (uploadDocRes.message == "SUCCESS") {
          setIsLoading(false);
          dispatch(updateUserInfo({ postPaidDoc: uploadDocRes.Imageurl }));
          dispatch(setNotificationDep(notificationDep + 1));
          onHide();
        }
        setIsLoading(false);
      } catch (error) {
        console.log("err", error);
        setIsLoading(false);
      }
    } else if (!postpaidFile && userData.postPaidDoc) {
      alert("Please upload the updated PDF");
    } else {
      alert("Please upload PDF first");
    }
  };

  // Get User Detail By UserId
  const getUserDetail = async () => {
    try {
      const userRes = await servicePost(
        `user/getUser`,
        {
          id: logedInUser?.id,
        },
        notification,
        authToken
      );

      console.log("userRes", userRes);
      setShimmerLoading(false);
      setUserData(userRes.data);
    } catch (error) {
      console.log("error", error);
    }
  };

  useEffect(() => {
    getUserDetail();
  }, [logedInUser?.id, notificationDep]);

  return (
    <div className="extra-drawer-main-wrapper">
      {shimmerLoading ? (
        <div className="postpaid-drawer-shimer-wrap">
          <ShimmerThumbnail height={250} rounded />
          <ShimmerSectionHeader center />
        </div>
      ) : !userData.postPaidDoc ? (
        <>
          <div className="extra-drawer-main-header postpaid-drawer-main-header">
            <div className="extra-back-wrap">
              <img src="../assets/icons/right-black-arrow.svg" alt="icon" />
              <h4>Enterprise YFOS application</h4>
            </div>

            <div className="extra-close-drwaer-btn">
              <img
                onClick={onHide}
                src="../assets/icons/close-icon.svg"
                alt="close-icon"
              />
            </div>
          </div>

          <div className="postpaid-contant-wrap">
            <p>
              With the Enterprise YFOS service you are asking to be approved for
              post payment service. To activate your postpaid service, please
              download the PDF form, complete all the required fields, upload
              the filled form, and submit it for approval.
            </p>
          </div>

          <div className="postpaid-main-wrapper">
            <div className="postpaid-main-wrap">
              <div className="postpaid-detail-repeat active">
                <h3> Download Pdf </h3>
                <a href="../assets/postpaid-form.pdf" download>
                  Click here to download
                </a>
              </div>
            </div>

            <div className="contractor-certificate-wrapper">
              <p className="postpaid-info-content">
                Upload and submit the completed PDF form.
              </p>

              <div className="contractor-certificate-wrap">
                <div className="certificate-upload-box">
                  {docUploadLoading ? (
                    <p> Documnet Uploading...</p>
                  ) : (
                    <>
                      <p
                        className={
                          postpaidPath ? "upload-lable active" : "upload-lable"
                        }
                      >
                        Postpaid form
                      </p>
                      <p className="uploaded-pdf">
                        {postpaidPath && postpaidPath}
                      </p>
                    </>
                  )}

                  <div className="upload-icon-wrap">
                    {!postpaidPath && (
                      <input type="file" onChange={handleFileChange} />
                    )}

                    {postpaidPath ? (
                      <img
                        onClick={() => setPostpaidPath("")}
                        src="../assets/icons/remove-icon.png"
                        alt="icon"
                      />
                    ) : (
                      <img src="../assets/icons/uploaded-icon.png" alt="icon" />
                    )}
                  </div>
                </div>
              </div>
              {errors.uploadedFile && (
                <p className="error-msg postpaid-error-msg">
                  {errors.uploadedFile}
                </p>
              )}
            </div>
          </div>

          <div className="postpaid-submit-btn">
            <Button
              onClick={submitPostpaidDocHandler}
              label="Submit"
              isLoading={isLoading}
            />
          </div>
        </>
      ) : (
        <>
          <div className="extra-drawer-main-header postpaid-drawer-main-header">
            <div className="extra-back-wrap">
              <img src="../assets/icons/right-black-arrow.svg" alt="icon" />
              <h4> Application Status </h4>
            </div>

            <div className="extra-close-drwaer-btn">
              <img
                onClick={onHide}
                src="../assets/icons/close-icon.svg"
                alt="close-icon"
              />
            </div>
          </div>

          <div className="postpaid-status-main-wrapper">
            {userData?.postPaidStatus == "Pending" ? (
              <div className="postpaid-status-main-wrap">
                <div className="postpaid-status-image">
                  <img src="./assets/pending-postpaid.png" alt="img" />
                </div>
                <div className="postpaid-status-content-wrap">
                  <h3> Application Pending </h3>
                  <p>
                    Your postpaid application is currently under review. You
                    will be notified as soon as there is an update on your
                    status. Thank you for your patience.
                  </p>
                </div>
              </div>
            ) : userData?.postPaidStatus == "Rejected" ? (
              <div className="postpaid-status-main-wrap">
                <div className="postpaid-status-image">
                  <img
                    className="postpaid-reject-image"
                    src="./assets/reject-postpaid.png"
                    alt="img"
                  />
                </div>
                <div className="postpaid-status-content-wrap">
                  <h3> Application Not Approved </h3>
                  <p>
                    Unfortunately, your postpaid application could not be
                    approved at this time. For more information or to explore
                    alternative options, please contact us at
                    example@example.com. We appreciate your understanding.
                  </p>
                </div>
              </div>
            ) : (
              <div className="postpaid-status-main-wrap">
                <div className="postpaid-status-image">
                  <img
                    className="postpaid-approved-image"
                    src="./assets/approved-postpaid.png"
                    alt="img"
                  />
                </div>
                <div className="postpaid-status-content-wrap">
                  <h3> Application Approved </h3>
                  <p>
                    Congratulations! Your postpaid application has been
                    approved. You can now start using postpaid billing for your
                    services. Thank you for choosing us!
                  </p>
                </div>
              </div>
            )}
          </div>
        </>
      )}
    </div>
  );
};

export default PostPaidDrawer;

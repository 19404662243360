import React, { useState, useEffect } from "react";
import Dropdown from "../dropdown/Dropdown";
import { ExtraCategoriesData } from "../dummydata/DummyData";
import Button from "../UIComponents/Button";
import { useDispatch } from "react-redux";
import { addToCart, updateExtraCart } from "../../redux/features/cartSlice";

const ExtrasDrawer = ({ onHide, data, edit }) => {
  const [enableButton, setEnableButton] = useState(false);
  const [activeIndex, setActiveIndex] = useState(null);
  const [selectedItem, setSelectedItem] = useState({ size: "", price: "" });
  const [selectedCategory, setSelectedCategory] = useState("");

  useEffect(() => {
    if (data) {
      const { size, price, category } = data;
      setSelectedItem({ size, price });
      setSelectedCategory(category);
      const index = items.findIndex(
        (item) => item.size === size && item.price === price
      );
      setActiveIndex(index);
    }
  }, [data]);

  const items = [
    { size: "Small", price: "19.99" },
    { size: "Medium", price: "29.99" },
    { size: "Large", price: "39.99" },
  ];

  const itemDetail = {
    id: data?.id,
    itemImage: data?.itemImage,
    category: selectedCategory,
    name: data?.name,
    price: selectedItem?.price,
    size: selectedItem?.size,
    edit: edit,
  };

  const handleClick = (index, size, price) => {
    setActiveIndex(index);
    setSelectedItem({ size, price });
  };

  const handleDropdownChange = (data) => {
    setSelectedCategory(data);
  };

  useEffect(() => {
    if (selectedItem?.size && selectedCategory) {
      setEnableButton(true);
    } else {
      setEnableButton(false);
    }
  }, [selectedItem?.size, selectedCategory]);

  const dispatch = useDispatch();
  const addToCartHandler = () => {
    if (!selectedItem?.size) {
      alert("Please Select Size");
      return;
    }

    if (!selectedCategory) {
      alert("Please Select Category");
      return;
    }
    dispatch(updateExtraCart(itemDetail));
    onHide();
  };

  return (
    <div className="extra-drawer-main-wrapper">
      <div className="extra-drawer-main-header">
        <div className="extra-back-wrap">
          <img src="./assets/icons/right-black-arrow.svg" alt="icon" />
          <h4> Extras </h4>
        </div>

        <div className="extra-close-drwaer-btn">
          <img
            onClick={onHide}
            src="./assets/icons/close-icon.svg"
            alt="close-icon"
          />
        </div>
      </div>

      <div className="extra-drawer-size-wrapper">
        <h4> Size</h4>

        <div className="extra-drawer-size-wrap">
          {items.map((item, index) => (
            <div
              key={index}
              className={`extra-drawer-size-repeat ${
                index === activeIndex ? "active" : ""
              }`}
              onClick={() => handleClick(index, item.size, item.price)}
            >
              <div className="extra-drawer-size">
                <p>{item.size}</p>
              </div>
              <div className="extra-drawer-price">
                <p>${item.price}</p>
              </div>
            </div>
          ))}
        </div>
      </div>

      <div className="recurring-dropdown-main-wrapper extra-category-dropdown-wrap">
        <Dropdown
          onTypeChange={handleDropdownChange}
          label="Category"
          data={ExtraCategoriesData}
          alreadyValue={data?.category}
        />
      </div>

      <div className="extra-addto-cart-btn">
        <Button
          extraClass={!enableButton ? "disabled-button" : "add-to-cart-btn"}
          onClick={addToCartHandler}
          label={edit ? "Update" : "Add to cart"}
        />
      </div>
    </div>
  );
};

export default ExtrasDrawer;

// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `::-webkit-scrollbar {
  width: 4px;
  height: 0px;
  background: #ccc;
}

::-webkit-scrollbar-thumb {
  background-color: #00a19b; /* Color of the scrollbar thumb */
}


`, "",{"version":3,"sources":["webpack://./src/components/dashboard/onPage.css"],"names":[],"mappings":"AAAA;EACE,UAAU;EACV,WAAW;EACX,gBAAgB;AAClB;;AAEA;EACE,yBAAyB,EAAE,iCAAiC;AAC9D","sourcesContent":["::-webkit-scrollbar {\r\n  width: 4px;\r\n  height: 0px;\r\n  background: #ccc;\r\n}\r\n\r\n::-webkit-scrollbar-thumb {\r\n  background-color: #00a19b; /* Color of the scrollbar thumb */\r\n}\r\n\r\n\r\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;

import React, { useState } from "react";
import Offcanvas from "react-bootstrap/Offcanvas";
import { useDispatch, useSelector } from "react-redux";
import OverlayTrigger from "react-bootstrap/OverlayTrigger";
import Tooltip from "react-bootstrap/Tooltip";
import { removeToCart } from "../../redux/features/cartSlice";
import AdditionalDrawer from "../drawers/AdditionalDrawer";
import AdditionalMultiDrawer from "../drawers/AdditionalMultiDrawer";
import AdditionalScrubDrawer from "../drawers/AdditionalScrubDrawer";
import { ShimmerPostItem } from "react-shimmer-effects";
const SpecialityItem = ({
  extraData,
  addItems,
  removeItem,
  summerItemLength,
}) => {
  const carts = useSelector((state) => state.allCart.carts);
  const [selectedData, setSelectedData] = useState();
  const [showDrawer, setShowDrawer] = useState(false);
  const [showAdditionMultiDrawer, setShowAdditionMultiDrawer] = useState(false);
  const [showAdditionScrubDrawer, setShowAdditionScrubDrawer] = useState(false);

  // for Additional Drawer Handler
  const handleClose = () => setShowDrawer(false);
  const handleShow = (data) => {
    setSelectedData(data);
    setShowDrawer(true);
  };

  // for Additional Multi Drawer Handler
  const handleShowMultiAddition = (data) => {
    setSelectedData(data);
    setShowAdditionMultiDrawer(true);
  };
  const handleCloseMultiAddition = () => setShowAdditionMultiDrawer(false);

  // for Additional Scrub Drawer Handler
  const handleShowScrubAddition = (data) => {
    setSelectedData(data);
    setShowAdditionScrubDrawer(true);
  };
  const handleCloseScrubAddition = () => setShowAdditionScrubDrawer(false);

  const dispatch = useDispatch();
  const handleRemoveItem = (id) => {
    dispatch(removeToCart(id));
  };

  const isAnyItemWithExtraSizeAdded = carts.some((cartItem) => {
    const dataItem = extraData.find((data) => data.id === cartItem.id);
    return dataItem;
  });

  return (
    <>
      <Offcanvas
        className="extras-offcanvas-wrapper"
        show={showDrawer}
        onHide={handleClose}
        placement="end"
      >
        <Offcanvas.Body>
          <AdditionalDrawer onHide={handleClose} data={selectedData} />
        </Offcanvas.Body>
      </Offcanvas>

      <Offcanvas
        className="extras-offcanvas-wrapper"
        show={showAdditionMultiDrawer}
        onHide={handleCloseMultiAddition}
        placement="end"
      >
        <Offcanvas.Body>
          <AdditionalMultiDrawer
            onHide={handleCloseMultiAddition}
            data={selectedData}
          />
        </Offcanvas.Body>
      </Offcanvas>

      <Offcanvas
        className="extras-offcanvas-wrapper"
        show={showAdditionScrubDrawer}
        onHide={handleCloseScrubAddition}
        placement="end"
      >
        <Offcanvas.Body>
          <AdditionalScrubDrawer
            onHide={handleCloseScrubAddition}
            data={selectedData}
          />
        </Offcanvas.Body>
      </Offcanvas>

      <div className="service-item-card-wrapper additional-services-card-wrap">
        <>
          <div className="service-item-grid">
            {extraData.length === 0
              ? Array.from({
                  length: summerItemLength ? summerItemLength : 3,
                }).map((_, index) => (
                  <div key={index} className="shimmer-service-item-card-wrap">
                    <ShimmerPostItem card title />
                  </div>
                ))
              : extraData.map((data, index) => {
                  const cartItem = carts.find(
                    (cartItem) => cartItem.id === data.id
                  );
                  const isDisabled = isAnyItemWithExtraSizeAdded && !cartItem;
                  return (
                    <div
                      className={`service-item-card-wrap ${
                        isDisabled ? "disabled" : ""
                      }`}
                      key={index}
                      style={{
                        filter: isDisabled ? "grayscale(100%)" : "none",
                      }}
                    >
                      <div className="info-icon-wrap">
                        <OverlayTrigger
                          placement="top"
                          delay={{ show: 250, hide: 400 }}
                          overlay={
                            <Tooltip id={`tooltip-up`}>{data.info}</Tooltip>
                          }
                        >
                          <img src="./assets/icons/info.svg" alt="info-icon" />
                        </OverlayTrigger>
                      </div>

                      <div className="service-item-image">
                        <div className="service-item-img">
                          <img src={data.itemImage} alt="img" />
                        </div>
                        <div className="add-cart-btn-wrapper">
                          {data.extraWithSize ? (
                            cartItem ? (
                              <div
                                className="add-cart-btn-wrap remove-cart-item-btn"
                                onClick={() => handleRemoveItem(data)}
                              >
                                <img
                                  className="delete-icon"
                                  src="./assets/icons/delete.svg"
                                  alt="icon"
                                />
                              </div>
                            ) : data.name == "Exterior Window Cleaning" ? (
                              <div
                                className="add-cart-btn-wrap add-cart-withextra"
                                onClick={() => handleShowMultiAddition(data)}
                              >
                                <img
                                  className="plus-icon"
                                  src="../assets/icons/plus.svg"
                                  alt="icon"
                                />
                              </div>
                            ) : data.name == "Machine Scrub Floors" &&
                              !data.flooringType ? (
                              <div
                                className="add-cart-btn-wrap add-cart-withextra"
                                onClick={() => handleShowScrubAddition(data)}
                              >
                                <img
                                  className="plus-icon"
                                  src="../assets/icons/plus.svg"
                                  alt="icon"
                                />
                              </div>
                            ) : (
                              <div
                                className="add-cart-btn-wrap"
                                onClick={() => handleShow(data)}
                              >
                                <img
                                  className="plus-icon"
                                  src="./assets/icons/plus.svg"
                                  alt="icon"
                                />
                              </div>
                            )
                          ) : (
                            <div
                              className={`add-cart-btn-wrap ${
                                cartItem?.isAdded ? "expanded" : ""
                              }`}
                            >
                              {cartItem?.isAdded ? (
                                <div
                                  className="card-delet-icon remove-cart-item-btn"
                                  onClick={() => removeItem(data)}
                                >
                                  <img
                                    className="delete-icon"
                                    src="./assets/icons/delete.svg"
                                    alt="icon"
                                  />
                                </div>
                              ) : (
                                <div
                                  className="plus-icon-wrap"
                                  onClick={() => addItems(data)}
                                >
                                  <img
                                    className="plus-icon"
                                    src="./assets/icons/plus.svg"
                                    alt="icon"
                                  />
                                </div>
                              )}
                            </div>
                          )}
                        </div>
                      </div>

                      <div className="service-item-card-desc">
                        {/* <h6> {data.subCategory} </h6> */}
                        {data.name?.length > 22 ? (
                          <OverlayTrigger
                            placement="top"
                            delay={{ show: 250, hide: 400 }}
                            overlay={
                              <Tooltip id={`tooltip-up`}>{data.name}</Tooltip>
                            }
                          >
                            <p> {data.name} </p>
                          </OverlayTrigger>
                        ) : (
                          <p> {data.name} </p>
                        )}
                        {/* <p> {data.name} </p> */}
                      </div>
                    </div>
                  );
                })}
          </div>
        </>
      </div>
    </>
  );
};

export default SpecialityItem;

// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.mobile-footer-main-wrapper {
  height: 80px;
  background: #00a19b;
  position: fixed;
  bottom: 0;
  min-width: 100%;
  z-index: 999;
}

.mobile-footer-main-wrap {
  display: flex;
  align-items: center;
  justify-content: space-around;
  height: 80px;
}

.mobile-footer-icon-warp.active {
  width: 55px;
  height: 55px;
  border-radius: 17px;
  background: #fff;
}

.mobile-footer-icon-warp svg {
  cursor: pointer;
}

.mobile-footer-icon-warp.active svg path {
  fill: #00a19b;
}

.mobile-footer-icon-warp.active ellipse#Ellipse_1930,
.mobile-footer-icon-warp.active circle#Ellipse_113,
.mobile-footer-icon-warp.active circle#Ellipse_114 {
  fill: #00a19b;
}

.mobile-footer-icon-warp.footer-notification {
  position: relative;
}

.footer-notification-count-wrap {
  width: 10px;
  height: 10px;
  background: #ff0000;
  border-radius: 50%;
  position: absolute;
  top: 10px;
  right: 16px;
}
`, "",{"version":3,"sources":["webpack://./src/components/footer/footer.css"],"names":[],"mappings":"AAAA;EACE,YAAY;EACZ,mBAAmB;EACnB,eAAe;EACf,SAAS;EACT,eAAe;EACf,YAAY;AACd;;AAEA;EACE,aAAa;EACb,mBAAmB;EACnB,6BAA6B;EAC7B,YAAY;AACd;;AAEA;EACE,WAAW;EACX,YAAY;EACZ,mBAAmB;EACnB,gBAAgB;AAClB;;AAEA;EACE,eAAe;AACjB;;AAEA;EACE,aAAa;AACf;;AAEA;;;EAGE,aAAa;AACf;;AAEA;EACE,kBAAkB;AACpB;;AAEA;EACE,WAAW;EACX,YAAY;EACZ,mBAAmB;EACnB,kBAAkB;EAClB,kBAAkB;EAClB,SAAS;EACT,WAAW;AACb","sourcesContent":[".mobile-footer-main-wrapper {\r\n  height: 80px;\r\n  background: #00a19b;\r\n  position: fixed;\r\n  bottom: 0;\r\n  min-width: 100%;\r\n  z-index: 999;\r\n}\r\n\r\n.mobile-footer-main-wrap {\r\n  display: flex;\r\n  align-items: center;\r\n  justify-content: space-around;\r\n  height: 80px;\r\n}\r\n\r\n.mobile-footer-icon-warp.active {\r\n  width: 55px;\r\n  height: 55px;\r\n  border-radius: 17px;\r\n  background: #fff;\r\n}\r\n\r\n.mobile-footer-icon-warp svg {\r\n  cursor: pointer;\r\n}\r\n\r\n.mobile-footer-icon-warp.active svg path {\r\n  fill: #00a19b;\r\n}\r\n\r\n.mobile-footer-icon-warp.active ellipse#Ellipse_1930,\r\n.mobile-footer-icon-warp.active circle#Ellipse_113,\r\n.mobile-footer-icon-warp.active circle#Ellipse_114 {\r\n  fill: #00a19b;\r\n}\r\n\r\n.mobile-footer-icon-warp.footer-notification {\r\n  position: relative;\r\n}\r\n\r\n.footer-notification-count-wrap {\r\n  width: 10px;\r\n  height: 10px;\r\n  background: #ff0000;\r\n  border-radius: 50%;\r\n  position: absolute;\r\n  top: 10px;\r\n  right: 16px;\r\n}\r\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;

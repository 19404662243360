import React, { useEffect, useRef, useState } from "react";
import "./navbar.css";
import Offcanvas from "react-bootstrap/Offcanvas";
import HistoryDrawer from "../drawers/HistoryDrawer";
import { useDispatch, useSelector } from "react-redux";
import { persistor } from "../../redux/app/store";
import OverlayTrigger from "react-bootstrap/OverlayTrigger";
import Tooltip from "react-bootstrap/Tooltip";
import CartDrawer from "../drawers/CartDrawer";
import NotificationDrawer from "../drawers/NotificationDrawer";
import { useMediaQuery } from "react-responsive";
import { useLocation, useNavigate } from "react-router-dom";
import ConfirmationModal from "../modals/ConfirmationModal";
import ChatDrawer from "../drawers/ChatDrawer";
import TrackOrderDrawer from "../drawers/TrackOrderDrawer";
import ProfileDrop from "./ProfileDrop";
import { isUserAuthenticated } from "../../routes/AuthUtils";
import { servicePost } from "../../helper/api";
import PostPaidDrawer from "../drawers/PostPaidDrawer";
import LogoutModal from "../modals/LogoutModal";

const Navbar = () => {
  const [userData, setUserData] = useState([]);
  const [partnerAssigned, setPartnerAssigned] = useState(false);
  const isMobile = useMediaQuery({ maxWidth: 1024 });
  const carts = useSelector((state) => state.allCart.carts);
  const [showNotificationDrawer, setShowNotificationDrawer] = useState(false);
  const [showCartDrawer, setShowCartDrawer] = useState(false);
  const [showConfirmationModal, setShowConfirmationModal] = useState(false);
  const [showProfileDrop, setShowProfileDrop] = useState(false);
  const [showLogoutModal, setShowLogoutModal] = useState(false);
  const [notificationCount, setNotificationCount] = useState();

  const [showPostpadDrawer, setShowPostpadDrawer] = useState(false);
  const handleClosePostpaidDrawer = () => setShowPostpadDrawer(false);
  const handleShowPostpaidDrawer = (title) => {
    setShowPostpadDrawer(true);
  };

  const logedInUser = useSelector((state) => state.user.userInfo);
  const bookingId = useSelector((state) => state?.bookingId?.bookingId);
  const notificationDep = useSelector(
    (state) => state.notificationDep.notificationDep
  );

  // get AuthToken from localStorage
  const authToken = localStorage.getItem("token");
  const notification = "";

  const showProfileDropdown = () => {
    setShowProfileDrop(true);
  };

  const hideProfileDropdown = () => {
    setShowProfileDrop(false);
  };

  const profileDropdownRef = useRef(null);

  // const [isUserAuthenticate, setIsUserAuthenticate] = useState(false);
  const [showChatDrawer, setShowChatDrawer] = useState(false);
  const handleCloseChat = () => setShowChatDrawer(false);
  const handleShowChat = (title) => {
    setShowChatDrawer(true);
  };

  // Track Order Drawer handler
  const [showTrackDrawer, setShowTrackDrawer] = useState(false);
  const handleCloseTracking = () => setShowTrackDrawer(false);
  const handleShowTracking = (title) => {
    setShowTrackDrawer(true);
  };

  const deleteNotificationCount = async () => {
    try {
      const deleteCountRes = await servicePost(
        "user/updateNotification",
        {
          accountId: logedInUser?.id,
        },
        notification,
        authToken
      );

      if (deleteCountRes.message == "SUCCESS") {
        setNotificationCount(0);
      }
    } catch (error) {
      console.log("error", error);
    }
  };

  const location = useLocation();
  const dispatch = useDispatch();
  const currentpath = location.pathname;
  const changeOrderPath = currentpath.includes("change-order");

  const handleCloseNotification = () => setShowNotificationDrawer(false);
  const handleShowNotification = (title) => {
    setShowNotificationDrawer(true);
    deleteNotificationCount();
  };

  const handleCloseCart = () => setShowCartDrawer(false);

  const handleShowCart = (title) => {
    setShowCartDrawer(true);
  };

  const navigate = useNavigate();
  const goToProfilePage = () => {
    navigate("/profile");
  };

  const goToDashboard = () => {
    navigate("/");
  };

  // Hide Profile Dropdown when Click Outside
  useEffect(() => {
    const handleClickOutside = (event) => {
      if (
        profileDropdownRef.current &&
        !profileDropdownRef.current.contains(event.target)
      ) {
        // Clicked outside the dropdown, hide it
        hideProfileDropdown();
      }
    };

    document.addEventListener("click", handleClickOutside);

    return () => {
      document.removeEventListener("click", handleClickOutside);
    };
  }, [hideProfileDropdown]);

  // Get User Detail By UserId
  const getUserDetail = async () => {
    try {
      const userRes = await servicePost(
        `user/getUser`,
        {
          id: logedInUser?.id,
        },
        notification,
        authToken
      );
      setUserData(userRes.data);
    } catch (error) {
      console.log("error", error);
    }
  };

  useEffect(() => {
    getUserDetail();
  }, [logedInUser?.id, notificationDep]);

  // Get Notification Count
  const getNotificationCount = async () => {
    try {
      const notificationCountRes = await servicePost(
        `user/latestNotificationCount`,
        {
          accountId: logedInUser?.id,
        },
        notification,
        authToken
      );
      setNotificationCount(notificationCountRes.data);
    } catch (error) {
      console.log("error", error);
    }
  };

  useEffect(() => {
    getNotificationCount();
  }, [logedInUser?.id, notificationCount, notificationDep]);

  const logoutHandler = () => {
    localStorage.clear();
    dispatch({ type: "RESET_STORE" });
    persistor.purge();
    navigate("/login");
  };

  return (
    <>
      <LogoutModal
        show={showLogoutModal}
        onHide={() => setShowLogoutModal(false)}
        showCloseIcon={true}
      />

      <ConfirmationModal
        show={showConfirmationModal}
        onHide={() => setShowConfirmationModal(false)}
        icon="../assets/icons/phone-icon.png"
        type={partnerAssigned ? "Cleaner Contact Number" : "Not Assigned"}
        content={
          partnerAssigned
            ? "+1 (746)-366-9378"
            : "This funcation will be enabled an hour before your Schedule sevice"
        }
        showCloseIcon={true}
      />

      <Offcanvas
        className="chat-offcanvas-wrapper"
        show={showChatDrawer}
        onHide={handleCloseChat}
        placement="end"
      >
        <Offcanvas.Body>
          <ChatDrawer
            bookingId={bookingId}
            onHide={handleCloseChat}
            title="Chat"
          />
        </Offcanvas.Body>
      </Offcanvas>

      <Offcanvas
        className="trackorder-offcanvas-wrapper"
        show={showTrackDrawer}
        onHide={handleCloseTracking}
        placement="end"
      >
        <Offcanvas.Body>
          <TrackOrderDrawer
            bookingId={bookingId}
            onHide={handleCloseTracking}
            title="track"
          />
        </Offcanvas.Body>
      </Offcanvas>

      <Offcanvas
        className="notification-offcanvas-wrapper"
        show={showNotificationDrawer}
        onHide={handleCloseNotification}
        placement="end"
      >
        <Offcanvas.Body>
          <NotificationDrawer
            data={carts}
            onHide={handleCloseNotification}
            title="Cart"
          />
        </Offcanvas.Body>
      </Offcanvas>

      <Offcanvas
        className="cart-offcanvas-wrapper"
        show={showCartDrawer}
        onHide={handleCloseCart}
        placement="end"
      >
        <Offcanvas.Body>
          <CartDrawer
            onHide={handleCloseCart}
            setShowCartDrawer={setShowCartDrawer}
          />
        </Offcanvas.Body>
      </Offcanvas>

      <Offcanvas
        className="extras-offcanvas-wrapper postpaid-offcanvas-wrapper"
        show={showPostpadDrawer}
        onHide={handleClosePostpaidDrawer}
        placement="end"
      >
        <Offcanvas.Body>
          <PostPaidDrawer onHide={handleClosePostpaidDrawer} />
        </Offcanvas.Body>
      </Offcanvas>

      <div
        className={
          isUserAuthenticated() && currentpath !== "/enter-twofa"
            ? "navbar-main-wrapper dashbord-navbar-active"
            : "navbar-main-wrapper dashbord-navbar-active"
        }
        style={changeOrderPath ? { pointerEvents: "none", opacity: 0.4 } : {}}
      >
        <div className="navbar-main-wrap container">
          <div className="navbar-left-wrap">
            {isUserAuthenticated() && currentpath !== "/enter-twofa" ? (
              <div className="navbar-logo-wrap-active">
                <img
                  onClick={goToDashboard}
                  src="../assets/icons/yfos-logo.png"
                  alt="logo"
                />
              </div>
            ) : (
              <div className="navbar-logo-wrap navbar-logo-wrap-active">
                <img src="../assets/icons/yfos-logo.png" alt="logo" />
                {/* <img src="../assets/logo-white.png" alt="logo" /> */}
              </div>
            )}
          </div>
          {isMobile && isUserAuthenticated() ? (
            currentpath.includes("/tracking-order/") ? (
              <div className="navbar-right-wrap">
                <img
                  onClick={handleShowChat}
                  src="../assets/icons/nav-chat.svg"
                  alt="message-icon"
                />

                <div className="nav-location-wrap" onClick={handleShowTracking}>
                  <img
                    className="nav-location-icon"
                    src="../assets/icons/location.svg"
                    alt="phone-icon"
                  />
                  <p> Check Location </p>
                  <img
                    className="nav-location-check-arrow"
                    src="../assets/icons/right-arrow.svg"
                    alt="arrow"
                  />
                </div>
              </div>
            ) : (
              <img
                onClick={() => setShowLogoutModal(true)}
                className="mobile-logout-btn"
                src="../assets/icons/mobile-logout.svg"
                alt="arrow"
              />
            )
          ) : !isMobile &&
            isUserAuthenticated() &&
            currentpath !== "/enter-twofa" ? (
            <div className="navbar-right-wrap">
              {userData?.postPaidDoc ? (
                <OverlayTrigger
                  placement="right"
                  delay={{ show: 250, hide: 400 }}
                  overlay={
                    <Tooltip id={`tooltip-up-`}>
                      {`Application ${userData?.postPaidStatus}`}
                    </Tooltip>
                  }
                >
                  <div className="nav-postpaid-main-wrap">
                    <div
                      className="postpaid-icon-wrap"
                      style={{ cursor: "pointer" }}
                      onClick={handleShowPostpaidDrawer}
                    >
                      <p> Enterprise</p>
                      <img src="../assets/icons/yfos-logo.png" alt="icon" />
                    </div>

                    <div className="postpaid-status-icon">
                      {userData?.postPaidStatus == "Approved" ? (
                        <img
                          src="../assets/icons/postpaid-complete.svg"
                          alt="icon"
                        />
                      ) : userData?.postPaidStatus == "Rejected" ? (
                        <img
                          src="../assets/icons/postpaid-reject.svg"
                          alt="icon"
                        />
                      ) : (
                        <img
                          src="../assets/icons/postpaid-pending.svg"
                          alt="icon"
                        />
                      )}
                    </div>
                  </div>
                </OverlayTrigger>
              ) : (
                <div className="nav-postpaid-main-wrap">
                  <div
                    className="postpaid-icon-wrap"
                    style={{ cursor: "pointer" }}
                    onClick={handleShowPostpaidDrawer}
                  >
                    <p> Enterprise</p>
                    <img src="../assets/icons/yfos-logo.png" alt="icon" />
                  </div>
                </div>
              )}

              <div className="nav-cart-wrapper" onClick={handleShowCart}>
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  width="35"
                  height="35"
                  viewBox="0 0 35 35"
                >
                  <defs>
                    <clipPath id="clip-path">
                      <rect
                        id="Rectangle_6452"
                        data-name="Rectangle 6452"
                        width="35"
                        height="35"
                        transform="translate(1460 13)"
                        fill="#1a1919"
                        opacity="0"
                      />
                    </clipPath>
                  </defs>
                  <g
                    id="Share_Icon"
                    data-name="Share Icon"
                    transform="translate(-1460 -13)"
                    clipPath="url(#clip-path)"
                  >
                    <g id="shopping-cart" transform="translate(1465.795 18.5)">
                      <path
                        id="Path_18689"
                        data-name="Path 18689"
                        d="M22.713,4.077A2.993,2.993,0,0,0,20.41,3H4.242L4.2,2.649A3,3,0,0,0,1.222,0H1A1,1,0,0,0,1,2h.222a1,1,0,0,1,.993.883l1.376,11.7A5,5,0,0,0,8.557,19H19a1,1,0,0,0,0-2H8.557a3,3,0,0,1-2.82-2h11.92a5,5,0,0,0,4.921-4.113l.785-4.354a2.994,2.994,0,0,0-.65-2.456Z"
                        fill="#1a1919"
                      />
                      <circle
                        id="Ellipse_113"
                        data-name="Ellipse 113"
                        cx="2"
                        cy="2"
                        r="2"
                        transform="translate(5 20)"
                        fill="#1a1919"
                      />
                      <circle
                        id="Ellipse_114"
                        data-name="Ellipse 114"
                        cx="2"
                        cy="2"
                        r="2"
                        transform="translate(15 20)"
                        fill="#1a1919"
                      />
                    </g>
                  </g>
                </svg>
                {carts?.length > 0 && (
                  <div className="nav-cart-item-count">
                    <p> {carts?.length} </p>
                  </div>
                )}
              </div>

              <div
                className="nav-notification-icon-wrap"
                onClick={handleShowNotification}
              >
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  width="35"
                  height="35"
                  viewBox="0 0 35 35"
                >
                  <defs>
                    <clipPath id="clip-path">
                      <rect
                        id="Rectangle_25"
                        data-name="Rectangle 25"
                        width="35"
                        height="35"
                        transform="translate(1460 13)"
                        fill="#fff"
                        opacity="0"
                      />
                    </clipPath>
                  </defs>
                  <g
                    id="Share_Icon"
                    data-name="Share Icon"
                    transform="translate(-1460 -13)"
                    clipPath="url(#clip-path)"
                  >
                    <g id="bell" transform="translate(1466.444 19.384)">
                      <path
                        id="Path_13"
                        data-name="Path 13"
                        d="M7.424,21A4.622,4.622,0,0,0,15.9,21Z"
                        transform="translate(-0.467 -1.547)"
                        fill="#1a1919"
                      />
                      <path
                        id="Path_14"
                        data-name="Path 14"
                        d="M20.823,11.624l-1.608-5.3A8.634,8.634,0,0,0,2.47,6.744L1.222,11.873a4.632,4.632,0,0,0,4.5,5.728H16.39a4.632,4.632,0,0,0,4.433-5.976Z"
                        transform="translate(0 0)"
                        fill="#1a1919"
                      />
                    </g>
                  </g>
                </svg>
                {notificationCount > 0 && (
                  <div className="notification-count-wrap"></div>
                )}
              </div>
              <div className="profile-drop-wrapper">
                {/* <img
                  onClick={goToProfilePage}
                  className="nav-user-icon"
                  src="../assets/icons/user-icon.svg"
                  alt="icon"
                /> */}

                {!userData?.profileImageURL ? (
                  <svg
                    onClick={goToProfilePage}
                    xmlns="http://www.w3.org/2000/svg"
                    width="42"
                    height="42"
                    viewBox="0 0 42 42"
                  >
                    <g
                      id="User_Icon"
                      data-name="User Icon"
                      transform="translate(-1676 -13)"
                    >
                      <g
                        id="Ellipse_6"
                        data-name="Ellipse 6"
                        transform="translate(1676 13)"
                        fill="#fff"
                        stroke="#f3f3f3"
                        strokeWidth="2"
                      >
                        <circle cx="21" cy="21" r="21" stroke="none" />
                        <circle cx="21" cy="21" r="20" fill="none" />
                      </g>
                      <g id="user" transform="translate(1606.23 25.833)">
                        <path
                          id="Path_2"
                          data-name="Path 2"
                          d="M90.4,298.675a5.574,5.574,0,0,0-5.072,5.625v.148a1.021,1.021,0,0,0,1.021,1.021h0a1.021,1.021,0,0,0,1.021-1.021v-.189a3.507,3.507,0,0,1,3.063-3.539,3.407,3.407,0,0,1,3.727,3.054q.016.166.017.333v.34a1.021,1.021,0,0,0,1.021,1.021h0a1.021,1.021,0,0,0,1.021-1.021v-.34a5.451,5.451,0,0,0-5.457-5.445Q90.578,298.663,90.4,298.675Z"
                          transform="translate(0 -289.135)"
                          fill="#1a1919"
                        />
                        <path
                          id="Path_3"
                          data-name="Path 3"
                          d="M132.091,8.167a4.083,4.083,0,1,0-4.083-4.083A4.083,4.083,0,0,0,132.091,8.167Zm0-6.125a2.042,2.042,0,1,1-2.042,2.042A2.042,2.042,0,0,1,132.091,2.042Z"
                          transform="translate(-41.321)"
                          fill="#1a1919"
                        />
                      </g>
                    </g>
                  </svg>
                ) : (
                  <img
                    onClick={goToProfilePage}
                    className="nav-profile-img"
                    src={userData?.profileImageURL}
                    alt="img"
                  />
                )}

                <div
                  data-bs-toggle="dropdown"
                  aria-expanded="false"
                  className="dropdown profiledrop-arrow-wrap"
                >
                  {/* <img
                    className="profile-drop-icon"
                    src="../assets/icons/dropdown-arrow.svg"
                    alt="icon"
                  /> */}

                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    width="11.374"
                    height="5.827"
                    viewBox="0 0 11.374 5.827"
                  >
                    <path
                      id="angle-small-down"
                      d="M16.137,8.154a.812.812,0,0,0-1.153,0l-3.72,3.72a.812.812,0,0,1-1.153,0l-3.72-3.72A.813.813,0,1,0,5.235,9.3l3.729,3.729a2.437,2.437,0,0,0,3.444,0L16.137,9.3A.812.812,0,0,0,16.137,8.154Z"
                      transform="translate(-4.999 -7.914)"
                      fill="#1a1919"
                    />
                  </svg>

                  <ul className="dropdown-menu">
                    <ProfileDrop hideProfileDropdown={hideProfileDropdown} />
                  </ul>
                </div>
              </div>

              {/* {showProfileDrop && (
                <ProfileDrop hideProfileDropdown={hideProfileDropdown} />
              )} */}
            </div>
          ) : (
            ""
          )}
        </div>
      </div>
    </>
  );
};

export default Navbar;

// redux/rootReducer.js
import { combineReducers } from "@reduxjs/toolkit";
import cartSlice from "../features/cartSlice";
import dropdownSlice from "../features/dropdownSlice";
import formSlice from "../features/formSlice";
import errorSlice from "../features/errorSlice";
import serviceTypeSlice from "../features/serviceTypeSlice";
import userSlice from "../features/userSlice";
import couponSlice from "../features/couponSlice";
import bookingSlice from "../features/bookingSlice";
import notificationDepSlice from "../features/notificationDepSlice";
import orderChangeSlice from "../features/orderChangeSlice";
import flooringPriceSlice from "../features/flooringPriceSlice";
import extraItemPriceSlice from "../features/extraItemPriceSlice";

const appReducer = combineReducers({
  allCart: cartSlice,
  allDowpdown: dropdownSlice,
  formData: formSlice,
  formErrors: errorSlice,
  flooringPrice: flooringPriceSlice,
  serviceType: serviceTypeSlice,
  user: userSlice,
  coupon: couponSlice,
  bookingId: bookingSlice,
  notificationDep: notificationDepSlice,
  orderChange: orderChangeSlice,
  extraItemPrice: extraItemPriceSlice,
});

const rootReducer = (state, action) => {
  if (action.type === "RESET_STORE") {
    state = undefined;
  }

  return appReducer(state, action);
};

export default rootReducer;

import React, { useState } from "react";
import Modal from "react-bootstrap/Modal";
import "./modal.css";
import { useNavigate } from "react-router-dom";
import {
  emptyCartItem,
  setServiceTypeForSq,
} from "../../redux/features/cartSlice";
import {
  updateDropdown,
  resetDropdown,
  resetSpecificDropdown,
} from "../../redux/features/dropdownSlice";
import { resetSpecificFormData } from "../../redux/features/formSlice";
import { setServiceType } from "../../redux/features/serviceTypeSlice";
import { resetCoupon } from "../../redux/features/couponSlice";
import { useDispatch, useSelector } from "react-redux";
import { clearFlooringPrices } from "../../redux/features/flooringPriceSlice";

const SpecialityModal = (props) => {
  const dropdownValues = useSelector((state) => state.allDowpdown);
  const tempIndustryDrop = props.tempIndustryDrop;
  const serviceType = useSelector((state) => state.serviceType.serviceType);

  console.log(
    "checkCondition",
    serviceType == "Commercial" &&
      tempIndustryDrop?.value?.name !== "Specialty Cleaning"
  );
  console.log("serviceType", serviceType);
  console.log("tempIndustryDrop", tempIndustryDrop?.value?.name);

  const dispatch = useDispatch();
  const navigate = useNavigate();

  const goToServices = () => {
    // UpdateDropdown when leave
    props.tempIndustryDrop &&
      dispatch(
        updateDropdown({
          field: tempIndustryDrop.field,
          value: tempIndustryDrop.value,
        })
      );

    dispatch(
      resetSpecificFormData([
        "squareFeet",
        "scheduleDate",
        "scheduleTime",
        "sessionStartTime",
        "contactname",
        "contactnumber",
        "otpAccess",
        "paywith",
      ])
    );
    dispatch(clearFlooringPrices());
    dispatch(emptyCartItem());
    dispatch(resetCoupon());

    if (props.clearIndustryData) {
      dispatch(resetSpecificDropdown(["industry", "floors", "bedrooms"]));
    } else {
      dispatch(resetSpecificDropdown(["floors", "bedrooms"]));
    }

    if (props.type) {
      dispatch(setServiceType(props.type));
      dispatch(setServiceTypeForSq(props.type));
    }

    if (props.redirectPath) {
      navigate(props.redirectPath);
    } else {
      navigate("/services");
    }

    if (
      props.page == "Special-service" &&
      serviceType == "Commercial" &&
      tempIndustryDrop?.value?.name !== "Specialty Cleaning"
    ) {
      navigate("/services-commercial");
    }

    if (
      props.page == "Special-service" &&
      serviceType == "Residential" &&
      tempIndustryDrop?.value?.name !== "Specialty Cleaning"
    ) {
      navigate("/services");
    }

    props.onHide();
  };

  // Stay On the Page
  const stayOnThePage = () => {
    // UpdateDropdown when leave
    props.tempIndustryDrop &&
      dispatch(
        updateDropdown({
          field: tempIndustryDrop.field,
          value: {
            name: dropdownValues?.industry.name,
            imageUrl: dropdownValues?.industry.imageUrl,
            activeImageUrl: dropdownValues?.industry.activeImageUrl,
          },
        })
      );
    props.onHide();
  };

  return (
    <Modal
      {...props}
      size="lg"
      backdrop="static"
      dialogClassName="confirmation-modal-main-wrapper speciality-modal-main-wrapper"
      aria-labelledby="contained-modal-title-vcenter"
      centered
    >
      <Modal.Body>
        <div className="confirm-modal-main-wrapper">
          <div className="confirm-modal-main-wrap">
            <div className="modal-logo-wrap">
              <img src="./assets/modal-logo.png" alt="modal-logo" />
            </div>

            <div className="close-icon-wrap">
              <img
                onClick={stayOnThePage}
                src="./assets/icons/close-icon.svg"
                alt="icon"
              />
            </div>

            <div className="specaility-modal-content-wrap">
              <h3> Speciality services </h3>
              <p>
                Your Previous selected items will be Removed from your cart. If
                you add these services
              </p>
              <div className="modal-leave-stay-btn-wrap">
                <p> You want to leave this Current to Page </p>
                <div className="modal-leave-stay-btn">
                  <button onClick={goToServices} className="simple-button">
                    Leave
                  </button>
                  <button onClick={stayOnThePage} className="add-to-cart-btn">
                    Stay
                  </button>
                </div>
              </div>
            </div>
          </div>
        </div>
      </Modal.Body>
    </Modal>
  );
};
export default SpecialityModal;

import React, { useState, useEffect } from "react";
import Button from "../UIComponents/Button";
import { useNavigate } from "react-router-dom";
import { persistor } from "../../redux/app/store";
import { useDispatch, useSelector } from "react-redux";
import { servicePost } from "../../helper/api";
import LogoutModal from "../modals/LogoutModal";

const ProfileDrop = () => {
  const [userData, setUserData] = useState([]);
  const [showLogoutModal, setShowLogoutModal] = useState(false);
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const logedInUser = useSelector((state) => state.user.userInfo);
  const notificationDep = useSelector(
    (state) => state.notificationDep.notificationDep
  );

  // get AuthToken from localStorage
  const authToken = localStorage.getItem("token");
  const notification = "";

  // Get User Detail By UserId
  const getUserDetail = async () => {
    try {
      const userRes = await servicePost(
        `user/getUser`,
        {
          id: logedInUser?.id,
        },
        notification,
        authToken
      );

      console.log("userRes", userRes);
      setUserData(userRes.data);
    } catch (error) {
      console.log("error", error);
    }
  };

  useEffect(() => {
    getUserDetail();
  }, [logedInUser?.id, notificationDep]);

  const logoutHandler = () => {
    localStorage.clear();
    dispatch({ type: "RESET_STORE" });
    persistor.purge();
    navigate("/login");
  };

  const goToProfilePage = () => {
    navigate("/profile");
  };

  return (
    <>
      <LogoutModal
        show={showLogoutModal}
        onHide={() => setShowLogoutModal(false)}
        showCloseIcon={true}
      />
      <div className="navbar-profile-drop-wrapper">
        <div className="profile-nav-main-wrap profile-link-item">
          <img
            src={
              userData.profileImageURL
                ? userData.profileImageURL
                : "./assets/icons/avtar.png"
            }
            alt="avtar"
          />
          <p onClick={goToProfilePage}>
            {userData?.fullName ? userData?.fullName : "--"}{" "}
          </p>
        </div>

        <div className="navbar-profile-drop-wrap">
          <div className="profile-nav-item-repeat profile-link-item">
            <img
              onClick={goToProfilePage}
              src="../assets/icons/user-icon.png"
              alt="icon"
            />
            <p onClick={goToProfilePage}> My Profile </p>
          </div>

          <div className="profile-nav-item-repeat">
            <img src="../assets/icons/mail-icon.png" alt="icon" />
            <p> {userData?.email ? userData?.email : "--"} </p>
          </div>

          <div className="profile-nav-item-repeat">
            <img src="../assets/icons/profile-phone-icon.png" alt="icon" />
            <p> {userData?.phone ? userData?.phone : "--"}</p>
          </div>

          <div className="profile-nav-item-repeat profile-link-item">
            <img
              onClick={() => setShowLogoutModal(true)}
              src="../assets/icons/profile-logout-icon.png"
              alt="icon"
            />
            <p onClick={() => setShowLogoutModal(true)}> Log Out </p>
          </div>
          {/* <div className="logut-button-wrap">
            <Button
              onClick={() => setShowLogoutModal(true)}
              extraClass="logout-button"
              label="Logout"
            />
          </div> */}
        </div>
      </div>
    </>
  );
};

export default ProfileDrop;

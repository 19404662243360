import React, { useState, useEffect } from "react";
import Dropdown from "../dropdown/Dropdown";
import Button from "../UIComponents/Button";
import { useDispatch, useSelector } from "react-redux";
import { updateExtraCart } from "../../redux/features/cartSlice";
import { setError } from "../../redux/features/errorSlice";
import { updateTotalPriceAndTime } from "../../redux/features/extraItemPriceSlice";

const AdditionalMultiDrawer = ({ onHide, data, edit }) => {
  const [selectedItems, setSelectedItems] = useState([]);
  const [enableButton, setEnableButton] = useState(false);
  const [totalPrice, setTotalPrice] = useState(0);
  const [totalTime, setTotalTime] = useState(0);
  const [selectedCategory, setSelectedCategory] = useState({});
  const [dropdownData, setDropdownData] = useState(data.dropDowns || []);
  const [dropdownErrors, setDropdownErrors] = useState({});
  const [dropdownEnabledState, setDropdownEnabledState] = useState([]);
  const errors = useSelector((state) => state.formErrors);

  const dispatch = useDispatch();

  // Set initial enabled state for dropdowns
  useEffect(() => {
    if (dropdownData.length > 0) {
      const initialEnabledState = dropdownData.map((_, index) =>
        index === 0 ? true : false
      );
      setDropdownEnabledState(initialEnabledState);
    }
  }, [dropdownData]);

  const addItem = () => {
    let hasError = false;

    // Check if all required dropdowns are selected
    dropdownData.forEach((dropdown) => {
      if (!selectedCategory[dropdown.type]) {
        setDropdownErrors((prevErrors) => ({
          ...prevErrors,
          [dropdown.type]: true,
        }));
        hasError = true;
      }
    });

    // Only show the alert when attempting to add the item
    if (hasError) {
      alert("Please Select all field");
      return; // Do not proceed if there are errors
    }

    // Calculate item price and time
    let itemPrice = 0;
    let itemTime = 0;

    // Step 1: Calculate base price and time from "Type of Service"
    const service = selectedCategory["Type of Service*"];
    if (service) {
      itemPrice += service.price;
      itemTime += service.time;
    }

    // Step 2: Apply price and time percentage adjustments
    const numberOfWindows = selectedCategory["Number of Windows*"];
    if (numberOfWindows) {
      const priceAdjustment =
        (numberOfWindows.pricePercentage / 100) * itemPrice;
      itemPrice += priceAdjustment;
      const timeAdjustment = (numberOfWindows.timePercentage / 100) * itemTime;
      itemTime += timeAdjustment;
    }

    const accessibility =
      selectedCategory["Height & Accessibility (% scroll)*"];
    if (accessibility) {
      const priceAdjustment = (accessibility.pricePercentage / 100) * itemPrice;
      itemPrice += priceAdjustment;
      const timeAdjustment = (accessibility.timePercentage / 100) * itemTime;
      itemTime += timeAdjustment;
    }

    // Round the final values
    itemPrice = parseFloat(itemPrice.toFixed(2));
    itemTime = parseFloat(itemTime.toFixed(2));

    const newItem = {
      "Type of Service*": service.name,
      "Number of Windows*": numberOfWindows.name,
      "Height & Accessibility (% scroll)*": accessibility.name,
      price: itemPrice,
      time: itemTime,
    };

    // Add the new item to the list
    setSelectedItems([...selectedItems, newItem]);

    // Update the total price and total time
    setTotalPrice((prevTotal) =>
      parseFloat((prevTotal + itemPrice).toFixed(2))
    );
    setTotalTime((prevTotal) => parseFloat((prevTotal + itemTime).toFixed(2)));

    // Reset dropdown selection
    setSelectedCategory({});
    setDropdownEnabledState(Array(dropdownData.length).fill(true));
    setDropdownErrors({});
  };

  const handleDropdownChange = (type, value, index) => {
    // Update the selected category state
    setSelectedCategory((prev) => ({ ...prev, [type]: value }));
    setDropdownErrors((prev) => ({ ...prev, [type]: false }));

    // Enable the next dropdown if the current one is selected
    const newEnabledState = [...dropdownEnabledState];
    if (value) {
      newEnabledState[index + 1] = true;
    }
    setDropdownEnabledState(newEnabledState);
  };

  useEffect(() => {
    // Check if all dropdowns are selected
    const allDropdownsSelected = dropdownData.every(
      (dropdown) => selectedCategory[dropdown.type]
    );

    if (allDropdownsSelected) {
      addItem();
    }
  }, [selectedCategory]);

  useEffect(() => {
    if (selectedItems.length > 0) {
      setEnableButton(true);
    }
  }, [selectedItems]);

  const addToCartHandler = () => {
    if (selectedItems.length > 0) {
      dispatch(
        updateExtraCart({
          id: data?.id,
          itemImage: data?.itemImage,
          selectedDropdown: selectedCategory,
          selectedDropdownItems: selectedItems,
          name: data?.name,
          price: totalPrice,
          time: totalTime,
          isFlooring: false,
          size: true,
          edit: edit,
          data: edit ? data?.data : data,
        })
      );
      onHide();
    }
  };

  // Function to handle item deletion
  const handleDeleteItem = (indexToDelete) => {
    setSelectedItems((prevItems) =>
      prevItems.filter((_, index) => index !== indexToDelete)
    );
  };

  return (
    <div className="extra-drawer-main-wrapper">
      <div className="extra-drawer-main-header">
        <div className="extra-back-wrap">
          <img src="../assets/icons/right-black-arrow.svg" alt="icon" />
          <h4> Additional Service </h4>
        </div>
        <div className="extra-close-drwaer-btn">
          <img
            onClick={onHide}
            src="../assets/icons/close-icon.svg"
            alt="close-icon"
          />
        </div>
      </div>
      <div className="addition-drawer-main-wrap">
        <div className="addition-drawer-item-wrap">
          <img src={data.itemImage} alt="img" />
          <p> {data.name}</p>
        </div>

        <div className="recurring-dropdown-main-wrapper extra-category-dropdown-wrap">
          {dropdownData.map((dropdown, index) => (
            <Dropdown
              extraClass={dropdownErrors[dropdown.type] ? "error-msg" : ""}
              key={index}
              onTypeChange={(value) =>
                handleDropdownChange(dropdown.type, value, index)
              }
              disabled={!dropdownEnabledState[index]}
              label={dropdown.type}
              data={dropdown.data}
              alreadyValue={selectedCategory[dropdown.type] || "Select"}
            />
          ))}
        </div>

        {selectedItems.length > 0 && (
          <div className="selected-items-list">
            <h3> Items</h3>
            {selectedItems.map((item, index) => (
              <div key={index} className="selected-item">
                {Object.keys(item)
                  .filter((key) => key !== "price" && key !== "time")
                  .map((key) => `${item[key]}`)
                  .join(", ")}{" "}
                {/* - Price: ${item.price}, Time: {item.time} */}
                <span
                  className="delete-item-icon"
                  onClick={() => handleDeleteItem(index)}
                >
                  &times;
                </span>
              </div>
            ))}
          </div>
        )}
      </div>
      <div className="extra-addto-cart-btn">
        {/* <p>Total Price: ${totalPrice}</p>
        <p>Total Time: {totalTime} </p> */}
        <Button
          extraClass={!enableButton ? "disabled-button" : "add-to-cart-btn"}
          onClick={addToCartHandler}
          label={edit ? "Update" : "Add to cart"}
        />
      </div>
    </div>
  );
};

export default AdditionalMultiDrawer;

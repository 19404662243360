import React, { useState, useEffect } from "react";
import Button from "../UIComponents/Button";
import { useNavigate } from "react-router-dom";
import { servicePost } from "../../helper/api";
import { useDispatch, useSelector } from "react-redux";
import { setUser } from "../../redux/features/userSlice";

const AuthenticationCode = () => {
  const [isLoading, setIsLoading] = useState(false);
  const [inputValues, setInputValues] = useState("");

  const user = useSelector((state) => state.user.userInfo);
  const [error, setError] = useState(false);
  const [errorMessage, setErrorMessage] = useState();

  // get AuthToken from localStorage
  const authToken = localStorage.getItem("token");
  const notification = "";

  const dispatch = useDispatch();

  const checkPin = () => {
    const inputElements = [...document.querySelectorAll("input.code-input")];
    inputElements.forEach((ele, index) => {
      ele.addEventListener("keydown", (e) => {
        if (e.keyCode === 8 && e.target.value === "")
          inputElements[Math.max(0, index - 1)].focus();
      });

      ele.addEventListener("input", (e) => {
        const [first, ...rest] = e.target.value;
        e.target.value = first ?? "";
        const lastInputBox = index === inputElements.length - 1;
        const didInsertContent = first !== undefined;
        if (didInsertContent && !lastInputBox) {
          inputElements[index + 1].focus();
          inputElements[index + 1].value = rest.join("");
          inputElements[index + 1].dispatchEvent(new Event("input"));
        }

        const code = inputElements.map(({ value }) => value).join("");
        setInputValues(code);
      });

      ele.addEventListener("paste", (e) => {
        e.preventDefault();
        const paste = e.clipboardData.getData("text");
        if (paste.length === 6 && !isNaN(paste)) {
          paste.split("").forEach((char, i) => {
            inputElements[i].value = char;
            inputElements[i].dispatchEvent(new Event("input"));
          });
        }
      });
    });
  };

  useEffect(() => {
    checkPin();
  }, []);

  const navigate = useNavigate();

  // Check Authentication Code
  const towfaEnableHandler = async () => {
    try {
      if (inputValues.length < 6) {
        setError(true);
        setErrorMessage("Fill all fields");
        setTimeout(() => {
          setErrorMessage("");
          setError(false);
        }, 5000);
        return;
      }

      setIsLoading(true);
      const submitTwaFa = await servicePost(
        "2fa/twofaAuthenticate",
        {
          user: user?.email,
          token: inputValues,
        },
        notification,
        authToken
      );

      if (submitTwaFa.data === true) {
        dispatch(
          setUser({
            userInfo: {
              ...user,
              twoFaVerified: true, // Set to true after 2FA verification
            },
            token: user?.token,
          })
        );
        navigate("/");
      } else {
        setIsLoading(false);
        setError(true);
        setErrorMessage("Authentication code expired.");
        setTimeout(() => {
          setErrorMessage("");
          setError(false);
        }, 5000);
      }
    } catch (error) {
      setIsLoading(false);
      setError(true);
      setErrorMessage("Something went wrong.");
      setTimeout(() => {
        setErrorMessage("");
        setError(false);
      }, 5000);
    }
  };

  return (
    <>
      <div className="login-main-wrapper authentication-code-main-wrapper">
        <div className="login-main-wrap container">
          <div className="auth-code-content">
            <p> Enter the 6-digit Google Authenticator code</p>
          </div>
          <h3>Authentication Code </h3>
          <div className="authenticate-twofa-otp-wrap">
            <div className="pin-code password-otp-input">
              <input
                className="code-input"
                type="number"
                maxLength="1"
                name="confirmPin1"
                autoFocus
              />
              <input
                className="code-input"
                type="number"
                maxLength="1"
                name="confirmPin2"
              />
              <input
                className="code-input"
                type="number"
                maxLength="1"
                name="confirmPin3"
              />
              <input
                className="code-input"
                type="number"
                maxLength="1"
                name="confirmPin4"
              />
              <input
                className="code-input"
                type="number"
                maxLength="1"
                name="confirmPin5"
              />
              <input
                className="code-input"
                type="number"
                maxLength="1"
                name="confirmPin6"
              />
            </div>

            {error ? (
              <div className="error-msg">
                <p> {errorMessage} </p>
              </div>
            ) : (
              ""
            )}
          </div>

          <div className="twofa-footer-wrap">
            {/* <p>
              Lorem ipsum dolor sit amet, consetetur sadipscing elitr sed diam
              nonumy eirmod tempor invidunt ut
            </p> */}

            <Button
              isLoading={isLoading}
              onClick={towfaEnableHandler}
              label="Confirm"
            />
          </div>
        </div>
      </div>
    </>
  );
};

export default AuthenticationCode;

import React, { useState } from "react";
import Button from "../UIComponents/Button";
import {
  CardNumberElement,
  CardExpiryElement,
  CardCvcElement,
  useStripe,
  useElements,
} from "@stripe/react-stripe-js";
import { servicePost } from "../../helper/api";
import { useDispatch, useSelector } from "react-redux";
import { setNotificationDep } from "../../redux/features/notificationDepSlice";

const AddCardDrawer = ({ onHide }) => {
  const stripe = useStripe();
  const elements = useElements();
  const [customerId, setCustomerId] = useState("");
  const [paymentMethodId, setPaymentMethodId] = useState("");
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(null);

  // get loggedInUser for Redux
  const logedInUser = useSelector((state) => state.user.userInfo);
  const notificationDep = useSelector(
    (state) => state.notificationDep.notificationDep
  );

  // get AuthToken from localStorage
  const authToken = localStorage.getItem("token");
  const notification = "";

  const dispatch = useDispatch();

  const [cardComplete, setCardComplete] = useState({
    cardNumber: false,
    expiryDate: false,
    cvc: false,
  });

  const isFormValid =
    cardComplete.cardNumber && cardComplete.expiryDate && cardComplete.cvc;

  const handleCardChange = (event, field) => {
    setCardComplete((prevState) => ({
      ...prevState,
      [field]: event.complete,
    }));
  };

  const cardStyle = {
    style: {
      base: {
        color: "#32325d",
        fontSize: "16px",
        "::placeholder": {
          color: "#6262629e",
        },
      },
      invalid: {
        color: "#fa755a",
        iconColor: "#fa755a",
      },
    },
  };

  const handleSaveCard = async () => {
    setLoading(true);
    setError(null);
    try {
      const cardElement = elements.getElement(CardNumberElement);
      const { paymentMethod, error: paymentMethodError } =
        await stripe.createPaymentMethod({
          type: "card",
          card: cardElement,
        });

      if (paymentMethodError) {
        setError(paymentMethodError.message);
        setLoading(false);
        return;
      }

      console.log("paymentMethod", paymentMethod);
      const lastFourDigits = paymentMethod.card.last4;
      const type = paymentMethod.card.funding;
      const cardType = paymentMethod.card.brand;
      const expiry = paymentMethod.card.exp_year;
      console.log("lastFourDigits", lastFourDigits);

      const response = await servicePost(
        "user/save-card",
        {
          paymentMethodId: paymentMethod.id,
        },
        notification,
        authToken
      );

      // Check if the response is successful
      if (response.success) {
        console.log("Allres", response);
        console.log("Card Saved:", response.customerId);
        console.log("paymentMethod.id:", paymentMethod.id);

        setCustomerId(response.customerId);
        setPaymentMethodId(paymentMethod.id);

        // Call the additional API to save card details
        const cardDataResponse = await servicePost(
          "booking/addCardData",
          {
            name: cardType,
            type: type,
            number: lastFourDigits,
            customerId: response.customerId,
            paymentMethodId: paymentMethod.id,
            expiryDate: expiry,
            accounId: logedInUser?.id,
          },
          notification,
          authToken
        );

        console.log("Card data response:", cardDataResponse);
        if (cardDataResponse.message == "SUCCESS") {
          dispatch(setNotificationDep(notificationDep + 1));
          onHide();
        }
      }

      setLoading(false);
    } catch (err) {
      console.log("err", err.response.data.error);
      setError(err?.response?.data?.error);
      setTimeout(() => {
        setError("");
      }, 3000);
      setLoading(false);
    }
  };

  return (
    <div className="extra-drawer-main-wrapper">
      <div className="extra-drawer-main-header postpaid-drawer-main-header">
        <div className="extra-back-wrap">
          <img src="./assets/icons/right-black-arrow.svg" alt="icon" />
          <h4>Add New Card</h4>
        </div>
        <div className="extra-close-drwaer-btn">
          <img
            onClick={onHide}
            src="./assets/icons/close-icon.svg"
            alt="close-icon"
          />
        </div>
      </div>

      <div className="addcard-main-wrapper">
        <form onSubmit={(e) => e.preventDefault()}>
          <div className="form-group">
            <label htmlFor="cardNumber">Card Number</label>
            <div className="custom-stripe-input">
              <CardNumberElement
                id="cardNumber"
                onChange={(e) => handleCardChange(e, "cardNumber")}
                options={cardStyle}
              />
            </div>
          </div>
          <div className="form-group">
            <label htmlFor="expiry">Expiry Date</label>
            <div className="custom-stripe-input">
              <CardExpiryElement
                id="expiry"
                onChange={(e) => handleCardChange(e, "expiryDate")}
                options={cardStyle}
              />
            </div>
          </div>
          <div className="form-group">
            <label htmlFor="cvc">CVC</label>
            <div className="custom-stripe-input">
              <CardCvcElement
                id="cvc"
                onChange={(e) => handleCardChange(e, "cvc")}
                options={cardStyle}
              />
            </div>
          </div>

          {error && (
            <div className="error-msg">
              <p>{error}</p>
            </div>
          )}

          <div className="save-card-btn">
            <Button
              disabled={!isFormValid || loading}
              onClick={handleSaveCard}
              label={loading ? "Saving..." : "Save Card"}
            />
          </div>
        </form>
      </div>
    </div>
  );
};

export default AddCardDrawer;

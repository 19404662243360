import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  flooringPrice: [], // To store array of prices
};

const flooringPriceSlice = createSlice({
  name: "flooringPrice",
  initialState,
  reducers: {
    updateFlooringPrices: (state, action) => {
      state.flooringPrice = action.payload;
    },
    clearFlooringPrices: (state) => {
      state.flooringPrice = [];
    },
  },
});

export const { updateFlooringPrices, clearFlooringPrices } =
  flooringPriceSlice.actions;
export default flooringPriceSlice.reducer;

import moment from "moment";
import React from "react";
import OverlayTrigger from "react-bootstrap/OverlayTrigger";
import Tooltip from "react-bootstrap/Tooltip";

const PaymentDetail = ({
  recurring,
  address,
  addressline2,
  city,
  PostalCode,
  scheduleDate,
  scheduleTime,
  paymentMethod,
  price,
  yfosServiceFess,
  discount,
  subTotal,
  fees,
  total,
  showTotal,
}) => {
  return (
    <>
      <div className="payment-method-detail-wrap">
        <div className="payment-detail-repeat">
          <div className="payment-detail-left">
            <p> Address:</p>
          </div>
          <div className="payment-detail-right">
            <p className="payment-detail-address">{address}</p>
          </div>
        </div>

        <div className="payment-detail-repeat">
          <div className="payment-detail-left">
            <p> Address line 2:</p>
          </div>
          <div className="payment-detail-right">
            <p>{addressline2}</p>
          </div>
        </div>

        <div className="payment-detail-repeat">
          <div className="payment-detail-left">
            <p> Postal code:</p>
          </div>
          <div className="payment-detail-right">
            <p>{PostalCode}</p>
          </div>
        </div>

        <div className="payment-detail-repeat">
          <div className="payment-detail-left">
            <p> Schedule: </p>
          </div>
          <div className="payment-detail-right">
            <div className="payment-schedule-date">
              <img src="../assets/icons/date.svg" alt="icon" />
              <p> {moment(scheduleDate).format("DD MMM YYYY")}</p>
            </div>

            <div className="payment-schedule-date">
              <img src="../assets/icons/time.svg" alt="icon" />
              <p> {scheduleTime} </p>
            </div>
          </div>
        </div>

        <div className="payment-detail-repeat">
          <div className="payment-detail-left">
            <p> Payment Method:</p>
          </div>
          <div className="payment-detail-right">
            {paymentMethod == "PostPaid" || paymentMethod == "Postpaid" ? (
              <img
                className="payment-method-postpaid-icon"
                src="../assets/icons/postpaid-icon.png"
                alt="icon"
              />
            ) : (
              <img
                className="payment-method-cart-icon"
                src="../assets/icons/card.svg"
                alt="icon"
              />
            )}
            {/* <img src="../assets/icons/card.svg" alt="icon" /> */}
            <p>{paymentMethod}</p>
          </div>
        </div>
      </div>

      <div className="payment-method-price-detail-wrap">
        <div className="payment-detail-repeat">
          <div className="payment-detail-left">
            <p> Discount </p>
          </div>
          <div className="payment-detail-right discount-filed-wrap">
            <p>${discount}</p>
          </div>
        </div>

        <div className="payment-detail-repeat">
          <div className="payment-detail-left">
            <p> YFOS Service</p>

            <OverlayTrigger
              placement="top"
              delay={{ show: 250, hide: 400 }}
              overlay={
                <Tooltip id={`tooltip-top`}>
                  YFOS service fees include company charges to ensure quality
                  service and operational excellence.
                </Tooltip>
              }
            >
              <img src="../assets/icons/info.svg" alt="info-icon" />
            </OverlayTrigger>
          </div>
          <div className="payment-detail-right">
            <p>${Number(yfosServiceFess)?.toFixed(2)}</p>
          </div>
        </div>

        <div className="payment-detail-repeat">
          <div className="payment-detail-left">
            <p> Sub total </p>
          </div>
          <div className="payment-detail-right">
            <p> ${subTotal} </p>
          </div>
        </div>

        <div className="payment-detail-repeat">
          <div className="payment-detail-left">
            <p> Taxes and Fee</p>

            <OverlayTrigger
              placement="top"
              delay={{ show: 250, hide: 400 }}
              overlay={
                <Tooltip id={`tooltip-top`}>
                  Additional fees may apply for shipping, handling, and taxes.
                </Tooltip>
              }
            >
              <img src="../assets/icons/info.svg" alt="info-icon" />
            </OverlayTrigger>
          </div>
          <div className="payment-detail-right">
            <img
              className="payment-method-cart-icon"
              src="../assets/icons/card.svg"
              alt="icon"
            />
            <p>${Number(fees).toFixed(2)}</p>
          </div>
        </div>
        {showTotal && (
          <div className="payment-detail-repeat total-payment-detail-repeat">
            <div className="payment-detail-left">
              <h4> Total </h4>
            </div>
            <div className="payment-detail-right">
              <h4>${total}</h4>
            </div>
          </div>
        )}
      </div>
    </>
  );
};

export default PaymentDetail;

import React, { useEffect, useState } from "react";
import InputField from "../UIComponents/InputField";
import { useMediaQuery } from "react-responsive";
import { updateCoupon, resetCoupon } from "../../redux/features/couponSlice";
import { useDispatch, useSelector } from "react-redux";
import { servicePost } from "../../helper/api";
import { selectSubTotal } from "../../redux/features/selectors";

const CouponDrawer = ({ onHide, orderData }) => {
  const [couponDetail, setCouponDetail] = useState();
  const couponData = useSelector((state) => state.coupon);
  const isMobile = useMediaQuery({ maxWidth: 1024 });
  const [couponCode, setCouponCode] = useState(couponData?.couponCode);
  const [couponInputError, setCouponInputError] = useState();
  const [couponDiscount, setCouponDiscount] = useState();

  // get AuthToken from localStorage
  const authToken = localStorage.getItem("token");
  const notification = "";

  // get loggedInUser for Redux
  const logedInUser = useSelector((state) => state.user.userInfo);

  const subtotalSelector = selectSubTotal(couponData?.couponDiscountPercentage);
  const { subTotal, discountAmount, cartItemsPrice, totalItemPrice } =
    useSelector(subtotalSelector);

  const dispatch = useDispatch();

  const couponCodeHandler = (e) => {
    setCouponCode(e.target.value);
    setCouponInputError(false);
  };

  const applyCoupanCode = (data) => {
    setCouponCode(data?.couponCode);
    setCouponDiscount(data?.discount);
    setCouponInputError(false);
  };

  const applyCoupanHandler = (data) => {
    if (data?.couponCode == "") {
      setCouponInputError(true);
      return;
    }

    dispatch(
      updateCoupon({
        couponCode: data?.couponCode,
        couponDiscountPercentage: data?.discount,
      })
    );
    onHide();
  };

  const removeCouponHandler = () => {
    dispatch(resetCoupon());
    setCouponCode("");
  };

  // Get Coupon Code And Detail

  const getCouponDetail = async () => {
    try {
      const couponRes = await servicePost(
        "auth/getCoupon",
        {
          accountId: logedInUser?.id ? logedInUser?.id : orderData?.accountId,
        },
        notification,
        authToken
      );

      setCouponDetail(couponRes.data);
    } catch (error) {
      console.log("error", error);
    }
  };

  useEffect(() => {
    getCouponDetail();
  }, []);

  return (
    <div className="extra-drawer-main-wrapper">
      <div className="extra-drawer-main-header">
        <div className="extra-back-wrap">
          <img src="./assets/icons/right-black-arrow.svg" alt="icon" />
          <h4> Apply Coupon Code </h4>
        </div>

        <div className="extra-close-drwaer-btn">
          <img
            onClick={onHide}
            src="./assets/icons/close-icon.svg"
            alt="close-icon"
          />
        </div>
      </div>

      <div className="apply-coupan-code-wrap">
        <div className="apply-coupan-input">
          <div className="login-option apply-coupan-input">
            <form>
              <InputField
                extraClass={
                  couponInputError ? "error-msg" : couponCode ? "active" : ""
                }
                type="text"
                name="coupancode"
                id="coupancode"
                label="Select Coupon Code"
                onChange={couponCodeHandler}
                value={couponCode}
                readOnly={true}
              />
            </form>
            {couponData?.couponCode ? (
              <div className="apply-coupan-btn">
                <button className="remove-coupon" onClick={removeCouponHandler}>
                  Remove
                </button>
              </div>
            ) : (
              <div className="apply-coupan-btn">
                {/* <button onClick={applyCoupanHandler}> Apply</button> */}
              </div>
            )}
          </div>
        </div>

        <div className="coupan-more-offers-wrap">
          <h5> More offers</h5>

          {couponDetail?.map((data, index) => {
            const isCouponEnabled = subTotal >= data?.amount;
            return (
              <div className="more-offer-repeat" key={index}>
                <div className="more-offer-card-wrap">
                  <div
                    className={
                      isCouponEnabled
                        ? "coupan-subtract-wrap"
                        : "coupan-subtract-wrap disabled"
                    }
                  >
                    {!isMobile ? (
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        width="53"
                        height="147.001"
                        viewBox="0 0 53 147.001"
                      >
                        <path
                          id="Subtraction_6"
                          data-name="Subtraction 6"
                          d="M842.246-5512.515a25.832,25.832,0,0,1-10.12-2.043,25.908,25.908,0,0,1-8.264-5.572,25.914,25.914,0,0,1-5.572-8.265,25.835,25.835,0,0,1-2.043-10.121v-9a7.008,7.008,0,0,0,7-7,7.008,7.008,0,0,0-7-7v-7a7.008,7.008,0,0,0,7-7,7.008,7.008,0,0,0-7-7v-7a7.008,7.008,0,0,0,7-7,7.008,7.008,0,0,0-7-7v-7a7.008,7.008,0,0,0,7-7,7.008,7.008,0,0,0-7-7v-9a25.839,25.839,0,0,1,2.043-10.121,25.913,25.913,0,0,1,5.572-8.265,25.913,25.913,0,0,1,8.264-5.572,25.832,25.832,0,0,1,10.12-2.043h27v147h-27Z"
                          transform="translate(-816.246 5659.516)"
                          fill="#00A19B"
                        />
                      </svg>
                    ) : (
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        width="53"
                        height="147.001"
                        viewBox="0 0 53 147.001"
                      >
                        <path
                          id="Subtraction_10"
                          data-name="Subtraction 10"
                          d="M869.246-5512.515h-39a13.908,13.908,0,0,1-9.9-4.1,13.91,13.91,0,0,1-4.1-9.9v-21a7.008,7.008,0,0,0,7-7,7.008,7.008,0,0,0-7-7v-7a7.008,7.008,0,0,0,7-7,7.008,7.008,0,0,0-7-7v-7a7.008,7.008,0,0,0,7-7,7.008,7.008,0,0,0-7-7v-7a7.008,7.008,0,0,0,7-7,7.008,7.008,0,0,0-7-7v-21a13.91,13.91,0,0,1,4.1-9.9,13.908,13.908,0,0,1,9.9-4.1h39v147h0Z"
                          transform="translate(-816.246 5659.516)"
                          fill="#00A19B"
                        />
                      </svg>
                    )}

                    <div className="discount-top-left">
                      <div className="content">{data?.discount}% OFF</div>
                    </div>
                  </div>
                  <div className="more-offer-left">
                    <div className="coupan-code-wrap">
                      <img src="../assets/icons/yfos-logo.png" alt="icon" />
                      <h5> {data?.couponCode} </h5>
                    </div>

                    <div className="coupan-offer-desc-wrap">
                      <h6>
                        {/* {data?.couponDescription} */}
                        Add minimum ${data?.amount} order to avail this offer
                      </h6>
                      <p>
                        {/* {data?.description} */}
                        Get {data?.discount}% discount using this coupon
                      </p>
                    </div>
                  </div>
                  <div className="more-offer-right">
                    <div
                      className={
                        isCouponEnabled
                          ? "apply-coupan-btn offer-apply-coupan-btn"
                          : "apply-coupan-btn offer-apply-coupan-btn disabled"
                      }
                    >
                      <button
                        disabled={!isCouponEnabled}
                        onClick={() => applyCoupanHandler(data)}
                      >
                        Apply
                      </button>
                    </div>
                  </div>
                </div>
              </div>
            );
          })}
        </div>
      </div>
    </div>
  );
};

export default CouponDrawer;

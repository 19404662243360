import React, { useEffect, useState } from "react";
import InputField from "../../UIComponents/InputField";
import RangeSlider from "../../UIComponents/RangeSlider";
import CardItem from "../../cardItem/CardItem";
import Button from "../../UIComponents/Button";
import { setError } from "../../../redux/features/errorSlice";
import OverlayTrigger from "react-bootstrap/OverlayTrigger";
import Tooltip from "react-bootstrap/Tooltip";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import moment from "moment";

import { useDispatch } from "react-redux";
import {
  addToCart,
  removeToCart,
  updateFlooringPercentage,
  updateMoppingPercentage,
  updateDustingPercentage,
  updateSqureFeetPercentage,
} from "../../../redux/features/cartSlice";
import { updateFormData } from "../../../redux/features/formSlice";
import { useLocation, useNavigate } from "react-router-dom";
import { useSelector } from "react-redux";
import MobileExtraItems from "../../cardItem/MobileExtraItems";
import TimePicker from "../components/TimePicker";
import { servicePost } from "../../../helper/api";
import SqRangeSlider from "../../UIComponents/SqRangeSlider";

const MobileChangeOrder = () => {
  const percentageData = useSelector((state) => state.allCart);
  const [enableBookBtn, setEnableBookBtn] = useState(false);
  const errors = useSelector((state) => state.formErrors);
  const [selectedDate, setSelectedDate] = useState(null);

  const location = useLocation();
  const orderData = location.state.orderData;

  // Residentail store Washroom Window and Extra Data
  const [washroomData, setWashroomData] = useState([]);
  const [windowsData, setWindowsData] = useState([]);
  const [extrasData, setExtrasData] = useState([]);

  const [showPercentageGuideModal, setShowPercentageGuideModal] =
    useState(false);

  const [floorinPercentage, setFlooringPercentage] = useState(
    percentageData.flooringPercentage
  );

  const [moppingPercentage, setMoppingPercentage] = useState(
    percentageData.moppingPercentage
  );
  const [dustingPercentage, setDustingPercentage] = useState(
    percentageData.dustingPercentage
  );

  const [squreFeetPercentage, setSqureFeetPercentage] = useState(
    percentageData.squreFeetPercentage
  );

  const formData = useSelector((state) => state.formData);

  // get AuthToken from localStorage
  const authToken = localStorage.getItem("token");
  const notification = "";

  const handleInputChange = (event) => {
    const { name, value } = event.target;
    if (value == "") {
      dispatch(setError({ field: name, value: true }));
    } else {
      dispatch(setError({ field: name, value: false }));
    }
    dispatch(updateFormData({ field: name, value }));
  };

  // HandleDate Change
  // HandleDate Change
  const handleDateChange = (date) => {
    setSelectedDate(date);
    dispatch(updateFormData({ field: "scheduleDate", value: date }));
    if (date) {
      dispatch(setError({ field: "date", value: false }));
    }
  };

  /// Add to cart
  const dispatch = useDispatch();
  const addToCartHandler = (data) => {
    dispatch(addToCart(data));
  };

  const removeFromCartHandler = (data) => {
    dispatch(removeToCart(data));
  };

  // handle Flooring Pecsentage Change
  function handleFlooringPercentage(event) {
    setFlooringPercentage(parseInt(event.target.value));
    setMoppingPercentage(parseInt(100 - event.target.value));
    dispatch(updateFlooringPercentage(parseInt(event.target.value)));
    dispatch(updateMoppingPercentage(parseInt(100 - event.target.value)));
  }

  // handle Dusting Percentage Change
  function handleDustingPercentage(event) {
    setDustingPercentage(parseInt(event.target.value));
    dispatch(updateDustingPercentage(parseInt(event.target.value)));
  }

  // handle SqureFeet Percentage Change
  function handleSqureFeetPercentage(event) {
    setSqureFeetPercentage(parseInt(event.target.value));
    dispatch(updateSqureFeetPercentage(parseInt(event.target.value)));
  }

  useEffect(() => {
    setEnableBookBtn(true);
  }, [formData]);

  const navigate = useNavigate();
  // go to Booking confirmation
  const goToBookingConfirmation = () => {
    // if (!formData?.squareFeet) {
    //   dispatch(setError({ field: "squareFeet", value: true }));
    //   return;
    // }

    navigate("/change-order-confirmation", {
      state: {
        orderData: orderData,
      },
    });
    dispatch(updateFlooringPercentage(floorinPercentage));
    dispatch(updateMoppingPercentage(moppingPercentage));
    dispatch(updateDustingPercentage(dustingPercentage));
    dispatch(updateSqureFeetPercentage(squreFeetPercentage));
  };

  // Get Washrooms Window and Addition Data
  const getResidentialData = async () => {
    try {
      const residentialDataRes = await servicePost(
        "residential/getResidetialData",
        {
          industry: orderData?.industry,
          // industry: "House",
        },
        notification,
        authToken
      );

      const responseData = residentialDataRes.data;
      setWashroomData(responseData.BathroomData);
      setWindowsData(responseData.WindowsData);
      setExtrasData(responseData.extraData);
    } catch (error) {
      console.log("error", error);
    }
  };

  useEffect(() => {
    getResidentialData();
  }, [orderData?.industry]);

  return (
    <>
      <div className="mobile-selected-service-detail-wrap container">
        <div className="home-sequare-feet-wrap">
          <div className="mobile-service-back-wrap">
            <h3> {orderData?.industry} </h3>
          </div>
          {/* <div className="login-option sequirefeet-input-wrap">
            <form>
              <InputField
                extraClass={
                  errors?.squareFeet
                    ? `squirefeet-input error-msg`
                    : "squirefeet-input"
                }
                type="number"
                onChange={handleInputChange}
                value={formData?.squareFeet}
                name="squareFeet"
                id="squareFeet"
                label="Enter Square Feet"
                passwordIcon={false}
                // placeholder="1000 Sq"
              />
              <p className="sq-placeholder"> Sq </p>
            </form>
            <div className="squrefeet-input-img">
              <img src="./assets/squire-feet.png" alt="img" />
            </div>
          </div> */}
          <div className="dusting-slider-wrap squrefeet-slider-wrap">
            <SqRangeSlider
              percentage={squreFeetPercentage}
              onChange={handleSqureFeetPercentage}
              step="100"
              min="500"
              max="20000"
              // leftIcon="./assets/squire-feet.png"
              // extraClass="dusting-slider"
              leftName="Select Sq of Property"
              extraClass="flooring-slider"
            />
          </div>
        </div>

        <div className="home-cleaning-persentage-warpper">
          <div className="home-cleaning-persentage-warp">
            <div className="cleaning-persentage-info">
              <h4> Select a Service </h4>
              <OverlayTrigger
                placement="right"
                delay={{ show: 250, hide: 400 }}
                overlay={
                  <Tooltip id={`tooltip-right`}>
                    Can you specify the percentage of cleaning needed? For
                    example, what percentage of your bedroom space requires
                    vacuuming? Please provide an estimate in percentage. (Slider
                    Left right to adjust the %)
                  </Tooltip>
                }
              >
                <img src="./assets/icons/info.svg" alt="info-icon" />
              </OverlayTrigger>
            </div>
          </div>
          <div className="flooring-slider-wrap">
            <div className="flooring-slider-info-wrap">
              <h5 className="flooring-text">Flooring</h5>
              <OverlayTrigger
                placement="top"
                delay={{ show: 250, hide: 400 }}
                overlay={
                  <Tooltip id={`tooltip-top`}>
                    Slide from left to right to set your vacuum and mopping
                    percentages.
                  </Tooltip>
                }
              >
                <img src="./assets/icons/info.svg" alt="info-icon" />
              </OverlayTrigger>
            </div>
            <RangeSlider
              percentage={floorinPercentage}
              moppingPercentage={moppingPercentage}
              onChange={handleFlooringPercentage}
              leftName="Vaccum Cleaning"
              rightName="Mopping or sweeping"
              extraClass="flooring-slider"
            />
          </div>

          <div className="dusting-slider-wrap">
            <div className="flooring-slider-info-wrap">
              <h5 className="dusting-text">Dusting</h5>
              <OverlayTrigger
                placement="top"
                delay={{ show: 250, hide: 400 }}
                overlay={
                  <Tooltip id={`tooltip-top`}>
                    Slide from left to right to set your Dusting percentages.
                  </Tooltip>
                }
              >
                <img src="./assets/icons/info.svg" alt="info-icon" />
              </OverlayTrigger>
            </div>
            <RangeSlider
              percentage={dustingPercentage}
              onChange={handleDustingPercentage}
              leftIcon="./assets/dusting-slider.png"
              extraClass="dusting-slider"
            />
          </div>

          <div className="service-items-main-wrapper">
            <div className="service-items-main-wrap">
              <h5> Washroom </h5>
              <CardItem
                data={washroomData}
                addItems={addToCartHandler}
                removeItem={removeFromCartHandler}
              />
            </div>
          </div>

          <div className="service-items-main-wrapper">
            <div className="service-items-main-wrap">
              <h5> Windows </h5>
              <CardItem
                data={windowsData}
                addItems={addToCartHandler}
                removeItem={removeFromCartHandler}
              />
            </div>
          </div>
        </div>

        <div className="service-items-main-wrapper">
          <div className="service-items-main-wrap extra-service-item-wrap">
            <h5> Additional Service </h5>
            <MobileExtraItems
              extraData={extrasData}
              addItems={addToCartHandler}
              removeItem={removeFromCartHandler}
              showItems="All"
            />
          </div>
        </div>

        <div className="schedule-main-wrapper">
          <div
            className="schedule-main-wrap"
            style={{
              pointerEvents: "none",
              opacity: 0.8,
            }}
          >
            <h5> Schedule </h5>
            <div className="date-and-time-wrapper">
              <div className="date-input-wrap">
                <DatePicker
                  minDate={new Date()}
                  maxDate={moment().add(30, "days").toDate()}
                  selected={orderData?.scheduleDate}
                  // onChange={(date) => handleDateChange(date)}
                  customInput={
                    <div className="custom-date-input">
                      <img
                        src="./assets/icons/date.svg"
                        alt="icon"
                        className="datepicker-icon"
                      />
                      <p>Date</p>
                      <input
                        className={errors?.date ? "error-msg" : ""}
                        type="text"
                        placeholder="Select date"
                        value={
                          orderData?.scheduleDate
                            ? moment(orderData?.scheduleDate).format(
                                "DD MMM YYYY"
                              )
                            : ""
                        }
                        readOnly
                      />
                    </div>
                  }
                />
              </div>

              <TimePicker
                disabled={!selectedDate}
                scheduleTime={orderData?.scheduleTime}
              />
            </div>
          </div>
          <div className="book-button-wrapper">
            <Button
              extraClass={!enableBookBtn ? "disabled-button" : ""}
              onClick={goToBookingConfirmation}
              // isLoading={isLoading}
              label="Book"
            />
          </div>
        </div>
      </div>
    </>
  );
};

export default MobileChangeOrder;

import React from "react";
import ReactDOM from "react-dom/client";
import "bootstrap/dist/css/bootstrap.min.css";
import "bootstrap/dist/js/bootstrap.bundle.min.js";
import App from "./App";
import reportWebVitals from "./reportWebVitals";
import { BrowserRouter } from "react-router-dom";
import { Provider } from "react-redux";
import { PersistGate } from "redux-persist/integration/react";
import { store, persistor } from "./redux/app/store";
import { Auth0Provider } from "@auth0/auth0-react";
import { Elements } from "@stripe/react-stripe-js";
import { loadStripe } from "@stripe/stripe-js";

const stripePublicKey = process.env.REACT_APP_STRIPE_KEY;

const stripePromise = loadStripe(
  "pk_test_51Pl3GO2M2pNXvfFREUGzISqkYtTKwdQfsNg9MF3fbIC0nBnBTVdJM3CL1tDWCmINSL59HKdoqDSHb6RXcjfY5xk3009PSbqKVK"
);

const root = ReactDOM.createRoot(document.getElementById("root"));
root.render(
  <React.StrictMode>
    <BrowserRouter>
      <Provider store={store}>
        <PersistGate loading={null} persistor={persistor}>
          {/* <Auth0Provider
            domain="dev-t3m6lbydqpzlx82k.us.auth0.com"
            clientId="Fgeq8S3A6ej2dv317zLr8zLTMRwVOohs"
            authorizationParams={{
              redirect_uri: window.location.origin,
            }}
          > */}
          <Elements stripe={stripePromise}>
            <App />
          </Elements>
          {/* </Auth0Provider> */}
        </PersistGate>
      </Provider>
    </BrowserRouter>
  </React.StrictMode>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();

import React, { useEffect } from "react";
import "./App.css";
import { Routes, Route, useLocation, useNavigate } from "react-router-dom";
import Login from "./components/auth/Login";
import Navbar from "./components/navbar/Navbar";
import ForgotPassword from "./components/auth/ForgotPassword";
import Signup from "./components/auth/Signup";
import SetForgotPassword from "./components/auth/SetForgotPassword";
import Dashboard from "./components/dashboard/Dashboard";
import Services from "./components/dashboard/Services";
import BookingConfrimation from "./components/dashboard/BookingConfrimation";
import TrackingOrder from "./components/dashboard/TrackingOrder";
import Profile from "./components/profile/Profile";
import SpecialServices from "./components/dashboard/SpecialServices";
import Footer from "./components/footer/Footer";
import { useMediaQuery } from "react-responsive";
import { useState } from "react";
import MobileServices from "./components/dashboard/mobile/MobileServices";
import MobileCart from "./components/dashboard/mobile/MobileCart";
import MobileNotification from "./components/dashboard/mobile/MobileNotification";
import ServicesCommercial from "./components/dashboard/ServicesCommercial";
import MobileCommercialService from "./components/dashboard/mobile/MobileCommercialService";
import EnableGuide from "./components/profile/twofa/EnableGuide";
import TwofaScan from "./components/profile/twofa/TwofaScan";
import TwofaCode from "./components/profile/twofa/TwofaCode";
import { ProtectedRoute, PublicRoute } from "./routes/ProtectedRoute";
import { isUserAuthenticated } from "./routes/AuthUtils";
import OtpVerification from "./components/auth/OtpVerification";
import EmailVerification from "./components/auth/EmailVerification";
import TwoFaDisable from "./components/profile/twofa/TwoFaDisable";
import AuthenticationCode from "./components/auth/AuthenticationCode";
import ChangeOrder from "./components/dashboard/changeOrder/ChangeOrder";
import ChangeOrderConfirmation from "./components/dashboard/changeOrder/ChangeOrderConfirmation";
import MobileChangeOrder from "./components/dashboard/changeOrder/MobileChangeOrder";
import ProceedChangeOrder from "./components/dashboard/changeOrder/ProceedChangeOrder";
import ChangeOrderCommercail from "./components/dashboard/changeOrder/ChangeOrderCommercail";
import ChangeOrderSpecaility from "./components/dashboard/changeOrder/ChangeOrderSpecaility";
import MobileCommercailOrder from "./components/dashboard/changeOrder/MobileCommercailOrder";
import Success from "./components/dashboard/Success";
import CancelPage from "./components/dashboard/CancelPage";
import SuccessChangeOrder from "./components/dashboard/SuccessChangeOrder";
import { serviceGet, servicePost } from "./helper/api";
import ScrollToTop from "./helper/ScrollToTop";

function App() {
  const isMobile = useMediaQuery({ maxWidth: 1024 });
  const location = useLocation();
  const currentpath = location.pathname;

  const getCsrfToken = async () => {
    try {
      const csrfTokenRes = await serviceGet("csrf-token", {
        withCredentials: true,
      });
      localStorage.setItem("csrfToken", csrfTokenRes.csrfToken);
    } catch (error) {
      console.log("tokenErrrp", error);
    }
  };

  useEffect(() => {
    getCsrfToken();
  }, []);

  const navigate = useNavigate();

  useEffect(() => {
    if (!isMobile) {
      // Redirect to desktop paths when screen size exceeds 1024px
      const currentPath = window.location.pathname;
      if (currentPath === "/services-mobile") {
        navigate("/services");
      } else if (currentPath === "/commercial-services-mobile") {
        navigate("/services-commercial");
      } else if (currentPath === "/mobile-cart") {
        navigate("/");
      } else if (currentPath === "/mobile-notification") {
        navigate("/");
      }
    }
  }, [isMobile, navigate]);

  return (
    <>
      <Navbar />
      <ScrollToTop />
      <Routes>
        <Route
          path="/change-order/:uuid"
          element={<ProceedChangeOrder />}
        ></Route>
        <Route
          path="/change-order-residentail"
          element={<ChangeOrder />}
        ></Route>
        <Route
          path="/change-order-commercial"
          element={<ChangeOrderCommercail />}
        ></Route>
        <Route
          path="/change-order-specialty"
          element={<ChangeOrderSpecaility />}
        ></Route>
        <Route
          path="/change-order-confirmation"
          element={<ChangeOrderConfirmation />}
        ></Route>
        <Route
          path="/change-order-mobile"
          element={<MobileChangeOrder />}
        ></Route>
        <Route
          path="/change-order-mobile-commercial"
          element={<MobileCommercailOrder />}
        ></Route>

        <Route element={<PublicRoute restrictedToUnauth={true} />}>
          <Route path="/sign-up" element={<Signup />}></Route>
          <Route path="/login" element={<Login />}></Route>
          <Route path="/forgot-password" element={<ForgotPassword />}></Route>
          <Route path="/verify-otp" element={<OtpVerification />}></Route>

          <Route
            path="/email-confirmation"
            element={<EmailVerification />}
          ></Route>
          <Route
            path="/set-forgot-password"
            element={<SetForgotPassword />}
          ></Route>
        </Route>

        <Route element={<PublicRoute restrictedToUnauth={false} />}>
          <Route path="/enter-twofa" element={<AuthenticationCode />}></Route>
        </Route>

        <Route element={<ProtectedRoute />}>
          <Route path="/" element={<Dashboard />}></Route>
          <Route path="/services" element={<Services />}></Route>
          <Route path="/success" element={<Success />}></Route>
          {/* <Route
            path="/change-order-success"
            element={<SuccessChangeOrder />}
          ></Route> */}
          <Route path="/cancel" element={<CancelPage />}></Route>

          <Route
            path="/services-commercial"
            element={<ServicesCommercial />}
          ></Route>
          {isMobile && (
            <>
              <Route
                path="/services-mobile"
                element={<MobileServices />}
              ></Route>
              <Route
                path="/commercial-services-mobile"
                element={<MobileCommercialService />}
              ></Route>

              <Route path="/mobile-cart" element={<MobileCart />}></Route>
              <Route
                path="/mobile-notification"
                element={<MobileNotification />}
              ></Route>
            </>
          )}
          <Route path="/special-services" element={<SpecialServices />}></Route>
          <Route
            path="/booking-confirmation"
            element={<BookingConfrimation />}
          ></Route>
          <Route
            path="/tracking-order/:uuid"
            element={<TrackingOrder />}
          ></Route>
          <Route path="/profile" element={<Profile />}></Route>
          <Route path="/2fa-enable-guide" element={<EnableGuide />}></Route>
          <Route path="/twofa-scan" element={<TwofaScan />}></Route>
          <Route path="/twofa-code" element={<TwofaCode />}></Route>
          <Route path="/twofa-disable" element={<TwoFaDisable />}></Route>
        </Route>
      </Routes>
      {isMobile &&
      isUserAuthenticated() &&
      currentpath !== "/2fa-enable-guide" &&
      currentpath !== "/twofa-scan" &&
      currentpath !== "/twofa-code" &&
      currentpath !== "/enter-twofa" ? (
        <Footer />
      ) : (
        ""
      )}
    </>
  );
}

export default App;

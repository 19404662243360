import React, { useState } from "react";
import Modal from "react-bootstrap/Modal";
import "./modal.css";
import Button from "../../components/UIComponents/Button";
import { useNavigate } from "react-router-dom";

const OrderCancelledModal = (props) => {
  const navigate = useNavigate();
  const goToDashboard = () => {
    navigate("/");
  };
  return (
    <Modal
      {...props}
      size="lg"
      backdrop="static"
      dialogClassName="save-exit-modal-main-wrapper cancelled-modal-main-wrapper"
      aria-labelledby="contained-modal-title-vcenter"
      centered
    >
      <Modal.Body>
        <div className="confirm-modal-main-wrapper">
          <div className="order-book-modal-main-wrap cancelled-order-book-modal-main-wrap">
            <div className="modal-logo-wrap">
              <img src="./assets/modal-logo.png" alt="modal-logo" />
            </div>

            <div className="close-icon-wrap">
              <img
                onClick={() => props.onHide()}
                src="./assets/icons/close-icon.svg"
                alt="icon"
              />
            </div>

            <div className="order-book-image">
              <h2> Your order has been Cancelled </h2>
              <img src="./assets/icons/cancelled-icon.png" alt="icon" />
              <h3> Cancelled </h3>
            </div>

            <div className="order-book-in-wrap">
              <h4> Booking ID #{props?.orderData?.bookingId} </h4>
              <p>
                You did not complete this order within 15 minutes, so your
                session has expired.
              </p>
            </div>

            <div className="logout-modal-button">
              <Button label="Continue" onClick={goToDashboard} />
            </div>
          </div>
        </div>
      </Modal.Body>
    </Modal>
  );
};
export default OrderCancelledModal;

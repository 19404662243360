import React from "react";

const CleaningItem = ({ image, category, label, price, percentage }) => {
  return (
    <>
      <div className="booking-cart-item-repeat">
        <div className="booking-cart-item-left">
          <div className="booking-item-image">
            <img src={image} alt="item-img" />
          </div>
          <div className="booking-item-detail cleaning-precentage-item">
            <h6> {category} </h6>
            <p> {label} </p>
            <span> {percentage}% </span>
          </div>
        </div>

        <div className="booking-cart-item-right-wrapper">
          <div className="booking-cart-item-right">
            <div className="booking-item-price-wrap">
              <p> ${price}</p>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default CleaningItem;

import React, { useEffect, useState } from "react";

import Dropdown from "../../dropdown/Dropdown";
import { updateDropdown } from "../../../redux/features/dropdownSlice";
import { setError } from "../../../redux/features/errorSlice";
import Button from "../../UIComponents/Button";

import { useDispatch } from "react-redux";
import { addToCart, removeToCart } from "../../../redux/features/cartSlice";
import { useLocation, useNavigate } from "react-router-dom";
import { useSelector } from "react-redux";
import SpecialityModal from "../../modals/SpecialityModal";
import AddressDetails from "../components/AddressDetails";
import { useMediaQuery } from "react-responsive";
import TimePicker from "../components/TimePicker";
import DatePicker from "react-datepicker";

import moment from "moment";
import SpecialityItem from "../../cardItem/SpecialityItem";
import RatingBookings from "../components/RatingBookings";
import { servicePost } from "../../../helper/api";
import SpecialServiceDetail from "../components/SpecialServiceDetail";
import { updateFormData } from "../../../redux/features/formSlice";
import InputField from "../../UIComponents/InputField";

const ChangeOrderSpecaility = () => {
  const isMobile = useMediaQuery({ maxWidth: 1024 });
  const errors = useSelector((state) => state.formErrors);
  const [enableBookBtn, setEnableBookBtn] = useState(false);
  const [selectedDate, setSelectedDate] = useState(null);
  const serviceType = useSelector((state) => state.serviceType.serviceType);
  const [showSpecialityModal, setShowSpecialityModal] = useState(false);
  const formData = useSelector((state) => state.formData);
  const cartItems = useSelector((state) => state.allCart.carts);
  const [tempIndustryDrop, setTempIndustryDrop] = useState();
  const [residentialServiceData, setResidentailServiceData] = useState([]);
  const [commercialServiceData, setCommercialServiceData] = useState([]);

  // get Location Data
  const location = useLocation();
  const orderData = location?.state?.orderData;

  const currentpath = location.pathname;
  const changeOrderPath = currentpath.includes("change-order");

  // get AuthToken from localStorage
  const authToken = localStorage.getItem("token");
  const notification = "";

  /// Add to cart
  const dispatch = useDispatch();
  const addToCartHandler = (data) => {
    dispatch(addToCart(data));
  };

  const removeFromCartHandler = (data) => {
    dispatch(removeToCart(data));
  };

  const navigate = useNavigate();
  // go to Booking confirmation
  const goToBookingConfirmation = () => {
    if (cartItems?.length == 0) {
      alert("Add AtLeast One Specialtiy service");
      return;
    }

    navigate("/change-order-confirmation", {
      state: {
        orderData: orderData,
      },
    });
  };

  // HandleDate Change
  const handleDateChange = (date) => {
    setSelectedDate(date);
    dispatch(updateFormData({ field: "scheduleDate", value: date }));
    if (date) {
      dispatch(setError({ field: "date", value: false }));
    }
  };

  //
  useEffect(() => {
    if (cartItems?.length > 0 && orderData?.scheduleDate) {
      setEnableBookBtn(true);
    } else {
      setEnableBookBtn(false);
    }
  }, [cartItems, orderData]);

  // Get Residentail Specialty Services
  const getResidentailServiceData = async () => {
    try {
      const serviceDataRes = await servicePost(
        "residential/getspecialServiceData",
        {
          // industry: orderData?.industry,
          industry: "Specialty Cleaning",
        },
        notification,
        authToken
      );
      setResidentailServiceData(serviceDataRes.data);
    } catch (error) {
      console.log("error", error);
    }
  };

  // Get Commercial Specialty Services
  const getCommercialServiceData = async () => {
    try {
      const serviceDataRes = await servicePost(
        "commercial/getspecialServiceData",
        {
          // industry: orderData?.industry,
          industry: "Specialty Cleaning",
        },
        notification,
        authToken
      );
      setCommercialServiceData(serviceDataRes.data);
    } catch (error) {
      console.log("error", error);
    }
  };

  useEffect(() => {
    getCommercialServiceData();
    getResidentailServiceData();
  }, [orderData?.industry]);

  // get Paltfrom fee and Texes
  const getPaltfromFeeAndTexs = async () => {
    try {
      const getFeeAndTexsRes = await servicePost(
        "booking/getTaxFeesData",
        {
          country: "canada",
          // city: dropdownValues?.city?.name?.toLowerCase(),
          city: "toronto",
        },
        notification,
        authToken
      );

      const data = getFeeAndTexsRes.data[0];
      console.log("getFeeAndTexsRes", data);
      console.log("data", data.platformFees);

      dispatch(updateFormData({ field: "taxes", value: data.tax }));
      dispatch(
        updateFormData({ field: "platformFees", value: data.platformFees })
      );
    } catch (error) {
      console.log("error", error);
    }
  };

  useEffect(() => {
    getPaltfromFeeAndTexs();
  }, [orderData?.address]);

  return (
    <>
      <SpecialityModal
        show={showSpecialityModal}
        onHide={() => setShowSpecialityModal(false)}
        redirectPath="/special-services"
        tempIndustryDrop={tempIndustryDrop}
        page="Special-service"
      />
      {!isMobile ? (
        <div className="dashboard-services-main-wrapper">
          <div className="dashboard-services-main-wrap container">
            <div className="service-header-wrap">
              <div className="service-header-left">
                <h3 className="for-desktop-view">
                  {" "}
                  {orderData?.serviceType} Cleaning{" "}
                </h3>
                <h3 className="for-mobile-view"> {orderData?.serviceType} </h3>
                <div className="service-header-reating">
                  <img src="./assets/icons/review-icon.png" alt="icon" />
                  {/* <p> 4.8 (102k bookings) </p> */}
                  <RatingBookings />
                </div>
              </div>

              <div className="service-header-right">
                <div className="service-right-nav">
                  <p> Home </p>
                  <img src="./assets/icons/right-black-arrow.svg" alt="arrow" />
                  <h6> {orderData?.serviceType} </h6>
                </div>
              </div>
            </div>

            <div className="dashboard-service-detail-wrapper">
              <div className="dashboard-service-detail-wrap">
                <div className="row">
                  <div className="col-md-12 col-lg-4">
                    <div
                      className={`address-detail-wrapper ${
                        !isMobile && "disabled-section"
                      }`}
                      style={{ pointerEvents: "none", opacity: 0.6 }}
                    >
                      {/* <AddressDetails /> */}
                      <div className="address-detail-wrap">
                        <h4>Your Address Details</h4>
                        <div className="address-form-input-wrapper">
                          <div className="login-form-main-wrap">
                            <div className="login-option">
                              <form>
                                <InputField
                                  extraClass={errors.address ? "error-msg" : ""}
                                  type="text"
                                  name="address"
                                  id="address"
                                  value={orderData?.address}
                                  label={
                                    errors.address
                                      ? "Enter Address"
                                      : "Address*"
                                  }
                                />
                              </form>
                            </div>
                            <div className="address-city-postal-wrapper">
                              <Dropdown
                                label="City*"
                                alreadyValue={{
                                  name: orderData?.city,
                                }}
                                value="Test"
                              />
                              <div className="postal-code-option">
                                <label>Postal Code</label>
                                <input
                                  type="text"
                                  name="postalcode"
                                  id="postalcode"
                                  value={orderData?.postalCode}
                                  maxLength="7"
                                  placeholder="Eg: A1B 2C3"
                                />
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                      <div className="service-select-wrapper">
                        <div className="service-select-wrap">
                          <div className="service-select-dropdown-wrap">
                            <h4> Select a service </h4>
                            <Dropdown
                              label="Industry"
                              alreadyValue={{
                                name: orderData.industry,
                              }}
                              checkIcon="./assets/icons/drowdown-check.svg"
                            />

                            {orderData?.floors &&
                              orderData?.serviceType == "Commercial" && (
                                <Dropdown
                                  label="Select Category"
                                  alreadyValue={{
                                    name: orderData.floors,
                                  }}
                                  checkIcon="./assets/icons/drowdown-check.svg"
                                />
                              )}
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>

                  <div className="col-md-12 col-lg-8">
                    <div className="home-service-detail-main-wrapper">
                      {/* <div className="selected-service-title">
                      <h3> Speciality Services </h3>
                    </div> */}

                      <div className="home-service-detail-main-wrap">
                        <div className="selected-service-detail-wrapper">
                          <div
                            className={
                              orderData?.serviceType === "Commercial"
                                ? "selected-service-detail-wrap"
                                : "selected-service-detail-wrap"
                            }
                          >
                            {orderData?.serviceType === "Commercial" ? (
                              <SpecialServiceDetail
                                changeOrderPath={changeOrderPath}
                                orderData={orderData}
                                specialServicesData={commercialServiceData}
                                selectedDate={orderData?.scheduleDate}
                                handleDateChange={handleDateChange}
                                enableBookBtn={enableBookBtn}
                                goToBookingConfirmation={
                                  goToBookingConfirmation
                                }
                                addToCartHandler={addToCartHandler}
                                removeFromCartHandler={removeFromCartHandler}
                                errors={errors}
                                summerItemLength="2"
                              />
                            ) : (
                              orderData?.serviceType === "Residential" && (
                                <SpecialServiceDetail
                                  changeOrderPath={changeOrderPath}
                                  orderData={orderData}
                                  specialServicesData={residentialServiceData}
                                  selectedDate={orderData?.scheduleDate}
                                  handleDateChange={handleDateChange}
                                  enableBookBtn={enableBookBtn}
                                  goToBookingConfirmation={
                                    goToBookingConfirmation
                                  }
                                  addToCartHandler={addToCartHandler}
                                  removeFromCartHandler={removeFromCartHandler}
                                  summerItemLength="4"
                                  errors={errors}
                                />
                              )
                            )}

                            <div className="selected-service-detail-right">
                              <div className="service-items-main-wrapper">
                                <div className="empty-service-wrapper">
                                  <img
                                    src="./assets/empty-image.png"
                                    alt="img"
                                  />
                                  <p> Select your service First</p>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      ) : (
        <div className="mobile-selected-service-detail-wrap container">
          <div className="mobile-selected-service-detail-left">
            <div className="home-sequare-feet-wrap">
              <div className="mobile-service-back-wrap">
                <h3> Speciality Services </h3>
              </div>
            </div>
          </div>

          <div className="selected-service-detail-right">
            <div className="service-items-main-wrapper">
              <div className="service-items-main-wrap special-extra-service-item-wrap extra-service-item-wrap">
                <h5> Services </h5>
                <SpecialityItem
                  extraData={
                    orderData?.serviceType === "Residential"
                      ? residentialServiceData
                      : commercialServiceData
                  }
                  summerItemLength={
                    orderData?.serviceType === "Residential" ? 4 : 2
                  }
                  addItems={addToCartHandler}
                  removeItem={removeFromCartHandler}
                />
              </div>
            </div>

            <div className="schedule-main-wrapper specaility-schedule-wrap">
              <div
                className="schedule-main-wrap"
                style={{
                  pointerEvents: "none",
                  opacity: 0.6,
                }}
              >
                <h5> Schedule </h5>

                <div className="date-and-time-wrapper">
                  <div className="date-input-wrap">
                    <DatePicker
                      minDate={new Date()}
                      maxDate={moment().add(30, "days").toDate()}
                      // selected={selectedDate}
                      // onChange={(date) => handleDateChange(date)}
                      customInput={
                        <div className="custom-date-input">
                          <img
                            src="./assets/icons/date.svg"
                            alt="icon"
                            className="datepicker-icon"
                          />
                          <p>Date</p>
                          <input
                            className={errors?.date ? "error-msg" : ""}
                            type="text"
                            placeholder="Select date"
                            value={
                              orderData?.scheduleDate
                                ? moment(orderData?.scheduleDate).format(
                                    "DD MMM YYYY"
                                  )
                                : ""
                            }
                            readOnly
                          />
                        </div>
                      }
                    />
                  </div>

                  <TimePicker disabled={!selectedDate} />
                </div>
              </div>
              <div className="book-button-wrapper">
                <Button
                  extraClass={!enableBookBtn ? "disabled-button" : ""}
                  onClick={goToBookingConfirmation}
                  // isLoading={isLoading}
                  label="Book"
                />
              </div>
            </div>
          </div>
        </div>
      )}
    </>
  );
};

export default ChangeOrderSpecaility;

import React from "react";

const TwofaProcessBar = ({ activeStep }) => {
  return (
    <div className="signup-step-header-wrap">
      <div className="progress-bar">
        <div
          className={`step ${
            activeStep === 1 ||
            activeStep === 2 ||
            activeStep === 3 ||
            activeStep === 4
              ? "active"
              : ""
          }`}
          id="step1"
        >
          <div className="dot"></div>
          <p> How to Enable </p>
        </div>
        <div
          className={`step ${
            activeStep === 2 || activeStep === 3 || activeStep === 4
              ? "active"
              : ""
          }`}
          id="step2"
        >
          <div className="dot"></div>
          <p>QR Scan</p>
        </div>
        <div
          className={`step ${
            activeStep === 3 || activeStep === 4 ? "active" : ""
          }`}
          id="step3"
        >
          <div className="dot"></div>
          <p>6 Digit Code</p>
        </div>
      </div>
    </div>
  );
};

export default TwofaProcessBar;

import React, { useState } from "react";
import Modal from "react-bootstrap/Modal";
import "./modal.css";

const TwofaModal = (props) => {
  return (
    <Modal
      {...props}
      size="lg"
      dialogClassName="two-confirmation-modal-main-wrapper"
      aria-labelledby="contained-modal-title-vcenter"
      centered
    >
      <Modal.Body>
        <div className="twofa-confirm-modal-main-wrapper">
          <div className="twofa-confirm-modal-main-wrap">
            <div className="modal-logo-wrap">
              <img src="./assets/modal-logo.png" alt="modal-logo" />
            </div>

            <div className="twofa-confirm-tick-image">
              <img src="./assets/twofa-modal.png" alt="icon" />
            </div>

            <div className="twofa-confirm-modal-content-wrap">
              {/* <h3> {props.type} </h3> */}
              <p> {props.content} </p>
            </div>
          </div>
        </div>
      </Modal.Body>
    </Modal>
  );
};
export default TwofaModal;

import React from "react";
import { useMediaQuery } from "react-responsive";

const PercentageItem = ({
  image,
  category,
  label,
  percentage,
  handleIncementPercentage,
  handleDecrementPercentage,
  price,
}) => {
  const isMobile = useMediaQuery({ maxWidth: 1024 });
  return (
    <>
      {!isMobile ? (
        <div className="booking-cart-item-repeat">
          <div className="booking-cart-item-left">
            <div className="booking-item-image">
              <img src={image} alt="item-img" />
            </div>
            <div className="booking-item-detail">
              <h6 className={category == "Flooring:" ? "flooring-cat" : ""}>
                {category}
              </h6>
              <p> {label} </p>
              {/* <span> {percentage}% </span> */}
            </div>
          </div>

          <div className="booking-cart-item-right-wrapper">
            <div className="booking-cart-item-right">
              <div className="update-quantity-btn">
                <h4
                  onClick={() => handleDecrementPercentage()}
                  className="decrement-btn-text"
                >
                  -
                </h4>
                <span className="percentage-text">{percentage}%</span>
                <h4
                  onClick={() => handleIncementPercentage()}
                  className="increment-btn-text"
                >
                  +
                </h4>
              </div>
              <div className="booking-item-price-wrap">
                {price ? <p> ${price.toFixed(2)}</p> : <p> $0.00 </p>}
              </div>
            </div>
          </div>
        </div>
      ) : (
        <div className="booking-cart-item-repeat">
          <div className="mobile-booking-cart-item-left">
            <div className="booking-item-detail">
              <p> {label} </p>
            </div>
            <div className="update-quantity-btn">
              <h4
                onClick={() => handleDecrementPercentage()}
                className="decrement-btn-text"
              >
                -
              </h4>
              <span>{percentage}%</span>
              <h4
                onClick={() => handleIncementPercentage()}
                className="increment-btn-text"
              >
                +
              </h4>
            </div>
          </div>

          <div className="booking-cart-item-right-wrapper">
            <div className="booking-cart-item-right">
              <div className="booking-item-price-wrap">
                {price ? <p> ${price.toFixed(2)}</p> : <p> $0.00 </p>}
              </div>
            </div>
          </div>
        </div>
      )}
    </>
  );
};

export default PercentageItem;

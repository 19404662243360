import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  serviceType: null,
};

const serviceTypeSlice = createSlice({
  name: "serviceType",
  initialState,
  reducers: {
    setServiceType: (state, action) => {
      state.serviceType = action.payload;
    },
  },
});

export const { setServiceType } = serviceTypeSlice.actions;
export default serviceTypeSlice.reducer;

import React, { Fragment, useState } from "react";
import Offcanvas from "react-bootstrap/Offcanvas";
import ExtrasDrawer from "../../drawers/ExtrasDrawer";
import { useMediaQuery } from "react-responsive";
import AdditionalDrawer from "../../drawers/AdditionalDrawer";
import { useSelector } from "react-redux";

const ProductItem = ({
  item,
  handleIncrement,
  handleDecrement,
  setShowCartDrawer,
  cartDrawer,
}) => {
  const isObjectEmpty = (obj) => {
    return Object.keys(obj).length === 0;
  };

  const isMobile = useMediaQuery({ maxWidth: 1024 });
  const [showDrawer, setShowDrawer] = useState(false);
  const handleClose = () => setShowDrawer(false);
  const dropdownValues = useSelector((state) => state.allDowpdown);

  const handleShow = (data) => {
    // setShowCartDrawer(false);
    setShowDrawer(true);
  };

  return (
    <>
      <Offcanvas
        className={
          cartDrawer
            ? "extras-offcanvas-wrapper active"
            : "extras-offcanvas-wrapper"
        }
        show={showDrawer}
        onHide={handleClose}
        placement="end"
      >
        <Offcanvas.Body>
          <AdditionalDrawer onHide={handleClose} data={item} edit={true} />
        </Offcanvas.Body>
      </Offcanvas>

      {isMobile ? (
        <div className="booking-cart-item-mobile-wrap">
          {item.size ? (
            <div className="booking-cart-item-repeat">
              <div className="mobile-booking-cart-item-left">
                <div className="booking-item-detail additional-dropdown-shows">
                  <p> {item.name}:</p>
                  {item.name === "Exterior Window Cleaning" ? (
                    <>
                      {item.selectedDropdownItems.map((item, index) => (
                        <div key={index} className="selected-item">
                          <p>
                            {Object.keys(item)
                              .filter(
                                (key) => key !== "price" && key !== "time"
                              )
                              .map((key) => `${item[key]}`)
                              .join(", ")}{" "}
                          </p>
                        </div>
                      ))}
                    </>
                  ) : (
                    <>
                      {item.additionItemSq && (
                        <p>
                          <span className="additional-dropdown-type">
                            SquareFeet:
                          </span>
                          {item.additionItemSq} Sq
                        </p>
                      )}

                      <p>
                        {Object.keys(item.selectedDropdown)?.map(
                          (categoryKey) => (
                            <Fragment key={categoryKey}>
                              <span className="additional-dropdown-type">
                                {categoryKey} :
                              </span>
                              {item.selectedDropdown[categoryKey]?.name
                                ? item.selectedDropdown[categoryKey].name
                                : ""}
                              <br />
                            </Fragment>
                          )
                        )}
                      </p>
                    </>
                  )}
                </div>
                <div
                  className="edit-extra-item-btn"
                  onClick={() => handleDecrement(item)}
                >
                  <p> Delete </p>
                </div>
              </div>

              <div className="booking-cart-item-right-wrapper">
                <div className="booking-cart-item-right">
                  <div className="booking-item-price-wrap">
                    <p> ${item.price} </p>
                  </div>
                </div>
              </div>
            </div>
          ) : (
            <div className="booking-cart-item-repeat">
              <div className="mobile-booking-cart-item-left">
                <div className="booking-item-detail">
                  <p> {item.name}</p>
                </div>
                <div className="update-quantity-btn">
                  {item.quantity == 1 ? (
                    <h4
                      className="decrement-btn-text"
                      onClick={() => handleDecrement(item)}
                    >
                      <img src="./assets/icons/delete.svg" alt="icon" />
                    </h4>
                  ) : (
                    <h4
                      className="decrement-btn-text"
                      onClick={() => handleDecrement(item)}
                    >
                      -
                    </h4>
                  )}

                  <span> {item.quantity} </span>
                  <h4
                    onClick={() => handleIncrement(item)}
                    className="increment-btn-text"
                  >
                    +
                  </h4>
                </div>
              </div>

              <div className="booking-cart-item-right-wrapper">
                <div className="booking-cart-item-right">
                  <div className="booking-item-price-wrap">
                    <p> ${item.quantity * item.price} </p>
                  </div>
                </div>
              </div>
            </div>
          )}
        </div>
      ) : (
        <div className="booking-cart-item-desktop-wrap">
          {item.size ? (
            <div className="booking-cart-item-repeat">
              <div className="booking-cart-item-left">
                <div className="booking-item-image">
                  <img src={item.itemImage} alt="item-img" />
                </div>
                <div className="booking-item-detail additional-dropdown-shows">
                  <h6> {item.name}</h6>
                  {item.name === "Exterior Window Cleaning" ? (
                    <>
                      {item.selectedDropdownItems.map((item, index) => (
                        <div key={index} className="selected-item">
                          <p>
                            {Object.keys(item)
                              .filter(
                                (key) => key !== "price" && key !== "time"
                              )
                              .map((key) => `${item[key]}`)
                              .join(", ")}{" "}
                          </p>
                        </div>
                      ))}
                    </>
                  ) : (
                    <>
                      {item.additionItemSq && (
                        <p>
                          <span className="additional-dropdown-type">
                            SquareFeet:
                          </span>
                          {item.additionItemSq} Sq
                        </p>
                      )}

                      <p>
                        {Object.keys(item.selectedDropdown)?.map(
                          (categoryKey) => (
                            <Fragment key={categoryKey}>
                              <span className="additional-dropdown-type">
                                {categoryKey} :
                              </span>
                              {item.selectedDropdown[categoryKey]?.name
                                ? item.selectedDropdown[categoryKey].name
                                : ""}
                              <br />
                            </Fragment>
                          )
                        )}
                      </p>
                    </>
                  )}
                </div>
              </div>

              <div className="booking-cart-item-right-wrapper">
                <div className="booking-cart-item-right">
                  <div
                    className="edit-extra-item-btn"
                    onClick={() => handleDecrement(item)}
                  >
                    <p> Delete </p>
                  </div>
                  <div className="booking-item-price-wrap">
                    <p> ${item?.price?.toFixed(2)} </p>
                  </div>
                </div>
              </div>
            </div>
          ) : (
            <div className="booking-cart-item-repeat">
              <div className="booking-cart-item-left">
                <div className="booking-item-image">
                  <img src={item.itemImage} alt="item-img" />
                </div>

                {dropdownValues.industry.name == "Specialty Cleaning" ? (
                  <div className="booking-item-detail specialty-booking-item-detail">
                    {item.category && <h6> {item.category}:</h6>}
                    <p> {item.name}</p>
                  </div>
                ) : (
                  <div className="booking-item-detail">
                    {item.category && <h6> {item.category}:</h6>}
                    <p> {item.subCategory}</p>
                    <p> {item.name}</p>
                  </div>
                )}
              </div>

              <div className="booking-cart-item-right-wrapper">
                <div className="booking-cart-item-right">
                  {dropdownValues?.industry?.name == "Specialty Cleaning" ? (
                    <div
                      className="edit-extra-item-btn"
                      onClick={() => handleDecrement(item)}
                    >
                      <p> Delete </p>
                    </div>
                  ) : (
                    <div className="update-quantity-btn">
                      {item?.quantity == 1 ? (
                        <h4
                          className="decrement-btn-text"
                          onClick={() => handleDecrement(item)}
                        >
                          <img src="../assets/icons/delete.svg" alt="icon" />
                        </h4>
                      ) : (
                        <h4
                          className="decrement-btn-text"
                          onClick={() => handleDecrement(item)}
                        >
                          -
                        </h4>
                      )}

                      <span> {item.quantity} </span>
                      <h4
                        onClick={() => handleIncrement(item)}
                        className="increment-btn-text"
                      >
                        +
                      </h4>
                    </div>
                  )}

                  <div className="booking-item-price-wrap">
                    <p> ${(item?.quantity * item?.price).toFixed(2)} </p>
                  </div>
                </div>
              </div>
            </div>
          )}
        </div>
      )}
    </>
  );
};

export default ProductItem;

import { configureStore } from "@reduxjs/toolkit";
import { persistStore, persistReducer } from "redux-persist";
import storage from "redux-persist/lib/storage";
import rootReducer from "./rootReducer";
import { createFilter } from "redux-persist-transform-filter";

const formDataFilter = createFilter(
  "formData", // The slice to target
  [
    "address",
    "addressline2",
    "postalcode",
    "squareFeet",
    "scheduleDate",
    "scheduleTime",
    "contactname",
    "contactnumber",
    "addressComplete",
    "otpAccess",
    "platformFees",
    "taxes",
    "sessionStartTime",
  ] // Fields to persist (exclude 'paywith')
);

// Persist configuration
const persistConfig = {
  key: "root",
  storage,
  whitelist: [
    "allCart",
    "allDowpdown",
    "formData", // Include formData with the transform applied
    "serviceType",
    "user",
    "coupon",
    "bookingId",
    "orderChange",
    "flooringPrice",
    "extraItemPrice",
  ], // specify which slices you want to persist
  transforms: [formDataFilter], // Apply the filter
};

// Create a persisted reducer
const persistedReducer = persistReducer(persistConfig, rootReducer);

// Create store
export const store = configureStore({
  reducer: persistedReducer,
  middleware: (getDefaultMiddleware) =>
    getDefaultMiddleware({
      serializableCheck: {
        ignoredActions: ["persist/PERSIST", "persist/REHYDRATE"],
      },
    }),
});

// Create a persistor
export const persistor = persistStore(store);

import moment from "moment";
import { serviceGet } from "./api";

export function convertTo12HourFormat(time24) {
  const [hours24, minutes, seconds] = time24.split(":");
  let period = "AM";
  let hours12 = parseInt(hours24, 10);

  if (hours12 >= 12) {
    period = "PM";
    if (hours12 > 12) {
      hours12 -= 12;
    }
  } else if (hours12 === 0) {
    hours12 = 12;
  }

  const formattedTime = `${hours12}:${minutes} ${period}`;
  return formattedTime;
}

export const getCsrfToken = async () => {
  let csrfToken = localStorage.getItem("csrfToken");

  if (!csrfToken) {
    try {
      const csrfTokenRes = await serviceGet("csrf-token", {
        withCredentials: true,
      });
      csrfToken = csrfTokenRes.csrfToken;
      localStorage.setItem("csrfToken", csrfToken);
    } catch (error) {
      console.error("Error fetching CSRF token:", error);
    }
  }

  return csrfToken;
};

// chatHelper.js
export const isChatEnabled = (scheduleDate, scheduleTime) => {
  // Parse scheduleDate
  const scheduleDateTime = moment(scheduleDate);
  // Extract start time from scheduleTime
  const startTime = scheduleTime.split(" - ")[0]; // "03:30 AM"
  const formattedTime = moment(startTime, "hh:mm A").format("HH:mm"); // Convert to 24-hour format

  // Combine scheduleDate and startTime
  const fullScheduleTime = moment(
    `${scheduleDateTime.format("YYYY-MM-DD")} ${formattedTime}`,
    "YYYY-MM-DD HH:mm"
  );

  // Calculate 2 hours before the schedule time
  const enableChatTime = fullScheduleTime.subtract(2, "hours");

  // Get current time
  const currentTime = moment();

  console.log("Enable Chat Time:", enableChatTime.format("YYYY-MM-DD HH:mm"));
  console.log("Current Time:", currentTime.format("YYYY-MM-DD HH:mm"));

  // Check if chat should be enabled
  return currentTime.isSameOrAfter(enableChatTime);
};

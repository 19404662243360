import React, { useState } from "react";
import "./twofa.css";
import TwofaProcessBar from "./TwofaProcessBar";
import Button from "../../UIComponents/Button";
import { useNavigate } from "react-router-dom";

const EnableGuide = () => {
  const [activeStep, setActiveStep] = useState(1);
  const navigate = useNavigate();
  const goToTwoScan = () => {
    navigate("/twofa-scan");
  };

  const goBack = () => {
    navigate(-1);
  };
  return (
    <div className="profile-twofa-main-wrapper">
      <TwofaProcessBar activeStep={activeStep} />

      <div className="profile-twofa-main-wrap">
        <div className="twofa-inner-wrapper">
          <div className="twofa-head-main-wrap">
            <img
              onClick={goBack}
              src="./assets/icons/back-arrow.png"
              alt="icon"
            />
            <h3> How to Enable </h3>
          </div>
          <div className="authenticator-main-wrapper">
            <div className="authenticator-image-wrap">
              <img src="./assets/icons/authenticator-logo.png" alt="icon" />
            </div>

            <div className="authenticator-store-wrap">
              <p> Get it on Appstore & Playstore </p>
              <div className="authenticator-store-icons-wrap">
                <img src="./assets/icons/app-store.png" alt="icon" />
                <img src="./assets/icons/play-store.png" alt="icon" />
              </div>
            </div>
          </div>
        </div>

        <div className="twofa-footer-wrapper">
          <div className="twofa-footer-wrap">
            <p>
              To enable Google 2FA, download the Google Authenticator app, go to
              your account’s security settings, and select “Enable Google 2FA”
              to display a QR code. Scan this code with the authenticator app to
              link your account and start securing your logins.
            </p>

            <Button onClick={goToTwoScan} label="Proceed" />
          </div>
        </div>
      </div>
    </div>
  );
};

export default EnableGuide;

import React, { useState } from "react";
import Modal from "react-bootstrap/Modal";
import "./modal.css";
import Button from "../UIComponents/Button";

const TimeSlotModal = (props) => {
  const handleModalClose = () => {
    props.onHide();
  };
  return (
    <>
      <Modal
        {...props}
        size="lg"
        dialogClassName="term-modal-main-wrapper timeslot-modal-main-wrapper"
        aria-labelledby="contained-modal-title-vcenter"
        centered
      >
        <Modal.Body>
          <div className="confirm-modal-main-wrapper">
            <div className="term-modal-main-wrap">
              <div className="close-icon-wrap">
                <img
                  onClick={() => props.onHide()}
                  src="./assets/icons/close-icon.svg"
                  alt="icon"
                />
              </div>

              <div className="term-modal-content-wrap">
                <h3 className="time-slot-modal-heading">
                  Time Slot Not Available
                </h3>

                <p className="time-slot-modal-contant">
                  Unfortunately, that time slot is no longer available for this
                  date. Please choose a different date
                </p>
              </div>

              <div className="pay-button-wrapper timeslot-modal-btn">
                <Button label="Ok" onClick={handleModalClose} />
              </div>
            </div>
          </div>
        </Modal.Body>
      </Modal>
    </>
  );
};
export default TimeSlotModal;

import React, { useState, useEffect } from "react";

import {
  CommercialIndustryData,
  CommercialCategory,
  CommercialType,
} from "../../dummydata/DummyData";
import { setError } from "../../../redux/features/errorSlice";
import Dropdown from "../../dropdown/Dropdown";
import { useDispatch, useSelector } from "react-redux";
import { updateDropdown } from "../../../redux/features/dropdownSlice";
import { useNavigate } from "react-router-dom";
import { useMediaQuery } from "react-responsive";
import SpecialityModal from "../../modals/SpecialityModal";
import PercentageGuideModal from "../../modals/PercentageGuideModal";
import { servicePost } from "../../../helper/api";
const CommercialDropdwons = () => {
  const isMobile = useMediaQuery({ maxWidth: 1024 });
  const [redirectPath, setRedirectPath] = useState();
  const formData = useSelector((state) => state.formData);
  const dropdownValues = useSelector((state) => state.allDowpdown);
  const errors = useSelector((state) => state.formErrors);

  // Get Dropdown Data Res
  const [industryData, setIndustryData] = useState([]);
  const [floorsData, setFloorsData] = useState([]);
  const [bedroomsData, setBedroomsData] = useState([]);
  const [tempIndustryDrop, setTempIndustryDrop] = useState();
  console.log("industryData", industryData);

  const [showSpecialityModal, setShowSpecialityModal] = useState(false);
  const [showPercentageGuideModal, setShowPercentageGuideModal] =
    useState(false);

  // get AuthToken from localStorage
  const authToken = localStorage.getItem("token");
  const notification = "";

  const dispatch = useDispatch();
  const navigate = useNavigate();

  // Handle Industry Dropdown data
  const handleDropdownChange = (field) => (value) => {
    setTempIndustryDrop({
      field: "industry",
      value: value,
    });

    if (value) {
      dispatch(setError({ field, value: false }));
    }

    if (
      field === "industry" &&
      // dropdownValues.bedrooms.name &&
      dropdownValues.floors.name &&
      value.name === "Specialty Cleaning"
    ) {
      setShowSpecialityModal(true);
      setRedirectPath("/special-services");
      return;
    }

    if (
      field === "industry" &&
      value.name === "Specialty Cleaning" &&
      // !dropdownValues.bedrooms.name
      !dropdownValues.floors.name
    ) {
      navigate("/special-services");
    }

    if (
      field === "industry" &&
      dropdownValues?.industry.name &&
      // dropdownValues.bedrooms.name &&
      dropdownValues.floors.name &&
      value.name !== "Specialty Cleaning"
    ) {
      setRedirectPath("/services-commercial");
      setShowSpecialityModal(true);
      return;
    }

    if (field === "floors" && !dropdownValues?.floors.name && !isMobile) {
      setShowPercentageGuideModal(true);
    }
    dispatch(updateDropdown({ field, value }));
  };

  // Handle Address Complete
  const handleAddressComplete = () => {
    if (!formData?.address) {
      dispatch(setError({ field: "address", value: true }));
      return;
    }

    // if (!formData?.addressline2) {
    //   dispatch(setError({ field: "addressline2", value: true }));
    //   return;
    // }

    // if (!dropdownValues?.city.name) {
    //   dispatch(setError({ field: "city", value: true }));
    //   return;
    // }

    if (!formData?.postalcode) {
      dispatch(setError({ field: "postalcode", value: true }));
      return;
    }
  };

  const getIndustryData = async () => {
    try {
      const industryDataRes = await servicePost(
        "commercial/getCommercialIndustryData",
        {},
        notification,
        authToken
      );
      setIndustryData(industryDataRes.data);
    } catch (error) {
      console.log("error", error);
    }
  };

  useEffect(() => {
    getIndustryData();
  }, []);

  const getFoorData = async () => {
    try {
      const floorDataRes = await servicePost(
        "commercial/getCategoryData",
        {
          industry: dropdownValues?.industry.name,
        },
        notification,
        authToken
      );
      setFloorsData(floorDataRes.data);
    } catch (error) {
      console.log("error", error);
    }
  };

  const getBedroomData = async () => {
    try {
      const bedroomDataRes = await servicePost(
        "commercial/getCleaningTypeData",
        {
          industry: dropdownValues?.industry.name,
          category: dropdownValues?.floors.name,
        },
        notification,
        authToken
      );
      setBedroomsData(bedroomDataRes.data);
    } catch (error) {
      console.log("error", error);
    }
  };

  useEffect(() => {
    getFoorData();
    getBedroomData();
  }, [dropdownValues?.industry.name, dropdownValues?.floors.name]);

  return (
    <>
      <SpecialityModal
        show={showSpecialityModal}
        onHide={() => setShowSpecialityModal(false)}
        redirectPath={redirectPath}
        tempIndustryDrop={tempIndustryDrop}
      />
      <PercentageGuideModal
        show={showPercentageGuideModal}
        onHide={() => setShowPercentageGuideModal(false)}
        // icon="./assets/confirm-tick.png"
        type="Forget Password"
        content="Reset Password otp send to your registered email"
      />
      <div className="service-select-dropdown-wrap">
        <h4> Select a service </h4>

        <Dropdown
          disabled={!formData?.addressComplete}
          extraClass={errors.industry ? "error-msg" : ""}
          onTypeChange={handleDropdownChange("industry")}
          label="Industry"
          data={industryData}
          alreadyValue={dropdownValues?.industry}
          checkIcon="./assets/icons/drowdown-check.svg"
          onClick={handleAddressComplete}
        />
        {dropdownValues?.industry.name && (
          <Dropdown
            extraClass={errors.floors ? "error-msg" : ""}
            onTypeChange={handleDropdownChange("floors")}
            // onTypeChange={handleFloorsChange}
            label="How many Floors"
            data={floorsData}
            alreadyValue={dropdownValues?.floors}
            checkIcon="./assets/icons/drowdown-check.svg"
          />
        )}

        {/* {dropdownValues?.floors.name && (
          <Dropdown
            extraClass={errors.bedrooms ? "error-msg" : ""}
            onTypeChange={handleDropdownChange("bedrooms")}
            // onTypeChange={handleBedroomsChange}
            label="Cleaning Type"
            data={bedroomsData}
            alreadyValue={dropdownValues?.bedrooms}
            checkIcon="./assets/icons/drowdown-check.svg"
          />
        )} */}
      </div>
    </>
  );
};

export default CommercialDropdwons;

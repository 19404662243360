import React, { useState } from "react";

function SqRangeSlider({
  percentage,
  moppingPercentage,
  onChange,
  leftIcon,
  rightIcon,
  leftName,
  rightName,
  extraClass,
  step,
  min,
  max,
}) {
  // Define the gradient colors based on the extraClass
  const firstColor = "#00A19B";
  const secondColor = extraClass === "flooring-slider" ? "#ccc" : "#fff"; // Second color based on extraClass
  return (
    <>
      <div className={`range-container ${extraClass}`}>
        <div className="chart-and-image-wrap sq-chart-and-img-wrap">
          {leftIcon && <img src={leftIcon} alt="icon" />}
          <p id="output">
            {percentage} <span> Sq </span>
          </p>
        </div>
        {leftName && (
          <div className="cleaning-name-wrap">
            <p>{leftName}</p>
          </div>
        )}

        <input
          className="sq-slider-input-wrap"
          type="range"
          id="stepsall"
          min={min}
          max={max}
          step={step}
          value={percentage}
          onChange={onChange}
          style={{
            background: `linear-gradient(to right, ${firstColor} 0%, ${firstColor} ${
              ((percentage - min) / (max - min)) * 100
            }%, ${secondColor} ${
              ((percentage - min) / (max - min)) * 100
            }%, ${secondColor} 100%)`,
          }}
        />

        {rightName ? (
          <div className="chart-and-image-wrap">
            <p id="output-reverse"> {moppingPercentage}Sq </p>
            {rightIcon && <img src={rightIcon} alt="icon" />}
          </div>
        ) : (
          ""
        )}

        {rightName && (
          <div className="cleaning-reverse-name-wrap">
            <p> {rightName} </p>
          </div>
        )}
      </div>
    </>
  );
}

export default SqRangeSlider;

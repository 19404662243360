// @flow
import { jwtDecode } from "jwt-decode";
/**
 * Checks if token is authenticated
 */

const AuthUtils = async () => {};

export default AuthUtils;

const isUserAuthenticated = () => {
  const token = getLoggedUser();
  if (!token) {
    return false;
  }
  if (token != undefined) {
    const decoded = jwtDecode(token);

    const currentTime = Date.now() / 1000;
    if (decoded.exp < currentTime) {
      // autoLogout(token.user);
      setTimeout(() => {
        localStorage.clear();
        console.warn("access token expired");
        return false;
      }, 2000);
    } else {
      return true;
    }
  }
};

/**
 * Returns the logged in token
 */
const getLoggedUser = () => {
  const token = localStorage.getItem("token");
  return token;
};

export { isUserAuthenticated, getLoggedUser };

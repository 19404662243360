import React, { useEffect, useState } from "react";

const RatingBookings = () => {
  const [rating, setRating] = useState(4.8);
  const [bookings, setBookings] = useState(1020);

  useEffect(() => {
    const updateValues = () => {
      setRating((prevRating) => {
        const newRating = prevRating + (Math.random() - 0.5) * 0.2;
        return Math.max(3.9, Math.min(4.9, newRating.toFixed(1)));
      });

      setBookings((prevBookings) => {
        // Always increment bookings
        const increment = Math.floor(Math.random() * 50 + 10); // Increment by 10 to 59
        const normalizedBookings = Math.min(3800, prevBookings + increment);
        return normalizedBookings;
      });
    };

    // Update every 10 minutes (600,000 milliseconds)
    const intervalId = setInterval(updateValues, 600000);

    return () => clearInterval(intervalId);
  }, []);

  return (
    <p>
      {rating} ({(bookings / 1000).toFixed(1)}k bookings)
    </p>
  );
};

export default RatingBookings;

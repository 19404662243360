import React, { useState } from "react";
import Offcanvas from "react-bootstrap/Offcanvas";
import { useSelector } from "react-redux";
import ExtrasDrawer from "../drawers/ExtrasDrawer";
import OverlayTrigger from "react-bootstrap/OverlayTrigger";
import Tooltip from "react-bootstrap/Tooltip";
import AdditionalDrawer from "../drawers/AdditionalDrawer";
import AdditionalMultiDrawer from "../drawers/AdditionalMultiDrawer";
import { ShimmerPostItem } from "react-shimmer-effects";
const ExtraItems = ({
  extraData,
  addItems,
  removeItem,
  specialityService,
  summerItemLength,
}) => {
  const carts = useSelector((state) => state.allCart.carts);
  const [selectedData, setSelectedData] = useState();
  const [showDrawer, setShowDrawer] = useState(false);

  const handleClose = () => setShowDrawer(false);
  const handleShow = (data) => {
    setSelectedData(data);
    setShowDrawer(true);
  };

  return (
    <>
      <Offcanvas
        className="extras-offcanvas-wrapper"
        show={showDrawer}
        onHide={handleClose}
        placement="end"
      >
        <Offcanvas.Body>
          <AdditionalDrawer onHide={handleClose} data={selectedData} />
        </Offcanvas.Body>
      </Offcanvas>

      <div className="service-item-card-wrapper additional-services-card-wrap">
        <>
          <div className="service-item-grid">
            {extraData.length === 0
              ? Array.from({
                  length: summerItemLength ? summerItemLength : 3,
                }).map((_, index) => (
                  <div key={index} className="shimmer-service-item-card-wrap">
                    <ShimmerPostItem card title />
                  </div>
                ))
              : extraData.map((data, index) => {
                  const cartItem = carts.find(
                    (cartItem) => cartItem.id === data.id
                  );
                  return (
                    <div className="service-item-card-wrap" key={index}>
                      <div className="info-icon-wrap">
                        <OverlayTrigger
                          placement="top"
                          delay={{ show: 250, hide: 400 }}
                          overlay={
                            <Tooltip id={`tooltip-up`}>{data.info}</Tooltip>
                          }
                        >
                          <img src="../assets/icons/info.svg" alt="info-icon" />
                        </OverlayTrigger>
                      </div>

                      <div className="service-item-image">
                        <div className="service-item-img">
                          <img src={data.itemImage} alt="img" />
                        </div>
                        <div className="add-cart-btn-wrapper">
                          {data.extraWithSize ? (
                            <div
                              className="add-cart-btn-wrap add-cart-withextra"
                              onClick={() => handleShow(data)}
                            >
                              <img
                                className="plus-icon"
                                src="../assets/icons/plus.svg"
                                alt="icon"
                              />
                            </div>
                          ) : (
                            <div
                              className={`add-cart-btn-wrap ${
                                cartItem?.isAdded ? "expanded" : ""
                              }`}
                            >
                              {cartItem?.isAdded && (
                                <>
                                  {cartItem?.quantity == 1 ? (
                                    <div
                                      className="card-delet-icon"
                                      onClick={() => removeItem(data)}
                                    >
                                      <img
                                        className="delete-icon"
                                        src="../assets/icons/delete.svg"
                                        alt="icon"
                                      />
                                    </div>
                                  ) : (
                                    <div
                                      className="card-delet-icon"
                                      onClick={() => removeItem(data)}
                                    >
                                      <img
                                        className="delete-icon decrement-icon"
                                        src="../assets/icons/decrement.svg"
                                        alt="icon"
                                      />
                                    </div>
                                  )}
                                  <span className="quantity">
                                    {cartItem?.quantity}
                                  </span>
                                </>
                              )}
                              <div
                                className="plus-icon-wrap"
                                onClick={() => addItems(data)}
                              >
                                <img
                                  className="plus-icon"
                                  src="../assets/icons/plus.svg"
                                  alt="icon"
                                />
                              </div>
                            </div>
                          )}
                        </div>
                      </div>

                      <div className="service-item-card-desc">
                        {data.name?.length > 22 ? (
                          <OverlayTrigger
                            placement="top"
                            delay={{ show: 250, hide: 400 }}
                            overlay={
                              <Tooltip id={`tooltip-up`}>{data.name}</Tooltip>
                            }
                          >
                            <p> {data.name} </p>
                          </OverlayTrigger>
                        ) : (
                          <p> {data.name} </p>
                        )}
                        {/* <p> {data.name} </p> */}
                      </div>
                    </div>
                  );
                })}
          </div>
        </>
      </div>
    </>
  );
};

export default ExtraItems;

import React, { useEffect, useState } from "react";
import "./trackingorder.css";
import InputField from "../UIComponents/InputField";
import Button from "../UIComponents/Button";
import { useDispatch, useSelector } from "react-redux";
import BookingItems from "./components/BookingItems";
import CleaningItem from "./components/CleaningItem";
import PaymentDetail from "./components/PaymentDetail";
import OrderTrack from "./components/OrderTrack";
import Offcanvas from "react-bootstrap/Offcanvas";
import ChatDrawer from "../drawers/ChatDrawer";
import ConfirmationModal from "../modals/ConfirmationModal";
import { useMediaQuery } from "react-responsive";
import { useLocation, useNavigate } from "react-router-dom";
import TrackOrderDrawer from "../drawers/TrackOrderDrawer";

import OverlayTrigger from "react-bootstrap/OverlayTrigger";
import Tooltip from "react-bootstrap/Tooltip";
import { CopyToClipboard } from "react-copy-to-clipboard";
import { servicePost } from "../../helper/api";
import { setNotificationDep } from "../../redux/features/notificationDepSlice";
import RatingBookings from "./components/RatingBookings";
import { useParams } from "react-router-dom";
import IndustryCharge from "./components/IndustryCharge";
import { ShimmerTitle, ShimmerThumbnail } from "react-shimmer-effects";

const TrackingOrder = () => {
  const [isLoading, setIsLoading] = useState(false);
  const isMobile = useMediaQuery({ maxWidth: 1024 });
  const [showConfirmationModal, setShowConfirmationModal] = useState(false);
  const [partnerAssigned, setPartnerAssigned] = useState(false);
  const cartItems = useSelector((state) => state.allCart.carts);
  const [showAll, setShowAll] = useState(false);
  const [editContact, setEditContact] = useState(false);
  const [showChatDrawer, setShowChatDrawer] = useState(false);
  const [isCopied, setIsCopied] = useState(false);
  const [bookingData, setBookingData] = useState();
  const [isOrderCancelled, setOrderCancelled] = useState(false);

  const [shimmerLoading, setShimmerLoading] = useState(true);

  const bookingComplete = bookingData?.bookingStatus == "Completed";
  const bookingCanceled = bookingData?.cancelStatus == true;
  const bookingDone = bookingComplete || bookingCanceled;

  const notificationDep = useSelector(
    (state) => state.notificationDep.notificationDep
  );

  const dispatch = useDispatch();

  const handleOrderCancel = (data) => {
    setOrderCancelled(data);
  };

  // get loggedInUser for Redux
  const logedInUser = useSelector((state) => state.user.userInfo);

  const { uuid } = useParams(); // Get the bookingId from the URL
  const bookingId = uuid;

  // get AuthToken from localStorage
  const authToken = localStorage.getItem("token");
  const notification = "";

  // // get BookingId From State
  // const location = useLocation();
  // const bookId = location.state?.bookingId;
  // const bookingId = bookId?.toString();

  const handleCopy = () => {
    setIsCopied(true);
    setTimeout(() => {
      setIsCopied(false);
    }, 2000); // Show copied message for 3 seconds
  };

  // Track Order Drawer handler
  const [showTrackDrawer, setShowTrackDrawer] = useState(false);
  const handleCloseTracking = () => setShowTrackDrawer(false);
  const handleShowTracking = (title) => {
    setShowTrackDrawer(true);
  };

  const handleCloseChat = () => setShowChatDrawer(false);
  const handleShowChat = (title) => {
    setShowChatDrawer(true);
  };

  const editContactInfo = () => {
    setEditContact(!editContact);
  };

  const handleToggleShow = () => {
    setShowAll((prevShowAll) => !prevShowAll);
  };

  const navigate = useNavigate();
  const goToDashboard = () => {
    navigate("/");
  };

  const [contactPerson, setContactPerson] = useState({
    contactname: "",
    contactnumber: "",
  });

  const { contactname, contactnumber } = contactPerson;

  // handle Input Change
  const handleInputChange = (event) => {
    const { name, value } = event.target;

    let formattedValue = value;
    if (name === "contactnumber") {
      // Remove all non-numeric characters
      const numericValue = value.replace(/\D/g, "");

      // Format the number as xxx-xxx-xxxx
      if (numericValue.length > 3 && numericValue.length <= 6) {
        formattedValue = `${numericValue.slice(0, 3)}-${numericValue.slice(3)}`;
      } else if (numericValue.length > 6) {
        formattedValue = `${numericValue.slice(0, 3)}-${numericValue.slice(
          3,
          6
        )}-${numericValue.slice(6, 10)}`;
      } else {
        formattedValue = numericValue;
      }
    }

    setContactPerson((oldValue) => {
      return {
        ...oldValue,
        [name]: formattedValue,
      };
    });
  };

  // Check if Values already exist
  useEffect(() => {
    if (bookingData) {
      setContactPerson({
        contactname: !bookingData.contactPersonName
          ? ""
          : bookingData.contactPersonName,
        contactnumber: !bookingData.contactPersonNumber
          ? ""
          : bookingData.contactPersonNumber,
      });
    }
  }, [bookingData]);

  // Get Booking Detail Basis of BookingId
  const getOrderDetail = async () => {
    try {
      const orderDetailRes = await servicePost(
        "booking/getBookingData",
        {
          bookingId: bookingId,
        },
        notification,
        authToken
      );

      setShimmerLoading(false);

      setBookingData(orderDetailRes.BookingDetail);
    } catch (error) {
      console.log("error", error);
    }
  };

  useEffect(() => {
    getOrderDetail();
  }, [bookingId, editContact, isOrderCancelled, notificationDep]);

  // Update Booking Order Info
  const updateContactPersonInfo = async () => {
    try {
      setIsLoading(true);
      const contactPersonRes = await servicePost(
        "booking/updateBookingData",
        {
          bookingId: bookingId,
          accountId: logedInUser?.id,
          contactPersonName: contactname,
          contactPersonNumber: contactnumber,
        },
        notification,
        authToken
      );

      if (contactPersonRes.message == "SUCCESS") {
        setIsLoading(false);
        setEditContact(false);
        dispatch(setNotificationDep(notificationDep + 1));
      }
    } catch (error) {
      console.log("error", error);
      setIsLoading(false);
    }
  };

  return (
    <>
      <ConfirmationModal
        show={showConfirmationModal}
        onHide={() => setShowConfirmationModal(false)}
        icon="../assets/icons/phone-icon.png"
        type={
          bookingData?.cleanerPartnerStatus
            ? "Cleaner Contact Number"
            : "Not Assigned"
        }
        content={
          bookingData?.cleanerPartnerStatus
            ? "+1 (746)-366-9378"
            : "This funcation will be enabled an hour before your Schedule sevice"
        }
        showCloseIcon={true}
      />

      <Offcanvas
        className="chat-offcanvas-wrapper"
        show={showChatDrawer}
        onHide={handleCloseChat}
        placement="end"
      >
        <Offcanvas.Body>
          <ChatDrawer
            bookingId={bookingId}
            onHide={handleCloseChat}
            title="Cart"
          />
        </Offcanvas.Body>
      </Offcanvas>

      <Offcanvas
        className="trackorder-offcanvas-wrapper"
        show={showTrackDrawer}
        onHide={handleCloseTracking}
        placement="end"
      >
        <Offcanvas.Body>
          <TrackOrderDrawer
            data=""
            onHide={handleCloseTracking}
            title="track"
          />
        </Offcanvas.Body>
      </Offcanvas>

      <div className="tracking-order-main-wrapper">
        <div className="tracking-order-main-wrap container">
          {!isMobile && (
            <>
              {shimmerLoading ? (
                <div className="shimmer-traking-header-wrap">
                  <ShimmerTitle line={1} gap={10} variant="primary" />
                </div>
              ) : (
                <div className="service-header-wrap">
                  <div className="service-header-left">
                    <h3 className="for-desktop-view">
                      {bookingData?.serviceType} Cleaning
                    </h3>
                    <h3 className="for-mobile-view">
                      {bookingData?.serviceType}
                    </h3>

                    <div className="service-header-reating">
                      <img src="../assets/icons/review-icon.png" alt="icon" />
                      {/* <p> 4.8 (102k bookings) </p> */}
                      <RatingBookings />
                    </div>

                    {bookingData?.otpAccess && (
                      <div className="tracking-service-title-wrap">
                        <div className="otp-guideline-info-icon">
                          <OverlayTrigger
                            placement="top"
                            delay={{ show: 250, hide: 400 }}
                            overlay={
                              <Tooltip id={`tooltip-top`}>
                                {bookingData?.otp
                                  ? "Share this OTP with your assigned cleaner partner."
                                  : "An OTP will be shown here 1 hour before your scheduled cleaning."}
                              </Tooltip>
                            }
                          >
                            <img
                              src="../assets/icons/info.svg"
                              alt="info-icon"
                            />
                          </OverlayTrigger>
                        </div>

                        <div className="otp-number-wrap">
                          <p>
                            {" "}
                            OTP:{" "}
                            {bookingData?.otp
                              ? bookingData?.otp
                              : "Pending"}{" "}
                          </p>

                          {bookingData?.otp && (
                            <CopyToClipboard
                              text={bookingData?.otp}
                              onCopy={handleCopy}
                            >
                              <img src="../assets/icons/copy.svg" alt="icon" />
                            </CopyToClipboard>
                          )}

                          {isCopied && (
                            <span className="copied-text">Copied!</span>
                          )}
                        </div>
                      </div>
                    )}
                  </div>

                  <div className="service-header-right">
                    <div className="service-right-nav">
                      <p> Home </p>
                      <img
                        src="../assets/icons/right-black-arrow.svg"
                        alt="arrow"
                      />
                      <h6> {bookingData?.serviceType} </h6>
                    </div>
                  </div>
                </div>
              )}
            </>
          )}

          <div className="dashboard-service-detail-wrapper">
            <div className="dashboard-service-detail-wrap">
              <div className="row">
                <div className="col-lg-4">
                  {!isMobile ? (
                    <div className="tracking-address-detail-wrapper">
                      {shimmerLoading ? (
                        <ShimmerThumbnail rounded />
                      ) : (
                        <div className="tracking-address-detail-wrap">
                          {/* <h4> Your service </h4>
                          <div className="tranking-address-confrim-icon">
                            <img src="../assets/confirm-tick.png" alt="icon" />
                          </div>

                          <div className="tracking-selected-services">
                            <img src="../assets/service-1.png" alt="img" />
                            <p>
                              {bookingData?.industry}
                              {bookingData?.floors && ","}
                            </p>
                            <p>
                              {bookingData?.floors}
                              {bookingData?.bedrooms && ","}
                            </p>
                            <p>{bookingData?.bedrooms} </p>
                          </div>

                          <div className="traking-detail-address-wrap booking-detail-address-wrap">
                            <h4> Your Address Details </h4>
                            <div className="booking-detail-address">
                              <p> Address </p>
                              <h6>{bookingData?.address}</h6>
                            </div>

                            <div className="booking-detail-address">
                              <p> City </p>
                              <h6>{bookingData?.city}</h6>
                            </div>
                          </div> */}
                          <div className="service-main-image-wrapper">
                            {bookingData?.serviceType == "Residential" ? (
                              <img
                                src="../assets/residentail-img.png"
                                alt="img"
                              />
                            ) : (
                              <img
                                src="../assets/commercail-img.png"
                                alt="img"
                              />
                            )}
                          </div>
                        </div>
                      )}
                    </div>
                  ) : shimmerLoading ? (
                    <div className="shimmer-traking-header-wrap">
                      <ShimmerTitle line={1} gap={10} variant="primary" />
                    </div>
                  ) : (
                    <div className="booking-cart-head">
                      <img
                        onClick={goToDashboard}
                        src="../assets/icons/previous-arrow.svg"
                        alt="icon"
                      />
                      <h4> {bookingData?.industry} </h4>

                      {bookingData?.otpAccess && (
                        <div className="mobile-view-otp-wrap">
                          <div className="mobile-otp-guideline-info-icon">
                            <OverlayTrigger
                              placement="top"
                              delay={{ show: 250, hide: 400 }}
                              overlay={
                                <Tooltip id={`tooltip-top`}>
                                  {bookingData?.otp
                                    ? "Share this OTP with your assigned cleaner partner."
                                    : "An OTP will be shown here 1 hour before your scheduled cleaning."}
                                </Tooltip>
                              }
                            >
                              <img
                                src="../assets/icons/info.svg"
                                alt="info-icon"
                              />
                            </OverlayTrigger>
                          </div>
                          <div className="otp-number-wrap">
                            <p>
                              OTP:{" "}
                              {bookingData?.otp ? bookingData?.otp : "Pending"}
                            </p>

                            {bookingData?.otp && (
                              <CopyToClipboard
                                text={bookingData?.otp}
                                onCopy={handleCopy}
                              >
                                <img
                                  src="../assets/icons/copy.svg"
                                  alt="icon"
                                />
                              </CopyToClipboard>
                            )}

                            {isCopied && (
                              <span className="copied-text">Copied!</span>
                            )}
                          </div>
                        </div>
                      )}
                    </div>
                  )}

                  <div className="contact-person-detail-wrapper">
                    {shimmerLoading ? (
                      <ShimmerThumbnail rounded />
                    ) : (
                      <div
                        className={
                          editContact
                            ? "contact-person-detail-wrap active"
                            : "contact-person-detail-wrap active contant-person-readonly-detail"
                        }
                      >
                        {editContact ? (
                          <>
                            <div className="contact-person-lable-wrap">
                              <div className="contact-person-lable">
                                <h4> Contact Person </h4>
                                <OverlayTrigger
                                  placement="top"
                                  delay={{ show: 250, hide: 400 }}
                                  overlay={
                                    <Tooltip id={`tooltip-top`}>
                                      This is Optional Information
                                    </Tooltip>
                                  }
                                >
                                  <img
                                    src="../assets/icons/info.svg"
                                    alt="info-icon"
                                  />
                                </OverlayTrigger>
                              </div>
                              <div className="contact-person-edit-wrap">
                                {!bookingDone &&
                                  (editContact ? (
                                    <img
                                      onClick={editContactInfo}
                                      src="../assets/icons/close-icon.svg"
                                      alt="icon"
                                    />
                                  ) : (
                                    <img
                                      onClick={editContactInfo}
                                      src="../assets/icons/edit-icon.png"
                                      alt="icon"
                                    />
                                  ))}
                              </div>
                            </div>

                            <div className="login-option">
                              <form>
                                <InputField
                                  extraClass={contactname ? "active" : ""}
                                  type="text"
                                  name="contactname"
                                  id="contactname"
                                  label="Enter Name"
                                  maxLength="50"
                                  value={contactname}
                                  passwordIcon={false}
                                  disabled={!editContact}
                                  onChange={handleInputChange}
                                />

                                <InputField
                                  extraClass={contactnumber ? "active" : ""}
                                  type="text"
                                  name="contactnumber"
                                  id="contactnumber"
                                  label="Enter Number"
                                  value={contactnumber}
                                  passwordIcon={false}
                                  disabled={!editContact}
                                  onChange={handleInputChange}
                                />
                              </form>

                              <div className="pay-button-wrapper">
                                <Button
                                  isLoading={isLoading}
                                  extraClass={editContact ? "" : "gray-btn"}
                                  label="Save Changes"
                                  onClick={updateContactPersonInfo}
                                />
                              </div>
                            </div>
                          </>
                        ) : (
                          <>
                            <div className="contact-person-lable-wrap">
                              <div className="contact-person-lable">
                                <h4> Contact Person </h4>
                                <OverlayTrigger
                                  placement="top"
                                  delay={{ show: 250, hide: 400 }}
                                  overlay={
                                    <Tooltip id={`tooltip-top`}>
                                      This is Optional Information
                                    </Tooltip>
                                  }
                                >
                                  <img
                                    src="../assets/icons/info.svg"
                                    alt="info-icon"
                                  />
                                </OverlayTrigger>
                              </div>
                              <div className="contact-person-edit-wrap">
                                {!bookingDone &&
                                  (editContact ? (
                                    <img
                                      onClick={editContactInfo}
                                      src="../assets/icons/close-icon.svg"
                                      alt="icon"
                                    />
                                  ) : (
                                    <img
                                      onClick={editContactInfo}
                                      src="../assets/icons/edit-icon.png"
                                      alt="icon"
                                    />
                                  ))}
                              </div>
                            </div>

                            <div className="readonly-info-wrap">
                              <div className="booking-detail-address">
                                <p> Name </p>
                                <h6>{bookingData?.contactPersonName}</h6>
                                {/* <h6>{formData?.contactname}</h6> */}
                              </div>

                              <div className="booking-detail-address">
                                <p> Number </p>
                                <h6>{bookingData?.contactPersonNumber}</h6>
                                {/* <h6>{formData?.contactnumber}</h6> */}
                              </div>
                            </div>
                          </>
                        )}
                      </div>
                    )}
                  </div>
                </div>

                <div className="col-lg-8">
                  <div className="home-service-detail-main-wrapper">
                    {shimmerLoading ? (
                      <ShimmerThumbnail rounded />
                    ) : (
                      <div className="home-tracking-service-detail-main-wrap">
                        <div className="selected-service-detail-wrapper">
                          <div className="selected-service-detail-wrap">
                            <div className="selected-service-detail-left">
                              <div className="booking-tracking-detail-wrap">
                                <div className="booking-id-tracking">
                                  <div className="tracking-back-wrap">
                                    <div className="tracking-back-btn">
                                      <img
                                        onClick={goToDashboard}
                                        src="../assets/icons/previous-arrow.svg"
                                        alt="icon"
                                      />
                                      <h4>
                                        Booking ID {bookingData?.bookingId}
                                      </h4>
                                    </div>

                                    <div className="tracking-icon-for-tabs">
                                      <img
                                        onClick={() => handleShowTracking()}
                                        src="../assets/icons/location.svg"
                                        alt="icon"
                                      />
                                      <p onClick={() => handleShowTracking()}>
                                        Status
                                      </p>
                                    </div>
                                  </div>
                                  <div className="tracking-item-count">
                                    <p>
                                      {bookingData?.cartItems?.length} items, $
                                      {bookingData?.subTotal}
                                    </p>
                                  </div>

                                  <div className="traking-sercice-wrap">
                                    <p>
                                      {bookingData?.industry}
                                      {bookingData?.floors && ","}{" "}
                                      {bookingData?.floors}
                                      {bookingData?.bedrooms && ","}{" "}
                                      {bookingData?.bedrooms}
                                    </p>
                                  </div>

                                  {bookingData?.industry ==
                                  "Specialty Cleaning" ? (
                                    ""
                                  ) : (
                                    <div className="traking-squirefeet-wrap">
                                      <p> Square Feet of your Home</p>
                                      <span>{bookingData?.squireFeet} Sq</span>
                                    </div>
                                  )}
                                </div>

                                <div className="booking-cart-items-wrap tracking-cart-item-wrap">
                                  <h5> Items </h5>
                                  <div className="tranking-booking-cart-item">
                                    {bookingData?.industry ==
                                    "Specialty Cleaning" ? (
                                      ""
                                    ) : (
                                      <>
                                        {bookingData?.industry ==
                                        "Post Construction" ? (
                                          <IndustryCharge
                                            image="../assets/icons/yfos-logo.png"
                                            category={bookingData?.bedrooms}
                                            label="Surcharge"
                                            percentage={
                                              bookingData?.industrySurcharge
                                            }
                                            price={
                                              bookingData?.industrySurcharge
                                            }
                                            type="industry charges"
                                            pageType="TrankingOrder"
                                          />
                                        ) : (
                                          <IndustryCharge
                                            image="../assets/icons/yfos-logo.png"
                                            category={bookingData?.industry}
                                            label="Surcharge"
                                            percentage={
                                              bookingData?.industrySurcharge
                                            }
                                            price={
                                              bookingData?.industrySurcharge
                                            }
                                            type="industry charges"
                                            pageType="TrankingOrder"
                                          />
                                        )}

                                        <IndustryCharge
                                          image="../assets/icons/yfos-logo.png"
                                          category={bookingData?.floors}
                                          label="Surcharge"
                                          percentage={
                                            bookingData?.floorSurcharge
                                          }
                                          price={bookingData?.floorSurcharge}
                                          type="floors charges"
                                          pageType="TrankingOrder"
                                        />
                                        <CleaningItem
                                          image="../assets/icons/vacuum-icon.png"
                                          category="Flooring:"
                                          label="Vacuum Cleaning"
                                          percentage={
                                            bookingData?.vacummCleaning
                                          }
                                          price={
                                            bookingData?.vacummCleaningPrice
                                          }
                                        />
                                        <CleaningItem
                                          image="../assets/icons/mopping-icon.png"
                                          category="Flooring:"
                                          label="Mopping or sweeping"
                                          percentage={
                                            bookingData?.moppingCleaning
                                          }
                                          price={
                                            bookingData?.moppingCleaningPrice
                                          }
                                        />
                                        <CleaningItem
                                          image="../assets/icons/mopping-icon.png"
                                          category="Dusting:"
                                          label="Dusting"
                                          percentage={bookingData?.dusting}
                                          price={bookingData?.dustingPrice}
                                        />
                                      </>
                                    )}
                                  </div>

                                  <div className="tranking-booking-cart-item">
                                    {bookingData?.cartItems
                                      ?.slice(
                                        0,
                                        showAll
                                          ? bookingData?.cartItems.length
                                          : 1
                                      )
                                      .map((item, index) => (
                                        <BookingItems key={index} item={item} />
                                      ))}
                                    {bookingData?.cartItems.length > 1 && (
                                      <div
                                        className="see-more-btn"
                                        onClick={handleToggleShow}
                                      >
                                        <p>
                                          {showAll ? "See Less" : "See More"}
                                        </p>
                                        <img
                                          src="../assets/icons/back-arrow-green.svg"
                                          alt="icon"
                                        />
                                      </div>
                                    )}
                                  </div>
                                </div>

                                <div className="payment-method-detail-wrapper">
                                  <PaymentDetail
                                    recurring={bookingData?.recurring}
                                    address={bookingData?.address}
                                    city={bookingData?.city}
                                    addressline2={bookingData?.addressLine2}
                                    PostalCode={bookingData?.postalCode}
                                    scheduleDate={bookingData?.scheduleDate}
                                    scheduleTime={bookingData?.scheduleTime}
                                    paymentMethod={bookingData?.paymentMethod}
                                    price={bookingData?.price}
                                    discount={bookingData?.discount}
                                    subTotal={bookingData?.subTotal}
                                    fees={bookingData?.fees}
                                    total={bookingData?.total}
                                    yfosServiceFess={
                                      bookingData?.yfosServiceCharge
                                    }
                                    showTotal={true}
                                  />
                                </div>
                              </div>
                            </div>

                            {!isMobile && (
                              <div className="selected-service-detail-right track-order-tabs-none">
                                <OrderTrack
                                  data={bookingData}
                                  onOrderCancel={handleOrderCancel}
                                />
                                <div className="right-sidebar-wrapper">
                                  <div
                                    onClick={handleShowChat}
                                    className="sidebar-chat-wrap"
                                  >
                                    <img
                                      src="../assets/icons/chat-icon.svg"
                                      alt="message icon"
                                    />
                                  </div>

                                  {/* <div
                                  className="sidebar-chat-wrap"
                                  onClick={() => setShowConfirmationModal(true)}
                                >
                                  <img
                                    src="../assets/icons/phone-icon.svg"
                                    alt="message icon"
                                  />
                                </div> */}
                                </div>
                              </div>
                            )}
                          </div>
                        </div>
                      </div>
                    )}
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default TrackingOrder;

import React, { useState } from "react";
import Modal from "react-bootstrap/Modal";
import "./modal.css";
import { updateFormData } from "../../redux/features/formSlice";
import { useDispatch } from "react-redux";

const PaywithModal = (props) => {
  const [isLoading, setIsLoading] = useState(false);
  const [selectedReason, setSelectedReason] = useState("");
  const dispatch = useDispatch();
  const handleDivClick = (value) => {
    setSelectedReason(value);
    dispatch(updateFormData({ field: "paywith", value: value }));
  };

  return (
    <>
      <Modal
        {...props}
        size="lg"
        dialogClassName="term-modal-main-wrapper"
        aria-labelledby="contained-modal-title-vcenter"
        centered
      >
        <Modal.Body>
          <div className="confirm-modal-main-wrapper">
            <div className="term-modal-main-wrap">
              <div className="close-icon-wrap">
                <img
                  onClick={() => props.onHide()}
                  src="./assets/icons/close-icon.svg"
                  alt="icon"
                />
              </div>

              <div className="term-modal-content-wrap paywith-modal-contant">
                <h3> Pay with </h3>
                <p>
                  Please choose one of the following options to complete your
                  booking:
                </p>
              </div>
              <div className="term-condition-content">
                <p> - Postpaid </p>
                <p> - Prepaid </p>
              </div>

              <div className="pay-modal-term-wrapper paywith-modal-main-wrap">
                <div
                  onClick={() => handleDivClick("Postpaid")}
                  className={`pay-modal-term-wrapper yfos-otp-access-wrap ${
                    selectedReason === "Postpaid" ? "active" : ""
                  }`}
                >
                  <form>
                    <div className="term-modal-checkmark-wrap">
                      <p> Pay with Postpaid</p>
                      <label className="checkbox-container">
                        <input
                          type="checkbox"
                          id="send-otp"
                          name="cancel-reason"
                          value="send-otp"
                          // onChange={handleOtpChange}
                          checked={selectedReason === "Postpaid"}
                        />
                        <span className="checkmark"> </span>
                      </label>
                    </div>
                  </form>
                </div>

                <div
                  onClick={() => handleDivClick("Prepaid")}
                  className={`pay-modal-term-wrapper yfos-otp-access-wrap ${
                    selectedReason === "Prepaid" ? "active" : ""
                  }`}
                >
                  <form>
                    <div className="term-modal-checkmark-wrap">
                      <p> Pay with Prepaid </p>
                      <label className="checkbox-container">
                        <input
                          type="checkbox"
                          id="no-otp"
                          name="cancel-reason"
                          value="no-otp"
                          // onChange={handleOtpChange}
                          checked={selectedReason === "Prepaid"}
                        />
                        <span className="checkmark"> </span>
                      </label>
                    </div>
                  </form>
                </div>
              </div>
            </div>
          </div>
        </Modal.Body>
      </Modal>
    </>
  );
};
export default PaywithModal;

import React, { useEffect, useState } from "react";
import "./services.css";
import Dropdown from "../dropdown/Dropdown";
import { updateDropdown } from "../../redux/features/dropdownSlice";
import { setError } from "../../redux/features/errorSlice";
import Button from "../UIComponents/Button";
import { availibleServiceCites } from "../dummydata/DummyData";
import { useDispatch } from "react-redux";
import { addToCart, removeToCart } from "../../redux/features/cartSlice";
import { useNavigate } from "react-router-dom";
import { useSelector } from "react-redux";
import SpecialityModal from "../modals/SpecialityModal";
import AddressDetails from "./components/AddressDetails";
import { useMediaQuery } from "react-responsive";
import TimePicker from "./components/TimePicker";
import DatePicker from "react-datepicker";

import moment from "moment";
import SpecialityItem from "../cardItem/SpecialityItem";
import RatingBookings from "./components/RatingBookings";
import { servicePost } from "../../helper/api";
import SpecialServiceDetail from "./components/SpecialServiceDetail";
import { updateFormData } from "../../redux/features/formSlice";
import TimeSlot from "./components/TimeSlot";
import ServiceAvailibleModal from "../modals/ServiceAvailibleModal";
import SessionExpireModal from "../modals/SessionExpireModal";

const SpecialServices = () => {
  const [isSessionExpiring, setIsSessionExpiring] = useState(false);
  const [showAvailibleModal, setShowAvailibleModal] = useState(false);
  const isMobile = useMediaQuery({ maxWidth: 1024 });
  const errors = useSelector((state) => state.formErrors);
  const [enableBookBtn, setEnableBookBtn] = useState(false);
  const [selectedDate, setSelectedDate] = useState(null);
  const serviceType = useSelector((state) => state.serviceType.serviceType);
  const [showSpecialityModal, setShowSpecialityModal] = useState(false);
  const dropdownValues = useSelector((state) => state.allDowpdown);
  const formData = useSelector((state) => state.formData);
  const cartItems = useSelector((state) => state.allCart.carts);

  //
  const [industryData, setIndustryData] = useState([]);
  const [tempIndustryDrop, setTempIndustryDrop] = useState();
  const [commercialIndustryData, setCommercialIndustryData] = useState([]);
  const [commercialFloorsData, setCommercialFloorsData] = useState([]);
  const [residentialServiceData, setResidentailServiceData] = useState([]);
  const [commercialServiceData, setCommercialServiceData] = useState([]);

  // get AuthToken from localStorage
  const authToken = localStorage.getItem("token");
  const notification = "";

  /// Add to cart
  const dispatch = useDispatch();
  const addToCartHandler = (data) => {
    dispatch(addToCart(data));
  };

  const removeFromCartHandler = (data) => {
    dispatch(removeToCart(data));
  };

  const navigate = useNavigate();
  // go to Booking confirmation
  const goToBookingConfirmation = () => {
    if (!formData?.address) {
      dispatch(setError({ field: "address", value: true }));
      return;
    }

    // if (!formData?.addressline2) {
    //   dispatch(setError({ field: "addressline2", value: true }));
    //   return;
    // }

    if (!formData?.postalcode) {
      dispatch(setError({ field: "postalcode", value: true }));
      return;
    }

    // if (!dropdownValues?.city?.name) {
    //   dispatch(setError({ field: "city", value: true }));
    //   return;
    // }

    if (cartItems?.length == 0) {
      alert("Add AtLeast One Specialtiy service");
      return;
    }

    if (!formData?.scheduleDate) {
      dispatch(setError({ field: "date", value: true }));
      return;
    }

    if (!formData?.scheduleTime) {
      dispatch(setError({ field: "time", value: true }));
      return;
    }

    // Check City Valid or not
    const isValidCity = availibleServiceCites.some((city) =>
      formData?.address.toLowerCase().includes(city.name.toLowerCase())
    );

    if (!isValidCity) {
      setShowAvailibleModal(true);
      return;
    }

    navigate("/booking-confirmation");
  };

  // Handle Industry Dropdown data
  const handleDropdownChange = (field) => (value) => {
    setTempIndustryDrop({
      field: "industry",
      value: value,
    });

    if (field === "industry" && dropdownValues?.industry?.name) {
      setShowSpecialityModal(true);
    }

    if (field == "floors") {
      dispatch(updateDropdown({ field, value }));
    }
  };

  // HandleDate Change
  const handleDateChange = (date) => {
    setSelectedDate(date);
    dispatch(updateFormData({ field: "scheduleDate", value: date }));
    dispatch(updateFormData({ field: "scheduleTime", value: "" }));
    if (date) {
      dispatch(setError({ field: "date", value: false }));
    }
  };

  //
  useEffect(() => {
    if (
      formData?.addressComplete &&
      cartItems?.length > 0 &&
      formData?.scheduleDate &&
      formData?.scheduleTime
    ) {
      setEnableBookBtn(true);
    } else {
      setEnableBookBtn(false);
    }
  }, [formData, cartItems, formData?.scheduleDate]);

  const goToServices = () => {
    navigate("/services");
  };

  // Rasidentail Industry Data
  const getIndustryData = async () => {
    try {
      const industryDataRes = await servicePost(
        "residential/getIndustryData",
        {},
        notification,
        authToken
      );
      setIndustryData(industryDataRes.data);
    } catch (error) {
      console.log("error", error);
    }
  };

  useEffect(() => {
    getIndustryData();
  }, []);

  // Get Commercail Industry Data
  const getCommercialIndustry = async () => {
    try {
      const commercialIndustryDataRes = await servicePost(
        "commercial/getCommercialIndustryData",
        {},
        notification,
        authToken
      );
      setCommercialIndustryData(commercialIndustryDataRes.data);
    } catch (error) {
      console.log("error", error);
    }
  };

  useEffect(() => {
    getCommercialIndustry();
  }, []);

  const getCommercailFoorData = async () => {
    try {
      const floorDataRes = await servicePost(
        "commercial/getCategoryData",
        {
          industry: dropdownValues?.industry.name,
        },
        notification,
        authToken
      );
      setCommercialFloorsData(floorDataRes.data);
    } catch (error) {
      console.log("error", error);
    }
  };

  useEffect(() => {
    getCommercailFoorData();
  }, [dropdownValues?.industry.name]);

  // Get Residentail Specialty Services
  const getResidentailServiceData = async () => {
    try {
      const serviceDataRes = await servicePost(
        "residential/getspecialServiceData",
        {
          industry: dropdownValues?.industry.name,
        },
        notification,
        authToken
      );
      setResidentailServiceData(serviceDataRes.data);
    } catch (error) {
      console.log("error", error);
    }
  };

  // Get Commercial Specialty Services
  const getCommercialServiceData = async () => {
    try {
      const serviceDataRes = await servicePost(
        "commercial/getspecialServiceData",
        {
          industry: dropdownValues?.industry.name,
        },
        notification,
        authToken
      );
      setCommercialServiceData(serviceDataRes.data);
    } catch (error) {
      console.log("error", error);
    }
  };

  useEffect(() => {
    getCommercialServiceData();
    getResidentailServiceData();
  }, [dropdownValues?.industry.name]);

  // Sechudal Time Exprie Section
  const sessionDuration = 15 * 60 * 1000;
  useEffect(() => {
    let expirationTimeout;

    const checkSessionExpiration = () => {
      if (formData.sessionStartTime) {
        const timeElapsed = Date.now() - formData.sessionStartTime;
        if (timeElapsed >= sessionDuration) {
          // Session has expired
          setIsSessionExpiring(true);
          dispatch(updateFormData({ field: "sessionStartTime", value: null }));
          dispatch(updateFormData({ field: "scheduleDate", value: "" }));
          dispatch(updateFormData({ field: "scheduleTime", value: "" }));
        } else {
          // Session is still valid, set a timeout for the remaining time
          const remainingTime = sessionDuration - timeElapsed;
          expirationTimeout = setTimeout(() => {
            setIsSessionExpiring(true);
            dispatch(
              updateFormData({ field: "sessionStartTime", value: null })
            );
            dispatch(updateFormData({ field: "scheduleDate", value: "" }));
            dispatch(updateFormData({ field: "scheduleTime", value: "" }));
          }, remainingTime);
        }
      }
    };

    // Check for session expiration on component mount
    checkSessionExpiration();

    return () => {
      clearTimeout(expirationTimeout);
    };
  }, [formData.sessionStartTime, dispatch]);

  return (
    <>
      <SpecialityModal
        show={showSpecialityModal}
        onHide={() => setShowSpecialityModal(false)}
        redirectPath="/special-services"
        tempIndustryDrop={tempIndustryDrop}
        page="Special-service"
      />
      <ServiceAvailibleModal
        show={showAvailibleModal}
        onHide={() => setShowAvailibleModal(false)}
        showCloseIcon={true}
      />

      <SessionExpireModal
        show={isSessionExpiring}
        onHide={() => setIsSessionExpiring(false)}
        showCloseIcon={true}
      />

      {!isMobile ? (
        <div className="dashboard-services-main-wrapper">
          <div className="dashboard-services-main-wrap container">
            <div className="service-header-wrap">
              <div className="service-header-left">
                <h3 className="for-desktop-view"> {serviceType} Cleaning </h3>
                <h3 className="for-mobile-view"> {serviceType} </h3>
                <div className="service-header-reating">
                  <img src="./assets/icons/review-icon.png" alt="icon" />
                  {/* <p> 4.8 (102k bookings) </p> */}
                  <RatingBookings />
                </div>
              </div>

              <div className="service-header-right">
                <div className="service-right-nav">
                  <p> Home </p>
                  <img src="./assets/icons/right-black-arrow.svg" alt="arrow" />
                  <h6> {serviceType} </h6>
                </div>
              </div>
            </div>

            <div className="dashboard-service-detail-wrapper">
              <div className="dashboard-service-detail-wrap">
                <div className="row">
                  <div className="col-md-12 col-lg-4">
                    <div className="address-detail-wrapper">
                      <AddressDetails />
                      <div className="service-select-wrapper">
                        <div className="service-select-wrap">
                          <div className="service-select-dropdown-wrap">
                            <h4> Select a service </h4>
                            <Dropdown
                              onTypeChange={handleDropdownChange("industry")}
                              label="Industry"
                              data={
                                serviceType == "Residential"
                                  ? industryData
                                  : commercialIndustryData
                              }
                              alreadyValue={dropdownValues?.industry}
                              checkIcon="./assets/icons/drowdown-check.svg"
                            />

                            {/* {dropdownValues?.industry.name &&
                              serviceType == "Commercial" && (
                                <Dropdown
                                  extraClass={errors.floors ? "error-msg" : ""}
                                  onTypeChange={handleDropdownChange("floors")}
                                  label="Select Category"
                                  data={commercialFloorsData}
                                  alreadyValue={dropdownValues?.floors}
                                  checkIcon="./assets/icons/drowdown-check.svg"
                                />
                              )} */}
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>

                  <div className="col-md-12 col-lg-8">
                    <div className="home-service-detail-main-wrapper">
                      {/* <div className="selected-service-title">
                      <h3> Speciality Services </h3>
                    </div> */}

                      <div className="home-service-detail-main-wrap">
                        <div className="selected-service-detail-wrapper">
                          <div className={"selected-service-detail-wrap"}>
                            {serviceType === "Commercial" ? (
                              <SpecialServiceDetail
                                specialServicesData={commercialServiceData}
                                selectedDate={formData?.scheduleDate}
                                handleDateChange={handleDateChange}
                                enableBookBtn={enableBookBtn}
                                goToBookingConfirmation={
                                  goToBookingConfirmation
                                }
                                addToCartHandler={addToCartHandler}
                                removeFromCartHandler={removeFromCartHandler}
                                errors={errors}
                                summerItemLength="2"
                              />
                            ) : (
                              serviceType === "Residential" && (
                                <SpecialServiceDetail
                                  specialServicesData={residentialServiceData}
                                  selectedDate={formData?.scheduleDate}
                                  handleDateChange={handleDateChange}
                                  enableBookBtn={enableBookBtn}
                                  goToBookingConfirmation={
                                    goToBookingConfirmation
                                  }
                                  addToCartHandler={addToCartHandler}
                                  removeFromCartHandler={removeFromCartHandler}
                                  errors={errors}
                                  summerItemLength="5"
                                />
                              )
                            )}

                            <div className="selected-service-detail-right">
                              <div className="service-items-main-wrapper">
                                <div className="empty-service-wrapper">
                                  <img
                                    src="./assets/empty-image.png"
                                    alt="img"
                                  />
                                  <p> Select your service First</p>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      ) : (
        <div className="mobile-selected-service-detail-wrap container">
          <div className="mobile-selected-service-detail-left">
            <div className="home-sequare-feet-wrap">
              <div className="mobile-service-back-wrap">
                <img
                  onClick={goToServices}
                  src="./assets/icons/previous-arrow.svg"
                  alt="icon"
                />
                <h3> Speciality Services </h3>
              </div>
            </div>
          </div>

          <div className="selected-service-detail-right">
            <div className="service-items-main-wrapper">
              <div className="service-items-main-wrap special-extra-service-item-wrap extra-service-item-wrap">
                <h5> Services </h5>
                <SpecialityItem
                  extraData={
                    serviceType === "Residential"
                      ? residentialServiceData
                      : commercialServiceData
                  }
                  summerItemLength={serviceType === "Residential" ? 4 : 2}
                  addItems={addToCartHandler}
                  removeItem={removeFromCartHandler}
                />
              </div>
            </div>

            <div className="schedule-main-wrapper specaility-schedule-wrap">
              <div className="schedule-main-wrap">
                <h5> Schedule </h5>

                <div className="date-and-time-wrapper">
                  <div className="date-input-wrap">
                    <DatePicker
                      minDate={new Date()}
                      maxDate={moment().add(30, "days").toDate()}
                      selected={formData?.scheduleDate}
                      onChange={(date) => handleDateChange(date)}
                      customInput={
                        <div className="custom-date-input">
                          <img
                            src="./assets/icons/date.svg"
                            alt="icon"
                            className="datepicker-icon"
                          />
                          <p>Date</p>
                          <input
                            className={errors?.date ? "error-msg" : ""}
                            type="text"
                            placeholder="Select date"
                            value={
                              formData?.scheduleDate
                                ? moment(formData?.scheduleDate).format(
                                    "DD MMM YYYY"
                                  )
                                : ""
                            }
                            readOnly
                          />
                        </div>
                      }
                    />
                  </div>

                  <TimeSlot disabled={!formData?.scheduleDate} />
                </div>
              </div>
              <div className="book-button-wrapper">
                <Button
                  extraClass={!enableBookBtn ? "disabled-button" : ""}
                  onClick={goToBookingConfirmation}
                  // isLoading={isLoading}
                  label="Book"
                />
              </div>
            </div>
          </div>
        </div>
      )}
    </>
  );
};

export default SpecialServices;

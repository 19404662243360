import React, { useEffect, useState } from "react";
import Button from "../../UIComponents/Button";
import { servicePost } from "../../../helper/api";
import { useNavigate } from "react-router-dom";
import { ShimmerText } from "react-shimmer-effects";

const ProceedChangeOrder = () => {
  const [linkExpired, setLinkExpired] = useState(false);
  const [orderData, setOrderData] = useState([]);
  const [shimmerLoading, setShimmerLoading] = useState(true);
  const navigate = useNavigate();

  console.log("orderData", orderData);

  // get Token By Url
  const userUuid = window.location.pathname;
  const userId = userUuid.split("/change-order/");
  const token = userId[1];
  console.log("token", token);

  // Check Change Order Expired.....
  useEffect(() => {
    const createdTime = new Date(orderData.createdAt).getTime();
    const currentTime = Date.now();
    const timeDifference = currentTime - createdTime;

    // 15 minutes in milliseconds
    const fifteenMinutesInMs = 15 * 60 * 1000;

    if (
      timeDifference >= fifteenMinutesInMs ||
      orderData?.workCompleteStatus == true ||
      orderData?.cancelStatus == true
    ) {
      // link Expired and Cancel order
      setLinkExpired(true);
    } else {
      // go next procced
      setLinkExpired(false);
    }
  }, [orderData]);

  // get Selected Booking Data
  const getOrderData = async () => {
    try {
      const orderDataRes = await servicePost("booking/getChangeOrderData", {
        token: token,
      });

      localStorage.setItem("token", orderDataRes?.token);
      // localStorage.setItem("changeReqtoken", orderDataRes?.token);
      setShimmerLoading(false);
      setOrderData(orderDataRes?.BookingDetail);
    } catch (error) {
      console.log("error", error);
    }
  };

  useEffect(() => {
    getOrderData();
  }, [token]);

  const goToChangeOrder = () => {
    if (orderData?.serviceType == "Residential") {
      if (orderData?.industry === "Specialty Cleaning") {
        navigate("/change-order-specialty", {
          state: {
            orderData: orderData,
          },
        });
      } else {
        navigate("/change-order-residentail", {
          state: {
            orderData: orderData,
          },
        });
      }
    }

    if (orderData?.serviceType == "Commercial") {
      if (orderData?.industry === "Specialty Cleaning") {
        navigate("/change-order-specialty", {
          state: {
            orderData: orderData,
          },
        });
      } else {
        navigate("/change-order-commercial", {
          state: {
            orderData: orderData,
          },
        });
      }
    }
  };

  const goToDashboard = () => {
    navigate("/login");
  };

  return (
    <>
      <div className="cleaning-link-main-wrapper">
        <div className="cleaning-order-complete-wrap container">
          <div className="employe-order-complete-img employee-order-cancel-img">
            <img src="../assets/icons/change-req-icon.png" alt="icon" />
          </div>

          {linkExpired ? (
            orderData?.workCompleteStatus == true ? (
              <div className="employee-change-req-content">
                <p>
                  Your session has expired, and your order has been completed.
                  The link is no longer valid. Please log in again to continue.
                </p>
              </div>
            ) : orderData?.cancelStatus == true ? (
              <div className="employee-change-req-content">
                <p>
                  Your session has expired, and your order has been canceled.
                  The link is no longer valid. Please log in again to proceed.
                </p>
              </div>
            ) : (
              <div className="employee-change-req-content">
                <p>
                  Your session has expired as the order was not completed within
                  15 minutes. The link is no longer valid. Please log in again
                  to continue.
                </p>
              </div>
            )
          ) : (
            <div className="employee-change-req-content">
              <p>
                You have 15 minutes to change this order. After 15 minutes, the
                order will be automatically canceled. Click the Proceed button
                to Change The Order.
              </p>
            </div>
          )}
          {linkExpired ? (
            <div className="change-order-proceed-btn">
              <Button
                disabled={shimmerLoading}
                label={shimmerLoading ? "Loading.." : "Continue"}
                onClick={goToDashboard}
              />
            </div>
          ) : (
            <div className="change-order-proceed-btn">
              <Button
                disabled={shimmerLoading}
                label={shimmerLoading ? "Loading.." : "Proceed"}
                onClick={goToChangeOrder}
              />
            </div>
          )}
        </div>
      </div>
    </>
  );
};

export default ProceedChangeOrder;

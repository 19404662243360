import React, { useState } from "react";
import Modal from "react-bootstrap/Modal";
import "./modal.css";
import Button from "../../components/UIComponents/Button";
import { useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { setOrderBookingId } from "../../redux/features/bookingSlice";

const OrderCompleteModal = (props) => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const goToLogin = () => {
    dispatch(setOrderBookingId(props?.orderData?.bookingId));
    navigate(`/tracking-order/${props?.orderData?.bookingId}`);
    // navigate("/tracking-order", {
    //   state: {
    //     bookingId: props?.orderData?.bookingId,
    //   },
    // });
  };
  return (
    <Modal
      {...props}
      size="lg"
      backdrop="static"
      dialogClassName="save-exit-modal-main-wrapper"
      aria-labelledby="contained-modal-title-vcenter"
      centered
    >
      <Modal.Body>
        <div className="confirm-modal-main-wrapper">
          <div className="order-book-modal-main-wrap">
            <div className="modal-logo-wrap">
              <img src="./assets/modal-logo.png" alt="modal-logo" />
            </div>

            {/* <div className="close-icon-wrap">
              <img
                onClick={() => props.onHide()}
                src="./assets/icons/close-icon.svg"
                alt="icon"
              />
            </div> */}

            <div className="order-book-image">
              <h2> Your order is book</h2>
              <img src="./assets/confirm-tick.png" alt="icon" />
              <h3> Complete </h3>
            </div>

            <div className="order-book-in-wrap">
              {/* <h4> Booking ID #{props?.orderData?.bookingId} </h4> */}
              {/* <p> {cartItems?.length + 3} Items</p> */}
              {/* <h4>
                {" "}
                OTP:{" "}
                <span className="change-order-otp">
                  {" "}
                  {props?.orderData?.otp}{" "}
                </span>{" "}
              </h4> */}
              <p> Booking ID #{props?.orderData?.bookingId} </p>
            </div>

            <div className="logout-modal-button">
              <Button label="Continue" onClick={goToLogin} />
            </div>
          </div>
        </div>
      </Modal.Body>
    </Modal>
  );
};
export default OrderCompleteModal;

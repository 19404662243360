import React, { useState, useEffect } from "react";
import moment from "moment";
import { servicePost } from "../../helper/api";
import { useSelector } from "react-redux";
import { isChatEnabled } from "../../helper/helper";

const ChatDrawer = ({ onHide, bookingId }) => {
  const [isLoading, setIsLoading] = useState(false);
  const [partnerAssigned, setPartnerAssigned] = useState(true);
  const [message, setMessage] = useState("");
  const [error, setError] = useState(false);
  const [data, setData] = useState();

  const bookingComplete = data?.workStartedStatus;
  const bookingCanceled = data?.cancelStatus == true;
  const bookingDone = bookingComplete || bookingCanceled;

  // get loggedInUser for Redux
  const logedInUser = useSelector((state) => state.user.userInfo);

  // get AuthToken from localStorage
  const authToken = localStorage.getItem("token");
  const notification = "";

  const chatInputHandler = (e) => {
    const { value } = e.target;
    setMessage(value);
    setError(false);
  };

  const [chatMessages, setChatMessages] = useState([]);

  // const [chatMessages, setChatMessages] = useState([
  //   {
  //     Message: "Hi, Customer Name",
  //     userId: "2",
  //     AccountId: 2,
  //     PostId: 3,
  //     MessageId: 1,
  //     date: "03 July, 2024",
  //   },
  //   {
  //     Message: "Hi, Cleaner",
  //     userId: "1",
  //     AccountId: 1,
  //     PostId: 3,
  //     MessageId: 1,
  //     date: "03 July, 2024",
  //   },
  //   // {
  //   //   userId: "2",
  //   //   Message: "I am Arrived your location",
  //   //   AccountId: 2,
  //   //   PostId: 3,
  //   //   MessageId: 1,
  //   //   date: "03 July, 2024",
  //   // },
  //   // {
  //   //   Message: "Hi, Customer Name",
  //   //   userId: "2",
  //   //   AccountId: 2,
  //   //   PostId: 3,
  //   //   MessageId: 1,
  //   //   date: "02 July, 2024",
  //   // },
  //   // {
  //   //   Message: "Hi, Cleaner",
  //   //   userId: "1",
  //   //   AccountId: 1,
  //   //   PostId: 3,
  //   //   MessageId: 1,
  //   //   date: "01 July, 2024",
  //   // },
  //   // {
  //   //   Message: "Hi, Sir",
  //   //   userId: "2",
  //   //   AccountId: 2,
  //   //   PostId: 3,
  //   //   MessageId: 1,
  //   //   date: "01 July, 2024",
  //   // },
  //   // {
  //   //   Message: "I am Comming Today 8 pm",
  //   //   userId: "2",
  //   //   AccountId: 2,
  //   //   PostId: 3,
  //   //   MessageId: 1,
  //   //   date: "01 July, 2024",
  //   // },
  // ]);

  const getDateLabel = (date) => {
    const today = moment().startOf("day");
    const messageDate = moment(date);
    const diff = today.diff(messageDate, "days");

    if (diff === 0) return "Today";
    if (diff === 1) return "Yesterday";
    return "Earlier";
  };

  const groupMessagesByDate = (messages) => {
    return messages.reduce((acc, message) => {
      const label = getDateLabel(message.createdAt);
      if (!acc[label]) {
        acc[label] = [];
      }
      acc[label].push(message);
      return acc;
    }, {});
  };

  const groupedMessages = groupMessagesByDate(chatMessages);
  const sendMessageHandler = async () => {
    if (message == "") {
      setError(true);
      return;
    }
    try {
      setIsLoading(true);
      const messageRes = await servicePost(
        "message/addMessageChatData",
        {
          message: message,
          userId: logedInUser?.id,
          bookingId: bookingId,
        },
        notification,
        authToken
      );

      if (messageRes.message == "SUCCESS") {
        setIsLoading(false);
        setMessage("");
        await getMessageData();
      }
    } catch (error) {
      console.log("error", error);
      setIsLoading(false);
    }
  };

  // Get Messages
  const getMessageData = async () => {
    try {
      const getMessageRes = await servicePost(
        "message/getMessageData",
        {
          bookingId: bookingId,
        },
        notification,
        authToken
      );

      console.log("getMessageRes", getMessageRes.BookingDetail);
      setChatMessages(getMessageRes?.BookingDetail);
    } catch (error) {
      console.log("error", error);
    }
  };

  useEffect(() => {
    getMessageData();
  }, [bookingId]);

  // Get Booking Detail Basis of BookingId
  const getOrderDetail = async () => {
    try {
      const orderDetailRes = await servicePost(
        "booking/getBookingData",
        {
          bookingId: bookingId.toString(),
        },
        notification,
        authToken
      );

      setData(orderDetailRes.BookingDetail);
    } catch (error) {
      console.log("error", error);
    }
  };

  useEffect(() => {
    getOrderDetail();
  }, [bookingId]);

  // Check if chat should be enabled
  const chatEnabled =
    data?.cleanerPartnerStatus &&
    isChatEnabled(data?.scheduleDate, data?.scheduleTime);

  console.log("chatEnabled", chatEnabled);

  return (
    <>
      <div className="notification-drawer-main-wrapper">
        <div className="notification-drawer-main-wrap chat-drawer-main-wrap">
          <div className="notification-drawer-header">
            <h3> Conversation </h3>
            <div className="drawer-close-icon-wrap">
              <img
                onClick={() => onHide()}
                src="../assets/icons/close-icon.svg"
                alt="icon"
              />
            </div>
          </div>

          {chatEnabled ? (
            <div className="replay-chat-box-wrapper">
              {chatMessages?.length == 0 ? (
                <div className="empty-conversation-wrap">
                  <img src="../assets/empty-chat.png" alt="icon" />
                  <h3> No Conversation</h3>
                  {!bookingDone ? (
                    <p>Chat with your cleaner partner here.</p>
                  ) : (
                    <p>No chats found</p>
                  )}
                </div>
              ) : (
                <div className="replay-chat-box-wrap">
                  <section className="msger">
                    <main className="msger-chat">
                      <div className="chat-messages-wrap">
                        {["Today", "Yesterday", "Earlier"].map(
                          (dateLabel) =>
                            groupedMessages[dateLabel] && (
                              <React.Fragment key={dateLabel}>
                                <div
                                  className="chat-date-divider"
                                  bis_skin_checked="1"
                                >
                                  <span>{dateLabel}</span>
                                </div>
                                {groupedMessages[dateLabel].map(
                                  (msg, index) => (
                                    <React.Fragment key={index}>
                                      <div
                                        className={`cleaner-partner ${
                                          msg.userId == logedInUser?.id
                                            ? "right"
                                            : "left"
                                        }`}
                                      >
                                        {/* {msg.userId == accountId
                                      ? "You"
                                      : "Cleaner"} */}
                                      </div>
                                      <div
                                        className={`msg ${
                                          msg.userId == logedInUser?.id
                                            ? "right-msg"
                                            : "left-msg"
                                        }`}
                                      >
                                        <div className="msg-bubble">
                                          <div className="msg-text">
                                            {msg.message}
                                          </div>
                                        </div>
                                      </div>
                                      <div
                                        className={`${
                                          msg.userId == logedInUser?.id
                                            ? "right"
                                            : "left"
                                        }-info-time`}
                                      >
                                        <p>{moment(msg.createdAt).fromNow()}</p>
                                      </div>
                                    </React.Fragment>
                                  )
                                )}
                              </React.Fragment>
                            )
                        )}
                      </div>
                    </main>
                  </section>
                </div>
              )}

              <div className="chat-send-message-wrapper">
                {!bookingDone && (
                  <div className="chat-send-message-wrap">
                    <input
                      className={
                        error ? "error-msg placeholder-error-text" : ""
                      }
                      onChange={chatInputHandler}
                      type="text"
                      value={message}
                      placeholder="Write Message here.."
                    />
                    <div className="chat-send-button">
                      <img
                        className={isLoading ? "disable-button" : ""}
                        onClick={!isLoading ? sendMessageHandler : ""}
                        src="../assets/icons/send-msg.png"
                        alt="icon"
                      />
                    </div>
                  </div>
                )}
              </div>
            </div>
          ) : (
            <div className="empty-conversation-wrapper">
              <div className="empty-conversation-wrap">
                <img src="../assets/empty-chat.png" alt="icon" />
                <h3> Not Assigned</h3>
                <p>
                  This funcation will be enabled 2 hour before your Schedule
                  sevice
                </p>
              </div>
            </div>
          )}
        </div>
      </div>
    </>
  );
};

export default ChatDrawer;

import React, { useState } from "react";
import Modal from "react-bootstrap/Modal";
import { persistor } from "../../redux/app/store";
import "./modal.css";
import { useDispatch } from "react-redux";
import { useNavigate } from "react-router-dom";

const LogoutModal = (props) => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const cancelLogout = () => {
    props.onHide();
  };

  // Stay On the Page
  const logoutHandler = () => {
    localStorage.clear();
    dispatch({ type: "RESET_STORE" });
    persistor.purge();
    props.onHide();
    navigate("/login");
  };

  return (
    <Modal
      {...props}
      size="lg"
      backdrop="static"
      dialogClassName="confirmation-modal-main-wrapper logout-modal-main-wrapper"
      aria-labelledby="contained-modal-title-vcenter"
      centered
    >
      <Modal.Body>
        <div className="confirm-modal-main-wrapper">
          <div className="confirm-modal-main-wrap">
            <div className="modal-logo-wrap">
              <img src="../assets/modal-logo.png" alt="modal-logo" />
            </div>

            {/* <div className="close-icon-wrap">
              <img
                onClick={stayOnThePage}
                src="./assets/icons/close-icon.svg"
                alt="icon"
              />
            </div> */}

            <div className="specaility-modal-content-wrap">
              <h3 style={{ textAlign: "center", marginBottom: "10px" }}>
                Logout ?
              </h3>
              <p>
                Are you sure you want to log out? You will need to log in again
                to access your account.
              </p>
              <div className="modal-leave-stay-btn-wrap">
                <div className="modal-leave-stay-btn">
                  <button onClick={logoutHandler} className="simple-button">
                    Logout
                  </button>
                  <button onClick={cancelLogout} className="add-to-cart-btn">
                    Cancel
                  </button>
                </div>
              </div>
            </div>
          </div>
        </div>
      </Modal.Body>
    </Modal>
  );
};
export default LogoutModal;

import React, { useState, useEffect } from "react";
import "./login.css";
import Button from "../UIComponents/Button";
import InputField from "../UIComponents/InputField";
import ConfirmationModal from "../modals/ConfirmationModal";
import { useLocation, useNavigate } from "react-router-dom";
import { servicePost } from "../../helper/api";
import validator from "validator";

const OtpVerification = () => {
  const [isLoading, setIsLoading] = useState(false);
  const [showConfirmationModal, setShowConfirmationModal] = useState(false);
  const [otp, setOtp] = useState("");
  const [errorMessage, setErrorMessage] = useState();
  const [passwordShown, setPasswordShown] = useState(false);
  const [confirmPasswordShown, setConfirmPasswordShown] = useState(false);
  const [otpVerified, setOtpVerified] = useState(false);
  const [otpExpired, setOtpExpired] = useState(false);

  const [modalType, setModalType] = useState();
  const [modalContant, setModalContant] = useState();

  // Set Forgot password data
  const [formData, setFormData] = useState({
    password: "",
    confirmPassword: "",
  });

  const { password, confirmPassword } = formData;
  const inputEventHandler = (e) => {
    const { name, value } = e.target;
    setFormData((preVal) => ({
      ...preVal,
      [name]: value,
    }));

    if (name == "password") {
      // Validate password
      if (
        !validator.isStrongPassword(value, {
          minLength: 8,
          minLowercase: 1,
          minUppercase: 1,
          minNumbers: 1,
          minSymbols: 1,
        })
      ) {
        setErrorMessage(
          "Password must contain: min 8 Characters, 1 uppercase & 1 lowercase letter, 1 number and 1 special character"
        );
        return;
      } else {
        setErrorMessage("");
      }
    }
  };

  const togglePassword = () => {
    setPasswordShown(!passwordShown);
  };

  const toggleConfirmPassword = () => {
    setConfirmPasswordShown(!confirmPasswordShown);
  };

  // Error Validation
  const [errors, setErrors] = useState({
    otp: "",
    password: "",
    confirmPassword: "",
  });

  const [confirmPin, setConfirmPin] = useState({
    confirmPin1: "",
    confirmPin2: "",
    confirmPin3: "",
    confirmPin4: "",
    confirmPin5: "",
    confirmPin6: "",
  });

  const location = useLocation();
  const email = location.state?.email;

  const pinChangeHandler = (e) => {
    const { name, value } = e.target;
    setConfirmPin((preVal) => ({
      ...preVal,
      [name]: value,
    }));
  };

  const checkPin = () => {
    const inputElements = [...document.querySelectorAll("input.code-input")];
    inputElements.forEach((ele, index) => {
      ele.addEventListener("keydown", (e) => {
        if (e.keyCode === 8 && e.target.value === "")
          inputElements[Math.max(0, index - 1)].focus();
      });

      ele.addEventListener("input", (e) => {
        const [first, ...rest] = e.target.value;
        e.target.value = first ?? "";
        const lastInputBox = index === inputElements.length - 1;
        const didInsertContent = first !== undefined;
        if (didInsertContent && !lastInputBox) {
          inputElements[index + 1].focus();
          inputElements[index + 1].value = rest.join("");
          inputElements[index + 1].dispatchEvent(new Event("input"));
        }

        const code = inputElements.map(({ value }) => value).join("");
        setOtp(code);
      });

      ele.addEventListener("paste", (e) => {
        e.preventDefault();
        const paste = e.clipboardData.getData("text");
        if (paste.length === 6 && !isNaN(paste)) {
          paste.split("").forEach((char, i) => {
            inputElements[i].value = char;
            inputElements[i].dispatchEvent(new Event("input"));
          });
        }
      });
    });
  };

  useEffect(() => {
    checkPin();
  }, []);

  const verifyOtpHandler = async () => {
    if (!otp || !otp.trim() || otp === undefined) {
      setErrors({ otp: "Otp is required" });
      setTimeout(() => {
        setErrors("");
      }, 5000);
      return;
    }
    try {
      setIsLoading(true);
      const signupUser = await servicePost(
        "auth/veriryOtp",
        {
          email: email.toLowerCase(),
          otp: otp,
        },
        {}
      );

      if (
        signupUser.message === "OTP Expired" ||
        signupUser.message === "Invalid OTP"
      ) {
        setIsLoading(false);
        setErrors({ otp: signupUser.message });
        setOtpExpired(signupUser.message);
        setTimeout(() => {
          setErrors("");
        }, 5000);
        return;
      }

      if (signupUser.message === "SUCCESS") {
        setIsLoading(false);
        setOtpVerified(true);
      }
    } catch (error) {
      setIsLoading(false);
      setErrorMessage(error.message);
      setTimeout(() => {
        setErrorMessage("");
      }, 5000);
    }
  };

  const navigate = useNavigate();

  const goToLogin = () => {
    navigate("/login");
  };

  const passwordChangeHandler = async () => {
    if (!password || !password.trim() || password === undefined) {
      setErrors({ password: "Password is required" });
      setTimeout(() => {
        setErrors("");
      }, 5000);
      return;
    }

    if (
      !confirmPassword ||
      !confirmPassword.trim() ||
      confirmPassword === undefined
    ) {
      setErrors({ confirmPassword: "Confirm password is required" });
      setTimeout(() => {
        setErrors("");
      }, 5000);
      return;
    }

    // Validate password
    if (
      !validator.isStrongPassword(password, {
        minLength: 8,
        minLowercase: 1,
        minUppercase: 1,
        minNumbers: 1,
        minSymbols: 1,
      })
    ) {
      setErrors((prevErrors) => ({
        ...prevErrors,
        password: "Enter Strong Password",
      }));
      return;
    } else {
      setErrors((prevErrors) => ({
        ...prevErrors,
        password: "",
      }));
    }

    if (password !== confirmPassword) {
      setErrors({
        confirmPassword: "Passwords do not match. Please try again.",
      });
      setTimeout(() => {
        setErrors("");
      }, 5000);
      return;
    }

    try {
      setIsLoading(true);
      const signupUser = await servicePost(
        "auth/resetpassword",
        {
          email: email.toLowerCase(),
          password: password,
        },
        {}
      );

      if (signupUser.message === "SUCCESS") {
        setIsLoading(false);
        setShowConfirmationModal(true);
        setModalType("Password");
        setModalContant("Your Password Successfully changed.");
        setTimeout(() => {
          setShowConfirmationModal(false);
          navigate("/login");
        }, 3000);
      }
    } catch (error) {
      setIsLoading(false);
      setErrorMessage(error.message);
      setTimeout(() => {
        setErrorMessage("");
      }, 5000);
    }
  };

  // Send Otp for Email Verification
  const sendOptResgiteredEmail = async () => {
    try {
      // setIsLoading(true);
      const signupUser = await servicePost(
        "auth/forgotpassword",
        {
          email: email.toLowerCase(),
        },
        {}
        //csrfTokenState
      );

      if (signupUser.message == "SUCCESS") {
        // setIsLoading(false);
        setShowConfirmationModal(true);
        setModalType("Forget Password");
        setModalContant("Reset Password otp send to your registered email");

        setTimeout(() => {
          setShowConfirmationModal(false);
        }, 3000);
      } else {
        // setIsLoading(false);
        setErrorMessage(signupUser.message);
        setTimeout(() => {
          setErrorMessage("");
        }, 5000);
      }
    } catch (error) {
      // setIsLoading(false);
      // console.log("error", error);
      setErrorMessage(error);
      setTimeout(() => {
        setErrorMessage("");
      }, 5000);
      return;
    }
  };

  return (
    <>
      <ConfirmationModal
        show={showConfirmationModal}
        onHide={() => setShowConfirmationModal(false)}
        type={modalType}
        content={modalContant}
        showCloseIcon={true}
      />

      {!otpVerified ? (
        <div className="login-main-wrapper">
          <div className="login-main-wrap container">
            <h3> OTP Verification </h3>
            <div className="forget-password-otp-wrap">
              <div className="pin-code password-otp-input">
                <input
                  className="code-input"
                  type="number"
                  maxLength="1"
                  name="confirmPin1"
                  onChange={pinChangeHandler}
                  autoFocus
                />
                <input
                  className="code-input"
                  type="number"
                  maxLength="1"
                  name="confirmPin2"
                  onChange={pinChangeHandler}
                />
                <input
                  className="code-input"
                  type="number"
                  maxLength="1"
                  name="confirmPin3"
                  onChange={pinChangeHandler}
                />
                <input
                  className="code-input"
                  type="number"
                  maxLength="1"
                  name="confirmPin4"
                  onChange={pinChangeHandler}
                />
                <input
                  className="code-input"
                  type="number"
                  maxLength="1"
                  name="confirmPin5"
                  onChange={pinChangeHandler}
                />
                <input
                  className="code-input"
                  type="number"
                  maxLength="1"
                  name="confirmPin6"
                  onChange={pinChangeHandler}
                />
              </div>
              <div className="otp-message-main-wrap">
                {errors?.otp ? (
                  <div className="error-msg">
                    <p> {errors?.otp} </p>
                  </div>
                ) : (
                  <p> OTP Sent to your email </p>
                )}
                <div className="resend-button">
                  {otpExpired == "OTP Expired" && (
                    <p onClick={sendOptResgiteredEmail}> Re-send </p>
                  )}
                  {/* {otpExpired == "OTP Expired" && <Button label="Re-send" />} */}
                </div>
              </div>
            </div>

            <div className="login-button">
              <Button
                isLoading={isLoading}
                label="Continue"
                onClick={verifyOtpHandler}
              />
            </div>
            {errorMessage ? (
              <div className="error-msg">
                <p> {errorMessage} </p>
              </div>
            ) : (
              <div style={{ justifyContent: "center" }} className="login-text">
                <p onClick={goToLogin}>
                  I already have an Account.
                  <span> Login Now </span>
                </p>
              </div>
            )}

            <div className="login-with-google-main-wrapper">
              <div className="full ordivider">
                <span>Or</span>
              </div>

              <div className="login-with-google-btn-wrap">
                <Button
                  extraClass="gray-btn"
                  label="Continue with Google"
                  icon="./assets/icons/google-icon.png"
                />
              </div>
              <div className="login-screen-description">
                <p>
                  By proceeding, you consent to receive calls and SMS messages,
                  including those sent by automated means, from YFOS and its
                  affiliates at the number provided.
                </p>
              </div>
            </div>
          </div>
        </div>
      ) : (
        <div className="login-main-wrapper">
          <div className="login-main-wrap container">
            <h3> Create new Password</h3>

            <div className="login-form-main-wrap">
              <div className="login-option">
                <form autoComplete="off">
                  <InputField
                    extraClass={errors?.password ? "error-msg" : ""}
                    type={passwordShown ? "text" : "password"}
                    name="password"
                    id="password"
                    label={errors?.password ? errors.password : "New Password"}
                    value={password}
                    passwordIcon={true}
                    togglePassword={togglePassword}
                    iconDisplaye={passwordShown}
                    onChange={inputEventHandler}
                  />

                  <InputField
                    extraClass={errors?.confirmPassword ? "error-msg" : ""}
                    type={confirmPasswordShown ? "text" : "password"}
                    name="confirmPassword"
                    id="confirmPassword"
                    label={
                      errors?.confirmPassword
                        ? errors.confirmPassword
                        : "Confirm Password"
                    }
                    value={confirmPassword}
                    passwordIcon={true}
                    togglePassword={toggleConfirmPassword}
                    iconDisplaye={confirmPasswordShown}
                    onChange={inputEventHandler}
                  />
                </form>
                <div className="login-button">
                  <Button
                    isLoading={isLoading}
                    onClick={passwordChangeHandler}
                    label="Save Password"
                  />
                </div>

                {errorMessage && (
                  <div className="error-msg">
                    <p> {errorMessage} </p>
                  </div>
                )}
                <div className="login-with-google-main-wrapper">
                  <div className="full ordivider">
                    <span>Or</span>
                  </div>

                  <div className="login-with-google-btn-wrap">
                    <Button
                      extraClass="gray-btn"
                      label="Continue with Google"
                      icon="./assets/icons/google-icon.png"
                    />
                  </div>
                  <div className="login-screen-description">
                    <p>
                      By proceeding, you consent to receive calls and SMS
                      messages, including those sent by automated means, from
                      YFOS and its affiliates at the number provided.
                    </p>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      )}
    </>
  );
};

export default OtpVerification;

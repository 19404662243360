import {
  BrowserRouter as Router,
  Navigate,
  Outlet,
  useLocation,
} from "react-router-dom";
import { isUserAuthenticated } from "./AuthUtils";
import { useSelector } from "react-redux";

// public routes accessable if user not authenticated
export const PublicRoute = ({
  restrictedToUnauth = false,
  redirectPath = "/",
}) => {
  if (restrictedToUnauth && isUserAuthenticated()) {
    return <Navigate to={redirectPath} replace />;
  }
  return <Outlet />;
};

// // ProtectedRoute routes accessable if user authenticated
// export const ProtectedRoute = ({ redirectPath = "/login" }) => {
//   if (!isUserAuthenticated()) {
//     localStorage.clear();
//     return <Navigate to={redirectPath} replace />;
//   }
//   return <Outlet />;
// };

export const ProtectedRoute = ({ defaultRedirectPath = "/" }) => {
  const logedInUser = useSelector((state) => state.user.userInfo);
  const location = useLocation();

  // If the user is not authenticated, clear localStorage and redirect to login
  if (!isUserAuthenticated()) {
    localStorage.clear();
    if (location.pathname.includes("/tracking-order/")) {
      localStorage.setItem("lastVisitedTrackingOrderUrl", location.pathname);
    }
    return <Navigate to="/login" replace />;
  }

  // If 2FA is enabled but the user hasn't completed 2FA verification, redirect to /enter-twofa
  if (
    (logedInUser?.enableOn === true || logedInUser?.enableOn === "true") &&
    !logedInUser?.twoFaVerified // Ensure that you store `twoFaVerified` status after successful 2FA
  ) {
    return <Navigate to="/enter-twofa" replace />;
  }

  // If 2FA is requested but not yet verified, keep the user on the /enter-twofa page
  if (
    (logedInUser?.enableOn === false ||
      logedInUser?.enableOn === "false" ||
      logedInUser?.enableOn === null) &&
    (logedInUser?.twoFaRequest === true ||
      logedInUser?.twoFaRequest === "true") &&
    !logedInUser?.twoFaVerified
  ) {
    return <Navigate to="/enter-twofa" replace />;
  }

  // If 2FA is verified or not required, allow access to all routes
  return <Outlet />;
};

import React, { useState, useEffect } from "react";
import "./twofa.css";
import TwofaProcessBar from "./TwofaProcessBar";
import Button from "../../UIComponents/Button";
import { useNavigate } from "react-router-dom";
import TwofaModal from "../../modals/TwofaModal";
import { servicePost } from "../../../helper/api";
import { useDispatch, useSelector } from "react-redux";
import { setUser, updateUserInfo } from "../../../redux/features/userSlice";
import { setNotificationDep } from "../../../redux/features/notificationDepSlice";
const TwofaCode = () => {
  const [isLoading, setIsLoading] = useState(false);
  const [activeStep, setActiveStep] = useState(3);
  const [inputValues, setInputValues] = useState("");
  const [showConfirmationModal, setShowConfirmationModal] = useState(false);

  const notificationDep = useSelector(
    (state) => state.notificationDep.notificationDep
  );

  const user = useSelector((state) => state.user.userInfo);
  const [error, setError] = useState(false);
  const [errorMessage, setErrorMessage] = useState();

  // get AuthToken from localStorage
  const authToken = localStorage.getItem("token");
  const notification = "";

  const navigate = useNavigate();
  const dispatch = useDispatch();

  const checkPin = () => {
    const inputElements = [...document.querySelectorAll("input.code-input")];
    inputElements.forEach((ele, index) => {
      ele.addEventListener("keydown", (e) => {
        if (e.keyCode === 8 && e.target.value === "") {
          inputElements[Math.max(0, index - 1)].focus();
        }
      });
      ele.addEventListener("input", (e) => {
        const [first, ...rest] = e.target.value;
        e.target.value = first ?? "";
        const lastInputBox = index === inputElements.length - 1;
        const didInsertContent = first !== undefined;
        if (didInsertContent && !lastInputBox) {
          inputElements[index + 1].focus();
          inputElements[index + 1].value = rest.join("");
          inputElements[index + 1].dispatchEvent(new Event("input"));
        }

        const code = inputElements.map(({ value }) => value).join("");
        setInputValues(code);
      });
      ele.addEventListener("paste", (e) => {
        e.preventDefault();
        const paste = e.clipboardData.getData("text").slice(0, 6);
        paste.split("").forEach((char, i) => {
          inputElements[i].value = char;
          inputElements[i].dispatchEvent(new Event("input"));
        });
      });
    });
  };

  useEffect(() => {
    checkPin();
  }, []);

  // Submit TwoFa Code and Verify
  const towfaEnableHandler = async () => {
    try {
      if (inputValues.length < 6) {
        setError(true);
        setErrorMessage("Fill all fields");
        setTimeout(() => {
          setErrorMessage("");
          setError(false);
        }, 5000);
        return;
      }

      setIsLoading(true);
      const submitTwaFa = await servicePost(
        "2fa/twofactorverified",
        {
          user: user?.email,
          token: inputValues,
        },
        notification,
        authToken
      );

      if (submitTwaFa.data === true) {
        dispatch(
          setUser({
            userInfo: {
              ...user,
              twoFaVerified: true,
            },
            token: user?.token,
          })
        );
        dispatch(setNotificationDep(notificationDep + 1));
        dispatch(updateUserInfo({ enableOn: true }));
        setShowConfirmationModal(true);
        // localStorage.setItem("isTwofaEnable", true);
        setIsLoading(false);
        setTimeout(() => {
          navigate("/profile");
          setShowConfirmationModal(false);
        }, 3000);
      } else {
        setIsLoading(false);
        setError(true);
        setErrorMessage("Authentication code expired.");
        setTimeout(() => {
          setErrorMessage("");
          setError(false);
        }, 5000);
      }
    } catch (error) {
      setIsLoading(false);
      setError(true);
      setErrorMessage("Invalid Code. Please try again");
      setTimeout(() => {
        setErrorMessage("");
        setError(false);
      }, 5000);
    }
  };

  const goBack = () => {
    navigate(-1);
  };

  return (
    <>
      <TwofaModal
        show={showConfirmationModal}
        onHide={() => setShowConfirmationModal(false)}
        content="Two-Factor Authentication successfully completed"
        showCloseIcon={true}
      />

      <div className="profile-twofa-main-wrapper">
        <TwofaProcessBar activeStep={activeStep} />

        <div className="profile-twofa-main-wrap">
          <div className="twofa-inner-wrapper">
            <div className="twofa-head-main-wrap">
              <img
                onClick={goBack}
                src="./assets/icons/back-arrow.png"
                alt="icon"
              />
              <h3> 6 Digit Code </h3>
            </div>
            <div className="twfa-scan-text">
              <p> Enter the 6-digit Google Authenticator code </p>
            </div>

            <div className="authentication-code-main-wrap">
              <p> Authentication Code </p>
              <div className="pin-code password-otp-input">
                <input
                  className="code-input"
                  type="number"
                  maxLength="1"
                  name="twoFaCode1"
                  autoFocus
                />
                <input
                  className="code-input"
                  type="number"
                  maxLength="1"
                  name="twoFaCode2"
                />
                <input
                  className="code-input"
                  type="number"
                  maxLength="1"
                  name="twoFaCode3"
                />
                <input
                  className="code-input"
                  type="number"
                  maxLength="1"
                  name="twoFaCode4"
                />
                <input
                  className="code-input"
                  type="number"
                  maxLength="1"
                  name="twoFaCode5"
                />
                <input
                  className="code-input"
                  type="number"
                  maxLength="1"
                  name="twoFaCode6"
                />
              </div>
            </div>
            {error ? (
              <div className="error-msg">
                <p> {errorMessage} </p>
              </div>
            ) : (
              ""
            )}
          </div>
          <div className="twofa-footer-wrapper">
            <div className="twofa-footer-wrap">
              <p>
                Enter the 6-digit code from Google Authenticator to complete
                your 2FA setup.
              </p>

              <Button
                isLoading={isLoading}
                onClick={towfaEnableHandler}
                label="Confirm"
              />
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default TwofaCode;

import React, { useEffect, useState } from "react";
import validator from "validator";
import { useNavigate } from "react-router-dom";
import Button from "../UIComponents/Button";
import InputField from "../UIComponents/InputField";
import { useAuth0 } from "@auth0/auth0-react";
import { servicePost } from "../../helper/api";
import ReCAPTCHA from "react-google-recaptcha";
import "./login.css";
import { useDispatch } from "react-redux";
import { setUser } from "../../redux/features/userSlice";
import axios from "axios";

const Login = () => {
  const [isLoading, setIsLoading] = useState(false);
  const [passwordShown, setPasswordShown] = useState(false);
  const [passwordShownicon, setPasswordShownicon] = useState(false);
  const [errorMessage, setErrorMessage] = useState();

  // verify recaptcha functionality
  const [isVerifyCaptcha, setIsVerifyCaptcha] = useState(false);

  const [ip, setIP] = useState();
  const [browserInfo, setBrowserInfo] = useState();

  useEffect(() => {
    // Setting the browser information to the state
    setBrowserInfo(navigator.userAgent);
  }, []);

  const getData = async () => {
    try {
      const res = await axios.get("https://ip-api.io/json");
      setIP(res.data.ip);
    } catch (error) {
      console.log("Error", error);
    }
  };

  useEffect(() => {
    getData();
  }, []);

  const togglePassword = () => {
    setPasswordShown(!passwordShown);
    setPasswordShownicon(!passwordShownicon);
  };

  const [formData, setFormData] = useState({
    email: "",
    password: "",
  });

  const { password, email } = formData;

  // Error Validation
  const [errors, setErrors] = useState({
    email: "",
    password: "",
  });

  const errorMessages = {
    email: "Email is required",
    password: "Password is required",
    invalidEmail: "Please enter a valid email",
    invalidPassword: "Please enter a valid password",
  };

  // get All InputValues
  const inputEventHandler = (event) => {
    const { name, value } = event.target;
    setFormData((oldValue) => {
      return {
        ...oldValue,
        [name]: value,
      };
    });
    setErrors({ ...errors, [name]: "" });
  };

  const navigate = useNavigate();
  const dispatch = useDispatch();

  const goToForgetPassword = () => {
    navigate("/forgot-password");
  };

  const goToSignup = () => {
    navigate("/sign-up");
  };

  // const loginReqHandler = () => {
  //   setIsLoading(true);
  //   localStorage.setItem("isUserAuthenticate", true);
  //   setTimeout(() => {
  //     setIsLoading(false);
  //     navigate("/");
  //   }, 5000);
  // };

  const lastVisitedTrackingOrderUrl = localStorage.getItem(
    "lastVisitedTrackingOrderUrl"
  );

  // Login Request Handler
  const loginReqHandler = async () => {
    try {
      const requiredFields = ["email", "password"];
      const newErrors = {};

      requiredFields.forEach((field) => {
        if (!formData[field] || !formData[field].trim()) {
          newErrors[field] = errorMessages[field];
        }
      });

      if (Object.keys(newErrors).length > 0) {
        setErrors((prevErrors) => ({ ...prevErrors, ...newErrors }));
        return;
      }

      // Validate email
      if (!validator.isEmail(email)) {
        setErrors((prevErrors) => ({
          ...prevErrors,
          email: errorMessages.invalidEmail,
        }));
        return;
      } else {
        setErrors((prevErrors) => ({
          ...prevErrors,
          email: "",
        }));
      }

      if (!isVerifyCaptcha) {
        setErrorMessage("Please check verify captcha.");
        setTimeout(() => {
          setErrorMessage("");
        }, 5000);
        return;
      }

      setIsLoading(true);

      const loginUser = await servicePost(
        "auth/login",
        {
          email: email.toLowerCase(),
          password: password,
          ipAddress: ip,
          browser: browserInfo,
        },
        {}
        //csrfTokenState
      );

      if (loginUser.message == "SUCCESS") {
        setIsLoading(false);
        localStorage.setItem("user", JSON.stringify(loginUser?.data));
        localStorage.setItem("token", loginUser?.token);

        dispatch(
          setUser({
            userInfo: loginUser?.data,
            token: loginUser?.token,
          })
        );

        if (
          loginUser?.data.enableOn == true ||
          loginUser?.data.enableOn == "true"
        ) {
          navigate("/enter-twofa");
        } else if (
          (loginUser?.data.enableOn == false ||
            loginUser?.data.enableOn == "false" ||
            loginUser?.data.enableOn == null) &&
          (loginUser?.data.twoFaRequest == true ||
            loginUser?.data.twoFaRequest == "true")
        ) {
          navigate("/enter-twofa");
        } else if (lastVisitedTrackingOrderUrl) {
          navigate(lastVisitedTrackingOrderUrl);
        } else {
          navigate("/");
        }
      }
    } catch (error) {
      console.log("err11", error);
      setIsLoading(false);
      // console.log("error", error.response.data.message);
      //setReqLoader(false);
      setErrorMessage(error.response?.data?.message);
      setTimeout(() => {
        setErrorMessage("");
      }, 5000);
      return;
    }
  };

  const { loginWithRedirect } = useAuth0();

  const verifyRecaptchaHandler = async (value) => {
    try {
      if (value === null) {
        setIsVerifyCaptcha(false);
      }
      const verifyToken = await servicePost(
        "auth/verifyRecaptcha",
        {
          token: value,
        },
        {}
      );

      console.log("verifyToken", verifyToken);
      if (verifyToken.message == "SUCCESS") {
        setIsVerifyCaptcha(true);
        // setButtonDes(true);
      }
    } catch (error) {
      console.log("error111", error);
      setIsVerifyCaptcha(false);
    }
  };

  return (
    <>
      <div className="login-main-wrapper">
        <div className="login-main-wrap container">
          <h3> Welcome to YFOS </h3>

          <div className="login-form-main-wrap">
            <div className="login-option">
              <form autoComplete="off">
                {/* Hidden input to trick autofill */}
                <input
                  type="text"
                  style={{ display: "none" }}
                  autoComplete="username"
                />
                <input
                  type="password"
                  style={{ display: "none" }}
                  autoComplete="new-password"
                />

                <InputField
                  extraClass={
                    errors?.email ? `error-msg` : email ? "active" : ""
                  }
                  type="text"
                  name="email"
                  id="email"
                  label={errors?.email ? errors.email : "Enter Your email*"}
                  passwordIcon={false}
                  onChange={inputEventHandler}
                  value={email}
                  className={email ? "active" : ""}
                  autoComplete="off"
                />

                <InputField
                  extraClass={errors?.password ? `error-msg` : ""}
                  type={passwordShown ? "text" : "password"}
                  name="password"
                  id="password"
                  label={
                    errors?.password ? errors.password : "Enter your Password*"
                  }
                  passwordIcon={true}
                  togglePassword={togglePassword}
                  iconDisplaye={passwordShownicon}
                  onChange={inputEventHandler}
                  value={password}
                  className={password ? "active" : ""}
                  autoComplete="new-password"
                />
              </form>

              <div className="login-button">
                <ReCAPTCHA
                  sitekey="6LdduYMjAAAAANl41zEe-rIU6sjjjIv4WDQNo57K"
                  onChange={verifyRecaptchaHandler}
                />
                <Button
                  onClick={loginReqHandler}
                  isLoading={isLoading}
                  label="Continue"
                />
              </div>

              {errorMessage ? (
                <div className="error-msg">
                  <p> {errorMessage} </p>
                </div>
              ) : (
                <div className="login-text">
                  <p onClick={goToSignup}>
                    I don't have an Account.
                    <span> Sign Up Now </span>
                  </p>
                  <p>
                    <span onClick={goToForgetPassword} className="forget-a">
                      Forgot Password?
                    </span>
                  </p>
                </div>
              )}

              <div className="login-with-google-main-wrapper">
                <div className="full ordivider">
                  <span>Or</span>
                </div>

                <div className="login-with-google-btn-wrap">
                  <Button
                    // onClick={() => loginUser()}
                    extraClass="gray-btn"
                    label="Continue with Google"
                    icon="./assets/icons/google-icon.png"
                  />
                </div>
                <div className="login-screen-description">
                  <p>
                    By proceeding, you consent to receive calls and SMS
                    messages, including those sent by automated means, from YFOS
                    and its affiliates at the number provided.
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default Login;

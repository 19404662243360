import React, { useEffect, useState } from "react";
import "./services.css";
import InputField from "../UIComponents/InputField";
import RangeSlider from "../UIComponents/RangeSlider";
import CardItem from "../cardItem/CardItem";
import ExtraItems from "../cardItem/ExtraItems";
import Button from "../UIComponents/Button";
import OverlayTrigger from "react-bootstrap/OverlayTrigger";
import Tooltip from "react-bootstrap/Tooltip";
import { availibleServiceCites } from "../dummydata/DummyData";

import { useDispatch } from "react-redux";
import {
  addToCart,
  removeToCart,
  updateFlooringPercentage,
  updateMoppingPercentage,
  updateDustingPercentage,
  updateSqureFeetPercentage,
} from "../../redux/features/cartSlice";
import { resetSpecificDropdown } from "../../redux/features/dropdownSlice";
import { updateFormData } from "../../redux/features/formSlice";
import { useMediaQuery } from "react-responsive";
import { useNavigate } from "react-router-dom";
import { useSelector } from "react-redux";
import PercentageGuideModal from "../modals/PercentageGuideModal";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import moment from "moment";
import TimePicker from "./components/TimePicker";
import AddressDetails from "./components/AddressDetails";
import SpecialityModal from "../modals/SpecialityModal";
import { setError, clearErrors } from "../../redux/features/errorSlice";
import CommercialDropdwons from "./components/CommercialDropdwons";
import { servicePost } from "../../helper/api";
import CommercialCardItem from "../cardItem/CommercialCardItem";
import RatingBookings from "./components/RatingBookings";
import TimeSlot from "./components/TimeSlot";
import { updateFlooringPrices } from "../../redux/features/flooringPriceSlice";
import ServiceAvailibleModal from "../modals/ServiceAvailibleModal";
import SessionExpireModal from "../modals/SessionExpireModal";
import SqRangeSlider from "../UIComponents/SqRangeSlider";

const ServicesCommercial = () => {
  const [isSessionExpiring, setIsSessionExpiring] = useState(false);
  const [showAvailibleModal, setShowAvailibleModal] = useState(false);
  const errors = useSelector((state) => state.formErrors);
  const [enableBookBtn, setEnableBookBtn] = useState(false);
  const [enableContinueBtn, setEnableContinueBtn] = useState(false);

  const [redirectPath, setRedirectPath] = useState();
  const [showSpecialityModal, setShowSpecialityModal] = useState(false);
  const [serviceCategoryData, setServiceCategoryData] = useState([]);
  const [serviceKitchenData, setServiceKitchenData] = useState([]);
  const [extrasData, setExtrasData] = useState([]);

  // Define media queries
  const isLaptop = useMediaQuery({ minWidth: 1200, maxWidth: 1599 });
  const smallLaptop = useMediaQuery({ minWidth: 1024, maxWidth: 1199 });

  // Determine the number of static and slice items based on media queries
  const maxStaticItems = isLaptop ? 3 : smallLaptop ? 2 : 4;
  const serviceSecondRow = serviceCategoryData?.slice(maxStaticItems) || [];

  const [selectedDate, setSelectedDate] = useState(null);
  const isMobile = useMediaQuery({ maxWidth: 1024 });
  const cartItems = useSelector((state) => state.allCart.carts);
  const percentageData = useSelector((state) => state.allCart);
  const dropdownValues = useSelector((state) => state.allDowpdown);

  const [showPercentageGuideModal, setShowPercentageGuideModal] =
    useState(false);

  const [floorinPercentage, setFlooringPercentage] = useState(
    percentageData.flooringPercentage
  );

  const [moppingPercentage, setMoppingPercentage] = useState(
    percentageData.moppingPercentage
  );
  const [dustingPercentage, setDustingPercentage] = useState(
    percentageData.dustingPercentage
  );

  const [squreFeetPercentage, setSqureFeetPercentage] = useState(
    percentageData.squreFeetPercentage
  );

  const formData = useSelector((state) => state.formData);

  // get AuthToken from localStorage
  const authToken = localStorage.getItem("token");
  const notification = "";

  const handleInputChange = (event) => {
    const { name, value } = event.target;
    if (value == "") {
      dispatch(setError({ field: name, value: true }));
    } else {
      dispatch(setError({ field: name, value: false }));
    }
    dispatch(updateFormData({ field: name, value }));
  };

  /// Add to cart
  const dispatch = useDispatch();
  const addToCartHandler = (data) => {
    dispatch(addToCart(data));
  };

  const removeFromCartHandler = (data) => {
    dispatch(removeToCart(data));
  };

  // handle Flooring Pecsentage Change
  function handleFlooringPercentage(event) {
    setFlooringPercentage(parseInt(event.target.value));
    setMoppingPercentage(parseInt(100 - event.target.value));
    dispatch(updateFlooringPercentage(parseInt(event.target.value)));
    dispatch(updateMoppingPercentage(parseInt(100 - event.target.value)));
  }

  // handle Dusting Percentage Change
  function handleDustingPercentage(event) {
    setDustingPercentage(parseInt(event.target.value));
    dispatch(updateDustingPercentage(parseInt(event.target.value)));
  }

  // handle SqureFeet Percentage Change
  function handleSqureFeetPercentage(event) {
    setSqureFeetPercentage(parseInt(event.target.value));
    dispatch(updateSqureFeetPercentage(parseInt(event.target.value)));
  }

  // Update PersentageData When update Some thing
  useEffect(() => {
    setFlooringPercentage(percentageData.flooringPercentage);
    setMoppingPercentage(percentageData.moppingPercentage);
    setDustingPercentage(percentageData.dustingPercentage);
    setSqureFeetPercentage(percentageData.squreFeetPercentage);
  }, [percentageData]);

  const navigate = useNavigate();

  const getSqFeetPrice = async () => {
    try {
      const sqFeetPriceRes = await servicePost(
        "booking/getSqFeetData",
        {
          vaccum: floorinPercentage,
          mopping: moppingPercentage,
          dusting: dustingPercentage,
          squarefeet: squreFeetPercentage,
          serviceType: "Commercial",
        },
        notification,
        authToken
      );

      dispatch(updateFlooringPrices(sqFeetPriceRes.data[0]));
    } catch (error) {
      console.log("error", error);
    }
  };

  // go to Booking confirmation
  const goToBookingConfirmation = () => {
    if (!formData?.address) {
      dispatch(setError({ field: "address", value: true }));
      return;
    }

    // if (!formData?.addressline2) {
    //   dispatch(setError({ field: "addressline2", value: true }));
    //   return;
    // }

    if (!formData?.postalcode) {
      dispatch(setError({ field: "postalcode", value: true }));
      return;
    }

    // if (!dropdownValues?.city?.name) {
    //   dispatch(setError({ field: "city", value: true }));
    //   return;
    // }

    // if (!formData?.squareFeet) {
    //   dispatch(setError({ field: "squareFeet", value: true }));
    //   return;
    // }

    if (!formData?.scheduleDate) {
      dispatch(setError({ field: "date", value: true }));
      return;
    }

    if (!formData?.scheduleTime) {
      dispatch(setError({ field: "time", value: true }));
      return;
    }

    getSqFeetPrice();

    // Check City Valid or not
    const isValidCity = availibleServiceCites.some((city) =>
      formData?.address.toLowerCase().includes(city.name.toLowerCase())
    );

    if (!isValidCity) {
      setShowAvailibleModal(true);
      return;
    }

    navigate("/booking-confirmation");
    dispatch(updateFlooringPercentage(floorinPercentage));
    dispatch(updateMoppingPercentage(moppingPercentage));
    dispatch(updateDustingPercentage(dustingPercentage));
    dispatch(updateSqureFeetPercentage(squreFeetPercentage));
  };

  const goToMobileServices = () => {
    if (!formData?.address) {
      dispatch(setError({ field: "address", value: true }));
      return;
    }

    // if (!formData?.addressline2) {
    //   dispatch(setError({ field: "addressline2", value: true }));
    //   return;
    // }

    // if (!dropdownValues?.city?.name) {
    //   dispatch(setError({ field: "city", value: true }));
    //   return;
    // }

    if (!formData?.postalcode) {
      dispatch(setError({ field: "postalcode", value: true }));
      return;
    }

    if (!dropdownValues?.industry?.name) {
      dispatch(setError({ field: "industry", value: true }));
      return;
    }

    if (!dropdownValues?.floors?.name) {
      dispatch(setError({ field: "floors", value: true }));
      return;
    }

    // if (!dropdownValues?.bedrooms) {
    //   dispatch(setError({ field: "bedrooms", value: true }));
    //   return;
    // }

    // Check City Valid or not
    const isValidCity = availibleServiceCites.some((city) =>
      formData?.address.toLowerCase().includes(city.name.toLowerCase())
    );

    if (!isValidCity) {
      setShowAvailibleModal(true);
      return;
    }

    // setShowPercentageGuideModal(true);
    navigate("/commercial-services-mobile");
  };

  // HandleDate Change
  const handleDateChange = (date) => {
    setSelectedDate(date);
    dispatch(updateFormData({ field: "scheduleDate", value: date }));
    dispatch(updateFormData({ field: "scheduleTime", value: "" }));
    if (date) {
      dispatch(setError({ field: "date", value: false }));
    }
  };

  useEffect(() => {
    if (
      formData?.addressComplete &&
      // formData?.squareFeet &&
      formData?.scheduleDate &&
      formData?.scheduleTime
    ) {
      setEnableBookBtn(true);
    } else {
      setEnableBookBtn(false);
    }

    if (
      formData?.addressComplete &&
      dropdownValues?.industry?.name &&
      dropdownValues?.floors &&
      dropdownValues?.bedrooms
    ) {
      setEnableContinueBtn(true);
    } else {
      setEnableContinueBtn(false);
    }
  }, [formData, formData?.scheduleDate, dropdownValues]);

  // if dropdownvalues Specialty Cleaning go to Speciality
  useEffect(() => {
    if (
      dropdownValues?.industry?.name === "Specialty Cleaning" &&
      cartItems?.length > 0
    ) {
      navigate("/special-services");
    }

    if (
      dropdownValues?.industry?.name === "Specialty Cleaning" &&
      cartItems?.length == 0
    ) {
      dispatch(resetSpecificDropdown(["industry", "floors", "bedrooms"]));
    }
  }, []);

  // Get service Category and Additional Service Data
  const getResidentialData = async () => {
    try {
      const commercialDataRes = await servicePost(
        "commercial/getCommercialData",
        {
          industry: dropdownValues?.industry?.name,
          // industry: "Office",
        },
        notification,
        authToken
      );

      const responseData = commercialDataRes.data;
      setServiceCategoryData(responseData.CommercialServiceCategories);
      setServiceKitchenData(responseData.CommercialKitchen);
      setExtrasData(responseData.CommercialAddtiionalServicies);
    } catch (error) {
      console.log("error", error);
    }
  };

  useEffect(() => {
    getResidentialData();
  }, [dropdownValues?.industry?.name]);

  // Sechudal Time Exprie Section
  const sessionDuration = 15 * 60 * 1000;
  useEffect(() => {
    let expirationTimeout;

    const checkSessionExpiration = () => {
      if (formData.sessionStartTime) {
        const timeElapsed = Date.now() - formData.sessionStartTime;
        if (timeElapsed >= sessionDuration) {
          // Session has expired
          setIsSessionExpiring(true);
          dispatch(updateFormData({ field: "sessionStartTime", value: null }));
          dispatch(updateFormData({ field: "scheduleDate", value: "" }));
          dispatch(updateFormData({ field: "scheduleTime", value: "" }));
        } else {
          // Session is still valid, set a timeout for the remaining time
          const remainingTime = sessionDuration - timeElapsed;
          expirationTimeout = setTimeout(() => {
            setIsSessionExpiring(true);
            dispatch(
              updateFormData({ field: "sessionStartTime", value: null })
            );
            dispatch(updateFormData({ field: "scheduleDate", value: "" }));
            dispatch(updateFormData({ field: "scheduleTime", value: "" }));
          }, remainingTime);
        }
      }
    };

    // Check for session expiration on component mount
    checkSessionExpiration();

    return () => {
      clearTimeout(expirationTimeout);
    };
  }, [formData.sessionStartTime, dispatch]);

  return (
    <>
      <SpecialityModal
        show={showSpecialityModal}
        onHide={() => setShowSpecialityModal(false)}
        redirectPath={redirectPath}
      />

      <ServiceAvailibleModal
        show={showAvailibleModal}
        onHide={() => setShowAvailibleModal(false)}
        showCloseIcon={true}
      />

      <SessionExpireModal
        show={isSessionExpiring}
        onHide={() => setIsSessionExpiring(false)}
        showCloseIcon={true}
      />

      <PercentageGuideModal
        show={showPercentageGuideModal}
        onHide={() => setShowPercentageGuideModal(false)}
        // icon="./assets/confirm-tick.png"
        type="Forget Password"
        content="Reset Password otp send to your registered email"
      />
      <div className="dashboard-services-main-wrapper">
        <div className="dashboard-services-main-wrap container">
          <div className="service-header-wrap">
            <div className="service-header-left">
              <h3 className="for-desktop-view"> Commercial Cleaning </h3>
              <h3 className="for-mobile-view"> Commercial </h3>
              <div className="service-header-reating">
                <img src="./assets/icons/review-icon.png" alt="icon" />
                {/* <p> 4.8 (102k bookings) </p> */}
                <RatingBookings />
              </div>
            </div>

            <div className="service-header-right">
              <div className="service-right-nav">
                <p> Home </p>
                <img src="./assets/icons/right-black-arrow.svg" alt="arrow" />
                <h6> Commercial </h6>
              </div>
            </div>
          </div>

          <div className="dashboard-service-detail-wrapper">
            <div className="dashboard-service-detail-wrap">
              <div className="row">
                <div className="col-md-12 col-lg-4">
                  <div className="address-detail-wrapper">
                    <AddressDetails />
                    <div className="service-select-wrapper">
                      <div className="service-select-wrap">
                        <CommercialDropdwons />
                      </div>
                    </div>
                    {isMobile && (
                      <div className="mobile-continue-button">
                        <Button
                          extraClass={
                            !enableContinueBtn ? "disabled-button" : ""
                          }
                          onClick={goToMobileServices}
                          label="Continue"
                        />
                      </div>
                    )}
                  </div>
                </div>

                <div className="col-md-12 col-lg-8">
                  {!isMobile && (
                    <div className="home-service-detail-main-wrapper">
                      {/* {dropdownValues?.industry?.name && (
                        <div className="selected-service-title">
                          <h3> {dropdownValues?.industry?.name} </h3>
                        </div>
                      )} */}

                      <div className="home-service-detail-main-wrap">
                        {!dropdownValues?.floors?.name ? (
                          <div className="empty-service-wrapper">
                            <img src="./assets/empty-image.png" alt="img" />
                            {formData?.addressComplete ? (
                              <p> Select your service Now</p>
                            ) : (
                              <p> Fill in your address details</p>
                            )}
                          </div>
                        ) : (
                          <div className="selected-service-detail-wrapper">
                            <div className="selected-service-detail-wrap">
                              <div className="selected-service-detail-left">
                                <div className="home-sequare-feet-wrap1">
                                  <div className="cleaning-persentage-info">
                                    <h4> Select your Service </h4>
                                    <OverlayTrigger
                                      placement="right"
                                      delay={{ show: 250, hide: 400 }}
                                      overlay={
                                        <Tooltip id={`tooltip-right`}>
                                          Can you specify the percentage of
                                          cleaning needed? For example, what
                                          percentage of your bedroom space
                                          requires vacuuming? Please provide an
                                          estimate in percentage. (Slider Left
                                          right to adjust the %)
                                        </Tooltip>
                                      }
                                    >
                                      <img
                                        src="./assets/icons/info.svg"
                                        alt="info-icon"
                                      />
                                    </OverlayTrigger>
                                  </div>
                                  {/* <h4> Enter Your Home Square Feet</h4> */}
                                  {/* <div className="login-option sequirefeet-input-wrap">
                                    <form>
                                      <InputField
                                        extraClass={
                                          errors?.squareFeet
                                            ? `squirefeet-input error-msg`
                                            : "squirefeet-input"
                                        }
                                        type="number"
                                        onChange={handleInputChange}
                                        value={formData?.squareFeet}
                                        name="squareFeet"
                                        id="squareFeet"
                                        label="Enter Square Feet of your Building"
                                        passwordIcon={false}
                                        // placeholder="1000 Sq"
                                      />
                                      <p className="sq-placeholder"> Sq </p>
                                    </form>
                                    <div className="squrefeet-input-img">
                                      <img
                                        src="./assets/squire-feet.png"
                                        alt="img"
                                      />
                                    </div>
                                  </div> */}
                                  <div className="dusting-slider-wrap squrefeet-slider-wrap">
                                    <SqRangeSlider
                                      percentage={squreFeetPercentage}
                                      onChange={handleSqureFeetPercentage}
                                      step="250"
                                      min="1000"
                                      max="50000"
                                      leftIcon="./assets/squire-feet.png"
                                      // extraClass="dusting-slider"
                                      leftName="Select Sq of Property"
                                      extraClass="flooring-slider"
                                    />
                                  </div>
                                </div>

                                <div className="home-cleaning-persentage-warpper">
                                  <div className="home-cleaning-persentage-warp">
                                    {/* <div className="cleaning-persentage-info">
                                      <h4> Select your Service </h4>
                                      <OverlayTrigger
                                        placement="right"
                                        delay={{ show: 250, hide: 400 }}
                                        overlay={
                                          <Tooltip id={`tooltip-right`}>
                                            Can you specify the percentage of
                                            cleaning needed? For example, what
                                            percentage of your bedroom space
                                            requires vacuuming? Please provide
                                            an estimate in percentage. (Slider
                                            Left right to adjust the %)
                                          </Tooltip>
                                        }
                                      >
                                        <img
                                          src="./assets/icons/info.svg"
                                          alt="info-icon"
                                        />
                                      </OverlayTrigger>
                                    </div> */}
                                  </div>
                                  <div className="flooring-slider-wrap">
                                    <div className="flooring-slider-info-wrap">
                                      <h5 className="flooring-text">
                                        Flooring
                                      </h5>
                                      <OverlayTrigger
                                        placement="top"
                                        delay={{ show: 250, hide: 400 }}
                                        overlay={
                                          <Tooltip id={`tooltip-top`}>
                                            Slide from left to right to set your
                                            vacuum and mopping percentages.
                                          </Tooltip>
                                        }
                                      >
                                        <img
                                          src="./assets/icons/info.svg"
                                          alt="info-icon"
                                        />
                                      </OverlayTrigger>
                                    </div>
                                    <RangeSlider
                                      percentage={floorinPercentage}
                                      moppingPercentage={moppingPercentage}
                                      onChange={handleFlooringPercentage}
                                      leftIcon="./assets/icons/vacuum-icon.png"
                                      rightIcon="./assets/icons/mopping-icon.png"
                                      leftName="Vaccum Cleaning"
                                      rightName="Mopping or sweeping"
                                      extraClass="flooring-slider"
                                    />
                                  </div>

                                  <div className="dusting-slider-wrap">
                                    <div className="flooring-slider-info-wrap">
                                      <h5 className="dusting-text">Dusting</h5>
                                      <OverlayTrigger
                                        placement="top"
                                        delay={{ show: 250, hide: 400 }}
                                        overlay={
                                          <Tooltip id={`tooltip-top`}>
                                            Slide from left to right to set your
                                            Dusting percentages.
                                          </Tooltip>
                                        }
                                      >
                                        <img
                                          src="./assets/icons/info.svg"
                                          alt="info-icon"
                                        />
                                      </OverlayTrigger>
                                    </div>
                                    <RangeSlider
                                      percentage={dustingPercentage}
                                      onChange={handleDustingPercentage}
                                      leftIcon="./assets/dusting-slider.png"
                                      extraClass="dusting-slider"
                                    />
                                  </div>

                                  <div className="service-items-main-wrapper">
                                    <div className="service-items-main-wrap">
                                      <h5> Washroom </h5>
                                      {/* <CommercialCardItem
                                        data={serviceCategoryData}
                                        addItems={addToCartHandler}
                                        removeItem={removeFromCartHandler}
                                      /> */}
                                      <CardItem
                                        data={serviceCategoryData}
                                        addItems={addToCartHandler}
                                        removeItem={removeFromCartHandler}
                                      />
                                    </div>
                                  </div>

                                  <div className="service-items-main-wrapper">
                                    <div className="service-items-main-wrap">
                                      <h5> Other Area </h5>
                                      <CardItem
                                        // data={serviceSecondRow}
                                        data={serviceKitchenData}
                                        addItems={addToCartHandler}
                                        removeItem={removeFromCartHandler}
                                      />
                                    </div>
                                  </div>
                                </div>
                              </div>

                              <div className="selected-service-detail-right">
                                <div className="service-items-main-wrapper">
                                  <div className="service-items-main-wrap extra-service-item-wrap">
                                    <h5> Additional Service </h5>
                                    <ExtraItems
                                      summerItemLength="3"
                                      extraData={extrasData}
                                      addItems={addToCartHandler}
                                      removeItem={removeFromCartHandler}
                                    />
                                  </div>
                                </div>

                                <div className="schedule-main-wrapper">
                                  <div className="schedule-main-wrap">
                                    <h5> Schedule </h5>

                                    <div className="date-and-time-wrapper">
                                      <div className="date-input-wrap">
                                        <DatePicker
                                          minDate={new Date()}
                                          maxDate={moment()
                                            .add(30, "days")
                                            .toDate()}
                                          selected={formData?.scheduleDate}
                                          onChange={(date) =>
                                            handleDateChange(date)
                                          }
                                          customInput={
                                            <div className="custom-date-input">
                                              <img
                                                src="./assets/icons/date.svg"
                                                alt="icon"
                                                className="datepicker-icon"
                                              />
                                              <p>Date</p>
                                              <input
                                                className={
                                                  errors?.date
                                                    ? "error-msg"
                                                    : ""
                                                }
                                                type="text"
                                                placeholder="Select date"
                                                value={
                                                  formData?.scheduleDate
                                                    ? moment(
                                                        formData?.scheduleDate
                                                      ).format("DD MMM YYYY")
                                                    : ""
                                                }
                                                readOnly
                                              />
                                            </div>
                                          }
                                        />
                                      </div>

                                      <TimeSlot
                                        disabled={!formData?.scheduleDate}
                                        // data={timeSlots}
                                      />
                                    </div>
                                  </div>
                                  <div className="book-button-wrapper">
                                    <Button
                                      extraClass={
                                        !enableBookBtn ? "disabled-button" : ""
                                      }
                                      // disabled={true}
                                      onClick={goToBookingConfirmation}
                                      // isLoading={isLoading}
                                      label="Book"
                                    />
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                        )}
                      </div>
                    </div>
                  )}
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default ServicesCommercial;

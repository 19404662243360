import React, { useEffect, useState } from "react";
import "./dashboard.css";
import RoundButton from "../UIComponents/RoundButton";
import Offcanvas from "react-bootstrap/Offcanvas";
import HistoryDrawer from "../drawers/HistoryDrawer";
import { HistoryData } from "../dummydata/DummyData";
import { useNavigate } from "react-router-dom";
import { resetSpecificDropdown } from "../../redux/features/dropdownSlice";
import { resetSpecificFormData } from "../../redux/features/formSlice";
import {
  emptyCartItem,
  setServiceTypeForSq,
} from "../../redux/features/cartSlice";
import { useDispatch, useSelector } from "react-redux";
import { setServiceType } from "../../redux/features/serviceTypeSlice";
import SpecialityModal from "../modals/SpecialityModal";
import { servicePost } from "../../helper/api";
import { clearFlooringPrices } from "../../redux/features/flooringPriceSlice";
import {
  ShimmerFeaturedGallery,
  ShimmerThumbnail,
} from "react-shimmer-effects";
import "./Shimmer.css";

const Dashboard = () => {
  const carts = useSelector((state) => state.allCart.carts);
  const [redirectPath, setRedirectPath] = useState();
  const serviceType = useSelector((state) => state.serviceType.serviceType);
  const [showSpecialityModal, setShowSpecialityModal] = useState(false);
  const [shimmerLoading, setShimmerLoading] = useState(true);

  //
  const [totalBookings, setTotalBookings] = useState();
  const [upcomingBookings, setUpcomingBookings] = useState();
  const [paymentHistory, setPaymentHistory] = useState();
  const [outstandingBills, setOutStandingBills] = useState();

  const [selectedData, setSelectedData] = useState();

  // get loggedInUser for Redux
  const logedInUser = useSelector((state) => state.user.userInfo);

  // get AuthToken from localStorage
  const authToken = localStorage.getItem("token");
  const notification = "";

  const [drawerTitle, setDrawerTitle] = useState("");
  const [showDrawer, setShowDrawer] = useState(false);
  const handleClose = () => setShowDrawer(false);
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const handleShow = (title, data) => {
    setDrawerTitle(title);
    setSelectedData(data);
    setShowDrawer(true);
  };

  // Handle Service Change
  const handleServiceTypeClick = (type) => {
    if (type === serviceType) {
      dispatch(setServiceType(type));
      dispatch(setServiceTypeForSq(type));

      if (type === "Commercial") {
        navigate("/services-commercial");
      } else {
        navigate("/services");
      }
    } else if (type !== serviceType && carts?.length === 0) {
      dispatch(setServiceType(type));
      dispatch(setServiceTypeForSq(type));
      if (type === "Commercial") {
        dispatch(resetSpecificDropdown(["industry", "floors", "bedrooms"]));
        dispatch(resetSpecificFormData(["squareFeet", "otpAccess", "paywith"]));
        dispatch(clearFlooringPrices());
        dispatch(emptyCartItem());
        navigate("/services-commercial");
      } else {
        dispatch(resetSpecificDropdown(["industry", "floors", "bedrooms"]));
        dispatch(resetSpecificFormData(["squareFeet", "otpAccess", "paywith"]));
        dispatch(clearFlooringPrices());
        dispatch(emptyCartItem());
        navigate("/services");
      }
    } else {
      setRedirectPath(
        type === "Commercial" ? "/services-commercial" : "/services"
      );
      setShowSpecialityModal(true);
    }
  };

  // Helper function to compare dates
  const isUpcomingBooking = (scheduleDate) => {
    const currentDate = new Date();
    const bookingDate = new Date(scheduleDate);
    return bookingDate > currentDate;
  };

  // Get Payment History data
  const getBookingsData = async () => {
    try {
      const paymentHistoryRes = await servicePost(
        "booking/getAllBookings",
        {
          accountId: logedInUser?.id,
        },
        notification,
        authToken
      );

      setShimmerLoading(false);

      // Check if price not null that order showing only
      const filteredReqest = paymentHistoryRes?.data.filter(
        (booking) => booking?.total !== null
      );

      // Filter upcoming bookings based on the scheduleDate
      const upcomingBookings = filteredReqest.filter(
        (booking) =>
          isUpcomingBooking(booking.scheduleDate) &&
          !booking?.workCompleteStatus &&
          !booking?.cancelStatus
      );

      // Filter paymentHistory where userPaymentId is not null/empty and bookingStatus is "Completed"
      const paymentHistory = filteredReqest.filter(
        (booking) =>
          booking?.userPaymentId &&
          booking.bookingStatus === "Completed" &&
          booking?.workCompleteStatus
      );

      // Filter outstandingBills where userPaymentId is null/empty and bookingStatus is "Completed"
      const outstandingBills = filteredReqest.filter(
        (booking) =>
          (!booking?.userPaymentId || booking?.userPaymentId === "") &&
          booking.bookingStatus === "Completed" &&
          booking?.workCompleteStatus
      );

      setUpcomingBookings(upcomingBookings);
      setTotalBookings(filteredReqest);
      setPaymentHistory(paymentHistory);
      setOutStandingBills(outstandingBills);
    } catch (error) {
      console.log("error", error);
    }
  };

  useEffect(() => {
    getBookingsData();
  }, [logedInUser?.id]);

  return (
    <>
      <SpecialityModal
        show={showSpecialityModal}
        onHide={() => setShowSpecialityModal(false)}
        redirectPath={redirectPath}
        type={
          redirectPath?.includes("commercial") ? "Commercial" : "Residential"
        }
        clearIndustryData={true}
      />
      <Offcanvas
        className="history-offcanvas-wrapper"
        show={showDrawer}
        onHide={handleClose}
        placement="end"
      >
        <Offcanvas.Body>
          <HistoryDrawer
            data={selectedData}
            onHide={handleClose}
            title={drawerTitle}
          />
        </Offcanvas.Body>
      </Offcanvas>
      <div className="dashboard-main-wrapper">
        <div className="dashboard-main-wrap">
          <div className="row align-items-center container">
            <div className="col-md-12 col-lg-7 dashbord-full-width">
              <div className="dashboard-service-wrapper">
                <h3> What exactly are you searching for? </h3>
                <div className="dashboard-service-wrap">
                  <div className="dashboard-commercial-wrap">
                    <div className="dashboard-commercial-image-wrap">
                      <img
                        onClick={() => handleServiceTypeClick("Commercial")}
                        src="./assets/commercial.png"
                        alt="img"
                      />
                    </div>
                    <RoundButton
                      label="Commercial"
                      onClick={() => handleServiceTypeClick("Commercial")}
                    />
                  </div>
                  <div className="dashboard-commercial-wrap">
                    <div className="dashboard-commercial-image-wrap">
                      <img
                        onClick={() => handleServiceTypeClick("Residential")}
                        src="./assets/residentail.png"
                        alt="img"
                      />
                    </div>
                    <RoundButton
                      label="Residential"
                      onClick={() => handleServiceTypeClick("Residential")}
                    />
                  </div>
                </div>
              </div>

              <div className="dashboard-history-main-wrapper">
                <div className="dashboard-history-main-wrap">
                  {shimmerLoading ? (
                    <>
                      <ShimmerThumbnail rounded />
                      <ShimmerThumbnail rounded />
                      <ShimmerThumbnail rounded />
                      <ShimmerThumbnail rounded />
                    </>
                  ) : (
                    <>
                      <div
                        className="dashboard-history-repeat"
                        onClick={() =>
                          handleShow("Total Booking", totalBookings)
                        }
                      >
                        <div className="dashbord-history-icon-wrap">
                          <img
                            src="./assets/icons/booking-icon-1.png"
                            alt="icon"
                          />
                        </div>
                        <div className="dashboard-history-contant">
                          <p> Total Booking </p>
                          <h4>
                            {totalBookings?.length < 10 && "0"}
                            {totalBookings?.length}{" "}
                          </h4>
                        </div>
                      </div>
                      <div
                        className="dashboard-history-repeat"
                        onClick={() =>
                          handleShow("Outstanding Bills", outstandingBills)
                        }
                      >
                        <div className="dashbord-history-icon-wrap">
                          <img
                            src="./assets/icons/booking-icon-2.png"
                            alt="icon"
                          />
                        </div>
                        <div className="dashboard-history-contant">
                          <p> Outstanding Bills </p>
                          <h4>
                            {outstandingBills?.length < 10 && "0"}
                            {outstandingBills?.length}
                          </h4>
                        </div>
                      </div>
                      <div
                        className="dashboard-history-repeat upcoming-active"
                        onClick={() =>
                          handleShow("Upcoming Booking", upcomingBookings)
                        }
                      >
                        <div className="dashbord-history-icon-wrap">
                          <img
                            src="./assets/icons/upcoming-booking.png"
                            alt="icon"
                          />
                        </div>
                        <div className="dashboard-history-contant">
                          <p> Upcoming Booking </p>
                          <h4>
                            {upcomingBookings?.length < 10 && "0"}
                            {upcomingBookings?.length}{" "}
                          </h4>
                        </div>
                      </div>
                      <div
                        className="dashboard-history-repeat"
                        onClick={() =>
                          handleShow("Payment History", paymentHistory)
                        }
                      >
                        <div className="dashbord-history-icon-wrap">
                          <img
                            src="./assets/icons/booking-icon-4.png"
                            alt="icon"
                          />
                        </div>
                        <div className="dashboard-history-contant">
                          <p> Payment History </p>
                          <h4>
                            {paymentHistory?.length < 10 && "0"}
                            {paymentHistory?.length}
                          </h4>
                        </div>
                      </div>
                    </>
                  )}
                </div>
              </div>
            </div>
            <div className="col-md-12 col-lg-5 dashboard-right-wrap">
              <div className="dashbord-right-image-wrapper">
                <img src="./assets/dashboard-image.png" alt="img" />
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default Dashboard;

import React, { useEffect, useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import { servicePost } from "../../helper/api";
import Button from "../UIComponents/Button";
import RoundButton from "../UIComponents/RoundButton";
import { useDispatch, useSelector } from "react-redux";
import {
  selectChangeOrderSubTotal,
  selectSubTotal,
  selectTotalTime,
} from "../../redux/features/selectors";
import { resetSpecificFormData } from "../../redux/features/formSlice";
import { resetSpecificDropdown } from "../../redux/features/dropdownSlice";
import { emptyCartItem } from "../../redux/features/cartSlice";
import { resetCoupon } from "../../redux/features/couponSlice";
import { setOrderBookingId } from "../../redux/features/bookingSlice";
import { setNotificationDep } from "../../redux/features/notificationDepSlice";
import OrderCompleteModal from "../modals/OrderCompleteModal";
import { clearFlooringPrices } from "../../redux/features/flooringPriceSlice";

const Success = () => {
  const [paymentId, setPaymentId] = useState();
  const location = useLocation();
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const [showConfirmModal, setShowComfirmModal] = useState(false);

  // get AuthToken from localStorage
  const authToken = localStorage.getItem("token");
  const notification = "";

  // get loggedInUser for Redux
  const logedInUser = useSelector((state) => state.user.userInfo);
  const couponData = useSelector((state) => state.coupon);
  const cartItems = useSelector((state) => state.allCart.carts);
  const percentageData = useSelector((state) => state.allCart);
  const dropdownValues = useSelector((state) => state.allDowpdown);
  const formData = useSelector((state) => state.formData);
  const serviceType = useSelector((state) => state.serviceType.serviceType);
  const otpAccess = formData.otpAccess == "send-otp" ? true : false;
  const notificationDep = useSelector(
    (state) => state.notificationDep.notificationDep
  );

  const flooringPrices = useSelector(
    (state) => state.flooringPrice.flooringPrice
  );

  const orderDataRedux = useSelector((state) => state.orderChange.orderData);

  // Get totalTime cartItem time + flooring + dusting time
  const totalTimeSelector = selectTotalTime();
  const { totalTime } = useSelector(totalTimeSelector);
  console.log("totalTime", totalTime);

  // Get subTotal cartItem price + flooring + dusting Price
  const subtotalSelector = selectSubTotal(couponData?.couponDiscountPercentage);

  const subtotalOrderChageSelector = selectChangeOrderSubTotal(
    couponData?.couponDiscountPercentage,
    orderDataRedux
  );

  const selectedSubtotalSelector = orderDataRedux
    ? subtotalOrderChageSelector
    : subtotalSelector;

  const {
    subTotal,
    discountAmount,
    cartItemsPrice,
    totalAmount,
    platformFeeAmount,
    totalAmountNoYfosFee,
    subTotalnoIndustryCharge,
    taxAmount,
    yfosServiceFees,
  } = useSelector(selectedSubtotalSelector);

  const FeesAndTexAmount = platformFeeAmount + taxAmount;
  // YFOS Service Fees
  // const yfosServiceFess =
  //   Number(totalAmountNoYfosFee.toFixed(0)) < 200 ? 200 : 0;

  // Get the subChargePercentage from the dropdownValues
  const subChargePercentage = dropdownValues?.industry?.subChargePercentage;

  const industrySubChargeAmount = subChargePercentage
    ? (subTotalnoIndustryCharge * parseFloat(subChargePercentage)) / 100
    : 0;

  // Get the subChargePercentage from the dropdownValues
  const postConstractionSubChargePercentage =
    dropdownValues?.bedrooms?.subChargePercentage;
  const postConstractionSubChargeAmount = postConstractionSubChargePercentage
    ? (subTotalnoIndustryCharge *
        parseFloat(postConstractionSubChargePercentage)) /
      100
    : 0;

  const industrySubCharge =
    dropdownValues?.industry?.name == "Post Construction"
      ? postConstractionSubChargeAmount
      : industrySubChargeAmount;

  const currentDate = new Date();
  const bookingDate = currentDate.toLocaleDateString();
  const bookingTime = currentDate.toLocaleTimeString();

  useEffect(() => {
    const query = new URLSearchParams(location.search);
    const sessionId = query.get("session_id");
    console.log("sessionId", sessionId);

    if (orderDataRedux) {
      // Change Order Request handle Case

      if (sessionId) {
        // Fetch the checkout session from Stripe using the session ID
        const fetchOrderCheckoutSession = async () => {
          try {
            const bookingResponse = await servicePost(
              "booking/updateChangeBookingData",
              {
                paymentIntent: null,
                sessionId: sessionId,
                userPaymentId: "",
                accountId: orderDataRedux?.accountId,
                couponCode: couponData?.couponCode
                  ? couponData?.couponCode
                  : "",
                serviceType: orderDataRedux?.serviceType,
                industry: orderDataRedux?.industry,
                floors: orderDataRedux?.floors,
                bedrooms: orderDataRedux?.bedrooms,
                bookingId: orderDataRedux?.bookingId,
                squireFeet:
                  orderDataRedux?.industry !== "Specialty Cleaning"
                    ? percentageData?.squreFeetPercentage
                    : null,
                contactPersonName: formData?.contactname,
                contactPersonNumber: formData?.contactnumber,
                vacummCleaning: percentageData.flooringPercentage,
                moppingCleaning: percentageData.moppingPercentage,
                vacummCleaningPrice: flooringPrices?.vaccumprice.toFixed(2),
                moppingCleaningPrice: flooringPrices?.moppingprice.toFixed(2),
                dusting: percentageData.dustingPercentage,
                dustingPrice: flooringPrices?.dustingprice.toFixed(2),
                recurring: "",
                address: orderDataRedux?.address,
                city: orderDataRedux?.city,
                postalCode: orderDataRedux?.postalCode,
                scheduleDate: orderDataRedux?.scheduleDate,
                scheduleTime: orderDataRedux?.scheduleTime,
                paymentMethod: orderDataRedux?.paymentMethod,
                price: cartItemsPrice,
                discount: discountAmount.toFixed(2),
                subTotal: subTotal.toFixed(2),
                fees: FeesAndTexAmount,
                total: totalAmount.toFixed(2),
                companyAddress: "Company Address",
                bookingStatus: "Processing",
                bookingTime: bookingTime,
                bookingDate: bookingDate,
                cleanerPartnerStatus: orderDataRedux?.cleanerPartnerStatus,
                assignedTime: "",
                assignedDate: "",
                arrivedStatus: orderDataRedux?.arrivedStatus,
                arrivedTime: orderDataRedux?.arrivedTime,
                arrivedDate: orderDataRedux?.arrivedDate,
                otp: orderDataRedux?.otp,
                otpAccess: true,
                cleanigServiceStatus: "",
                reviewReating: "",
                reviewText: "",
                cartItems: cartItems,
                totalTime: totalTime,
                industrySurcharge: orderDataRedux?.industrySurcharge,
                floorSurcharge: orderDataRedux?.floorSurcharge,
                yfosServiceCharge: yfosServiceFees,
                addressLine2: orderDataRedux?.addressline2,
              },
              "Order Updated",
              authToken
            );

            if (bookingResponse.message === "SUCCESS") {
              dispatch(emptyCartItem());
              dispatch(resetCoupon());
              dispatch(
                resetSpecificFormData([
                  "squareFeet",
                  "scheduleDate",
                  "scheduleTime",
                  "sessionStartTime",
                  "contactname",
                  "contactnumber",
                  "otpAccess",
                  "paywith",
                ])
              );
              dispatch(clearFlooringPrices());

              dispatch(
                resetSpecificDropdown(["industry", "floors", "bedrooms"])
              );
              dispatch(setOrderBookingId(orderDataRedux?.bookingId));
              dispatch(setNotificationDep(notificationDep + 1));
              setPaymentId(orderDataRedux?.bookingId);
              setShowComfirmModal(true);
            }

            // Process the checkout session data as needed
          } catch (error) {
            console.error("Error fetching checkout session:", error);
          }
        };

        fetchOrderCheckoutSession();
      }
    } else {
      // Create New Order handle Case
      if (sessionId) {
        // Fetch the checkout session from Stripe using the session ID
        const fetchCheckoutSession = async () => {
          try {
            const response = await servicePost(
              `booking/addBookingData`,
              {
                paymentIntent: null,
                sessionId: sessionId,
                userPaymentId: "",
                accountId: logedInUser?.id,
                couponCode: couponData?.couponCode
                  ? couponData?.couponCode
                  : "",
                serviceType: serviceType,
                industry: dropdownValues?.industry?.name,
                floors: dropdownValues?.floors?.name,
                bedrooms: dropdownValues?.bedrooms?.name,
                bookingId: "",
                squireFeet:
                  dropdownValues?.industry?.name !== "Specialty Cleaning"
                    ? percentageData?.squreFeetPercentage
                    : null,
                contactPersonName: formData?.contactname,
                contactPersonNumber: formData?.contactnumber,
                vacummCleaning: percentageData.flooringPercentage,
                moppingCleaning: percentageData.moppingPercentage,
                vacummCleaningPrice: flooringPrices?.vaccumprice.toFixed(2),
                moppingCleaningPrice: flooringPrices?.moppingprice.toFixed(2),
                dusting: percentageData.dustingPercentage,
                dustingPrice: flooringPrices?.dustingprice.toFixed(2),
                recurring: "",
                address: formData?.address,
                city: dropdownValues?.city?.name,
                postalCode: formData?.postalcode,
                scheduleDate: formData?.scheduleDate,
                scheduleTime: formData?.scheduleTime,
                paymentMethod: "Credit Card",
                price: cartItemsPrice,
                discount: discountAmount.toFixed(2),
                subTotal: subTotal.toFixed(2),
                fees: FeesAndTexAmount,
                total: totalAmount.toFixed(2),
                companyAddress: "Company Address",
                bookingStatus: "Processing",
                bookingTime: bookingTime,
                bookingDate: bookingDate,
                cleanerPartnerStatus: "",
                assignedTime: "",
                assignedDate: "",
                arrivedStatus: "",
                arrivedTime: "",
                arrivedDate: "",
                otp: "",
                otpAccess: otpAccess,
                cleanigServiceStatus: "",
                reviewReating: "",
                reviewText: "",
                cartItems: cartItems,
                totalTime: totalTime,
                industrySurcharge: industrySubCharge,
                floorSurcharge: dropdownValues?.floors?.floorCost,
                yfosServiceCharge: yfosServiceFees,
                addressLine2: formData?.addressline2,
              },
              "Order Created",
              authToken
            );

            const paymentId = response.data;

            if (response.data) {
              dispatch(emptyCartItem());
              dispatch(resetCoupon());
              dispatch(
                resetSpecificFormData([
                  "squareFeet",
                  "scheduleDate",
                  "scheduleTime",
                  "sessionStartTime",
                  "contactname",
                  "contactnumber",
                  "otpAccess",
                  "paywith",
                ])
              );

              dispatch(clearFlooringPrices());

              dispatch(
                resetSpecificDropdown(["industry", "floors", "bedrooms"])
              );
              dispatch(setOrderBookingId(paymentId));
              dispatch(setNotificationDep(notificationDep + 1));
              setPaymentId(paymentId);

              navigate(`/tracking-order/${paymentId}`);
              // navigate("/tracking-order", {
              //   state: {
              //     bookingId: paymentId,
              //   },
              // });
            }

            // Process the checkout session data as needed
          } catch (error) {
            console.error("Error fetching checkout session:", error);
          }
        };

        fetchCheckoutSession();
      }
    }
  }, [location.search]);

  const goToTrackOrder = () => {
    if (paymentId) {
      dispatch(setOrderBookingId(paymentId));
      navigate(`/tracking-order/${paymentId}`);
      // navigate("/tracking-order", {
      //   state: {
      //     bookingId: paymentId,
      //   },
      // });
    } else {
      window.location.reload();
    }
  };

  return (
    <>
      <OrderCompleteModal
        orderData={orderDataRedux}
        show={showConfirmModal}
        onHide={() => setShowComfirmModal(false)}
      />

      <div className="success-page-wrapper">
        <div className="success-page-wrap">
          <h3>Thank You Cleaning With Us!</h3>

          <p className="confirm-payment-msg">Your payment was successful.</p>
          <div className="success-track-order-btn">
            <RoundButton onClick={goToTrackOrder} label="Track Order" />
          </div>
        </div>
      </div>
    </>
  );
};

export default Success;

import React, { useState, useEffect } from "react";
import Dropdown from "../dropdown/Dropdown";
import Button from "../UIComponents/Button";
import { useDispatch, useSelector } from "react-redux";
import { updateExtraCart } from "../../redux/features/cartSlice";
import InputField from "../UIComponents/InputField";
import { setError } from "../../redux/features/errorSlice";
import { updateTotalPriceAndTime } from "../../redux/features/extraItemPriceSlice";
import OverlayTrigger from "react-bootstrap/OverlayTrigger";
import Tooltip from "react-bootstrap/Tooltip";

const AdditionalDrawer = ({ onHide, data, edit }) => {
  const [enableButton, setEnableButton] = useState(false);
  const [totalPrice, setTotalPrice] = useState(null);
  const [totalTime, setTotalTime] = useState(null);
  const [selectedCategory, setSelectedCategory] = useState(
    edit ? data.selectedDropdown : {}
  );

  const [errorMessage, setErrorMessage] = useState("Enter Square Feet");

  const [dropdownData, setDropdownData] = useState([]);
  const [isFlooring, setIsFlooring] = useState(edit ? data?.isFlooring : true);
  const [additionItemSq, setAdditionItemSq] = useState(
    edit ? data.additionItemSq : ""
  );
  const [dropdownErrors, setDropdownErrors] = useState({});
  const [dropdownEnabledState, setDropdownEnabledState] = useState([]);
  const errors = useSelector((state) => state.formErrors);

  // Set initial enabled state for dropdowns when dropdownData changes
  useEffect(() => {
    if (dropdownData.length > 0) {
      const initialEnabledState = dropdownData.map((_, index) =>
        index === 0 ? true : false
      );
      setDropdownEnabledState(initialEnabledState);
    }
  }, [dropdownData]);

  useEffect(() => {
    if (edit) {
      if (data?.data) {
        const activeDropdownData = isFlooring
          ? data?.data?.dropDowns
          : data?.data?.tilesDropdDown;
        setDropdownData(activeDropdownData || []);
      }
    } else {
      if (data) {
        const activeDropdownData = isFlooring
          ? data.dropDowns
          : data.tilesDropdDown;
        setDropdownData(activeDropdownData || []);
      }
    }
  }, [data, isFlooring]);

  useEffect(() => {
    const allDropdownsFilled = dropdownData.every((dropdown) => {
      if (dropdown.type.toLowerCase().includes("optional")) {
        return true; // Skip this check, treat it as filled
      }
      return selectedCategory[dropdown.type];
    });
    setEnableButton(
      Boolean(
        (data?.squireFeet || data?.additionItemSq ? additionItemSq : true) &&
          allDropdownsFilled
      )
    );
  }, [additionItemSq, selectedCategory, dropdownData]);

  const dispatch = useDispatch();

  const addToCartHandler = () => {
    let hasError = false;

    if (
      data?.squireFeet || data?.additionItemSq ? additionItemSq === "" : false
    ) {
      dispatch(setError({ field: "additionItemSq", value: true }));
      setErrorMessage("Enter Square Feet");
      hasError = true;
    } else if (
      data?.squireFeet || data?.additionItemSq
        ? additionItemSq === "" ||
          additionItemSq < 100 ||
          additionItemSq > 10000
        : false
    ) {
      dispatch(
        setError({
          field: "additionItemSq",
          value: true,
        })
      );
      setErrorMessage("Enter a value between 100 and 10000.");
      hasError = true;
    } else {
      dispatch(setError({ field: "additionItemSq", value: false }));
    }

    if (hasError) {
      return;
    }

    for (let dropdown of dropdownData) {
      // Skip dropdowns that include "optional" in their type
      if (dropdown.type.toLowerCase().includes("optional")) {
        continue; // Skip this dropdown and move to the next one
      }
      if (!selectedCategory[dropdown.type]) {
        setDropdownErrors({ [dropdown.type]: true });
        hasError = true;
        break; // Exit the loop if there's an error
      }
    }

    if (hasError) {
      return;
    }

    dispatch(
      updateExtraCart({
        id: data?.id,
        itemImage: data?.itemImage,
        selectedDropdown: selectedCategory,
        name: data?.name,
        price: totalPrice,
        time: totalTime,
        additionItemSq: additionItemSq,
        isFlooring: isFlooring,
        size: true,
        edit: edit,
        data: edit ? data?.data : data,
      })
    );
    onHide();
  };

  const handleDropdownChange = (type, value, index) => {
    setSelectedCategory((prev) => ({ ...prev, [type]: value }));
    setDropdownErrors((prev) => ({ ...prev, [type]: false }));

    // Enable the next dropdown if the current one is selected
    const newEnabledState = [...dropdownEnabledState];
    if (value) {
      newEnabledState[index + 1] = true;
    }
    setDropdownEnabledState(newEnabledState);
  };

  const handleToggle = (flooringState) => {
    setIsFlooring(flooringState);
    setSelectedCategory({});
    if (edit) {
      setDropdownData(
        flooringState ? data?.data?.dropDowns : data?.data?.tilesDropdDown
      );
    } else {
      setDropdownData(flooringState ? data.dropDowns : data.tilesDropdDown);
    }
  };

  const inputChangeHandler = (event) => {
    const { name, value } = event.target;
    setAdditionItemSq(value);
    if (value === "") {
      dispatch(setError({ field: name, value: true }));
    } else {
      dispatch(setError({ field: name, value: false }));
    }
  };

  const handleSelectedCategory = (selectedCategory) => {
    let basePrice = data.price > 0 ? Number(data.price) : 0;
    let totalPrice = 0;
    let totalTime = data.time > 0 ? Number(data.time) : 0;
    let firstDropdown = true;
    let firstDropdownTime = true;

    const squareFeet = additionItemSq ? parseFloat(additionItemSq) : 0;

    if (
      data.price &&
      data.time &&
      !data.flooringType &&
      data.dropDowns == null
    ) {
      basePrice = data.price * squareFeet;
      totalTime = data.time * squareFeet;
    }

    Object.keys(selectedCategory).forEach((category) => {
      const currentItem = selectedCategory[category];
      // Price calculation
      if (currentItem.price !== null && currentItem.price !== undefined) {
        const itemPrice = parseFloat(currentItem.price);
        console.log("itemPrice", itemPrice);

        if (data?.squireFeet && firstDropdown) {
          basePrice += parseFloat(itemPrice) * parseFloat(squareFeet);
          console.log("Base Price (with squareFeet)", basePrice);
          firstDropdown = false;
        } else {
          basePrice += itemPrice;
          console.log("Base Price (without squareFeet)", basePrice);
        }
      }

      // Price percentage calculation
      if (
        currentItem.price === null &&
        currentItem.pricePercentage !== null &&
        currentItem.pricePercentage !== undefined
      ) {
        const pricePercentage = parseFloat(currentItem.pricePercentage); // Ensure it's a number
        basePrice += (pricePercentage / 100) * basePrice;
        console.log("Base Price (with pricePercentage)", basePrice);
      }

      // Time calculation
      if (currentItem.time !== null && currentItem.time !== undefined) {
        const itemTime = parseFloat(currentItem.time); // Ensure time is parsed correctly
        if (data?.squireFeet && firstDropdownTime) {
          totalTime += itemTime * squareFeet;
          console.log("Total Time (with squareFeet)", totalTime);
          firstDropdownTime = false;
        } else {
          totalTime += itemTime;
          console.log("Total Time (without squareFeet)", totalTime);
        }
      }

      // Time percentage calculation
      if (
        currentItem.time === null &&
        currentItem.timePercentage !== null &&
        currentItem.timePercentage !== undefined
      ) {
        const timePercentage = parseFloat(currentItem.timePercentage); // Ensure it's a number
        totalTime += (timePercentage / 100) * totalTime;
        console.log("Total Time (with timePercentage)", totalTime);
      }
    });

    totalPrice = parseFloat(basePrice.toFixed(2));
    totalTime = parseFloat(totalTime.toFixed(2));

    dispatch(
      updateTotalPriceAndTime({
        totalPrice,
        totalTime,
      })
    );

    setTotalPrice(totalPrice);
    setTotalTime(totalTime);
  };

  useEffect(() => {
    handleSelectedCategory(selectedCategory);
  }, [selectedCategory, additionItemSq, data.price]);

  return (
    <div className="extra-drawer-main-wrapper">
      <div className="extra-drawer-main-header">
        <div className="extra-back-wrap">
          <img src="../assets/icons/right-black-arrow.svg" alt="icon" />
          <h4> Additional Service </h4>
        </div>
        <div className="extra-close-drwaer-btn">
          <img
            onClick={onHide}
            src="../assets/icons/close-icon.svg"
            alt="close-icon"
          />
        </div>
      </div>
      <div className="addition-drawer-main-wrap">
        <div className="addition-drawer-item-wrap">
          <img src={data?.itemImage} alt="img" />
          <p> {data?.name}</p>
        </div>
        {(data?.squireFeet || data?.additionItemSq) && (
          <div className="additional-drawer-sqfeet-wrap">
            <div className="additional-sqfeet-wrap">
              <p> Total Sqft</p>
              <OverlayTrigger
                placement="top"
                delay={{ show: 250, hide: 400 }}
                overlay={
                  <Tooltip id={`tooltip-up`}>
                    Please enter a value between 100 and 10000.
                  </Tooltip>
                }
              >
                <img src="../assets/icons/info.svg" alt="info-icon" />
              </OverlayTrigger>
            </div>

            <div className="login-option sequirefeet-input-wrap addtional-drawer-squirefeet-input">
              <form>
                <InputField
                  extraClass={
                    errors?.additionItemSq
                      ? `squirefeet-input error-msg`
                      : "squirefeet-input"
                  }
                  type="number"
                  name="additionItemSq"
                  id="additionItemSq"
                  onChange={inputChangeHandler}
                  value={additionItemSq}
                  label={
                    errors?.additionItemSq ? errorMessage : "Enter Square Feet"
                  }
                  passwordIcon={false}
                />
                <p className="sq-placeholder"> Sq </p>
              </form>
            </div>
          </div>
        )}
        {(data?.flooringType || data?.data?.flooringType) && (
          <div className="flooring-dropdown-toggle-wrapper">
            <h3> Flooring Type </h3>
            <div className="flooring-dropdown-toggle">
              <div
                className={`flooring-toggle-left ${
                  !isFlooring ? "active" : ""
                }`}
                onClick={() => handleToggle(false)}
              >
                <p> Tiles </p>
              </div>
              <div
                className={`flooring-toggle-left ${isFlooring ? "active" : ""}`}
                onClick={() => handleToggle(true)}
              >
                <p> Flooring </p>
              </div>
            </div>
          </div>
        )}
        <div className="recurring-dropdown-main-wrapper extra-category-dropdown-wrap">
          {dropdownData.map((dropdown, index) => (
            <Dropdown
              extraClass={dropdownErrors[dropdown.type] ? "error-msg" : ""}
              key={index}
              onTypeChange={(value) =>
                handleDropdownChange(dropdown.type, value, index)
              }
              disabled={!dropdownEnabledState[index]}
              label={dropdown.type}
              data={dropdown.data}
              alreadyValue={selectedCategory[dropdown.type] || "Select"}
            />
          ))}
        </div>
      </div>
      <div className="extra-addto-cart-btn">
        <Button
          extraClass={!enableButton ? "disabled-button" : "add-to-cart-btn"}
          onClick={addToCartHandler}
          label={edit ? "Update" : "Add to cart"}
        />
      </div>
    </div>
  );
};

export default AdditionalDrawer;

import React from "react";
const InputField = ({
  type,
  onChange,
  extraClass,
  name,
  id,
  label,
  value,
  placeholder,
  togglePassword,
  iconDisplaye,
  passwordIcon,
  disabled,
  maxLength,
  readOnly,
  onBlur,
}) => {
  return (
    <div className={extraClass ? `${extraClass} field` : "field"}>
      <input
        className={extraClass}
        onBlur={onBlur}
        type={type}
        name={name}
        id={id}
        onChange={onChange}
        disabled={disabled}
        readOnly={readOnly}
        maxLength={maxLength}
        value={value}
        autoComplete="off"
        placeholder={placeholder}
        required
      />
      <label title={label} data-title={label}></label>

      {passwordIcon && (
        <div className="password-icon">
          <img
            onClick={togglePassword}
            src={
              iconDisplaye
                ? "./assets/icons/close-eye.svg"
                : "./assets/icons/view-icon.svg"
            }
            alt="icon"
          />
        </div>
      )}
    </div>
  );
};

export default InputField;

import React, { useState } from "react";
import Modal from "react-bootstrap/Modal";
import "./modal.css";
import Button from "../UIComponents/Button";
import { useDispatch, useSelector } from "react-redux";
import { servicePost } from "../../helper/api";
import { setNotificationDep } from "../../redux/features/notificationDepSlice";

const MobileOrderCancel = (props) => {
  const [isLoading, setIsLoading] = useState(false);
  const [selectedReason, setSelectedReason] = useState("");
  const [cancelReason, setCancelReason] = useState("");
  const [error, setError] = useState(false);
  const [errorMsg, SetErrorMsg] = useState("");
  const notificationDep = useSelector(
    (state) => state.notificationDep.notificationDep
  );

  // get AuthToken from localStorage
  const authToken = localStorage.getItem("token");
  const notification = "";
  const dispatch = useDispatch();

  const reasons = [
    "Found a Better Price Elsewhere",
    "Incorrect Item Ordered",
    "Change of Mind",
    "Item Not Needed Anymore",
    "Order Delayed or Taking Too Long",
    "Duplicate Order Placed",
    "Other",
  ];

  const handleReasonChange = (event) => {
    setSelectedReason(event.target.value);
  };

  const cancelOrderHandler = async () => {
    if (selectedReason == "") {
      setError(true);
      SetErrorMsg("Please Select Any Reason");
      setTimeout(() => {
        setError(false);
        SetErrorMsg("");
      }, 3000);
      return;
    }

    if (selectedReason == "Other" && cancelReason == "") {
      setError(true);
      SetErrorMsg("");
      return;
    }
    try {
      setIsLoading(true);
      const cancelOrderRes = await servicePost(
        "booking/cancelBooking",
        {
          bookingId: props.data?.bookingId,
          cancelReason:
            selectedReason == "Other" ? cancelReason : selectedReason,
          cancelBy: "User",
        },
        notification,
        authToken
      );

      if (cancelOrderRes.message == "SUCCESS") {
        setIsLoading(false);
        dispatch(setNotificationDep(notificationDep + 1));
        props.onHide();
        props.onOrderCancel(true);
      }
    } catch (error) {
      console.log("error", error);
      setIsLoading(false);
    }
  };

  return (
    <Modal
      {...props}
      size="lg"
      dialogClassName="cancelorder-modal-main-wrapper"
      aria-labelledby="contained-modal-title-vcenter"
      centered
    >
      <Modal.Body>
        <div className="cancel-modal-main-wrapper">
          <div className="cancel-modal-main-wrap container">
            <div className="cancel-modal-content-wrap">
              <div className="mobile-cancel-modal-head">
                <h3> Why you Cancel this order ? </h3>
                <img
                  onClick={() => props.onHide()}
                  src="./assets/icons/close-icon.svg"
                  alt="icon"
                />
              </div>
              {error && errorMsg !== "" ? (
                <p className="error-msg"> {errorMsg} </p>
              ) : (
                <p className="error-msg-empty"> sdsd</p>
              )}

              <div className="cancel-order-reason-wrapper">
                <form>
                  {reasons.map((reason, index) => (
                    <div key={index} className="radio-option">
                      <input
                        type="radio"
                        id={`reason-${index}`}
                        name="cancel-reason"
                        value={reason}
                        checked={selectedReason === reason}
                        onChange={handleReasonChange}
                      />
                      <label htmlFor={`reason-${index}`}>{reason}</label>
                    </div>
                  ))}
                </form>

                <div className="cancel-booking-main-wrap">
                  <textarea
                    disabled={selectedReason !== "Other"}
                    className={
                      selectedReason !== "Other" ? "disable-textarea" : ""
                    }
                    placeholder="Write Reason here"
                  ></textarea>
                  <div className="cancel-booking-btn">
                    <Button
                      onClick={cancelOrderHandler}
                      extraClass="cancel-order-button"
                      label="Cancel Booking"
                    />
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </Modal.Body>
    </Modal>
  );
};
export default MobileOrderCancel;

// Dummy data for Dropdown...
const RecurringData = [
  {
    name: "One Time",
  },
  {
    name: "Once a Week",
  },
  {
    name: "Monthly",
  },
];

const IndustryData = [
  {
    name: "House",
    image: "./assets/icons/drop-icon-1.png",
  },
  {
    name: "Apartment",
    image: "./assets/icons/drop-icon-2.png",
  },
  {
    name: "Condo",
    image: "./assets/icons/drop-icon-2.png",
  },
  {
    name: "Specialty Cleaning",
    image: "./assets/icons/drop-icon-3.png",
  },
];

const FloorsData = [
  {
    name: "1 Floors",
  },
  {
    name: "2 Floors",
  },
  {
    name: "3 Floors",
  },
  {
    name: "4 Floors",
  },
  {
    name: "5 Floors",
  },
];

const BedroomsData = [
  {
    name: "1 Bedrooms",
  },
  {
    name: "2 Bedrooms",
  },
  {
    name: "3 Bedrooms",
  },
  {
    name: "4 Bedrooms",
  },
  {
    name: "5 Bedrooms",
  },
  {
    name: "6 Bedrooms",
  },
  {
    name: "7 Bedrooms",
  },
  {
    name: "8 Bedrooms",
  },
  {
    name: "9 Bedrooms",
  },
  {
    name: "10 Bedrooms",
  },
];

const CommercialIndustryData = [
  {
    name: "Office Bulifing",
    imageUrl: "./assets/icons/drop-icon-1.png",
  },
  {
    name: "Healthcare Facilities",
    imageUrl: "./assets/icons/drop-icon-2.png",
  },
  {
    name: "Retail Stores",
    imageUrl: "./assets/icons/drop-icon-2.png",
  },
  {
    name: "Specialty Cleaning",
    imageUrl: "./assets/icons/drop-icon-3.png",
  },
];

const CommercialCategory = [
  {
    name: "Corporate Offices",
  },
  {
    name: "Co-Working Spaces",
  },
  {
    name: "Administrative Offices",
  },
];

const CommercialType = [
  {
    name: "General Cleaning",
  },
  {
    name: "Post-Constrution",
  },
];

// City Dropdown Data
const CityDummyData = [
  {
    name: "Toronto",
  },
  {
    name: "Winnipeg",
  },
  {
    name: "Vancouver",
  },
  {
    name: "Edmonton",
  },
  {
    name: "Calgary",
  },
];

// Extra products Category
const ExtraCategoriesData = [
  {
    name: "Nylon",
  },
  {
    name: "Leather",
  },
  {
    name: "Woollen",
  },
];

// Bathrooms dummy data
const BathroomData = [
  {
    id: 1,
    itemImage: "./assets/bathroom-1.png",
    price: "10.00",
    category: "Washroom",
    name: "Bathroom",
    subCategory: "1/pc",
    info: "Click on the Plus button to add the item to your cart.",
  },
  {
    id: 2,
    itemImage: "./assets/bathroom-2.png",
    price: "20.00",
    category: "Washroom",
    name: "Bathroom",
    subCategory: "2/pc",
    info: "Click on the Plus button to add the item to your cart.",
  },
  {
    id: 3,
    itemImage: "./assets/bathroom-3.png",
    price: "50.00",
    category: "Washroom",
    name: "Bathroom",
    subCategory: "5/pc",
    info: "Click on the Plus button to add the item to your cart.",
  },
  {
    id: 4,
    itemImage: "./assets/bathroom-3.png",
    price: "50.00",
    category: "Washroom",
    name: "Bathroom",
    subCategory: "5/pc",
    info: "Click on the Plus button to add the item to your cart.",
  },

  {
    id: 5,
    itemImage: "./assets/bathroom-3.png",
    price: "50.00",
    category: "Washroom",
    name: "Bathroom",
    subCategory: "5/pc",
    info: "Click on the Plus button to add the item to your cart.",
  },
  {
    id: 6,
    itemImage: "./assets/bathroom-1.png",
    price: "50.00",
    category: "Washroom",
    name: "Bathroom",
    subCategory: "5/pc",
    info: "Click on the Plus button to add the item to your cart.",
  },
];

// Windows dummy data
const WindowsData = [
  {
    id: 7,
    itemImage: "./assets/window-1.png",
    price: "10.00",
    category: "Window",
    name: "Window",
    subCategory: "1/pc",
    info: "Click on the Plus button to add the item to your cart.",
  },
  {
    id: 8,
    itemImage: "./assets/window-2.png",
    price: "20.00",
    category: "Window",
    name: "Window",
    subCategory: "2/pc",
    info: "Click on the Plus button to add the item to your cart.",
  },
  {
    id: 9,
    itemImage: "./assets/window-3.png",
    price: "50.00",
    category: "Window",
    name: "Window",
    subCategory: "3/pc",
    info: "Click on the Plus button to add the item to your cart.",
  },
  {
    id: 10,
    itemImage: "./assets/window-4.png",
    price: "50.00",
    category: "Window",
    name: "Window",
    subCategory: "4/pc",
    info: "Click on the Plus button to add the item to your cart.",
  },

  {
    id: 11,
    itemImage: "./assets/window-2.png",
    price: "50.00",
    category: "Window",
    name: "Window",
    subCategory: "5/pc",
    info: "Click on the Plus button to add the item to your cart.",
  },
];

// extras dummy data
const extraData = [
  {
    id: 12,
    itemImage: "./assets/extra-1.png",
    price: "10.00",
    name: "Carpet",
    // subCategory: "1/pc",
    extraWithSize: true,
    squireFeet: true,
    flooringType: false,
    info: "Click on the Plus button to add the item to your cart.",
    dropDowns: [
      {
        type: "Type of Service",
        data: [
          {
            name: "Standard-Shampoo",
          },
          {
            name: "Deep-Shampoo & Extract ",
          },
          {
            name: "Steam-Hot Water Extraction",
          },
          {
            name: "Spot & Stain Removal",
          },
        ],
      },
      {
        type: "Type of Material",
        data: [
          {
            name: "Nylon",
          },
          {
            name: "Polyester",
          },
          {
            name: "Olefin",
          },
          {
            name: "Blend",
          },
          {
            name: "Berber",
          },
        ],
      },
      {
        type: "Area or Size",
        data: [
          {
            name: "Room, 1,2,3",
          },
          {
            name: "Bedroom, 1,2,3",
          },
          {
            name: "Living Room",
          },
          {
            name: "Hallway",
          },
          {
            name: "Stairs",
          },
        ],
      },
    ],
  },
  {
    id: 13,
    itemImage: "./assets/extra-2.png",
    price: "20.00",
    name: "Chimney",
    // subCategory: "2/pc",
    extraWithSize: true,
    squireFeet: true,
    flooringType: true,
    info: "Click on the Plus button to add the item to your cart.",
    dropDowns: [
      {
        type: "Type of Flooring",
        data: [
          {
            name: "Fabric",
          },
          {
            name: "Deep-Shampoo & Extract ",
          },
          {
            name: "Steam-Hot Water Extraction",
          },
          {
            name: "Spot & Stain Removal",
          },
        ],
      },
      {
        type: "Type of Material",
        data: [
          {
            name: "Light",
          },
          {
            name: "Polyester",
          },
          {
            name: "Olefin",
          },
          {
            name: "Blend",
          },
          {
            name: "Berber",
          },
        ],
      },
      {
        type: "Area or Size",
        data: [
          {
            name: "Sqft",
          },
          {
            name: "Bedroom, 1,2,3",
          },
          {
            name: "Living Room",
          },
          {
            name: "Hallway",
          },
          {
            name: "Stairs",
          },
        ],
      },
    ],

    tilesDropdDown: [
      {
        type: "Type of Tiles",
        data: [
          {
            name: "Simple Tiles",
          },
          {
            name: "Squire Tiles",
          },
        ],
      },
      {
        type: "Material of Tiles",
        data: [
          {
            name: "Nylon",
          },
          {
            name: "Polyester",
          },
        ],
      },
      {
        type: "Area or Size",
        data: [
          {
            name: "Living Room",
          },
          {
            name: "Hallway",
          },
          {
            name: "Stairs",
          },
        ],
      },
    ],
  },
  {
    id: 14,
    itemImage: "./assets/extra-3.png",
    price: "50.00",
    name: "Stove",
    // subCategory: "5/pc",
    extraWithSize: false,
    info: "Click on the Plus button to add the item to your cart.",
  },
  {
    id: 15,
    itemImage: "./assets/extra-4.png",
    price: "50.00",
    name: "Sink",
    // subCategory: "5/pc",
    extraWithSize: false,
    info: "Click on the Plus button to add the item to your cart.",
  },

  {
    id: 16,
    itemImage: "./assets/bathroom-1.png",
    price: "50.00",
    name: "Bathroom",
    // subCategory: "5/pc",
    extraWithSize: false,
    info: "Click on the Plus button to add the item to your cart.",
  },
  {
    id: 17,
    itemImage: "./assets/bathroom-1.png",
    price: "50.00",
    name: "Bathroom",
    // subCategory: "5/pc",
    extraWithSize: false,
    info: "Click on the Plus button to add the item to your cart.",
  },
  {
    id: 18,
    itemImage: "./assets/extra-1.png",
    price: "10.00",
    name: "Carpet",
    // subCategory: "1/pc",
    extraWithSize: false,
    info: "Click on the Plus button to add the item to your cart.",
  },
  {
    id: 19,
    itemImage: "./assets/extra-2.png",
    price: "20.00",
    name: "Chimney",
    // subCategory: "2/pc",
    extraWithSize: false,
    info: "Click on the Plus button to add the item to your cart.",
  },

  {
    id: 20,
    itemImage: "./assets/extra-1.png",
    price: "10.00",
    name: "Carpet",
    // subCategory: "1/pc",
    extraWithSize: false,
    info: "Click on the Plus button to add the item to your cart.",
  },
  {
    id: 21,
    itemImage: "./assets/extra-2.png",
    price: "20.00",
    name: "Chimney",
    // subCategory: "2/pc",
    extraWithSize: false,
    info: "Click on the Plus button to add the item to your cart.",
  },

  {
    id: 22,
    itemImage: "./assets/extra-1.png",
    price: "10.00",
    name: "Carpet",
    // subCategory: "1/pc",
    extraWithSize: false,
    info: "Click on the Plus button to add the item to your cart.",
  },
  {
    id: 23,
    itemImage: "./assets/extra-2.png",
    price: "20.00",
    name: "Chimney",
    // subCategory: "2/pc",
    extraWithSize: false,
    info: "Click on the Plus button to add the item to your cart.",
  },

  {
    id: 24,
    itemImage: "./assets/extra-1.png",
    price: "10.00",
    name: "Carpet",
    // subCategory: "1/pc",
    extraWithSize: false,
    info: "Click on the Plus button to add the item to your cart.",
  },
  {
    id: 25,
    itemImage: "./assets/extra-2.png",
    price: "20.00",
    name: "Chimney",
    // subCategory: "2/pc",
    extraWithSize: false,
    info: "Click on the Plus button to add the item to your cart.",
  },
];

// extras dummy data
const specialServicesData = [
  {
    id: 26,
    itemImage: "./assets/special-1.png",
    price: "10.00",
    name: "Floor Care",
    // subCategory: "1/pc",
    extraWithSize: true,
    squireFeet: true,
    flooringType: false,
    info: "Click on the Plus button to add the item to your cart.",
    dropDowns: [
      {
        type: "Type of Service",
        data: [
          {
            name: "Standard-Shampoo",
          },
          {
            name: "Deep-Shampoo & Extract ",
          },
          {
            name: "Steam-Hot Water Extraction",
          },
          {
            name: "Spot & Stain Removal",
          },
        ],
      },
      {
        type: "Type of Material",
        data: [
          {
            name: "Nylon",
          },
          {
            name: "Polyester",
          },
          {
            name: "Olefin",
          },
          {
            name: "Blend",
          },
          {
            name: "Berber",
          },
        ],
      },
      {
        type: "Area or Size",
        data: [
          {
            name: "Room, 1,2,3",
          },
          {
            name: "Bedroom, 1,2,3",
          },
          {
            name: "Living Room",
          },
          {
            name: "Hallway",
          },
          {
            name: "Stairs",
          },
        ],
      },
    ],
  },
  {
    id: 27,
    itemImage: "./assets/extra-2.png",
    price: "20.00",
    name: "Chimney",
    // subCategory: "2/pc",
    extraWithSize: false,
    info: "Click on the Plus button to add the item to your cart.",
  },
  {
    id: 28,
    itemImage: "./assets/special-2.png",
    price: "50.00",
    name: "Construc-tion Mess",
    // subCategory: "5/pc",
    extraWithSize: false,
    info: "Click on the Plus button to add the item to your cart.",
  },
  {
    id: 29,
    itemImage: "./assets/extra-4.png",
    price: "50.00",
    name: "Sink",
    // subCategory: "5/pc",
    extraWithSize: false,
    info: "Click on the Plus button to add the item to your cart.",
  },

  {
    id: 30,
    itemImage: "./assets/special-4.png",
    price: "50.00",
    name: "Window Cleaning",
    // subCategory: "5/pc",
    extraWithSize: false,
    info: "Click on the Plus button to add the item to your cart.",
  },
  {
    id: 31,
    itemImage: "./assets/special-3.png",
    price: "50.00",
    name: "Power Sweeping",
    // subCategory: "5/pc",
    extraWithSize: false,
    info: "Click on the Plus button to add the item to your cart.",
  },
  {
    id: 32,
    itemImage: "./assets/special-5.png",
    price: "90.00",
    name: "Power Washing",
    // subCategory: "1/pc",
    extraWithSize: false,
    info: "Click on the Plus button to add the item to your cart.",
  },
  {
    id: 33,
    itemImage: "./assets/special-6.png",
    price: "20.00",
    name: "High Dusting",
    // subCategory: "2/pc",
    extraWithSize: false,
    info: "Click on the Plus button to add the item to your cart.",
  },
  {
    id: 34,
    itemImage: "./assets/special-3.png",
    price: "10.00",
    name: "Carpet",
    // subCategory: "1/pc",
    extraWithSize: false,
    info: "Click on the Plus button to add the item to your cart.",
  },
  {
    id: 35,
    itemImage: "./assets/special-2.png",
    price: "20.00",
    name: "Chimney",
    // subCategory: "2/pc",
    extraWithSize: false,
    info: "Click on the Plus button to add the item to your cart.",
  },

  {
    id: 36,
    itemImage: "./assets/special-1.png",
    price: "10.00",
    category: "Carpet",
    // subCategory: "1/pc",
    extraWithSize: false,
    info: "Click on the Plus button to add the item to your cart.",
  },
  {
    id: 37,
    itemImage: "./assets/special-4.png",
    price: "20.00",
    name: "Chimney",
    // subCategory: "2/pc",
    extraWithSize: false,
    info: "Click on the Plus button to add the item to your cart.",
  },
];

const HistoryData = [
  {
    item: "images",
    itemDetail: "Retail, 1Floor, 1 Bedroom..",
    schedule: "02 May, 2024",
    amount: "$600",
    status: "complete",
  },

  {
    item: "images",
    itemDetail: "Retail, 1Floor, 1 Bedroom..",
    schedule: "01 April, 2024",
    amount: "$60",
    status: "complete",
  },

  {
    item: "images",
    itemDetail: "Retail, 1Floor, 1 Bedroom..",
    schedule: "02 June, 2024",
    amount: "$800",
    status: "complete",
  },
];

const AdditionlService = [
  {
    name: "Standard-Shampoo",
  },
  {
    name: "Deep-Shampoo & Extract ",
  },
  {
    name: "Steam-Hot Water Extraction",
  },
  {
    name: "Spot & Stain Removal",
  },
];

const AdditionalMaterial = [
  {
    name: "Nylon",
  },
  {
    name: "Polyester",
  },
  {
    name: "Olefin",
  },
  {
    name: "Blend",
  },
  {
    name: "Berber",
  },
];

const AdditionalArea = [
  {
    name: "Room, 1,2,3",
  },
  {
    name: "Bedroom, 1,2,3",
  },
  {
    name: "Living Room",
  },
  {
    name: "Hallway",
  },
  {
    name: "Stairs",
  },
];

const BookingDetail = [
  {
    serviceType: "Residential",
    industry: "House",
    floors: "2 Floors",
    bedrooms: "2 Bedrooms",
    bookingId: "#57837465892742",
    squireFeet: "1000",
    contactPersonName: "Jhon",
    contactPersonNumber: "932-122-2323",
    vacummCleaning: "50%",
    moppingCleaning: "50%",
    vacummCleaningPrice: "200",
    moppingCleaningPrice: "200",
    dusting: "57%",
    dustingPrice: "200",
    recurring: "one Time",
    address: "7035 Maxwell Rd suite 209",
    city: "Mississauga, Canada",
    postalCode: "D9H 6X7",
    scheduleDate: "02May, 2023",
    scheduleTime: " - 2:30 AM",
    paymentMethod: "Credit Card",
    price: "570",
    discount: "00.00",
    subTotal: "570.00",
    fees: "30.00",
    total: "600.00",
    companyAddress: "Company Address",
    bookingStatus: "Booking Confirmed",
    bookingTime: "04:14 PM",
    bookingDate: "02 May, 2024",
    cleanerPartnerStatus: "Cleaner Partner Assigned",
    assignedTime: "12:00 PM",
    assignedDate: "02 May, 2024",
    arrivedStatus: "Arrived at your Location",
    arrivedTime: "12:20 PM",
    arrivedDate: "02 May, 2024",
    otp: "546738",
    otpAccess: true,
    cleanigServiceStatus: "Completed",
    reviewReating: "4",
    reviewText: "Very good Service",

    cartItems: [
      {
        category: "Washroom",
        id: "8",
        industry: "Apartment",
        isAdded: true,
        itemImage:
          "https://pccex.s3.us-east-2.amazonaws.com/cleaning-Images/1721037233596_bathroom-2.png",
        name: "Bathroom",
        price: "20",
        quantity: 1,
        subCategory: "2/pc",
      },
      {
        category: "Washroom",
        id: "9",
        industry: "House",
        isAdded: true,
        itemImage:
          "https://pccex.s3.us-east-2.amazonaws.com/cleaning-Images/1721037233596_bathroom-2.png",
        name: "Bathroom",
        price: "40",
        quantity: 2,
        subCategory: "2/pc",
      },
      {
        additionItemSq: "1000",
        data: {
          id: 807,
          itemImage:
            "https://pccex.s3.us-east-2.amazonaws.com/cleaning-Images/1721045209692_1.png",
          price: "20",
          category: "extra",
          subCategory: "2/pc",
        },
        id: 807,
        isAdded: true,
        isFlooring: false,
        itemImage:
          "https://pccex.s3.us-east-2.amazonaws.com/cleaning-Images/1721045209692_1.png",
        name: "Carpet",
        price: "10",
        quantity: 1,
        selectedDropdown: {
          "Area or Size": { name: "Nylon" },
          "Type of Material": { name: "Nylon" },
        },
      },
    ],
  },
];

const availibleServiceCites = [
  {
    name: "Toronto",
  },
  {
    name: "Mississauga",
  },
  {
    name: "Brampton",
  },
  {
    name: "Markham",
  },
  {
    name: "Vaughan",
  },
  {
    name: "Richmond Hill",
  },
  {
    name: "Oakville",
  },
  {
    name: "Burlington",
  },
  {
    name: "Milton",
  },
  {
    name: "Halton Hills",
  },
];

export {
  RecurringData,
  IndustryData,
  FloorsData,
  BedroomsData,
  BathroomData,
  WindowsData,
  extraData,
  specialServicesData,
  ExtraCategoriesData,
  CityDummyData,
  CommercialIndustryData,
  CommercialCategory,
  CommercialType,
  HistoryData,
  AdditionlService,
  AdditionalMaterial,
  AdditionalArea,
  BookingDetail,
  availibleServiceCites,
};

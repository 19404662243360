import React from "react";
import { useMediaQuery } from "react-responsive";

const IndustryCharge = ({ image, category, label, price, pageType }) => {
  const isMobile = useMediaQuery({ maxWidth: 1024 });
  return (
    <>
      {!isMobile ? (
        <div className="booking-cart-item-repeat">
          <div className="booking-cart-item-left">
            <div className="booking-item-image">
              <img src={image} alt="item-img" />
            </div>
            <div className="booking-item-detail">
              <h6>{category}</h6>
              <p> {label} </p>
            </div>
          </div>

          <div className="booking-cart-item-right-wrapper">
            <div className="booking-cart-item-right">
              {pageType == "TrankingOrder" ? (
                ""
              ) : (
                <div className="update-quantity-btn">
                  <h4 className="decrement-btn-text"></h4>

                  <span className="percentage-text">---</span>

                  <h4 className="increment-btn-text"></h4>
                </div>
              )}

              <div className="booking-item-price-wrap">
                {price ? <p> ${Number(price).toFixed(2)}</p> : <p> $0.00 </p>}
              </div>
            </div>
          </div>
        </div>
      ) : (
        <div className="booking-cart-item-repeat">
          <div className="mobile-booking-cart-item-left">
            <div className="booking-item-detail">
              <h6>{category}</h6>
              <p> {label} </p>
            </div>
          </div>

          <div className="booking-cart-item-right-wrapper">
            <div className="booking-cart-item-right">
              <div className="booking-item-price-wrap">
                {price ? <p> ${Number(price).toFixed(2)}</p> : <p> $0.00</p>}
              </div>
            </div>
          </div>
        </div>
      )}
    </>
  );
};

export default IndustryCharge;

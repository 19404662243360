import React, { useState } from "react";
import "./login.css";
import Button from "../UIComponents/Button";
import InputField from "../UIComponents/InputField";
import ConfirmationModal from "../modals/ConfirmationModal";
import { useNavigate } from "react-router-dom";
import { servicePost } from "../../helper/api";
import validator from "validator";

const ForgotPassword = () => {
  const [isLoading, setIsLoading] = useState(false);
  const [showConfirmationModal, setShowConfirmationModal] = useState(false);
  const [email, setEmail] = useState();
  const [errorMessage, setErrorMessage] = useState();

  // Error Validation
  const [errors, setErrors] = useState({
    email: "",
    otp: "",
    password: "",
    confirmPassword: "",
  });

  const navigate = useNavigate();
  const goToLogin = () => {
    navigate("/login");
  };

  // Send Otp for Email Verification
  const sendOptResgiteredEmail = async () => {
    if (!email || !email.trim() || email == undefined) {
      setErrors({
        email: "Email is required",
      });
      setTimeout(() => {
        setErrors("");
      }, 5000);
      return;
    }

    if (validator.isEmail(email)) {
    } else {
      setErrors({
        email: "Please, enter valid Email!",
      });
      setTimeout(() => {
        setErrors("");
      }, 5000);
      return;
    }

    try {
      setIsLoading(true);
      const signupUser = await servicePost(
        "auth/forgotpassword",
        {
          email: email.toLowerCase(),
        },
        {}
        //csrfTokenState
      );

      if (signupUser.message == "SUCCESS") {
        setIsLoading(false);
        setShowConfirmationModal(true);

        setTimeout(() => {
          setShowConfirmationModal(false);
          navigate("/verify-otp", {
            state: {
              email: email,
            },
          });
        }, 3000);
      } else {
        setIsLoading(false);
        setErrorMessage(signupUser.message);
        setTimeout(() => {
          setErrorMessage("");
        }, 5000);
      }
    } catch (error) {
      setIsLoading(false);
      // console.log("error", error);
      setErrorMessage(error);
      setTimeout(() => {
        setErrorMessage("");
      }, 5000);
      return;
    }
  };

  return (
    <>
      <ConfirmationModal
        show={showConfirmationModal}
        onHide={() => setShowConfirmationModal(false)}
        // icon="./assets/confirm-tick.png"
        type="Forget Password"
        content="Reset Password otp send to your registered email"
        showCloseIcon={true}
      />
      <div className="login-main-wrapper">
        <div className="login-main-wrap container">
          <h3> You want to Forget your Password? </h3>

          <div className="login-form-main-wrap">
            <div className="login-option">
              <form>
                <InputField
                  extraClass={
                    errors?.email ? `error-msg` : email ? "active" : ""
                  }
                  type="text"
                  name="email"
                  id="email"
                  onChange={(e) => setEmail(e.target.value)}
                  value={email}
                  label={
                    errors?.email ? errors.email : "Enter Your email address"
                  }
                  passwordIcon={false}
                />
              </form>
              <div className="login-button">
                <Button
                  isLoading={isLoading}
                  label="Reset"
                  onClick={sendOptResgiteredEmail}
                />
              </div>

              {errorMessage ? (
                <div className="error-msg">
                  <p> {errorMessage} </p>
                </div>
              ) : (
                <div
                  style={{ justifyContent: "center" }}
                  className="login-text"
                >
                  <p onClick={goToLogin}>
                    I already have an Account.
                    <span> Login Now </span>
                  </p>
                </div>
              )}

              <div className="login-with-google-main-wrapper">
                <div className="full ordivider">
                  <span>Or</span>
                </div>

                <div className="login-with-google-btn-wrap">
                  <Button
                    extraClass="gray-btn"
                    label="Continue with Google"
                    icon="./assets/icons/google-icon.png"
                  />
                </div>
                <div className="login-screen-description">
                  <p>
                    By proceeding, you consent to receive calls and SMS
                    messages, including those sent by automated means, from YFOS
                    and its affiliates at the number provided.
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default ForgotPassword;

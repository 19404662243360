import React, { useState, useEffect } from "react";
import "./profile.css";
import InputField from "../UIComponents/InputField";
import Button from "../UIComponents/Button";
import OverlayTrigger from "react-bootstrap/OverlayTrigger";
import Tooltip from "react-bootstrap/Tooltip";
import { useNavigate } from "react-router-dom";
import TwofaModal from "../modals/TwofaModal";
import { useDispatch, useSelector } from "react-redux";
import { serviceImagePost, servicePost, servicePut } from "../../helper/api";
import { updateUserInfo } from "../../redux/features/userSlice";
import Lottie from "lottie-react";
import LoadingLottie from "../lottiefiles/loading.json";
import validator from "validator";
import { setNotificationDep } from "../../redux/features/notificationDepSlice";
import {
  ShimmerCircularImage,
  ShimmerTitle,
  ShimmerThumbnail,
} from "react-shimmer-effects";
import ConfirmationModal from "../modals/ConfirmationModal";

const Profile = () => {
  const [isLoading, setIsLoading] = useState(false);
  const [imageUploadLoading, setImageUploadLoading] = useState(false);
  const [showConfirmationModal, setShowConfirmationModal] = useState(false);
  const [showPasswordModal, setShowPasswordModal] = useState();
  const [editProfile, setEditProfile] = useState(false);
  const [selectedImage, setSelectedImage] = useState(null);
  const [isTwoFAEnabled, setIsTwoFAEnabled] = useState(false);
  const [isTwoFADisabled, setIsTwoFADisabled] = useState(true);
  const [userData, setUserData] = useState([]);
  const [uploadedImage, setUploadedImage] = useState(null);
  const [anyChanges, setAnyChanges] = useState(false);
  const [shimmerLoading, setShimmerLoading] = useState(true);

  const [currentPasswordShown, setCurrentPasswordShown] = useState(false);
  const [passwordShown, setPasswordShown] = useState(false);
  const [confirmPasswordShown, setConfirmPasswordShown] = useState(false);

  const [error, setError] = useState(false);
  const [errorMessage, setErrorMessage] = useState();
  const logedInUser = useSelector((state) => state.user.userInfo);
  const [passwordErrorMsg, setPasswrodErrorMsg] = useState();

  // get AuthToken from localStorage
  const authToken = localStorage.getItem("token");
  const notification = "";

  const notificationDep = useSelector(
    (state) => state.notificationDep.notificationDep
  );

  const [profileData, setProfileData] = useState({
    name: "",
    phoneNumber: "",
    currentPassword: "",
    newPassword: "",
    confirmPassword: "",
  });

  const { name, phoneNumber, currentPassword, newPassword, confirmPassword } =
    profileData;

  // Toggle Passwords
  const toggleCurrentPassword = () => {
    setCurrentPasswordShown(!currentPasswordShown);
  };

  const togglePassword = () => {
    setPasswordShown(!passwordShown);
  };

  const toggleConfirmPassword = () => {
    setConfirmPasswordShown(!confirmPasswordShown);
  };

  const profileChangeHandler = (e) => {
    const { name, value } = e.target;

    let formattedValue = value;

    if (name === "phoneNumber") {
      // Remove all non-numeric characters
      const numericValue = value.replace(/\D/g, "");

      // Limit the number of digits to 10
      const limitedNumericValue = numericValue.slice(0, 10);

      // Format the number as xxx-xxx-xxxx
      if (limitedNumericValue.length > 3 && limitedNumericValue.length <= 6) {
        formattedValue = `${limitedNumericValue.slice(
          0,
          3
        )}-${limitedNumericValue.slice(3)}`;
      } else if (limitedNumericValue.length > 6) {
        formattedValue = `${limitedNumericValue.slice(
          0,
          3
        )}-${limitedNumericValue.slice(3, 6)}-${limitedNumericValue.slice(
          6,
          10
        )}`;
      } else {
        formattedValue = limitedNumericValue;
      }
    }

    setProfileData((preVal) => {
      const updatedProfileData = {
        ...preVal,
        [name]: formattedValue,
      };

      // Check if there is any change in profile data
      const hasChanges = Object.keys(updatedProfileData).some(
        (key) => updatedProfileData[key] !== preVal[key]
      );

      // Set anyChanges state to true if there are changes
      setAnyChanges(true);

      return updatedProfileData;
    });

    if (name == "newPassword") {
      const newPassword = value;
      // Validate password
      if (
        !validator.isStrongPassword(newPassword, {
          minLength: 8,
          minLowercase: 1,
          minUppercase: 1,
          minNumbers: 1,
          minSymbols: 1,
        })
      ) {
        setPasswrodErrorMsg(
          "Password must contain: min 8 Characters, 1 uppercase & 1 lowercase letter, 1 number and 1 special character"
        );
        return;
      } else {
        setPasswrodErrorMsg("");
      }
    }

    setErrors((prevErrors) => ({
      ...prevErrors,
      [name]: "",
    }));
  };

  // Error Validation
  const [errors, setErrors] = useState({
    username: "",
    currentPassword: "",
    newPassword: "",
    confirmPassword: "",
    uploadedFiles: "",
  });

  const errorMessages = {
    currentPassword: "Verify your current password",
    newPassword: "Enter New Password",
    confirmPassword: "Enter Confirm Password",
    uploadedFiles: "Please upload minimum 1 Image",
    invalidPassword: "Enter Strong Password",
  };

  const dispatch = useDispatch();
  const navigate = useNavigate();

  useEffect(() => {
    if (isTwoFAEnabled) {
      // setShowConfirmationModal(true);
      setTimeout(() => {
        navigate("/2fa-enable-guide");
      }, 1000);
    }

    if (!isTwoFADisabled) {
      setShowConfirmationModal(true);

      setTimeout(() => {
        setShowConfirmationModal(false);
      }, 3000);
    }
  }, [isTwoFAEnabled, isTwoFADisabled]);

  // Handle toggle button change
  const handleToggleChange = () => {
    setIsTwoFAEnabled(!isTwoFAEnabled);
  };

  // Disable Request Email Send handler
  const desableReqHanlder = async () => {
    try {
      const twoFaDisableReq = await servicePost(
        "2fa/twofaDisableRequest",
        {
          email: logedInUser?.email,
        },
        notification,
        authToken
      );

      if (twoFaDisableReq.message == "SUCCESS") {
        dispatch(updateUserInfo({ enableOn: false, twoFaRequest: true }));
        dispatch(setNotificationDep(notificationDep + 1));

        setShowConfirmationModal(true);

        setIsTwoFADisabled(!isTwoFADisabled);
        // localStorage.setItem("isTwofaEnable", false);

        setTimeout(() => {
          setShowConfirmationModal(false);
        }, 3000);
      }
    } catch (error) {
      setError(true);
      setErrorMessage("Something went wrong.");
      setTimeout(() => {
        setErrorMessage("");
        setError(false);
      }, 5000);
    }
  };

  const editProfileInfo = () => {
    setEditProfile(!editProfile);
    setAnyChanges(false);
  };

  // Get User Detail By UserId
  const getUserDetail = async () => {
    try {
      const userRes = await servicePost(
        `user/getUser`,
        {
          id: logedInUser?.id,
        },
        notification,
        authToken
      );

      console.log("userRes", userRes);
      setShimmerLoading(false);
      setUserData(userRes.data);
    } catch (error) {
      console.log("error", error);
    }
  };

  useEffect(() => {
    getUserDetail();
  }, [logedInUser?.id, editProfile]);

  // Check if Values already exist
  useEffect(() => {
    if (userData) {
      setProfileData({
        name: !userData.fullName ? "" : userData.fullName,
        phoneNumber: !userData.phone ? "" : userData.phone,
      });

      setUploadedImage(
        !userData.profileImageURL ? "" : userData.profileImageURL
      );

      // loginUser?.data.enableOn == true ||
      //   loginUser?.data.enableOn == "true"
    }
  }, [userData]);

  /// Handle File Upload
  const handleFileChange = async (event) => {
    const file = event.target.files[0];
    const maxSize = 1 * 1024 * 1024; // 1MB in bytes

    // Check if the file size exceeds the maximum size
    if (file && file.size > maxSize) {
      setErrors((prevErrors) => ({
        ...prevErrors,
        uploadedFiles: "The max. file size limit is 1MB.",
      }));

      // Remove the error message after 5 seconds
      setTimeout(() => {
        setErrors((prevErrors) => ({
          ...prevErrors,
          uploadedFiles: null,
        }));
      }, 3000);

      return;
    }

    // Validate file type
    const allowedFileTypes = ["image/png", "image/jpeg", "image/jpg"];
    if (!allowedFileTypes.includes(file?.type)) {
      setErrors((prevErrors) => ({
        ...prevErrors,
        uploadedFiles: "Only PNG and JPG files are allowed.",
      }));

      // Remove the error message after 5 seconds
      setTimeout(() => {
        setErrors((prevErrors) => ({
          ...prevErrors,
          uploadedFiles: null,
        }));
      }, 3000);

      return;
    }

    if (file) {
      try {
        setImageUploadLoading(true);
        const formData = new FormData();
        formData.append("image", file);
        formData.append("id", logedInUser?.id);

        // Make the API call to upload the image and get the URL
        const response = await serviceImagePost(
          "user/uploadImageData",
          formData,
          authToken
        );

        setUploadedImage(response.Imageurl);
        dispatch(setNotificationDep(notificationDep + 1));
        setImageUploadLoading(false);
      } catch (error) {
        setImageUploadLoading(false);
        console.error("Error uploading image:", error);
      }
    }
  };

  // Update user Profile Values and Image...
  const editProfileHandler = async () => {
    if (
      (currentPassword !== "" &&
        currentPassword !== undefined &&
        currentPassword !== null) ||
      (newPassword !== "" && newPassword !== undefined && newPassword !== null)
    ) {
      const requiredFields = [
        "newPassword",
        "currentPassword",
        "confirmPassword",
      ];
      const newErrors = {};

      requiredFields.forEach((field) => {
        if (!profileData[field] || !profileData[field].trim()) {
          newErrors[field] = errorMessages[field];
        }
      });

      // Check if there are any errors; if yes, update the state and return
      if (Object.keys(newErrors).length > 0) {
        setErrors((prevErrors) => ({ ...prevErrors, ...newErrors }));
        return;
      }

      // Validate password
      if (
        !validator.isStrongPassword(newPassword, {
          minLength: 8,
          minLowercase: 1,
          minUppercase: 1,
          minNumbers: 1,
          minSymbols: 1,
        })
      ) {
        setErrors((prevErrors) => ({
          ...prevErrors,
          newPassword: errorMessages.invalidPassword,
        }));
        return;
      } else {
        setErrors((prevErrors) => ({
          ...prevErrors,
          newPassword: "",
        }));
      }

      if (newPassword !== confirmPassword) {
        setErrors((prevErrors) => ({
          ...prevErrors,
          confirmPassword: "Password does not match",
        }));
        return;
      }
    }

    setIsLoading(true);
    try {
      const updatedProfile = await servicePut(
        "user/update/userInfo",
        {
          id: logedInUser?.id,
          fullname: name ? name : userData?.fullName,
          phone: phoneNumber ? phoneNumber : userData?.phone,
          currentpassword: currentPassword,
          newpassword: newPassword ? newPassword : "",
        },
        authToken
      );
      setIsLoading(false);
      setEditProfile(false);
      setAnyChanges(false);
      dispatch(setNotificationDep(notificationDep + 1));
      setShowPasswordModal(true);

      setTimeout(() => {
        setShowPasswordModal(false);
      }, 3000);
    } catch (err) {
      setIsLoading(false);
      if (err.response.data.message == "Wrong Password") {
        setErrors((prevErrors) => ({
          ...prevErrors,
          currentPassword: "Wrong Password",
        }));
        return;
      } else {
        setErrors((prevErrors) => ({
          ...prevErrors,
          currentPassword: "",
        }));
      }
      setIsLoading(false);
    }
  };

  return (
    <>
      <TwofaModal
        show={showConfirmationModal}
        onHide={() => setShowConfirmationModal(false)}
        content="An email has been sent to Disable your Two-Factor Authentication"
        showCloseIcon={true}
      />

      <ConfirmationModal
        show={showPasswordModal}
        onHide={() => setShowPasswordModal(false)}
        icon="./assets/icons/personal-info.png"
        type="Profile"
        content="Your profile has been updated successfully."
        showCloseIcon={true}
      />
      <div className="profile-main-wrapper">
        <div className="profile-main-wrap container">
          <div className="profile-left-wrap">
            <div className="profile-page-main-image">
              <img src="./assets/profile-cover-img.png" alt="img" />
            </div>

            <div className="profile-detail-main-wrapper">
              <div className="profile-picture-wrap">
                {errors.uploadedFiles && (
                  <span className="error-msg profile-error-msg">
                    <p> {errors.uploadedFiles} </p>
                  </span>
                )}
                {uploadedImage ? (
                  <>
                    {/* <div className="profile-guideline-info-wrap">
                      <OverlayTrigger
                        placement="top"
                        delay={{ show: 250, hide: 400 }}
                        overlay={
                          <Tooltip id={`tooltip-up`}>
                            Please upload an image with a maximum size of 1 MB.
                            Only PNG, JPG, and JPEG file types are allowed.
                          </Tooltip>
                        }
                      >
                        <img src="./assets/icons/info.svg" alt="info-icon" />
                      </OverlayTrigger>
                    </div> */}

                    <img src={uploadedImage} alt="Uploaded" />
                  </>
                ) : (
                  <>
                    {/* <div className="profile-guideline-info-wrap">
                      <OverlayTrigger
                        placement="top"
                        delay={{ show: 250, hide: 400 }}
                        overlay={
                          <Tooltip id={`tooltip-up`}>
                            Please upload an image with a maximum size of 1 MB.
                            Only PNG, JPG, and JPEG file types are allowed.
                          </Tooltip>
                        }
                      >
                        <img src="./assets/icons/info.svg" alt="info-icon" />
                      </OverlayTrigger>
                    </div> */}

                    <img src="./assets/icons/avtar.png" alt="Default image" />
                  </>
                )}
                <div className="profile-edit-icon">
                  <label htmlFor="fileInput">
                    {!imageUploadLoading ? (
                      <>
                        <img
                          src="./assets/icons/edit-icon.png"
                          alt="Edit Icon"
                        />
                      </>
                    ) : imageUploadLoading ? (
                      <div
                        className="upload-profileImage-gif"
                        data-testid="lottie"
                      >
                        <Lottie animationData={LoadingLottie} loop={true} />
                      </div>
                    ) : (
                      ""
                    )}
                  </label>
                  <input
                    id="fileInput"
                    type="file"
                    accept="image/*"
                    style={{ display: "none" }} // Hide the input element
                    onChange={handleFileChange}
                  />
                </div>
              </div>
              {shimmerLoading ? (
                <div className="pofile-detail-main-wrap">
                  <ShimmerTitle line={2} gap={8} variant="primary" />
                </div>
              ) : (
                <div className="pofile-detail-main-wrap">
                  <h3> {userData?.fullName} </h3>
                  <p> {userData?.email}</p>
                </div>
              )}
            </div>

            <div className="profile-info-main-wrapper">
              {shimmerLoading ? (
                <div className="shimmer-profile-basic-info-wrap">
                  <ShimmerThumbnail rounded />
                </div>
              ) : (
                <div className="profile-basic-info-wrap profile-left-info-wrapper">
                  <div className="profile-info-header-wrapper">
                    <div className="profile-info-heading-wrap">
                      <h4> Personal info </h4>
                      <img src="./assets/icons/personal-info.png" alt="icon" />
                    </div>

                    <Button
                      label={!editProfile ? "Edit" : "Close"}
                      onClick={editProfileInfo}
                    />
                  </div>
                  <div className="profile-left-inner-wrapper">
                    {editProfile ? (
                      <>
                        <div className="login-option profile-inner-basic-info">
                          <form autoComplete="off">
                            <InputField
                              extraClass={name ? "active" : ""}
                              type="text"
                              name="name"
                              id="name"
                              label="Name"
                              disabled={!editProfile}
                              value={name}
                              onChange={profileChangeHandler}
                            />

                            <div className="phone-number-input-box">
                              <div className="number-flag-box-wrap">
                                <img
                                  src="./assets/icons/canada-flag.png"
                                  alt="icon"
                                />
                                <p> +1 </p>
                              </div>
                              <InputField
                                extraClass={phoneNumber ? "active" : ""}
                                type="text"
                                name="phoneNumber"
                                id="phoneNumber"
                                label="Phone Number"
                                disabled={!editProfile}
                                value={phoneNumber}
                                onChange={profileChangeHandler}
                              />
                            </div>
                          </form>
                        </div>
                      </>
                    ) : (
                      <div className="profile-readonly-info-wrap">
                        <div className="profile-readonly-info">
                          <div className="profile-readonly-info-repeat">
                            <p> Name </p>
                            <span>
                              {userData.fullName ? userData.fullName : "--"}
                            </span>
                          </div>

                          <div className="profile-readonly-info-repeat">
                            <p> Phone Number </p>
                            <span>
                              {userData.phone ? userData.phone : "--"}
                            </span>
                          </div>
                        </div>
                      </div>
                    )}

                    {shimmerLoading ? (
                      <div className="shimmer-profile-basic-info-wrap">
                        <ShimmerThumbnail rounded />
                      </div>
                    ) : (
                      <>
                        <div className="change-password-info-wrap">
                          {editProfile ? (
                            <>
                              <div className="login-option">
                                <form autoComplete="off">
                                  <InputField
                                    extraClass={
                                      errors?.currentPassword ? `error-msg` : ""
                                    }
                                    type={
                                      currentPasswordShown ? "text" : "password"
                                    }
                                    name="currentPassword"
                                    id="currentPassword"
                                    label={
                                      errors?.currentPassword
                                        ? errors.currentPassword
                                        : "Current Password"
                                    }
                                    disabled={!editProfile}
                                    value={currentPassword || ""}
                                    onChange={profileChangeHandler}
                                    passwordIcon={true}
                                    togglePassword={toggleCurrentPassword}
                                    iconDisplaye={currentPasswordShown}
                                  />

                                  <InputField
                                    extraClass={
                                      errors?.newPassword ? `error-msg` : ""
                                    }
                                    type={passwordShown ? "text" : "password"}
                                    name="newPassword"
                                    id="newPassword"
                                    label={
                                      errors?.newPassword
                                        ? errors.newPassword
                                        : "New Password"
                                    }
                                    disabled={!editProfile}
                                    value={newPassword || ""}
                                    onChange={profileChangeHandler}
                                    passwordIcon={true}
                                    togglePassword={togglePassword}
                                    iconDisplaye={passwordShown}
                                  />

                                  {newPassword && !passwordErrorMsg && (
                                    <InputField
                                      extraClass={
                                        errors?.confirmPassword
                                          ? `error-msg`
                                          : ""
                                      }
                                      type={
                                        confirmPasswordShown
                                          ? "text"
                                          : "password"
                                      }
                                      name="confirmPassword"
                                      id="confirmPassword"
                                      label={
                                        errors?.confirmPassword
                                          ? errors.confirmPassword
                                          : "Confirm Password"
                                      }
                                      disabled={!editProfile}
                                      value={confirmPassword || ""}
                                      onChange={profileChangeHandler}
                                      passwordIcon={true}
                                      togglePassword={toggleConfirmPassword}
                                      iconDisplaye={confirmPasswordShown}
                                    />
                                  )}
                                </form>
                                {passwordErrorMsg && (
                                  <div className="error-msg password-err-msg">
                                    <p> {passwordErrorMsg}</p>
                                  </div>
                                )}
                              </div>
                            </>
                          ) : (
                            <div className="profile-readonly-info-wrap">
                              <div className="profile-readonly-info">
                                <div className="profile-readonly-info-repeat">
                                  <p> Password </p>
                                  <span> ************************ </span>
                                </div>
                              </div>
                            </div>
                          )}
                        </div>
                      </>
                    )}
                  </div>
                </div>
              )}

              {shimmerLoading ? (
                <div className="shimmer-profile-basic-info-wrap">
                  <ShimmerThumbnail rounded />
                </div>
              ) : (
                <>
                  <div className="profile-basic-info-wrap change-password-info-wrap">
                    <div className="profile-info-header-wrapper">
                      <div className="profile-info-heading-wrap">
                        <h4> 2FA Security </h4>
                        <div className="profile-twofa-security-img-wrap">
                          <img src="./assets/icons/2fa-icon.png" alt="icon" />
                          <OverlayTrigger
                            placement="top"
                            delay={{ show: 250, hide: 400 }}
                            overlay={
                              <Tooltip id={`tooltip-up`}>
                                {(logedInUser?.enableOn == true ||
                                  logedInUser?.enableOn == "true") &&
                                (logedInUser?.twoFaRequest == "false" ||
                                  logedInUser?.twoFaRequest == false ||
                                  logedInUser?.twoFaRequest == null)
                                  ? "Disables 2FA for your account. This means you'll only need your password to sign in."
                                  : (logedInUser?.enableOn == false ||
                                      logedInUser?.enableOn == "false" ||
                                      logedInUser?.enableOn == null) &&
                                    (logedInUser?.twoFaRequest == true ||
                                      logedInUser?.twoFaRequest == "true")
                                  ? "Disables 2FA for your account. This means you'll only need your password to sign in."
                                  : "Enables 2FA for your account. This adds an extra layer of security by requiring a second verification code during login."}
                              </Tooltip>
                            }
                          >
                            <img
                              className="info-icon"
                              src="./assets/icons/info.svg"
                              alt="info-icon"
                            />
                          </OverlayTrigger>
                        </div>
                      </div>
                    </div>
                    {(logedInUser?.enableOn == true ||
                      logedInUser?.enableOn == "true") &&
                    (logedInUser?.twoFaRequest == "false" ||
                      logedInUser?.twoFaRequest == false ||
                      logedInUser?.twoFaRequest == null) ? (
                      <div
                        className={
                          !editProfile ? "profile-readonly-info-wrap" : ""
                        }
                      >
                        <div className="twofa-enable-main-wrapper">
                          <div className="twofa-enable-main-wrap">
                            <div className="twofa-enable-left-wrap">
                              <p>Disable 2FA</p>
                            </div>
                            <div className="postpaid-payment-right-wrap">
                              <div className="postpaid-toggle-button">
                                <label className="switch">
                                  <input
                                    type="checkbox"
                                    checked={isTwoFADisabled}
                                    onChange={desableReqHanlder}
                                  />
                                  <span className="slider round"></span>
                                </label>
                              </div>
                            </div>
                          </div>
                          {error ? (
                            <div className="error-msg">
                              <p> {errorMessage} </p>
                            </div>
                          ) : (
                            ""
                          )}
                        </div>
                      </div>
                    ) : (logedInUser?.enableOn == false ||
                        logedInUser?.enableOn == "false" ||
                        logedInUser?.enableOn == null) &&
                      (logedInUser?.twoFaRequest == true ||
                        logedInUser?.twoFaRequest == "true") ? (
                      <div className="profile-readonly-info-wrap1">
                        <div className="twofa-enable-main-wrapper">
                          <div className="twofa-desable-req-main-wrap">
                            <div className="twofa-enable-left-wrap">
                              <p className="disable-req-text">
                                Email has been Sent to disable
                              </p>
                            </div>

                            <img
                              src="./assets/icons/desable-req.png"
                              alt="icon"
                            />
                          </div>
                          <p
                            className="resend-email-text"
                            onClick={desableReqHanlder}
                          >
                            Resend email
                          </p>
                        </div>
                      </div>
                    ) : (
                      <div className="profile-readonly-info-wrap1">
                        <div className="twofa-enable-main-wrapper">
                          <div className="twofa-enable-main-wrap">
                            <div className="twofa-enable-left-wrap">
                              <p>Enable 2FA</p>
                            </div>
                            <div className="postpaid-payment-right-wrap">
                              <div className="postpaid-toggle-button">
                                <label className="switch">
                                  <input
                                    type="checkbox"
                                    checked={isTwoFAEnabled}
                                    onChange={handleToggleChange}
                                  />
                                  <span className="slider round"></span>
                                </label>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    )}
                  </div>
                </>
              )}
            </div>
          </div>
          {editProfile && (
            <div className="profile-changes-button container">
              <Button
                isLoading={isLoading}
                disabled={!anyChanges}
                label={anyChanges ? "Save Changes" : "Save Changes"}
                onClick={editProfileHandler}
              />
            </div>
          )}
        </div>
      </div>
    </>
  );
};

export default Profile;

import React, { Fragment, useEffect, useState } from "react";
import PercentageItem from "../dashboard/components/PercentageItem";
import { useSelector } from "react-redux";
import { useDispatch } from "react-redux";
import {
  addToCart,
  removeToCart,
  updateFlooringPercentage,
  updateMoppingPercentage,
  updateDustingPercentage,
} from "../../redux/features/cartSlice";
import ProductItem from "../dashboard/components/ProductItem";
import Button from "../UIComponents/Button";
import { useNavigate } from "react-router-dom";

const CartDrawer = ({ onHide, setShowCartDrawer }) => {
  const [enableBookBtn, setEnableBookBtn] = useState(false);
  const cartItems = useSelector((state) => state.allCart.carts);
  const percentageData = useSelector((state) => state.allCart);
  const dropdownValues = useSelector((state) => state.allDowpdown);
  const formData = useSelector((state) => state.formData);
  const serviceType = useSelector((state) => state.serviceType.serviceType);

  const [floorinPercentage, setFlooringPercentage] = useState(
    percentageData.flooringPercentage
  );

  const [moppingPercentage, setMoppingPercentage] = useState(
    percentageData.moppingPercentage
  );

  const [dustingPercentage, setDustingPercentage] = useState(
    percentageData.dustingPercentage
  );

  /// Add to cart
  const dispatch = useDispatch();
  const handleIncrement = (data) => {
    dispatch(addToCart(data));
  };

  const handleDecrement = (data) => {
    dispatch(removeToCart(data));
  };

  // Increment Flooring Percentage (Decrement Mopping Percentage)
  const incrementFlooringPercentage = () => {
    setFlooringPercentage((prevFlooringPercentage) => {
      if (prevFlooringPercentage < 100) {
        const newFlooringPercentage = prevFlooringPercentage + 1;
        const newMoppingPercentage = 100 - newFlooringPercentage;
        dispatch(updateFlooringPercentage(newFlooringPercentage));
        dispatch(updateMoppingPercentage(newMoppingPercentage));
        setMoppingPercentage(newMoppingPercentage); // Ensure both states are updated together
        return newFlooringPercentage;
      }
      return prevFlooringPercentage;
    });
  };

  // Decrement Flooring Percentage (Increment Mopping Percentage)
  const decrementFlooringPercentage = () => {
    setFlooringPercentage((prevFlooringPercentage) => {
      if (prevFlooringPercentage > 0) {
        const newFlooringPercentage = prevFlooringPercentage - 1;
        const newMoppingPercentage = 100 - newFlooringPercentage;
        dispatch(updateFlooringPercentage(newFlooringPercentage));
        dispatch(updateMoppingPercentage(newMoppingPercentage));
        setMoppingPercentage(newMoppingPercentage); // Ensure both states are updated together
        return newFlooringPercentage;
      }
      return prevFlooringPercentage;
    });
  };

  // Increment Mopping Percentage (Decrement Flooring Percentage)
  const incrementMoppingPercentage = () => {
    setMoppingPercentage((prevMoppingPercentage) => {
      if (prevMoppingPercentage < 100) {
        const newMoppingPercentage = prevMoppingPercentage + 1;
        const newFlooringPercentage = 100 - newMoppingPercentage;
        dispatch(updateMoppingPercentage(newMoppingPercentage));
        dispatch(updateFlooringPercentage(newFlooringPercentage));
        setFlooringPercentage(newFlooringPercentage); // Ensure both states are updated together
        return newMoppingPercentage;
      }
      return prevMoppingPercentage;
    });
  };

  // Decrement Mopping Percentage (Increment Flooring Percentage)
  const decrementMoppingPercentage = () => {
    setMoppingPercentage((prevMoppingPercentage) => {
      if (prevMoppingPercentage > 0) {
        const newMoppingPercentage = prevMoppingPercentage - 1;
        const newFlooringPercentage = 100 - newMoppingPercentage;
        dispatch(updateMoppingPercentage(newMoppingPercentage));
        dispatch(updateFlooringPercentage(newFlooringPercentage));
        setFlooringPercentage(newFlooringPercentage); // Ensure both states are updated together
        return newMoppingPercentage;
      }
      return prevMoppingPercentage;
    });
  };

  // Increment Flooring Percentage
  const incrementDustingPercentage = () => {
    setDustingPercentage((prevDustingPercentage) => {
      if (prevDustingPercentage < 100) {
        const newDustingPercentage = prevDustingPercentage + 1;
        dispatch(updateDustingPercentage(newDustingPercentage));
        return newDustingPercentage;
      }
      return prevDustingPercentage; // No change if it already is 100
    });
  };

  // Decrement Flooring Percentage
  const decrementDustingPercentage = () => {
    setDustingPercentage((prevDustingPercentage) => {
      if (prevDustingPercentage > 0) {
        const newDustingPercentage = prevDustingPercentage - 1;
        dispatch(updateDustingPercentage(newDustingPercentage));
        return newDustingPercentage;
      }
      return prevDustingPercentage; // No change if it already is 0
    });
  };

  const navigate = useNavigate();
  const goToBookingConfirmation = () => {
    if (
      serviceType == "Commercial" &&
      dropdownValues?.industry.name !== "Specialty Cleaning"
    ) {
      navigate("/services-commercial");
    } else if (
      serviceType == "Residential" &&
      dropdownValues?.industry.name !== "Specialty Cleaning"
    ) {
      navigate("/services");
    } else {
      navigate("/special-services");
    }

    onHide();
  };

  useEffect(() => {
    if (
      formData?.addressComplete &&
      // formData?.squareFeet &&
      formData?.scheduleDate &&
      formData?.scheduleTime
    ) {
      setEnableBookBtn(true);
    } else {
      setEnableBookBtn(false);
    }
  }, [formData, formData?.scheduleDate]);

  return (
    <>
      <div className="cart-drawer-main-wrapper">
        <div className="cart-drawer-main-wrap">
          <div className="cart-drawer-header">
            <h3> My Cart </h3>
            <div className="drawer-close-icon-wrap">
              <img
                onClick={() => onHide()}
                src="../assets/icons/close-icon.svg"
                alt="icon"
              />
            </div>
          </div>

          {cartItems?.length === 0 ? (
            <div className="noting-found-main-wrapper">
              <div className="noting-found-main-wrap nothing-found-cart-wrap">
                <img src="../assets/icons/empty-cart.png" alt="empty-cart" />
                <p>Nothing Found</p>
              </div>
            </div>
          ) : (
            <>
              <div className="cart-drawer-retail-wrap">
                <div className="cart-square-feet-wrap">
                  <h4>
                    {" "}
                    {dropdownValues?.industry.name},{" "}
                    {dropdownValues?.floors.name},{" "}
                    {dropdownValues?.bedrooms.name}{" "}
                  </h4>
                  <p> Square Feet of your Home </p>
                  <span> {percentageData?.squreFeetPercentage} Sq </span>
                </div>

                <div className="cart-square-image">
                  <img src="../assets/service-1.png" alt="img" />
                </div>
              </div>

              <div className="cart-drawer-item-wrapper">
                <div className="cart-drawer-item-wrap">
                  <div className="booking-cart-items-wrap">
                    <h5> Items </h5>
                    <div className="booking-cart-flooring-wrap">
                      <PercentageItem
                        image="../assets/icons/vacuum-icon.png"
                        category="Flooring:"
                        label="Vacuum Cleaning"
                        percentage={percentageData.flooringPercentage}
                        handleIncementPercentage={incrementFlooringPercentage}
                        handleDecrementPercentage={decrementFlooringPercentage}
                      />

                      <PercentageItem
                        image="../assets/icons/mopping-icon.png"
                        category="Flooring:"
                        label="Mopping or sweeping"
                        percentage={percentageData.moppingPercentage}
                        handleIncementPercentage={incrementMoppingPercentage}
                        handleDecrementPercentage={decrementMoppingPercentage}
                      />

                      <PercentageItem
                        image="../assets/dusting-slider.png"
                        category="Dusting:"
                        label=""
                        percentage={percentageData.dustingPercentage}
                        handleIncementPercentage={incrementDustingPercentage}
                        handleDecrementPercentage={decrementDustingPercentage}
                      />

                      {cartItems?.map((item, index) => {
                        return (
                          <Fragment key={index}>
                            <ProductItem
                              item={item}
                              handleIncrement={handleIncrement}
                              handleDecrement={handleDecrement}
                              setShowCartDrawer={setShowCartDrawer}
                              cartDrawer={true}
                            />
                          </Fragment>
                        );
                      })}
                    </div>
                  </div>
                </div>

                <div className="cart-booking-wrapper">
                  <div className="cart-button-wrap">
                    <Button
                      onClick={goToBookingConfirmation}
                      label="Continue"
                    />
                  </div>
                </div>
              </div>
            </>
          )}
        </div>
      </div>
    </>
  );
};

export default CartDrawer;

import React from "react";
import Lottie from "lottie-react";
import LoadingLottie from "../lottiefiles/loading.json";
import "./style.css";

const Button = ({ label, extraClass, icon, onClick, isLoading, disabled }) => {
  const buttonStyle = {
    // fontSize: size,
  };

  return (
    <button
      onClick={onClick}
      style={buttonStyle}
      className={`simple-button ${extraClass}`}
      disabled={isLoading || disabled}
    >
      {icon ? <img src={icon} alt="icon" /> : ""}
      {isLoading ? (
        <div className="button-loading-gif" data-testid="lottie">
          <Lottie animationData={LoadingLottie} loop={true} />
        </div>
      ) : (
        label
      )}
    </button>
  );
};

export default Button;

// redux/notificationDepSlice.js
import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  notificationDep: 0,
};

const notificationDepSlice = createSlice({
  name: "notificationDep",
  initialState,
  reducers: {
    setNotificationDep: (state, action) => {
      state.notificationDep = action.payload;
    },
  },
});

export const { setNotificationDep } = notificationDepSlice.actions;

export default notificationDepSlice.reducer;

import React, { useEffect, useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import { servicePost } from "../../helper/api";
import Button from "../UIComponents/Button";
import RoundButton from "../UIComponents/RoundButton";
import { useDispatch, useSelector } from "react-redux";
import { selectSubTotal } from "../../redux/features/selectors";
import { resetSpecificFormData } from "../../redux/features/formSlice";
import { resetSpecificDropdown } from "../../redux/features/dropdownSlice";
import { emptyCartItem } from "../../redux/features/cartSlice";
import { resetCoupon } from "../../redux/features/couponSlice";
import { setOrderBookingId } from "../../redux/features/bookingSlice";
import { setNotificationDep } from "../../redux/features/notificationDepSlice";
import { clearFlooringPrices } from "../../redux/features/flooringPriceSlice";

const SuccessChangeOrder = () => {
  const [paymentId, setPaymentId] = useState();
  const location = useLocation();
  const navigate = useNavigate();
  const dispatch = useDispatch();

  // get loggedInUser for Redux
  const logedInUser = useSelector((state) => state.user.userInfo);
  const couponData = useSelector((state) => state.coupon);
  const cartItems = useSelector((state) => state.allCart.carts);
  const percentageData = useSelector((state) => state.allCart);
  const dropdownValues = useSelector((state) => state.allDowpdown);
  const formData = useSelector((state) => state.formData);
  const serviceType = useSelector((state) => state.serviceType.serviceType);
  const otpAccess = formData.otpAccess == "send-otp" ? true : false;
  const notificationDep = useSelector(
    (state) => state.notificationDep.notificationDep
  );

  // get AuthToken from localStorage
  const authToken = localStorage.getItem("token");
  const notification = "";

  // Get subTotal cartItem price + flooring + dusting Price
  const TexFees = 30;
  const subtotalSelector = selectSubTotal(couponData?.couponDiscountPercentage);
  const { subTotal, discountAmount, cartItemsPrice, totalItemPrice } =
    useSelector(subtotalSelector);
  const totalAmountToPay = subTotal + TexFees;

  const currentDate = new Date();
  const bookingDate = currentDate.toLocaleDateString();
  const bookingTime = currentDate.toLocaleTimeString();

  useEffect(() => {
    const query = new URLSearchParams(location.search);
    const sessionId = query.get("session_id");
    console.log("sessionId", sessionId);

    if (sessionId) {
      // Fetch the checkout session from Stripe using the session ID
      const fetchCheckoutSession = async () => {
        try {
          const response = await servicePost(
            `booking/addBookingData`,
            {
              paymentIntent: null,
              sessionId: sessionId,
              userPaymentId: "",
              accountId: logedInUser?.id,
              couponCode: couponData?.couponCode ? couponData?.couponCode : "",
              serviceType: serviceType,
              industry: dropdownValues?.industry?.name,
              floors: dropdownValues?.floors?.name,
              bedrooms: dropdownValues?.bedrooms?.name,
              bookingId: "",
              squireFeet:
                dropdownValues?.industry?.name !== "Specialty Cleaning"
                  ? percentageData?.squreFeetPercentage
                  : null,
              contactPersonName: formData?.contactname,
              contactPersonNumber: formData?.contactnumber,
              vacummCleaning: percentageData.flooringPercentage,
              moppingCleaning: percentageData.moppingPercentage,
              vacummCleaningPrice: percentageData?.flooringPrice.toFixed(2),
              moppingCleaningPrice: percentageData?.moppingPrice.toFixed(2),
              dusting: percentageData.dustingPercentage,
              dustingPrice: percentageData?.dustingPrice.toFixed(2),
              recurring: "",
              address: formData?.address,
              city: dropdownValues?.city?.name,
              postalCode: formData?.postalcode,
              scheduleDate: formData?.scheduleDate,
              scheduleTime: formData?.scheduleTime,
              paymentMethod: "Credit Card",
              price: cartItemsPrice,
              discount: discountAmount.toFixed(2),
              subTotal: subTotal.toFixed(2),
              fees: TexFees,
              total: totalAmountToPay.toFixed(2),
              companyAddress: "Company Address",
              bookingStatus: "Processing",
              bookingTime: bookingTime,
              bookingDate: bookingDate,
              cleanerPartnerStatus: "",
              assignedTime: "",
              assignedDate: "",
              arrivedStatus: "",
              arrivedTime: "",
              arrivedDate: "",
              otp: "",
              otpAccess: otpAccess,
              cleanigServiceStatus: "",
              reviewReating: "",
              reviewText: "",
              cartItems: cartItems,
            },
            "Order Created",
            authToken
          );

          const paymentId = response.data;

          if (response.data) {
            dispatch(emptyCartItem());
            dispatch(resetCoupon());
            dispatch(
              resetSpecificFormData([
                "squareFeet",
                "scheduleDate",
                "scheduleTime",
                "sessionStartTime",
                "contactname",
                "contactnumber",
                "otpAccess",
                "paywith",
              ])
            );
            dispatch(clearFlooringPrices());

            dispatch(resetSpecificDropdown(["industry", "floors", "bedrooms"]));
            dispatch(setOrderBookingId(paymentId));
            dispatch(setNotificationDep(notificationDep + 1));
            setPaymentId(paymentId);

            navigate(`/tracking-order/${paymentId}`);
            // navigate("/tracking-order", {
            //   state: {
            //     bookingId: paymentId,
            //   },
            // });
          }

          // Process the checkout session data as needed
        } catch (error) {
          console.error("Error fetching checkout session:", error);
        }
      };

      fetchCheckoutSession();
    }
  }, [location.search]);

  const goToTrackOrder = () => {
    if (paymentId) {
      dispatch(setOrderBookingId(paymentId));
      navigate(`/tracking-order/${paymentId}`);
      // navigate("/tracking-order", {
      //   state: {
      //     bookingId: paymentId,
      //   },
      // });
    } else {
      window.location.reload();
    }
  };

  return (
    <div className="success-page-wrapper">
      <div className="success-page-wrap">
        <h3>Thank You Cleaning With Us!</h3>

        <p className="confirm-payment-msg">Your payment was successful.</p>
        <div className="success-track-order-btn">
          <RoundButton onClick={goToTrackOrder} label="Track Order" />
        </div>
      </div>
    </div>
  );
};

export default SuccessChangeOrder;

import React, { useState } from "react";
import validator from "validator";

import "react-phone-number-input/style.css";
import PhoneInput from "react-phone-number-input";

import "./login.css";
import Button from "../UIComponents/Button";
import InputField from "../UIComponents/InputField";
import { useNavigate } from "react-router-dom";
import ConfirmationModal from "../modals/ConfirmationModal";
import { servicePost } from "../../helper/api";

const Signup = () => {
  const [isLoading, setIsLoading] = useState(false);
  const [showConfirmationModal, setShowConfirmationModal] = useState(false);
  const [passwordShown, setPasswordShown] = useState(false);
  const [confirmPasswordShown, setConfirmPasswordShown] = useState(false);
  const [phonenumber, setPhoneNumber] = useState();
  const [errorMessage, setErrorMessage] = useState();

  const togglePassword = () => {
    setPasswordShown(!passwordShown);
  };

  const toggleConfirmPassword = () => {
    setConfirmPasswordShown(!confirmPasswordShown);
  };

  const [formData, setFormData] = useState({
    email: "",
    fullName: "",
    password: "",
    confirmPassword: "",
  });

  const { email, password, fullName, confirmPassword } = formData;

  // Error Validation
  const [errors, setErrors] = useState({
    email: "",
    password: "",
    username: "",
    confirmPassword: "",
  });

  const errorMessages = {
    username: "Username is required",
    email: "Email is required",
    password: "Password is required",
    confirmPassword: "confirmPassword is required",
    invalidEmail: "Please enter a valid email",
    invalidPassword: "Please enter a valid password",
  };

  // get All InputValues
  const inputEventHandler = (event) => {
    const { name, value } = event.target;

    setFormData((oldValue) => {
      return {
        ...oldValue,
        [name]: value,
      };
    });

    if (name == "password") {
      // Validate password
      if (
        !validator.isStrongPassword(value, {
          minLength: 8,
          minLowercase: 1,
          minUppercase: 1,
          minNumbers: 1,
          minSymbols: 1,
        })
      ) {
        setErrors((prevErrors) => ({
          ...prevErrors,
          password: errorMessages.invalidPassword,
        }));
        setErrorMessage(
          "Password must contain: min 8 Characters, 1 uppercase & 1 lowercase letter, 1 number and 1 special character"
        );
        return;
      } else {
        setErrors((prevErrors) => ({
          ...prevErrors,
          password: "",
        }));

        setErrorMessage("");
      }
    }
    setErrors({ ...errors, [name]: "" });
  };

  // Signup Request handler
  const signupReqHandler = async () => {
    try {
      const requiredFields = ["email", "password", "confirmPassword"];
      const newErrors = {};

      requiredFields.forEach((field) => {
        if (!formData[field] || !formData[field].trim()) {
          newErrors[field] = errorMessages[field];
        }
      });

      //Check if password Empty
      if (password === "") {
        newErrors["password"] = errorMessages["password"];
      }

      if (Object.keys(newErrors).length > 0) {
        setErrors((prevErrors) => ({ ...prevErrors, ...newErrors }));
        return;
      }

      // Validate email
      if (!validator.isEmail(email)) {
        setErrors((prevErrors) => ({
          ...prevErrors,
          email: errorMessages.invalidEmail,
        }));
        return;
      } else {
        setErrors((prevErrors) => ({
          ...prevErrors,
          email: "",
        }));
      }

      // Validate password
      if (
        !validator.isStrongPassword(password, {
          minLength: 8,
          minLowercase: 1,
          minUppercase: 1,
          minNumbers: 1,
          minSymbols: 1,
        })
      ) {
        setErrors((prevErrors) => ({
          ...prevErrors,
          password: errorMessages.invalidPassword,
        }));
        return;
      } else {
        setErrors((prevErrors) => ({
          ...prevErrors,
          password: "",
        }));
      }

      // Check if passwords match
      if (password !== confirmPassword) {
        setErrors((prevErrors) => ({
          ...prevErrors,
          confirmPassword: "Passwords do not match. Please try again.",
        }));
        return;
      } else {
        setErrors((prevErrors) => ({
          ...prevErrors,
          confirmPassword: "",
        }));
      }

      setIsLoading(true);
      const signupUser = await servicePost(
        "auth/signIn",
        {
          email: email.toLowerCase(),
          password: password,
          fullname: fullName,
          phonenumber: phonenumber,
        },
        {}
        //csrfTokenState
      );

      console.log("signupUser", signupUser);
      // let signupUser = await servicePost(`signIn`, formData);
      if (signupUser.message == "SUCCESS") {
        // setModalShow(true);
        /// setReqLoader(false);
        setIsLoading(false);
        setShowConfirmationModal(true);
        setTimeout(function () {
          navigate("/login");
          setShowConfirmationModal(false);
        }, 3000);
      }
    } catch (error) {
      setIsLoading(false);
      console.log("error.response", error);
      // console.log("error", error.response?.data.message);
      //setReqLoader(false);
      setErrorMessage(error.response?.data.message);
      // setErrorMessage(error.response?.data.message);
      setTimeout(() => {
        setErrorMessage("");
      }, 5000);
      return;
    }
  };

  const navigate = useNavigate();

  // go to forget password
  const goToLogin = () => {
    navigate("/login");
  };

  // send Link to the Registered Email
  const sendLinkResgiteredEmail = () => {
    setShowConfirmationModal(true);
    // setTimeout(() => {
    //   setShowConfirmationModal(false);
    // }, 3000);
  };

  return (
    <>
      <ConfirmationModal
        show={showConfirmationModal}
        onHide={() => setShowConfirmationModal(false)}
        icon="./assets/confirm-tick.png"
        type="Account Verfiy"
        content="Verfication Link to your email address"
      />
      <div className="login-main-wrapper signup-main-wrapper">
        <div className="login-main-wrap container">
          <h3> What's your email and phone number? </h3>

          <div className="login-form-main-wrap">
            <div className="login-option">
              <form autoComplete="off">
                <input
                  type="text"
                  style={{ display: "none" }}
                  autoComplete="username"
                />
                <input
                  type="password"
                  style={{ display: "none" }}
                  autoComplete="new-password"
                />
                <InputField
                  type="text"
                  name="fullName"
                  id="fullName"
                  onChange={inputEventHandler}
                  value={fullName}
                  label="Full name"
                  passwordIcon={false}
                />

                <InputField
                  extraClass={
                    errors?.email ? `error-msg` : email ? "active" : ""
                  }
                  type="text"
                  name="email"
                  id="email"
                  value={email}
                  onChange={inputEventHandler}
                  label={
                    errors?.email ? errors.email : "Enter Your email address"
                  }
                  passwordIcon={false}
                />

                <div className="field phone-number-input">
                  <PhoneInput
                    country="CA"
                    placeholder="Enter phone number"
                    defaultCountry="CA"
                    countryCallingCodeEditable={false}
                    value={phonenumber}
                    limitMaxLength={10}
                    international={false} // Restrict to local format
                    onChange={setPhoneNumber}
                  />
                </div>

                <InputField
                  extraClass={errors?.password ? `error-msg` : ""}
                  type={passwordShown ? "text" : "password"}
                  name="password"
                  id="password"
                  onChange={inputEventHandler}
                  label={
                    errors?.password ? errors.password : "Enter your Password"
                  }
                  passwordIcon={true}
                  togglePassword={togglePassword}
                  iconDisplaye={passwordShown}
                />

                <InputField
                  extraClass={errors?.confirmPassword ? `error-msg` : ""}
                  type={confirmPasswordShown ? "text" : "password"}
                  name="confirmPassword"
                  id="confirmPassword"
                  label={
                    errors?.confirmPassword
                      ? errors.confirmPassword
                      : "Re-Enter your Password"
                  }
                  onChange={inputEventHandler}
                  passwordIcon={true}
                  togglePassword={toggleConfirmPassword}
                  iconDisplaye={confirmPasswordShown}
                />
              </form>
              <div className="login-button">
                <Button
                  onClick={signupReqHandler}
                  isLoading={isLoading}
                  label="Continue"
                />
              </div>

              {errorMessage ? (
                <div className="error-msg">
                  <p> {errorMessage} </p>
                </div>
              ) : (
                <div
                  style={{ justifyContent: "center" }}
                  className="login-text"
                >
                  <p onClick={goToLogin}>
                    I already have an Account.
                    <span> Login Now </span>
                  </p>
                </div>
              )}

              <div className="login-with-google-main-wrapper">
                <div className="full ordivider">
                  <span>Or</span>
                </div>

                <div className="login-with-google-btn-wrap">
                  <Button
                    extraClass="gray-btn"
                    label="Continue with Google"
                    icon="./assets/icons/google-icon.png"
                  />
                </div>
                <div className="login-screen-description">
                  <p>
                    By proceeding, you consent to receive calls and SMS
                    messages, including those sent by automated means, from YFOS
                    and its affiliates at the number provided.
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default Signup;

// redux/bookingSlice.js
import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  bookingId: null,
};

const bookingSlice = createSlice({
  name: "booking",
  initialState,
  reducers: {
    setOrderBookingId: (state, action) => {
      state.bookingId = action.payload;
    },
  },
});

export const { setOrderBookingId } = bookingSlice.actions;

export default bookingSlice.reducer;

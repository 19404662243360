import React, { useState, useRef, useEffect } from "react";
import "./TimePicker.css";
import { updateFormData } from "../../../redux/features/formSlice";
import { useDispatch, useSelector } from "react-redux";
import { setError } from "../../../redux/features/errorSlice";
import moment from "moment";
import { servicePost } from "../../../helper/api";
import TimeSlotModal from "../../modals/TimeSlotModal";

const TimeSlot = ({ disabled }) => {
  const [isLoading, setIsLoading] = useState(true);
  const [showSlotModal, setShowSlotModal] = useState(false);
  const [timeSlots, setTimeSlots] = useState([]);
  const [isOpen, setIsOpen] = useState(false);
  const [selectedTime, setSelectedTime] = useState("");
  const [openUpwards, setOpenUpwards] = useState(false);
  const timePickableRef = useRef(null);
  const timePickerRef = useRef(null);
  const formData = useSelector((state) => state.formData);
  const errors = useSelector((state) => state.formErrors);
  const dispatch = useDispatch();

  // get AuthToken from localStorage
  const authToken = localStorage.getItem("token");
  const notification = "";

  // formmated scheduleDate 2024-10-02 this formate
  const formatToYYYYMMDD = (dateStr) => {
    if (!dateStr) return "";
    const dateObj = new Date(dateStr);
    if (isNaN(dateObj)) {
      console.error("Invalid date string:", dateStr);
      return "";
    }
    const year = dateObj.getFullYear();
    const month = String(dateObj.getMonth() + 1).padStart(2, "0");
    const day = String(dateObj.getDate()).padStart(2, "0");
    return `${year}-${month}-${day}`;
  };

  const schedualDateFormate = formatToYYYYMMDD(formData?.scheduleDate);

  const handleTimePickableFocus = () => {
    setIsOpen(true);
    checkDropdownPosition();

    const handleClickOutside = (event) => {
      if (
        timePickableRef.current &&
        !timePickableRef.current.contains(event.target)
      ) {
        setIsOpen(false);
        document.removeEventListener("mousedown", handleClickOutside);
      }
    };

    document.addEventListener("mousedown", handleClickOutside);
  };

  const checkDropdownPosition = () => {
    const inputRect = timePickableRef.current.getBoundingClientRect();
    const dropdownHeight = timePickerRef.current?.offsetHeight || 0;
    const spaceBelow = window.innerHeight - inputRect.bottom;
    const spaceAbove = inputRect.top;

    if (spaceBelow < dropdownHeight && spaceAbove > dropdownHeight) {
      setOpenUpwards(true); // Open upwards
    } else {
      setOpenUpwards(false); // Open downwards
    }
  };

  const handleTimeSelect = (time) => {
    setSelectedTime(time);
    dispatch(updateFormData({ field: "scheduleTime", value: time }));
    dispatch(setError({ field: "time", value: false }));
    setIsOpen(false);
  };

  // Check Time Slot Availible
  const checkTimeSlotHandler = () => {
    if (!isLoading && timeSlots.length === 0) {
      setShowSlotModal(true);
    }
  };

  // Get Time Slot from API
  const getTimeSlots = async () => {
    setIsLoading(true);
    try {
      const timeSlotRes = await servicePost(
        "booking/getScheduleData",
        {
          scheduleDate: schedualDateFormate,
        },
        notification,
        authToken
      );
      const data = timeSlotRes.data;

      if (data) {
        const allTimeSlots = data.flatMap((item) => {
          // Parse the times string into a JSON array
          const timesArray = JSON.parse(item.times);

          // Map through the parsed times array and format each time range
          return timesArray
            .filter((time) => time.startTime && time.endTime) // Check if both startTime and endTime are not empty
            .map((time) => {
              const startTime = moment(time.startTime, "HH:mm").format(
                "hh:mm A"
              );
              const endTime = moment(time.endTime, "HH:mm A").format("hh:mm A");
              return `${startTime} - ${endTime}`;
            });
        });

        // Sort allTimeSlots by the starting time
        const sortedTimeSlots = allTimeSlots.sort((a, b) => {
          const startA = moment(a.split(" - ")[0], "hh:mm A");
          const startB = moment(b.split(" - ")[0], "hh:mm A");
          return startA - startB;
        });

        setTimeSlots(sortedTimeSlots.length > 0 ? sortedTimeSlots : []);
      } else {
        setTimeSlots([]);
      }
    } catch (error) {
      console.log("error", error);
      setTimeSlots([]);
    } finally {
      setIsLoading(false); // End loading
    }
  };

  useEffect(() => {
    getTimeSlots();
  }, [formData?.scheduleDate]);

  return (
    <>
      <TimeSlotModal
        show={showSlotModal}
        onHide={() => setShowSlotModal(false)}
        showCloseIcon={true}
      />

      <div className="time-input-wrap" ref={timePickableRef}>
        <label className="time-input-label">
          <img
            src="../assets/icons/time.svg"
            alt="icon"
            className="timepicker-icon"
          />
          <p>Time</p>
          <input
            className={`${errors.time ? "error-msg" : ""} ${
              disabled ? "time-pickable disabled" : "time-pickable"
            }`}
            disabled={disabled}
            type="text"
            placeholder="Select Time"
            value={formData.scheduleTime}
            onFocus={handleTimePickableFocus}
            onClick={checkTimeSlotHandler}
            readOnly
          />
        </label>
        {isOpen && timeSlots.length > 0 && (
          <div
            ref={timePickerRef}
            className={`time-picker ${openUpwards ? "open-up" : "open-down"}`}
          >
            {timeSlots.map((slot, index) => (
              <div
                key={index}
                className="time-option"
                onClick={() => handleTimeSelect(slot)}
              >
                {slot}
              </div>
            ))}
          </div>
        )}
      </div>
    </>
  );
};

export default TimeSlot;

import React, { useState } from "react";
import Modal from "react-bootstrap/Modal";
import "./modal.css";

const ConfirmationModal = (props) => {
  return (
    <Modal
      {...props}
      size="lg"
      dialogClassName="confirmation-modal-main-wrapper"
      aria-labelledby="contained-modal-title-vcenter"
      centered
    >
      <Modal.Body>
        <div className="confirm-modal-main-wrapper">
          <div className="confirm-modal-main-wrap">
            <div className="modal-logo-wrap">
              <img src="../assets/modal-logo.png" alt="modal-logo" />
            </div>
            {props?.showCloseIcon && (
              <div className="close-icon-wrap">
                <img
                  onClick={() => props.onHide()}
                  src="../assets/icons/close-icon.svg"
                  alt="icon"
                />
              </div>
            )}

            <div className="confirm-tick-image">
              {props.icon ? (
                <img src={props.icon} alt="icon" />
              ) : (
                <h4> ****</h4>
              )}
            </div>

            <div className="confirm-modal-content-wrap">
              <h3> {props.type} </h3>
              <p> {props.content} </p>
            </div>
          </div>
        </div>
      </Modal.Body>
    </Modal>
  );
};
export default ConfirmationModal;

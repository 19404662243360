import React, { useState, useEffect } from "react";
import "./twofa.css";
import TwofaProcessBar from "./TwofaProcessBar";
import Button from "../../UIComponents/Button";
import { useNavigate } from "react-router-dom";
import { CopyToClipboard } from "react-copy-to-clipboard";
import { servicePost } from "../../../helper/api";
import { useDispatch, useSelector } from "react-redux";
import { setNotificationDep } from "../../../redux/features/notificationDepSlice";
import { ShimmerThumbnail } from "react-shimmer-effects";

const TwofaScan = () => {
  const [shimmerLoading, setShimmerLoading] = useState(true);
  const [activeStep, setActiveStep] = useState(2);
  const [selectedReason, setSelectedReason] = useState("");
  const [termError, setTermError] = useState(false);
  const [isCopied, setIsCopied] = useState(false);
  const user = useSelector((state) => state.user.userInfo);
  const notificationDep = useSelector(
    (state) => state.notificationDep.notificationDep
  );

  // get AuthToken from localStorage
  const authToken = localStorage.getItem("token");
  const notification = "";

  const [twoFaUrl, setTwoFaUrl] = useState(
    localStorage.getItem("twoFaUrl") || ""
  );
  const [setupKey, setSetupKey] = useState(
    localStorage.getItem("setupKey") || ""
  );
  const handleCopy = () => {
    setIsCopied(true);
    setTimeout(() => {
      setIsCopied(false);
    }, 2000);
  };

  const dispatch = useDispatch();

  // Create Twofa
  const createUrl = async () => {
    try {
      const getUrl = await servicePost(
        "2fa/createtwofa",
        {
          email: user?.email,
        },
        notification,
        authToken
      );
      setShimmerLoading(false);
      setTwoFaUrl(getUrl.data);
      setSetupKey(getUrl.setupKey);
      dispatch(setNotificationDep(notificationDep + 1));
      localStorage.setItem("twoFaUrl", getUrl.data);
      localStorage.setItem("setupKey", getUrl.setupKey);
    } catch (error) {
      setShimmerLoading(false);
      console.error("Error creating 2fa:", error);
    }
  };

  useEffect(() => {
    if (
      !localStorage.getItem("twoFaUrl") &&
      !localStorage.getItem("setupKey") &&
      user?.email
    ) {
      createUrl();
    }
  }, [user?.email]);

  // Handle Checkbox
  const handleReasonChange = (event) => {
    if (selectedReason === event.target.value) {
      setSelectedReason("");
    } else {
      setTermError(false);
      setSelectedReason(event.target.value);
    }
  };
  const navigate = useNavigate();

  // After Scan Qr Code Go Twofa Code page
  const goToTwofaCode = () => {
    if (selectedReason) {
      setTermError(false);
      navigate("/twofa-code");
    } else {
      setTermError(true);
    }
  };

  const goBack = () => {
    navigate(-1);
  };
  return (
    <div className="profile-twofa-main-wrapper">
      <TwofaProcessBar activeStep={activeStep} />

      <div className="profile-twofa-main-wrap">
        <div className="twofa-inner-wrapper">
          <div className="twofa-head-main-wrap">
            <img
              onClick={goBack}
              src="./assets/icons/back-arrow.png"
              alt="icon"
            />

            <h3> QR Scan </h3>
          </div>
          <div className="twfa-scan-text">
            <p> Scan the QR code using Google Authenticator for the OTP</p>
          </div>

          <div className="authenticator-main-wrapper">
            {!twoFaUrl ? (
              <div className="shimmer-scaner-image-wrap">
                <ShimmerThumbnail rounded />
              </div>
            ) : (
              <div className="scaner-image-wrap">
                <img src={twoFaUrl} alt="icon" />
              </div>
            )}

            <div className="secert-key-wrap">
              <p> {setupKey} </p>
              <CopyToClipboard text={setupKey} onCopy={handleCopy}>
                <img src="./assets/icons/copy.svg" alt="icon" />
              </CopyToClipboard>
              {isCopied && <span className="copied-text">Copied!</span>}
            </div>
          </div>
        </div>

        <div className="twofa-footer-wrapper">
          <div className="twofa-footer-wrap">
            <form>
              <div className="twofa-scan-term-checkbox">
                <label className="checkbox-container twofa-checkbox-container">
                  <input
                    type="checkbox"
                    id="terms-condition"
                    name="cancel-reason"
                    value="terms-condition"
                    onChange={handleReasonChange}
                    checked={selectedReason === "terms-condition"}
                  />
                  <span
                    className={termError ? "error-msg checkmark" : "checkmark"}
                  ></span>
                </label>
                <p>
                  Enable Google 2FA by downloading Google Authenticator, going
                  to your account settings, and selecting “Enable Google 2FA.”
                  Scan the QR code to link your account and secure your logins.
                </p>
              </div>
            </form>

            <Button
              extraClass={!selectedReason ? "disabled-button" : ""}
              onClick={goToTwofaCode}
              label="Proceed"
            />
          </div>
        </div>
      </div>
    </div>
  );
};

export default TwofaScan;

import React from "react";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import TimePicker from "./TimePicker"; // Assuming you have a TimePicker component
import Button from "../../UIComponents/Button"; // Assuming you have a Button component
import SpecialityItem from "../../cardItem/SpecialityItem"; // Assuming you have a SpecialityItem component
import moment from "moment";
import TimeSlot from "./TimeSlot";

const SpecialServiceDetail = ({
  changeOrderPath,
  orderData,
  specialServicesData,
  selectedDate,
  handleDateChange,
  enableBookBtn,
  goToBookingConfirmation,
  addToCartHandler,
  removeFromCartHandler,
  errors,
  summerItemLength,
}) => (
  <div className="selected-service-detail-left special-service-detail-left">
    <div className="service-items-main-wrap extra-service-item-wrap">
      <h5> Speciality Services </h5>
      <SpecialityItem
        summerItemLength={summerItemLength}
        extraData={specialServicesData}
        addItems={addToCartHandler}
        removeItem={removeFromCartHandler}
      />
    </div>
    <div className="schedule-main-wrapper specaility-schedule-wrap">
      <div
        className="schedule-main-wrap"
        style={changeOrderPath ? { pointerEvents: "none", opacity: 0.6 } : {}}
      >
        <h5> Schedule </h5>

        <div className="date-and-time-wrapper">
          {changeOrderPath ? (
            <div className="date-input-wrap">
              <DatePicker
                minDate={new Date()}
                maxDate={moment().add(30, "days").toDate()}
                selected={orderData?.scheduleDate}
                // onChange={(date) => handleDateChange(date)}
                customInput={
                  <div className="custom-date-input">
                    <img
                      src="./assets/icons/date.svg"
                      alt="icon"
                      className="datepicker-icon"
                    />
                    <p>Date</p>
                    <input
                      className={errors?.date ? "error-msg" : ""}
                      type="text"
                      placeholder="Select date"
                      value={
                        orderData?.scheduleDate
                          ? moment(orderData?.scheduleDate).format(
                              "DD MMM YYYY"
                            )
                          : ""
                      }
                      readOnly
                    />
                  </div>
                }
              />
            </div>
          ) : (
            <div className="date-input-wrap">
              <DatePicker
                minDate={new Date()}
                maxDate={moment().add(30, "days").toDate()}
                selected={selectedDate}
                onChange={(date) => handleDateChange(date)}
                customInput={
                  <div className="custom-date-input">
                    <img
                      src="./assets/icons/date.svg"
                      alt="icon"
                      className="datepicker-icon"
                    />
                    <p>Date</p>
                    <input
                      className={errors?.date ? "error-msg" : ""}
                      type="text"
                      placeholder="Select date"
                      value={
                        selectedDate
                          ? moment(selectedDate).format("DD MMM YYYY")
                          : ""
                      }
                      readOnly
                    />
                  </div>
                }
              />
            </div>
          )}

          <TimeSlot disabled={!selectedDate} />
        </div>
      </div>
      <div className="book-button-wrapper">
        <Button
          extraClass={!enableBookBtn ? "disabled-button" : ""}
          onClick={goToBookingConfirmation}
          // isLoading={isLoading}
          label="Book"
        />
      </div>
    </div>
  </div>
);

export default SpecialServiceDetail;

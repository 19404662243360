import React, { useState } from "react";

const StartRating = ({ onRatingChange }) => {
  const [rating, setRating] = useState(null);

  const handleRatingClick = (newRating) => {
    setRating(newRating);
    onRatingChange(newRating);
  };

  return (
    <div className="rating-main-wrapper">
      {[...Array(5)].map((star, index) => {
        const currentRate = index + 1;
        return (
          <label key={index}>
            <input
              type="radio"
              name="rating"
              value={currentRate}
              onClick={() => handleRatingClick(currentRate)}
            />
            <svg
              xmlns="http://www.w3.org/2000/svg"
              width="23.064"
              height="22.365"
              viewBox="0 0 23.064 22.365"
              aria-label={`Star ${currentRate}`}
            >
              <path
                id="star"
                d="M1.216,11.375l3.261,2.382L3.239,17.592A2.957,2.957,0,0,0,7.8,20.885l3.189-2.347,3.19,2.344a2.956,2.956,0,0,0,4.565-3.29l-1.239-3.836,3.261-2.382A2.956,2.956,0,0,0,19.03,6.033H15.024L13.808,2.244a2.956,2.956,0,0,0-5.63,0L6.963,6.033h-4A2.956,2.956,0,0,0,1.22,11.375Z"
                transform="translate(0.537 0.31)"
                fill={currentRate <= rating ? "#72BBBB" : "none"}
                stroke="#e2e2e2"
                strokeWidth="1"
              />
            </svg>
          </label>
        );
      })}
    </div>
  );
};

export default StartRating;

import React, { useState, useEffect, useRef } from "react";
import Button from "../../UIComponents/Button";
import ConfirmationModal from "../../modals/ConfirmationModal";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import { jwtDecode } from "jwt-decode";
import { servicePost } from "../../../helper/api";
import { updateUserInfo } from "../../../redux/features/userSlice";
import { useDispatch } from "react-redux";

function useQuery() {
  const { search } = useLocation();
  return React.useMemo(() => new URLSearchParams(search), [search]);
}

const TwoFaDisable = () => {
  const [isLoading, setIsLoading] = useState(false);
  const [showConfirmationModal, setShowConfirmationModal] = useState(false);
  const [checkVerified, setCheckVerified] = useState(false);
  const [error, setError] = useState(null);

  const dispatch = useDispatch();
  const navigate = useNavigate();
  const query = useQuery();
  const token = query.get("token");
  const { id } = useParams();

  const apiCallMade = useRef(false);

  useEffect(() => {
    if (token && !apiCallMade.current) {
      apiCallMade.current = true; // Set the flag to prevent further calls
      verifyEmailToken(token);
    } else if (!token) {
      setError("Invalid or missing token.");
    }
  }, [token]);

  const verifyEmailToken = async (token) => {
    try {
      const decoded = jwtDecode(token);
      const accountId = decoded?.data?.id;
      const currentTime = Date.now() / 1000;

      if (decoded.exp < currentTime) {
        console.warn("Access token expired");
        setError("Your Token Expired. Please make Request Again");
        setCheckVerified(false);
      } else {
        const response = await servicePost("2fa/twofadisable", {
          accountId: accountId,
        });

        if (response.isExpired) {
          setError("Your Token Expired. Please make Request Again");
          setCheckVerified(false);
        } else {
          setCheckVerified(true);
          dispatch(updateUserInfo({ enableOn: false, twoFaRequest: false }));
        }
      }
    } catch (err) {
      console.error("Error verifying token:", err);
      setError("An error occurred during token verification.");
      setCheckVerified(false);
    }
  };

  const goToProfile = () => {
    navigate("/profile");
  };

  return (
    <>
      <ConfirmationModal
        show={showConfirmationModal}
        onHide={() => setShowConfirmationModal(false)}
        icon="./assets/confirm-tick.png"
        type="Sign up"
        content="Your Account Successfully Created"
        showCloseIcon={true}
      />

      {error ? (
        <div className="login-main-wrapper email-confirmation-wrapper">
          <div className="login-main-wrap container">
            <div className="email-confirmation-icon">
              <img src="./assets/icons/expired-token.png" alt="icon" />
            </div>
            <h3> Disable 2FA </h3>
            <p>{error}</p>
            <div className="login-button">
              <Button
                onClick={goToProfile}
                isLoading={isLoading}
                label="Continue"
              />
            </div>
          </div>
        </div>
      ) : checkVerified ? (
        <div className="login-main-wrapper email-confirmation-wrapper">
          <div className="login-main-wrap container">
            <div className="email-confirmation-icon">
              <img src="./assets/icons/email-confirmation.png" alt="icon" />
            </div>
            <h3>Disable 2FA</h3>
            <p>
              The two-factor authentication has been disabled for your account
            </p>
            <div className="login-button">
              <Button
                onClick={goToProfile}
                isLoading={isLoading}
                label="Continue"
              />
            </div>
          </div>
        </div>
      ) : (
        <div className="login-main-wrapper email-confirmation-wrapper">
          <div className="login-main-wrap container">
            <div className="email-confirmation-icon">
              <img src="./assets/icons/expired-token.png" alt="icon" />
            </div>
            <h3>Disable 2FA</h3>
            <p>Your Token Expired. Please make Request Again</p>
            <div className="login-button">
              <Button
                onClick={goToProfile}
                isLoading={isLoading}
                label="Continue"
              />
            </div>
          </div>
        </div>
      )}
    </>
  );
};

export default TwoFaDisable;
